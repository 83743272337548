import React from "react";
import TextComponent from "../Text/TextComponent";
var LightBoldText = React.forwardRef(function (props, ref) {
    var render = function () {
        var _a;
        var content = props.children;
        if ((props === null || props === void 0 ? void 0 : props.boldText) != null) {
            content = content.replace(props.boldText, '{ACTION}');
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(TextComponent, { className: "font-poppins text-left text-sm text-c-text1FromDesign", hasActions: true, actionClasses: ['font-semibold text-black'], actionOnClicks: [], actionReplacements: [(_a = props === null || props === void 0 ? void 0 : props.boldText) !== null && _a !== void 0 ? _a : ''], title: content, dangerouslySetInnerHTML: { __html: 'its there' } })));
    };
    return render();
});
export default LightBoldText;
