import React from "react";
var LightSubmitButtonWithIcon = function (props) {
    var _a;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : "submit";
    var disableCls = props.disabled ? 'opacity-50 cursor-pointer' : '';
    var purpleType = "bg-white rounded-full text-c-purple font-poppins text-sm px-4 py-2 border border-solid border-c-purple ".concat(disableCls);
    var RedType = "bg-white rounded-full text-c-red font-poppins text-sm px-4 py-2 border border-solid border-c-red ".concat(disableCls);
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: (props === null || props === void 0 ? void 0 : props.RedTypeOrPurpleType) === "red" ? RedType : purpleType },
                React.createElement("div", { className: "flex inline" },
                    React.createElement("div", { className: "mr-2" }, props.icon),
                    React.createElement("div", { className: "mt-0.5" }, props.title)))));
    };
    return render();
};
export default LightSubmitButtonWithIcon;
