import React, { useRef, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Api } from "../../classes/api";
import { useDispatch, useSelector } from "react-redux";
import env from "../../config/env";
import FullPageLoader from "../Loader/FullPageLoader";
import SelectClient from "../Client/SelectClient";
import DynamicAnswers from "../../pages/Questionnaire/DynamicAnswers";
import AnaQuestionnaire from "../../pages/Questionnaire/AnaQuestionnaire";
import AlreadyAnsweredPage from "../../pages/Questionnaire/AlreadyAnsweredPage";
import PaymentPage from "../../pages/Payment/Payment";
import PayWithNewCard from "../../pages/Payment/PayWithNewCard";
import PaymentSuccess from "../../pages/Payment/PaymentSuccess";
import PaymentError from "../../pages/Payment/PaymentError";
import ElementsContainer from "../Stripe/ElementsContainer";
import GenerateReport from "../../pages/Report/GenerateReport";
import SelectReportType from "../../pages/Report/SelectReportType";
import LandingPage from "../../pages/Frontend/LandingPage";
import ContactUs from "../../pages/Frontend/ContactUs";
import AboutUs from "../../pages/Frontend/AboutUs";
import Contact from "../../pages/Contact/Contact";
import ClientUpdatedSuccess from "../../pages/Client/ClientUpdatedSuccess";
import ClientAddedSuccess from "../../pages/Client/ClientAddedSuccess";
import F from "../../utils/functions";
import LinkExpired from "../../pages/Miscellaneous/LinkExpired";
import CheckAuth from "../Auth/CheckAuth";
import Politics from "../../pages/Frontend/Politics";
import PageNotFound from "../../pages/Frontend/PageNotFound";
import { loadCommonState } from "../../feature/common";
import HelpCenter from "../../pages/Frontend/HelpCenter";
import { CookieConsent } from "react-cookie-consent";
import { lang } from "../../lang/lang";
var EditCard = React.lazy(function () { return import("../../pages/Setting/EditCard"); });
var QuestionnairePreviewPage = React.lazy(function () { return import("../../pages/Questionnaire/QuestionnairePreviewPage"); });
var LaunchQuestionnaire = React.lazy(function () { return import("../../pages/Questionnaire/LaunchQuestionnaire"); });
var AnswerQuestionnaire = React.lazy(function () { return import("../../pages/Questionnaire/AnswerQuestionnaire"); });
var LaunchQuestionnaireSuccess = React.lazy(function () { return import("../../pages/Questionnaire/LaunchQuestionnaireSuccess"); });
var ClientQuestionnaire = React.lazy(function () { return import("../../pages/Client/ClientQuestionnaire"); });
var TestPage = React.lazy(function () { return import("../../pages/TestPage"); });
var Register = React.lazy(function () { return import("../../pages/Register/Register"); });
var LogIn = React.lazy(function () { return import("../../pages/LogIn/LogIn"); });
var Dashboard = React.lazy(function () { return import("../../pages/Dashboard/Dashboard"); });
var ForgotPassword = React.lazy(function () { return import("../../pages/ForgotPassword/ForgotPassword/ForgotPassword"); });
var ForgotEmail = React.lazy(function () { return import("../../pages/ForgotEmail/ForgotEmail/ForgotEmail"); });
var FindEmail = React.lazy(function () { return import("../../pages/ForgotEmail/FindEmail/FindEmail"); });
var ResetPassword = React.lazy(function () { return import("../../pages/ForgotPassword/ResetPassword/ResetPassword"); });
var AddClient = React.lazy(function () { return import("../../pages/Client/AddClient"); });
var ClientPage = React.lazy(function () { return import("../../pages/Client/ClientPage"); });
var EditClient = React.lazy(function () { return import("../../pages/Client/EditClient"); });
var RemoveCollaborator = React.lazy(function () { return import("../../pages/Client/RemoveCollaborator"); });
var ProfileSetting = React.lazy(function () { return import("../../pages/Setting/ProfileSetting"); });
var ModifyPassword = React.lazy(function () { return import("../../pages/Setting/ModifyPassword"); });
var PaymentInformations = React.lazy(function () { return import("../../pages/Setting/PaymentInformations"); });
var PaymentHistory = React.lazy(function () { return import("../../pages/Setting/PaymentHistory"); });
var PreferencesAndNotifications = React.lazy(function () { return import("../../pages/Setting/PreferencesAndNotifications"); });
var DeleteAccount = React.lazy(function () { return import("../../pages/Setting/DeleteAccount"); });
var VerifyPhoneNumber = React.lazy(function () { return import("../../pages/Setting/VerifyPhoneNumber"); });
var EmailVerified = React.lazy(function () { return import("../../pages/User/EmailVerified"); });
var AddCard = React.lazy(function () { return import("../../pages/Setting/AddCard"); });
var PaymentSignUp = React.lazy(function () { return import("../../pages/Register/PaymentSignUp"); });
var Resources = React.lazy(function () { return import("../../pages/Resources/Resource"); });
var CustomRoutes = function () {
    var isUserLoaded = useRef(false);
    var loader = useSelector(function (store) { return store.loader.value; });
    var LG = F.getLang();
    var dispatch = useDispatch();
    var firstLoad = function () {
        var _a = useCookies([env.cookies.token]), cookies = _a[0], _ = _a[1];
        Api.initiate(cookies[env.cookies.token], LG);
    };
    if (isUserLoaded.current === false) {
        firstLoad();
    }
    useEffect(function () {
        dispatch(loadCommonState());
    }, []);
    var getFullPageLoading = function () {
        if (loader.fullPage) {
            return React.createElement(FullPageLoader, null);
        }
    };
    var render = function () {
        var _a;
        return (React.createElement("div", { className: "scroll-smooth" },
            getFullPageLoading(),
            React.createElement(BrowserRouter, null,
                React.createElement(Suspense, { fallback: (_a = getFullPageLoading()) !== null && _a !== void 0 ? _a : '' },
                    React.createElement(CookieConsent, { location: "bottom", buttonText: lang[LG].okay, cookieName: "cookieConsent", style: { background: "#2B373B" }, buttonStyle: { color: "#4e503b", fontSize: "13px" }, expires: 150 }, lang[LG].siteUsesCookies),
                    React.createElement(Routes, null,
                        React.createElement(Route, { path: env.routes.register, element: React.createElement(Register, null) }),
                        React.createElement(Route, { path: env.routes.login, element: React.createElement(LogIn, null) }),
                        React.createElement(Route, { path: env.routes.loginParam, element: React.createElement(LogIn, null) }),
                        React.createElement(Route, { path: env.routes.dashboard, element: React.createElement(CheckAuth, null,
                                React.createElement(Dashboard, null)) }),
                        React.createElement(Route, { path: env.routes.forgotPassword, element: React.createElement(ForgotPassword, null) }),
                        React.createElement(Route, { path: env.routes.forgotEmail, element: React.createElement(ForgotEmail, null) }),
                        React.createElement(Route, { path: env.routes.findEmail, element: React.createElement(FindEmail, null) }),
                        React.createElement(Route, { path: env.routes.resetPassword, element: React.createElement(ResetPassword, null) }),
                        React.createElement(Route, { path: env.routes.clientAdd, element: React.createElement(CheckAuth, null,
                                React.createElement(AddClient, null)) }),
                        React.createElement(Route, { path: env.routes.testPage, element: React.createElement(TestPage, null) }),
                        React.createElement(Route, { path: env.routes.clientPage, element: React.createElement(CheckAuth, null,
                                React.createElement(ClientPage, null)) }),
                        React.createElement(Route, { path: env.routes.editClient, element: React.createElement(CheckAuth, null,
                                React.createElement(EditClient, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.removeCollaborator, element: React.createElement(CheckAuth, null,
                                React.createElement(RemoveCollaborator, null)) }),
                        React.createElement(Route, { path: env.routes.profileSetting, element: React.createElement(CheckAuth, null,
                                React.createElement(ProfileSetting, null)) }),
                        React.createElement(Route, { path: env.routes.modifyPassword, element: React.createElement(CheckAuth, null,
                                React.createElement(ModifyPassword, null)) }),
                        React.createElement(Route, { path: env.routes.paymentInformation, element: React.createElement(CheckAuth, null,
                                React.createElement(PaymentInformations, null)) }),
                        React.createElement(Route, { path: env.routes.paymentHistory, element: React.createElement(CheckAuth, null,
                                React.createElement(PaymentHistory, null)) }),
                        React.createElement(Route, { path: env.routes.preferencesAndNotifications, element: React.createElement(CheckAuth, null,
                                React.createElement(PreferencesAndNotifications, null)) }),
                        React.createElement(Route, { path: env.routes.deleteAccount, element: React.createElement(CheckAuth, null,
                                React.createElement(DeleteAccount, null)) }),
                        React.createElement(Route, { path: env.routes.verifyPhoneNumber, element: React.createElement(CheckAuth, null,
                                React.createElement(VerifyPhoneNumber, null)) }),
                        React.createElement(Route, { path: env.routes.emailVerified, element: React.createElement(EmailVerified, null) }),
                        React.createElement(Route, { path: env.routes.linkExpired, element: React.createElement(LinkExpired, null) }),
                        React.createElement(Route, { path: env.routes.addCard, element: React.createElement(CheckAuth, null,
                                React.createElement(AddCard, { backgroundCls: "bg-settings bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.editCard, element: React.createElement(CheckAuth, null,
                                React.createElement(EditCard, { backgroundCls: "bg-settings bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.paymentSignUp, element: React.createElement(PaymentSignUp, null) }),
                        React.createElement(Route, { path: env.routes.paymentSignUpParam, element: React.createElement(PaymentSignUp, null) }),
                        React.createElement(Route, { path: env.routes.questionnairePreview, element: React.createElement(CheckAuth, null,
                                React.createElement(QuestionnairePreviewPage, { returnUrl: env.routes.dashboard })) }),
                        React.createElement(Route, { path: env.routes.questionnairePreviewHome, element: React.createElement(QuestionnairePreviewPage, { returnUrl: env.routes.home }) }),
                        React.createElement(Route, { path: env.routes.selectClient, element: React.createElement(CheckAuth, null,
                                React.createElement(SelectClient, null)) }),
                        React.createElement(Route, { path: env.routes.launchQuestionnaire, element: React.createElement(CheckAuth, null,
                                React.createElement(LaunchQuestionnaire, null)) }),
                        React.createElement(Route, { path: env.routes.answerQuestionnaire, element: React.createElement(CheckAuth, { allowed: true },
                                React.createElement(AnswerQuestionnaire, null)) }),
                        React.createElement(Route, { path: env.routes.launchQuestionnaireSuccess, element: React.createElement(LaunchQuestionnaireSuccess, null) }),
                        React.createElement(Route, { path: env.routes.answerQuestionnaireStep, element: React.createElement(AnswerQuestionnaire, null) }),
                        React.createElement(Route, { path: env.routes.launchQuestionnaireSuccess, element: React.createElement(CheckAuth, null,
                                React.createElement(LaunchQuestionnaireSuccess, null)) }),
                        React.createElement(Route, { path: env.routes.clientQuestionnaire, element: React.createElement(CheckAuth, null,
                                React.createElement(ClientQuestionnaire, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.dynamicAnswers, element: React.createElement(CheckAuth, { allowed: true },
                                React.createElement(DynamicAnswers, null)) }),
                        React.createElement(Route, { path: env.routes.anaQuestionnaire, element: React.createElement(AnaQuestionnaire, null) }),
                        React.createElement(Route, { path: env.routes.alreadyAnsweredPage, element: React.createElement(AlreadyAnsweredPage, null) }),
                        React.createElement(Route, { path: env.routes.contactInformation, element: React.createElement(Contact, { backgroundCls: "bg-questionnaire-2 bg-cover bg-center" }) }),
                        React.createElement(Route, { path: env.routes.paymentPage, element: React.createElement(CheckAuth, null,
                                React.createElement(ElementsContainer, null,
                                    React.createElement(PaymentPage, null))) }),
                        React.createElement(Route, { path: env.routes.payWithNewCard, element: React.createElement(CheckAuth, null,
                                React.createElement(PayWithNewCard, null)) }),
                        React.createElement(Route, { path: env.routes.paymentSuccess, element: React.createElement(PaymentSuccess, { backgroundCls: "bg-questionnaire-bank bg-cover bg-center" }) }),
                        React.createElement(Route, { path: env.routes.paymentFail, element: React.createElement(PaymentError, { backgroundCls: "bg-questionnaire-bank bg-cover bg-center" }) }),
                        React.createElement(Route, { path: env.routes.paymentFailNewCard, element: React.createElement(PaymentError, { backgroundCls: "bg-questionnaire-bank bg-cover bg-center", isNewPage: true }) }),
                        React.createElement(Route, { path: env.routes.generateReport, element: React.createElement(CheckAuth, null,
                                React.createElement(GenerateReport, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.selectReportType, element: React.createElement(CheckAuth, null,
                                React.createElement(SelectReportType, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.home, element: React.createElement(LandingPage, null) }),
                        React.createElement(Route, { path: env.routes.contactUs, element: React.createElement(ContactUs, null) }),
                        React.createElement(Route, { path: env.routes.aboutUs, element: React.createElement(AboutUs, null) }),
                        React.createElement(Route, { path: env.routes.politics, element: React.createElement(Politics, null) }),
                        React.createElement(Route, { path: env.routes.helpCenter, element: React.createElement(HelpCenter, null) }),
                        React.createElement(Route, { path: env.routes.resources, element: React.createElement(Resources, null) }),
                        React.createElement(Route, { path: env.routes.clientUpdatedSuccess, element: React.createElement(CheckAuth, null,
                                React.createElement(ClientUpdatedSuccess, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: env.routes.clientAddedSuccess, element: React.createElement(CheckAuth, null,
                                React.createElement(ClientAddedSuccess, { backgroundCls: "bg-client-list bg-cover bg-center" })) }),
                        React.createElement(Route, { path: "*", element: React.createElement(PageNotFound, null) }))))));
    };
    return render();
};
export default CustomRoutes;
