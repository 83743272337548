import React, { useEffect, useRef, useState } from 'react';
import Text from "../Field/Text";
import Functions from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import useAwayClick from "../../hooks/content/useAwayClick";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
import env from "../../config/env";
var DropDown = function (props) {
    var elementRef = useRef(null);
    var elementRefClickAway = useAwayClick(elementRef);
    var _a = useState(props.selectedItem), selectedItem = _a[0], setSelectedItem = _a[1];
    var getDropDownItems = function (key, title, data) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black px-2 py-1 hover:bg-c-scroll", display: "block", onClick: function (e) {
                setSelectedItem({
                    key: key,
                    title: title,
                    data: data
                });
                Functions.escape();
            }, key: key }, title);
    };
    var checkIfSelectedItemExits = function () {
        if (selectedItem != null) {
            var item = props.items.filter(function (item) { return selectedItem.title == item.title; });
            if (item.length === 0) {
                setSelectedItem(undefined);
            }
        }
    };
    // if dropdown open
    var ifDDOpen = function () {
        return !elementRefClickAway;
    };
    useEffect(function () {
        if (selectedItem != null) {
            props.onChange(selectedItem);
        }
    }, [selectedItem]);
    var render = function () {
        var _a, _b, _c, _d;
        checkIfSelectedItemExits();
        var containerClass = "flex justify-between items-center px-3 border ".concat(ifDDOpen() ? 'border-c-purple' : 'border-c-gray', " rounded relative bg-c-scroll");
        var iconClass = "flex items-center cursor-pointer";
        var dropBoxClass = "border border-c-gray rounded absolute mr-4 top-12 left-0 bg-white w-full h-40 z-10 overflow-y-scroll";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') },
                React.createElement("div", { className: "".concat(containerClass, " w-full h-10 ").concat(props.error ? 'border-c-red' : '', " ").concat(props.disabled ? 'bg-c-scroll hover:cursor-not-allowed' : ''), ref: elementRef },
                    React.createElement(Text, { additionalClass: "truncate ..." }, (_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title) !== null && _b !== void 0 ? _b : props === null || props === void 0 ? void 0 : props.placeHolder),
                    React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconColor, additionalClass: ifDDOpen() ? 'rotate-180' : '' }) }),
                    React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(elementRefClickAway || props.disabled ? 'hidden' : '') }, props.items.map(function (item) { return getDropDownItems(item.key, item.title, item.data); }))),
                props.error && React.createElement(TextError, { addtionalClass: 'text-xs', message: (_d = (_c = props.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : '' }))));
    };
    return render();
};
export default DropDown;
