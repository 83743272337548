var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clsx from "clsx";
import React, { useState } from "react";
import UpArraow from "../../assets/icons/UpArraow";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
var File = function (props) {
    var _a = useState(false), error = _a[0], setError = _a[1];
    var LG = F.getLang();
    var render = function () {
        var ctnClass = 'cursor-pointer';
        if (props.disabled)
            ctnClass += 'hover:cursor-not-allowed ';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex col-span-1 ".concat(ctnClass, " mt-2 ml-6") },
                React.createElement("label", { htmlFor: props.id, className: 'flex' },
                    React.createElement(UpArraow, null),
                    React.createElement("span", { className: "flex text-c-purple ".concat(ctnClass) }, lang[LG].anaQuestionnaire.uploadfile),
                    React.createElement("input", __assign({}, props.register, { className: clsx({
                            'hidden': true
                        }), onChange: props.onChange, id: props.id, type: 'file', disabled: props.disabled, accept: "application/pdf" }))))));
    };
    return render();
};
export default File;
