import React from 'react';
var IconContainer = function (props) {
    var render = function () {
        var _a;
        var iconClasses = (_a = props.cssClasses) !== null && _a !== void 0 ? _a : "absolute top-1 right-1 cursor-pointer";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: iconClasses, onClick: props.onClick }, props.icon)));
    };
    return render();
};
export default IconContainer;
