import React from "react";
import Text from "../../components/Field/Text";
var StarIcon = function (props) {
    var _a, _b;
    var height = props.height ? props.height.toString() : '12';
    var width = props.width ? props.width.toString() : '12';
    return (React.createElement("div", { className: (_a = props.additionDivClass) !== null && _a !== void 0 ? _a : '', onClick: props.onClick },
        React.createElement("svg", { className: (_b = props.additionalClass) !== null && _b !== void 0 ? _b : '', viewBox: "0 0 ".concat(height, " ").concat(width), height: "".concat(height, "px"), width: "".concat(width, "px"), xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("polygon", { fill: "#6c0c6c", id: "star", points: "6,0,7.763355756877419,3.5729490168751576,\n             11.706339097770922,4.145898033750315,\n             8.853169548885461,6.927050983124842,9.52671151375484,\n             10.854101966249683,6,9,2.4732884862451616,\n             10.854101966249685,3.1468304511145395,\n             6.927050983124842,0.29366090222907815,\n             4.145898033750317,4.23664424312258,\n             3.572949016875158" })),
        React.createElement(Text, { additionalClass: "text-sm text-c-purple cursor-pointer  px-2" }, props.textTittle),
        " "));
};
export default StarIcon;
