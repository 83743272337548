var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../classes/api";
import { EQuestionnaireActivity, EQuestionnaireAssistance } from "../enums/questionnaire";
import env from "../config/env";
import { saveLoader } from "./loader";
import F from "../utils/functions";
var initialValue = {
    currentSection: 0,
    totalSections: 0,
    sections: [],
    answers: {},
    saved: false,
    additionalInformation: {},
    savedAndQuit: false,
    sectionId: 0,
    result: {},
};
export var registerQuestionnaireSlice = createSlice({
    name: "questionnaire",
    initialState: initialValue,
    reducers: {
        updateQuestionnaire: function (state, action) {
            state = action.payload;
            return state;
        },
        updateCurrentPage: function (state, action) {
            var _a = action.payload, index = _a.index, currentPage = _a.currentPage;
            var section = state.sections[index];
            section.currentPage = currentPage;
            state.sections[index] = section;
            return state;
        },
        updateSectionIndex: function (state, action) {
            var currentSection = action.payload.currentSection;
            state.currentSection = currentSection;
            return state;
        },
        updateSectionAdditional: function (state, action) {
            var _a;
            var _b = action.payload, index = _b.index, value = _b.value, currentPage = _b.currentPage, sectionId = _b.sectionId;
            state.additionalInformation[index] = __assign(__assign({}, state.additionalInformation[index]), (_a = {}, _a[currentPage] = {
                value: value,
                sectionId: sectionId,
            }, _a));
            return state;
        },
        updateAnswerActivity: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, activity = _a.activity, subSectionId = _a.subSectionId;
            var answer = state.answers[questionId];
            if (answer != null) {
                answer.activity = activity;
                answer.subSectionId = subSectionId;
                if (activity === EQuestionnaireActivity.na) {
                    answer.assistance = EQuestionnaireAssistance.n;
                }
            }
            else {
                answer = __assign(__assign({}, action.payload), { assistance: EQuestionnaireAssistance.n });
            }
            state.answers[questionId] = answer;
            return state;
        },
        updateAnswerAssistance: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, assistance = _a.assistance, subSectionId = _a.subSectionId;
            var answer = state.answers[questionId];
            if ((answer === null || answer === void 0 ? void 0 : answer.activity) === EQuestionnaireActivity.na)
                return state;
            if (answer != null) {
                answer.assistance = assistance;
                answer.subSectionId = subSectionId;
            }
            else {
                answer = __assign(__assign({}, action.payload), { activity: EQuestionnaireActivity.n });
            }
            state.answers[questionId] = answer;
            return state;
        },
        updateQuestionnaireResult: function (state) {
            var scores = {};
            for (var qid in state.answers) {
                var _a = state.answers[qid], sectionId = _a.sectionId, subSectionId = _a.subSectionId, assistance = _a.assistance, activity = _a.activity;
                if (!(sectionId in scores)) {
                    scores[sectionId] = {};
                }
                if (!scores[sectionId].hasOwnProperty(subSectionId)) {
                    scores[sectionId][subSectionId] = {
                        'activity': [],
                        'assistance': [],
                    };
                }
                var activityScore = activity === EQuestionnaireActivity.na ? 0 :
                    activity === EQuestionnaireActivity.never ? 1 :
                        activity === EQuestionnaireActivity.rarely ? 2 :
                            activity === EQuestionnaireActivity.sometimes ? 3 :
                                activity === EQuestionnaireActivity.often ? 4 :
                                    activity === EQuestionnaireActivity.always ? 5 : 0;
                scores[sectionId][subSectionId].activity.push(activityScore);
                var assistanceScore = assistance === EQuestionnaireAssistance.n &&
                    activity === EQuestionnaireActivity.na ? 0 :
                    assistance === EQuestionnaireAssistance.none ? 5 :
                        assistance === EQuestionnaireAssistance.low ? 4 :
                            assistance === EQuestionnaireAssistance.moderate ? 3 :
                                assistance === EQuestionnaireAssistance.significant ? 2 :
                                    assistance === EQuestionnaireAssistance.extreme ? 1 : 0;
                scores[sectionId][subSectionId].assistance.push(assistanceScore);
            }
            var result = {};
            for (var sectionId in scores) {
                for (var subSectionId in scores[sectionId]) {
                    if (!result.hasOwnProperty(sectionId))
                        result[sectionId] = {};
                    result[sectionId][subSectionId] = {
                        average: {
                            activity: +F.averageSkip0(scores[sectionId][subSectionId].activity),
                            assistance: +F.averageSkip0(scores[sectionId][subSectionId].assistance),
                        }
                    };
                }
            }
            state.result = result;
            return state;
        },
        questionnaireSaved: function (state, action) {
            state.saved = action.payload.saved;
            state.savedAndQuit = action.payload.savedAndQuit;
            return state;
        },
        updateError: function (state, action) {
            state.error = action.payload.error;
            return state;
        }
    }
});
export var updateQuestionnaire = (_a = registerQuestionnaireSlice.actions, _a.updateQuestionnaire), updateAnswerActivity = _a.updateAnswerActivity, updateAnswerAssistance = _a.updateAnswerAssistance, updateSectionAdditional = _a.updateSectionAdditional, updateCurrentPage = _a.updateCurrentPage, updateSectionIndex = _a.updateSectionIndex, questionnaireSaved = _a.questionnaireSaved, updateQuestionnaireResult = _a.updateQuestionnaireResult, updateError = _a.updateError;
export function saveAnswer(params) {
    return function (dispatch, getState) {
        return __awaiter(this, void 0, void 0, function () {
            var hashedId, saved, savedAndQuit, submit, store, _a, answers, additionalInformation, result, payload, errorOccurred, qid, _b, assistance, activity, sectionId, subSectionId, sid, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        hashedId = params.hashedId, saved = params.saved, savedAndQuit = params.savedAndQuit, submit = params.submit;
                        store = getState();
                        _a = store.questionnaire, answers = _a.answers, additionalInformation = _a.additionalInformation, result = _a.result;
                        payload = {
                            answers: {},
                            information: {},
                            hashedId: hashedId,
                            result: result,
                            submit: submit
                        };
                        errorOccurred = true;
                        for (qid in answers) {
                            _b = answers[qid], assistance = _b.assistance, activity = _b.activity, sectionId = _b.sectionId, subSectionId = _b.subSectionId;
                            payload.answers[qid] = {
                                activity: activity,
                                assistance: assistance,
                                sectionId: sectionId,
                                subSectionId: subSectionId
                            };
                        }
                        for (sid in additionalInformation) {
                            if (typeof additionalInformation[sid] !== "undefined") {
                                payload.information[sid] = additionalInformation[sid];
                            }
                        }
                        dispatch(saveLoader({
                            fullPage: true,
                        }));
                        return [4 /*yield*/, Api.post(payload, env.api.updateAnswers)];
                    case 1:
                        response = _c.sent();
                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                            dispatch(questionnaireSaved({
                                saved: saved,
                                savedAndQuit: savedAndQuit,
                            }));
                        }
                        else {
                            dispatch(updateError({
                                error: response
                            }));
                            errorOccurred = false;
                        }
                        dispatch(saveLoader({
                            fullPage: false,
                        }));
                        return [2 /*return*/, errorOccurred];
                }
            });
        });
    };
}
export function fetchQuestionnaire(payload) {
    return function (dispatch, getState) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var store, response, responseAnswers, stateAnswersContainer, qid, responseInformation, questions, noOfPages, sectionNumber, currentPage, findQuestionIndex, sections, value;
            var _l;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        store = getState();
                        return [4 /*yield*/, Api.getD(__assign({}, payload), env.api.loadQuestionnaire)];
                    case 1:
                        response = _m.sent();
                        responseAnswers = JSON.parse((_a = response === null || response === void 0 ? void 0 : response.data.answers) !== null && _a !== void 0 ? _a : "{}");
                        stateAnswersContainer = {};
                        for (qid in responseAnswers) {
                            stateAnswersContainer[qid] = __assign(__assign({}, responseAnswers[qid]), { questionId: +qid });
                        }
                        responseInformation = JSON.parse((_b = response === null || response === void 0 ? void 0 : response.data.information) !== null && _b !== void 0 ? _b : "{}");
                        questions = (_c = response === null || response === void 0 ? void 0 : response.data.questions) !== null && _c !== void 0 ? _c : [];
                        noOfPages = Math.ceil(questions.length / 5);
                        sectionNumber = (_d = response === null || response === void 0 ? void 0 : response.data.currentSection) !== null && _d !== void 0 ? _d : 0;
                        sectionNumber = +sectionNumber + 1;
                        currentPage = 1;
                        if ((_e = response === null || response === void 0 ? void 0 : response.data.lastAnswer) === null || _e === void 0 ? void 0 : _e.questionId) {
                            findQuestionIndex = questions.findIndex(function (q) { return q.questionId === response.data.lastAnswer.questionId; });
                            if (findQuestionIndex !== -1 && typeof payload.action === "undefined") {
                                currentPage = Math.ceil((findQuestionIndex + 1) / 5);
                            }
                            else if (payload.action === 'backward') {
                                currentPage = noOfPages;
                            }
                            else if (payload.action === 'forward') {
                                currentPage = 1;
                            }
                            currentPage = currentPage === 0 ? 1 : currentPage;
                        }
                        sections = {
                            index: sectionNumber,
                            questions: questions,
                            isCompleted: false,
                            sectionName: (_f = response === null || response === void 0 ? void 0 : response.data.sectionName) !== null && _f !== void 0 ? _f : '',
                            noOfPages: noOfPages,
                            currentPage: currentPage,
                            subSectionName: (_g = response === null || response === void 0 ? void 0 : response.data.subSectionName) !== null && _g !== void 0 ? _g : '',
                            sectionId: (_h = response === null || response === void 0 ? void 0 : response.data.sectionId) !== null && _h !== void 0 ? _h : 0,
                        };
                        value = {
                            currentSection: sectionNumber,
                            totalSections: (_j = response === null || response === void 0 ? void 0 : response.data.totalSections) !== null && _j !== void 0 ? _j : 0,
                            sections: __assign(__assign({}, store.questionnaire.sections), (_l = {}, _l[sectionNumber] = sections, _l)),
                            answers: Object.keys(stateAnswersContainer).length > 0 ? stateAnswersContainer : store.questionnaire.answers,
                            saved: store.questionnaire.saved,
                            additionalInformation: Object.keys(responseInformation).length > 0 ? responseInformation : store.questionnaire.additionalInformation,
                            savedAndQuit: store.questionnaire.savedAndQuit,
                            sectionId: (_k = response === null || response === void 0 ? void 0 : response.data.sectionId) !== null && _k !== void 0 ? _k : 0,
                            result: (response === null || response === void 0 ? void 0 : response.data.result) == null ? store.questionnaire.result : response === null || response === void 0 ? void 0 : response.data.result
                        };
                        dispatch(updateQuestionnaire(value));
                        return [2 /*return*/];
                }
            });
        });
    };
}
export default registerQuestionnaireSlice.reducer;
