import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
var ElementsContainer = function (props) {
    var pk = useSelector(function (store) { return store.common.pk; });
    var stripePromise = loadStripe(pk);
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Elements, { stripe: stripePromise }, props.children)));
    };
    return render();
};
export default ElementsContainer;
