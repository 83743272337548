import moment from "moment";
import env from "../config/env";
import { lang } from "../lang/lang";
import { ERespondentVersion, ERespondentVersionEnglish } from "../enums/respondent";
import { useSelector } from "react-redux";
import { getCountries, getStates } from "country-state-picker";
var Functions = /** @class */ (function () {
    function Functions() {
    }
    Functions.convertToNum = function (value) {
        value = value != null ? +value : 0;
        value = isNaN(value) ? 0 : value;
        return value;
    };
    Functions.removeKeys = function (object, keys) {
        keys.forEach(function (key) { return delete object[key]; });
    };
    var _a;
    _a = Functions;
    Functions.escape = function () {
        document.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 27 }));
    };
    Functions.removeParams = function (path) {
        var _b;
        return (_b = path.split(":")[0]) !== null && _b !== void 0 ? _b : "";
    };
    Functions.stringToMonth = function (month, LG) {
        var monthStr = "";
        switch (month) {
            case "01":
                monthStr = lang[LG].months[0].name;
                break;
            case "02":
                monthStr = lang[LG].months[1].name;
                break;
            case "03":
                monthStr = lang[LG].months[2].name;
                break;
            case "04":
                monthStr = lang[LG].months[3].name;
                break;
            case "05":
                monthStr = lang[LG].months[4].name;
                break;
            case "06":
                monthStr = lang[LG].months[5].name;
                break;
            case "07":
                monthStr = lang[LG].months[6].name;
                break;
            case "08":
                monthStr = lang[LG].months[7].name;
                break;
            case "09":
                monthStr = lang[LG].months[8].name;
                break;
            case "10":
                monthStr = lang[LG].months[9].name;
                break;
            case "11":
                monthStr = lang[LG].months[10].name;
                break;
            case "12":
                monthStr = lang[LG].months[11].name;
                break;
            default:
                break;
        }
        return monthStr;
    };
    Functions.getFormatedDate = function (dateStr, lang) {
        if (lang === void 0) { lang = 'fr'; }
        var monthNames = ["janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre"];
        if (dateStr === '')
            return '';
        var date = moment(dateStr);
        if (lang == 'en') {
            return date.format('D MMMM Y');
        }
        else {
            var month = monthNames[date.month()];
            var date1 = date.format('D Y');
            return date1.replace(' ', ' ' + month + ' ');
        }
    };
    Functions.replaceNonDigits = function (number) {
        return number.replace(/[^0-9\.]+/g, '');
    };
    Functions.replacePhoneDigits = function (number) {
        return number.replace(/[^+][^0-9\.]+/g, '');
    };
    Functions.selectedDropDownItem = function (items, key, value) {
        if (typeof key === "string") {
            return items.filter(function (item) { return item[key] == value; })[0];
        }
        else {
            return items.filter(function (item) { return item[key[0]][key[1]] == value; })[0];
        }
    };
    Functions.getGenderItems = function (LG) {
        if (LG == 'fr') {
            return [
                { key: 1, title: lang[LG].gender.he, data: 'M' },
                { key: 2, title: lang[LG].gender.she, data: 'F' },
                { key: 3, title: lang[LG].gender.they, data: 'T' },
                { key: 4, title: lang[LG].gender.theyFr, data: 'TF' },
            ];
        }
        else {
            return [
                { key: 1, title: lang[LG].gender.he, data: 'M' },
                { key: 2, title: lang[LG].gender.she, data: 'F' },
                { key: 3, title: lang[LG].gender.they, data: 'T' },
            ];
        }
    };
    Functions.getPoliticsItems = function (LG) {
        return [
            { key: 0, title: lang[LG].politicsDrop.policy, data: 'Privacy-policies' },
            { key: 1, title: lang[LG].politicsDrop.products, data: 'Privacy-policies-for-Eduplan-Solutions-products' },
            { key: 2, title: lang[LG].politicsDrop.relative, data: 'Politique-relative-à-edpro' },
            { key: 3, title: lang[LG].politicsDrop.products, data: 'Conditions-d\'utilisation' },
            { key: 4, title: lang[LG].politicsDrop.coppa, data: 'Programme-de-gestion-de-la-confidentialité' },
            { key: 5, title: lang[LG].politicsDrop.coppa1, data: 'Conformité-réglementaire-selon-le-territoire-(COPPA)' },
            { key: 6, title: lang[LG].politicsDrop.coppa2, data: 'Cookies' },
        ];
    };
    Functions.getLangItems = function (LG) {
        return [
            { key: 1, title: lang[LG].answerQuestionnaire.selectLanPage.btn1, data: 'fr' },
            { key: 2, title: lang[LG].answerQuestionnaire.selectLanPage.btn2, data: 'en' },
        ];
    };
    Functions.getLangItemsReport = function (LG) {
        return [
            { key: 1, title: lang[LG].answerQuestionnaire.selectLanPage.btn1, data: 'fr' },
            //{key: 2, title: lang[LG].answerQuestionnaire.selectLanPage.btn2, data: 'en'}, if you add en You can use getLangItems function
        ];
    };
    Functions.getLangSelectedItems = function (LG) {
        return { key: 1,
            title: (LG == 'fr') ? lang[LG].answerQuestionnaire.selectLanPage.btn1 : lang[LG].answerQuestionnaire.selectLanPage.btn2,
            data: (LG == 'fr') ? "fr" : "en",
        };
        var versions = [
            {
                key: 1,
                title: (LG == 'fr') ? lang[LG].answerQuestionnaire.selectLanPage.btn1 : lang[LG].answerQuestionnaire.selectLanPage.btn2,
                data: (LG == 'fr') ? "fr" : "en",
            },
        ];
    };
    Functions.getLanguage = function (LG) {
        var language = [
            {
                key: 1,
                title: lang[LG].answerQuestionnaire.selectLanPage.btn1,
                data: 'fr',
            },
            {
                key: 2,
                title: 'English',
                data: 'en',
            },
        ];
        return language;
    };
    Functions.getVersions = function (LG) {
        if (LG === void 0) { LG = 'en'; }
        var versions = [
            {
                key: 1,
                title: (LG == 'fr') ? ERespondentVersion.professional : ERespondentVersionEnglish.professional,
                data: ERespondentVersionEnglish.professional,
            },
            {
                key: 2,
                title: (LG == 'fr') ? ERespondentVersion.parent : ERespondentVersionEnglish.parent,
                data: ERespondentVersionEnglish.parent,
            },
            {
                key: 3,
                title: (LG == 'fr') ? ERespondentVersion.learner : ERespondentVersionEnglish.learner,
                data: ERespondentVersionEnglish.learner,
            },
        ];
        return versions;
    };
    Functions.getQuestionnaireSorting = function (LG) {
        var options = [
            {
                key: 1,
                title: lang[LG].sortItem.pertinence,
                data: {},
            },
            {
                key: 2,
                title: lang[LG].sortItem.ascendingPrice,
                data: {
                    order: 'asc',
                    column: 'price'
                },
            },
            {
                key: 3,
                title: lang[LG].sortItem.decreasingPrice,
                data: {
                    order: 'desc',
                    column: 'price'
                },
            },
            {
                key: 4,
                title: lang[LG].sortItem.alphabetic1,
                data: {
                    order: 'asc',
                    column: 'title'
                },
            },
            {
                key: 5,
                title: lang[LG].sortItem.alphabetic2,
                data: {
                    order: 'desc',
                    column: 'title'
                },
            },
            {
                key: 6,
                title: lang[LG].sortItem.recentlyAdded,
                data: {
                    order: 'desc',
                    column: 'created_at'
                },
            }
        ];
        return options;
    };
    Functions.getLang = function () { return useSelector(function (store) { return store.language; }); };
    Functions.dateFormats = {
        'en': 'MM/DD/YYYY',
        'fr': 'DD/MM/YYYY'
    };
    Functions.getFormattedDate = function (date, lang) {
        if (date === '')
            return '';
        var formattedDate = moment(date).format(Functions.dateFormats[lang]);
        return formattedDate;
    };
    Functions.getFormattedPrice = function (price, lang) {
        var formattedString = '';
        switch (lang) {
            case 'en':
                formattedString = "".concat(env.currencySign).concat(price.toFixed(2));
                break;
            case "fr":
                formattedString = "".concat(price.toFixed(2).replace('.', ',')).concat(env.currencySign);
                break;
        }
        return formattedString;
    };
    Functions.getCountriesItems = function () {
        var items = getCountries().filter(function (item) { return item.code === 'ca'; });
        return items.map(function (item, index) {
            return {
                key: index,
                title: item.name,
                data: item,
            };
        });
    };
    Functions.getStateItems = function (countryCode) {
        var _b;
        var items = (_b = getStates(countryCode)) !== null && _b !== void 0 ? _b : [];
        return items.map(function (item, index) {
            return {
                key: index,
                title: item,
                data: item,
            };
        });
    };
    Functions.getCredit = function (maxCredit) {
        var finalArray = [];
        for (var i = 0; i <= maxCredit; i += 1) {
            finalArray.push({ key: i, title: i, data: i });
        }
        return finalArray;
    };
    Functions.createUrl = function (route) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        var url = _a.removeParams(route);
        return "".concat(url).concat(params.join('/'));
    };
    Functions.sliceArray = function (array, sliceSize) {
        var finalArray = [];
        for (var i = 0; i < array.length; i += sliceSize) {
            var chunk = array.slice(i, i + sliceSize);
            finalArray.push(chunk);
        }
        return finalArray;
    };
    Functions.capitalize = function (variable) {
        return variable.charAt(0).toUpperCase() + variable.slice(1);
    };
    Functions.average = function (arr, round) {
        if (round === void 0) { round = true; }
        var average = arr.reduce(function (a, b) { return a + b; }) / arr.length;
        return round ? average.toFixed(2) : average.toString();
    };
    Functions.averageSkip0 = function (arr, round) {
        if (round === void 0) { round = true; }
        var average = arr.reduce(function (a, b) { return a + b; }) / arr.filter(function (arr) { return arr !== 0; }).length;
        return round ? average.toFixed(2) : average.toString();
    };
    Functions.uFirst = function (s) {
        if (s.length === 0)
            return '';
        return s[0].toUpperCase() + s.slice(1);
    };
    Functions.isLoggedIn = function () { return useSelector(function (store) { return store.user.value.isLoggedIn; }); };
    Functions.user = function () { return useSelector(function (store) { return store.user; }); };
    return Functions;
}());
export default Functions;
