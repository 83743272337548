import React from "react";
var BaseText = function (props) {
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins text-left text-sm text-black ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') }, props.children)));
    };
    return render();
};
export default BaseText;
