var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from "react";
import env from "../../config/env";
import { lang } from "../../lang/lang";
import Text from "../Field/Text";
import IconContainer from "../IconContainer/IconContainer";
import moment from "moment";
import useAwayClick from "../../hooks/content/useAwayClick";
import Functions from "../../utils/functions";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
var YearPicker = function (props) {
    var LG = Functions.getLang();
    var monthRef = useRef();
    var yearRef = useRef();
    var monthRefClickAway = useAwayClick(monthRef);
    var yearRefClickAway = useAwayClick(yearRef);
    var getDropDownItem = function (key, title, stateKey, stateStrKey) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black px-2 py-2 hover:bg-c-scroll", display: "block", onClick: function () {
                var _a;
                var newObject = __assign(__assign({}, props.date), (_a = {}, _a[stateKey] = key, _a[stateStrKey] = title, _a));
                props.validate(newObject);
                Functions.escape();
            }, key: key }, title);
    };
    var getYears = function () {
        var currentYear = moment().year();
        var startingYear = currentYear - 100;
        var jsxContainer = [];
        for (var year = currentYear; year >= startingYear; year--) {
            jsxContainer.push(getDropDownItem(year, year.toString(), "year", "yearStr"));
        }
        return jsxContainer;
    };
    var getYearsend = function () {
        var _a, _b;
        var currentYear = moment().year();
        var startingYear = (_b = (_a = props.date) === null || _a === void 0 ? void 0 : _a.year) !== null && _b !== void 0 ? _b : currentYear - 100;
        //console.log(yearRef);
        var jsxContainer = [];
        for (var year = currentYear; year >= startingYear; year--) {
            jsxContainer.push(getDropDownItem(year, year.toString(), "month", "monthStr"));
        }
        return jsxContainer;
    };
    var containerClass = "flex justify-between items-center p-2 border border-c-gray rounded text-c-text relative mr-4 hover:border-c-purple";
    var iconClass = "flex items-center cursor-pointer";
    var dropBoxClass = "p-text-hover-ep py-2 border border-c-gray rounded text-c-text absolute mr-4 hover:border-c-purple top-12 left-0 bg-white w-full h-40 z-10 overflow-y-scroll";
    var section = function (ctnClass, label, items, ref, isHidden, disable, state) {
        var tempLabel = state ? state : label;
        if (disable)
            ctnClass += ' bg-c-scroll hover:cursor-not-allowed ';
        return (React.createElement("div", { className: "".concat(containerClass, " ").concat(ctnClass, " ").concat(props.error ? 'border-c-red' : ''), ref: ref },
            React.createElement(Text, null, tempLabel),
            React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconColor }) }),
            React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(isHidden || disable ? 'hidden' : '') }, items)));
    };
    var render = function () {
        var _a, _b, _c, _d, _e, _f;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex flex-wrap basis-full justify-start mt-1" },
                React.createElement(Text, { additionalClass: 'pr-3 pt-3' }, lang[LG].datePicker.begining),
                section('w-28', lang[LG].datePicker.year, getYears(), yearRef, yearRefClickAway, (_a = props === null || props === void 0 ? void 0 : props.hideDate) !== null && _a !== void 0 ? _a : false, (_b = props.date) === null || _b === void 0 ? void 0 : _b.yearStr),
                React.createElement(Text, { additionalClass: 'pr-3 pt-3' }, lang[LG].datePicker.end),
                section('w-28', lang[LG].datePicker.year, getYearsend(), monthRef, monthRefClickAway, ((_c = props.date) === null || _c === void 0 ? void 0 : _c.year) == null || ((_d = props.date) === null || _d === void 0 ? void 0 : _d.year) == 0, (_e = props.date) === null || _e === void 0 ? void 0 : _e.monthStr)),
            props.error && React.createElement(TextError, { message: (_f = props.error.message) !== null && _f !== void 0 ? _f : '' })));
    };
    return render();
};
export default YearPicker;
