import React from "react";
var CheckBoxIconChecked = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { className: props.additionalClass, onClick: props.onClick, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "20", height: "20", viewBox: "0 0 20 20" },
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip-Square_checkbox_checked" },
                    React.createElement("rect", { width: "20", height: "20" }))),
            React.createElement("g", { id: "Square_checkbox_checked", clipPath: "url(#clip-Square_checkbox_checked)" },
                React.createElement("g", { id: "Group_2161", transform: "translate(-729 -606.633)" },
                    React.createElement("g", { id: "Rectangle_1228", transform: "translate(729 606.633)", fill: "#6c0c6c", stroke: "#6c0c6c", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1" },
                        React.createElement("rect", { width: "20", height: "20", rx: "3", stroke: "none" }),
                        React.createElement("rect", { x: "0.5", y: "0.5", width: "19", height: "19", rx: "2.5", fill: "none" })),
                    React.createElement("path", { id: "Icon_material-check", d: "M9.2,15.852,6.152,12.909l-1.037,1L9.2,17.85,17.967,9.38l-1.03-1Z", transform: "translate(727.459 603.515)", fill: "#fff" }))))));
};
export default CheckBoxIconChecked;
