import React from "react";
var MasterCardIcon = function (props) {
    var render = function () {
        var height = props.height ? props.height.toString() : '40';
        var width = props.width ? props.width.toString() : '40';
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", height: "".concat(height, "px"), width: "".concat(width, "px"), version: "1.1", viewBox: "-96 -98.908 832 593.448" },
            React.createElement("path", { id: "rect887", display: "inline", fill: "#ff5f00", strokeWidth: "5.494", d: "M224.833 42.298h190.416v311.005H224.833z" }),
            React.createElement("path", { id: "path889", d: "M244.446 197.828a197.448 197.448 0 0175.54-155.475 197.777 197.777 0 100 311.004 197.448 197.448 0 01-75.54-155.53z", fill: "#eb001b", strokeWidth: "5.494" }),
            React.createElement("path", { id: "path893", d: "M640 197.828a197.777 197.777 0 01-320.015 155.474 197.777 197.777 0 000-311.004A197.777 197.777 0 01640 197.773z", className: "e", fill: "#f79e1b", strokeWidth: "5.494" })));
    };
    return render();
};
export default MasterCardIcon;
