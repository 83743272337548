var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from "react";
import env from "../../config/env";
import Title from "../Title/Title";
import Functions from "../../utils/functions";
import { lang } from "../../lang/lang";
import { useForm } from "react-hook-form";
import Label from "../Field/Label";
import Validations from "../../utils/validations";
import TextArea from "../Input/TextArea";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../Input/Input";
import validations from "../../utils/validations";
import SubmitButton from "../Button/SubmitButton";
import useMutationContact from "../../hooks/questionnaire/useMutationContact";
import FormError from "../Error/FormError";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import clsx from "clsx";
import CircleCheck from "../../assets/icons/CircleCheck";
import Text from "../Field/Text";
import TextError from "../Field/TextError";
import { validation_lang } from "../../lang/validations";
var ContactForm = function (props) {
    var LG = Functions.getLang();
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, setValue = _a.setValue, reset = _a.reset, errors = _a.formState.errors;
    var _b = useState(false), messageSent = _b[0], setMessageSent = _b[1];
    var captchaRef = useRef(null);
    var postContact = useMutationContact().postContact;
    var _c = useState(), errorResponse = _c[0], setErrorResponse = _c[1];
    var fullPageLoader = useFullPageLoader();
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    fullPageLoader(true);
                    return [4 /*yield*/, postContact.mutateAsync(__assign(__assign({}, data), { hashedId: (_a = props.hash) !== null && _a !== void 0 ? _a : '' }))];
                case 1:
                    response = _c.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) !== 200)
                        setErrorResponse(response);
                    else {
                        setErrorResponse(undefined);
                        reset();
                        (_b = captchaRef === null || captchaRef === void 0 ? void 0 : captchaRef.current) === null || _b === void 0 ? void 0 : _b.reset();
                        props.onSuccess();
                        setMessageSent(true);
                    }
                    fullPageLoader(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var render = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                props.layout !== 'home' &&
                    React.createElement(Title, null, lang[LG].contact),
                props.layout === 'home' &&
                    React.createElement("div", { className: "mt-5" },
                        React.createElement(Label, null, lang[LG].yourEmailAddress),
                        React.createElement(Input, { additionalClass: "mt-2", register: register('email', Validations.email(LG)), title: 'email@email.com', type: "text", disableOnChange: true, error: errors.email })),
                React.createElement("div", { className: "mt-5" },
                    React.createElement(Label, null, lang[LG].subject),
                    React.createElement(Input, { additionalClass: "mt-2", register: register('subject', Validations.required(LG)), title: lang[LG].enterSubject, type: "text", disableOnChange: true, error: errors.subject })),
                React.createElement("div", { className: "mt-5" },
                    React.createElement(TextArea, { additionalClass: "mt-2", register: register('message', __assign(__assign({}, Validations.required(LG)), { maxLength: {
                                value: 500,
                                message: validation_lang[LG].messageMaxLength
                            } })), title: lang[LG].enterMessage, rows: 10, disableOnChange: true, error: errors.message })),
                React.createElement("input", __assign({ type: "hidden" }, register('g-recaptcha-response', validations.captcha(LG)))),
                React.createElement("div", { className: clsx({
                        "flex justify-between mt-5": true,
                        "flex-col space-y-5": props.layout === 'home',
                        "items-center": props.layout !== 'home',
                    }) },
                    React.createElement(ReCAPTCHA, { ref: captchaRef, className: "", sitekey: env.recaptcha, onChange: function (token) { return __awaiter(void 0, void 0, void 0, function () {
                            var value;
                            return __generator(this, function (_a) {
                                value = token !== null && token !== void 0 ? token : '';
                                setValue('g-recaptcha-response', value);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(TextError, { addtionalClass: "m-[0px]", message: (_b = (_a = errors["g-recaptcha-response"]) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : '' }),
                    React.createElement(SubmitButton, { additionalClass: clsx({
                            "w-fit": props.layout === 'home',
                        }), title: lang[LG].send })),
                messageSent &&
                    React.createElement("div", { className: "flex items-center my-5 space-x-2 w-112" },
                        React.createElement(CircleCheck, { additionalClass: "w-12", color: env.iconGreen }),
                        React.createElement(Text, null, lang[LG].contactUsSuccessMessage)),
                errorResponse !== null && React.createElement(FormError, { response: errorResponse }))));
    };
    return render();
};
export default ContactForm;
