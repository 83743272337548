import React, { useEffect, useState } from "react";
var Text = React.forwardRef(function (props, ref) {
    var _a, _b, _c;
    var display = (_a = props.display) !== null && _a !== void 0 ? _a : 'inline-block';
    var textWeight = props.isbold ? 'font-semibold' : 'font-light';
    textWeight = (_b = props.fontWeight) !== null && _b !== void 0 ? _b : textWeight;
    var fontSize = (_c = props.fontSize) !== null && _c !== void 0 ? _c : 'text-sm';
    var _d = useState(''), content = _d[0], setContent = _d[1];
    useEffect(function () {
        var content = props.children;
        if (props.searchText != null && props.searchText != '') {
            // const regex = new RegExp(props.searchText, "gi");
            // content = content.replace(regex, "<span class='bg-c-highlight'>$&</span>");
        }
        setContent(content);
    }, [props.children, props.searchText]);
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins ".concat(fontSize, " ").concat(textWeight, " ").concat(display, " ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), dangerouslySetInnerHTML: { __html: content }, onClick: props.onClick, ref: ref, title: props.title, id: props.id })));
    };
    return render();
});
export default Text;
