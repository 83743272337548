var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import TagSetInSingleQuestionnaire from "../TagSet/TagSetInSingleQuestionnaire";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import HeartIcon from "../../assets/icons/HeartIcon";
import useMutationFavourite from "../../hooks/questionnaire/useMutationFavourite";
import LightText from "../Field/LightText";
import { useSelector } from "react-redux";
import F from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import SingleQuestionnaire from "./SingleQuestionnaire";
import UpWard from "../../assets/icons/UpWard";
import DownWard from "../../assets/icons/DownWard";
var GroupQuestionnaire = function (props) {
    var _a;
    var user = useSelector(function (store) { return store.user; });
    var LG = F.getLang();
    var myCollection = props.selectedTab === 'myCollection' ? true : false;
    var navigate = useNavigate();
    var _b = useState(false), count = _b[0], setCount = _b[1];
    var contentRef = useRef(null);
    var _c = useState(0), height = _c[0], setHeight = _c[1];
    var _d = useState((_a = props.favourite) !== null && _a !== void 0 ? _a : 0), favourite = _d[0], setFavourite = _d[1];
    useEffect(function () {
        if (props.isOpen) {
            var contentEl = contentRef.current;
            setHeight(contentEl.scrollHeight);
        }
        else {
            setHeight(0);
        }
    }, [props.isOpen]);
    useEffect(function () {
        setFavourite(props.favourite);
    }, [props.favourite]);
    var postFavourite = useMutationFavourite().postFavourite;
    var frenchDiv = (React.createElement("div", { className: "border rounded-md shadow-md px-4 pb-1 mt-2 mx-2 h-8 w-max" },
        React.createElement("div", { className: "text-xs font-poppins flex container inline-flex" },
            React.createElement("div", { className: "ml-1" }, "Fran\u00E7ais"))));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "my-3 ".concat((count || props.selectedTab != 'myCollection') ? 'border border-c-gray rounded-lg hover:border-c-purple border-collapse' : '') },
            (count || props.selectedTab != 'myCollection') && React.createElement("div", { className: "flex justify-between h-fit min-h-[7rem] hover:bg-c-scroll hover:rounded-lg hover:cursor-pointer w-full", style: { zIndex: 1 } },
                React.createElement("div", { className: "container flex inline w-10/12", onClick: props.btnOnClick },
                    " ",
                    React.createElement("div", { className: "flex items-center justify-left" },
                        React.createElement("img", { src: props.photo_url, alt: lang[LG].partnerLogo, className: "h-[100%] w-auto max-w-[160px] max-h-[142px]", onClick: function () {
                                if (props.url != null) {
                                    window.open(props.url, '_blank');
                                }
                            } })),
                    React.createElement("div", { className: "container pt-4 pb-6 pl-6" },
                        React.createElement("div", { className: "container flex" },
                            React.createElement("div", { className: "flex flex-grow" },
                                React.createElement(LightText, { additionalClass: "flex-grow font-poppins text-l font-semibold", searchText: props.searchText }, props.title))),
                        React.createElement("div", { className: "mt-3" },
                            React.createElement(TagSetInSingleQuestionnaire, { titleSet: props.tagSet, searchTag: props.searchTag })))),
                React.createElement("div", { className: "h-full p-2 w-fit" },
                    React.createElement("div", { className: "w-fit" },
                        React.createElement("div", { className: "flex h-12 relative stack-top right-0 float-right" },
                            frenchDiv,
                            React.createElement("div", { className: "z-20 relative" }, user.value.isLoggedIn &&
                                React.createElement(HeartIcon, { additionalClass: "mt-3 ml-1 mr-2", color: favourite == 0 ? env.iconLightGrey : env.iconRed, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var response;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, postFavourite.mutateAsync({
                                                        questionnaireCateId: props.catId
                                                    })];
                                                case 1:
                                                    response = _a.sent();
                                                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                                                        setFavourite(favourite === 0 ? 1 : 0);
                                                    }
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } }))),
                        React.createElement("div", { className: "container flex items-center text-c-purple pb-[10%] pt-4 w-fit float-right", onClick: props.btnOnClick },
                            React.createElement("a", { className: "pl-2 flex text-sm font-poppins font-light" },
                                lang[LG].accordionText,
                                props.isOpen && React.createElement(UpWard, { height: 18, width: 18, additionalClass: "mr-1" }),
                                !props.isOpen && React.createElement(DownWard, { color: env.iconColorPurple, height: 18, width: 18, additionalClass: "mr-1" })))),
                    myCollection && React.createElement("div", { className: "" }))),
            React.createElement("div", { ref: contentRef, className: "".concat(props.isOpen ? 'block' : 'hidden') }, props.questionnaire.map(function (questionnaire, idx) {
                var _a, _b;
                var quantity = (_a = questionnaire.quantity) !== null && _a !== void 0 ? _a : 0;
                if (props.selectedTab === 'myCollection' && quantity === 0) {
                    return;
                }
                else if (count == false && props.selectedTab == 'myCollection') {
                    setCount(true);
                }
                /*if(props.searchText != '' && !props.isOpen)
                  props.btnOnClick()*/
                return (React.createElement(SingleQuestionnaire, { key: "que-".concat(questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.id), questionnaireId: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.id, tagSet: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.tags, title: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.title, content: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.short_description, price: (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.price) / 100, ageSmall: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.age_lower_band, ageLarge: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.age_upper_band, general: (_b = questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.general) !== null && _b !== void 0 ? _b : 0, quantity: quantity, searchText: props.searchText, partnerLogo: questionnaire.photo_url, selectedTab: props.selectedTab, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (props.hideMyCollection)
                                navigate("".concat(F.removeParams(env.routes.questionnairePreviewHome)).concat(questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.id));
                            else
                                navigate("".concat(F.removeParams(env.routes.questionnairePreview)).concat(questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.id));
                            return [2 /*return*/];
                        });
                    }); }, favourite: questionnaire.favourite, hideMyCollection: props.hideMyCollection }));
            })))));
};
export default GroupQuestionnaire;
