import React from "react";
import clsx from "clsx";
var NotAlignedCenterModal = function (props) {
    var render = function () {
        var _a;
        var _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: clsx((_a = {
                        "h-screen w-screen flex bg-c-gray justify-center": true
                    },
                    _a[(_b = props.additionalCls) !== null && _b !== void 0 ? _b : ''] = true,
                    _a)) }, props.children)));
    };
    return render();
};
export default NotAlignedCenterModal;
