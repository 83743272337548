import React from "react";
import ReactDOM from "react-dom/client";
import CustomRoutes from "./components/CustomRoutes/CustomRoutes";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import store from './store';
import DisableDevtool from 'disable-devtool';
import { QueryClient, QueryClientProvider } from 'react-query';
var queryClient = new QueryClient();
var container = document.getElementById('app');
var root = ReactDOM.createRoot(container);
DisableDevtool();
root.render(React.createElement(React.StrictMode, null,
    React.createElement(Provider, { store: store },
        React.createElement(CookiesProvider, null,
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(CustomRoutes, null))))));
