var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from "react";
import Container from "../Layouts/Container";
import { lang } from "../../lang/lang";
import TextIconField from "../Input/TextIconField";
import SearchIcon from "../../assets/icons/SearchIcon";
import env from "../../config/env";
import AgeSelector from "../DropDown/AgeSelector";
import Tag from "../TagSet/Tag";
import RedTag from "../TagSet/RedTag";
import GroupQuestionnaire from "../QuestionnaireList/GroupQuestionnaire";
import F from "../../utils/functions";
import Text from "../Field/Text";
import { useQueryClients } from "../../hooks/client/useQueryClient";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useQueryTags from "../../hooks/questionnaire/useQueryTags";
import clsx from "clsx";
import CloseIcon from "../../assets/icons/CloseIcon";
import IconContainer from "../IconContainer/IconContainer";
import SubmitButton from "../Button/SubmitButton";
import Cart from "../../assets/icons/Cart";
import TextWithIcon from "../Field/TextWithIcon";
import AddDocument from "../../assets/icons/AddDocument";
import HeartIcon from "../../assets/icons/HeartIcon";
import IconButton from "../Button/IconButton";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import ForwardIcon from "../../assets/icons/ForwardIcon";
var AssessmentTool = function (props) {
    var LG = F.getLang();
    var _a = useState(props.hideMyCollection != null ? 'allCollection' : 'myCollection'), selectedTab2 = _a[0], setSelectedTab2 = _a[1];
    var navigate = useNavigate();
    var user = useSelector(function (store) { return store.user; });
    var _b = useState([]), tags = _b[0], setTags = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(true), isClose = _d[0], setIsClose = _d[1];
    var getTags = useQueryTags().getTags;
    var inputRef = useRef(null);
    var _e = useState([]), currentIdx = _e[0], setCurrentIdx = _e[1];
    var btnOnClick = function (idx) {
        var array = __spreadArray([], currentIdx, true); // make a separate copy of the array
        var index = array.indexOf(idx);
        if (index !== -1) {
            array.splice(index, 1);
            setCurrentIdx(array);
        }
        else {
            var arrayNew = __spreadArray(__spreadArray([], array, true), [idx], false);
            setCurrentIdx(arrayNew);
        }
    };
    useEffect(function () {
        var _a, _b;
        if (getTags.status !== 'loading') {
            setTags((_b = (_a = getTags.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : []);
        }
    }, [getTags]);
    useEffect(function () {
        getTags.refetch();
        SetTag([]);
    }, [LG]);
    var fetchQuestionnairesSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, searchQuestionnaires.mutateAsync({
                        sort: questionnaireSorting === null || questionnaireSorting === void 0 ? void 0 : questionnaireSorting.data,
                        keyword: searchInputOfQuestionnaires,
                        min_age: smallAge,
                        max_age: bigAge,
                        tags: tag.join(','),
                        favourite: favouriteTag,
                        user_id: user.value.id,
                    })];
                case 1:
                    response = _b.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        // @ts-ignore
                        SetQuestionnairesOfSearch(response === null || response === void 0 ? void 0 : response.data.questionnaires);
                        (_a = props.setTutorial) === null || _a === void 0 ? void 0 : _a.call(props, response === null || response === void 0 ? void 0 : response.data.myCollection);
                        setCurrentIdx([]);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _f = useState(0), smallAge = _f[0], SetSmallAge = _f[1];
    var _g = useState(100), bigAge = _g[0], SetBigAge = _g[1];
    var _h = useState(false), showRemoveAge = _h[0], setShowRemoveAge = _h[1];
    var baseAgeArray = [{ key: 1, title: '0', data: 0 }, { key: 2, title: '2', data: 2 }, {
            key: 3,
            title: '4',
            data: 4
        }, { key: 4, title: '6', data: 6 }, { key: 5, title: '8', data: 8 }, {
            key: 6,
            title: '10',
            data: 10
        }, {
            key: 7,
            title: '12',
            data: 12
        }, { key: 8, title: '14', data: 14 }, { key: 9, title: '16', data: 16 }, {
            key: 10,
            title: '18',
            data: 18
        }, {
            key: 11,
            title: '20',
            data: 20
        }, { key: 12, title: '22', data: 22 }, { key: 13, title: '24', data: 24 }, {
            key: 14,
            title: '26',
            data: 26
        }, {
            key: 15,
            title: '28',
            data: 28
        }, { key: 16, title: '30', data: 30 }, { key: 17, title: '32', data: 32 }, {
            key: 18,
            title: '34',
            data: 34
        }];
    var _j = useState(baseAgeArray), bigAgeArray = _j[0], SetBigAgeArray = _j[1];
    function generateBigAgeArray(input) {
        var result = [];
        for (var i = 0; i < baseAgeArray.length; i++) {
            if (baseAgeArray[i].data >= input) {
                result.push(baseAgeArray[i]);
            }
            else
                continue;
        }
        return result;
    }
    var searchQuestionnaires = useQueryClients().searchQuestionnaires;
    var _k = useState([]), questionnairesFromAPIOfSearch = _k[0], SetQuestionnairesOfSearch = _k[1]; // this state saves data of search api
    var _l = useState([]), tag = _l[0], SetTag = _l[1];
    var _m = useState(false), favouriteTag = _m[0], setFavouriteTag = _m[1];
    var _o = useState(), questionnaireSorting = _o[0], setQuestionnaireSorting = _o[1];
    var _p = useState(""), searchInputOfQuestionnaires = _p[0], setSearchInputOfQuestionnaires = _p[1];
    useEffect(function () {
        fetchQuestionnairesSearch();
    }, [smallAge, bigAge, searchInputOfQuestionnaires, tag, favouriteTag, questionnaireSorting, user.value.id]);
    var saveTag = function (value) {
        var temp = tag;
        if (temp.indexOf(value) !== -1) {
            temp = temp.filter(function (val) { return val != value; });
        }
        else {
            temp = __spreadArray(__spreadArray([], temp, true), [value], false);
        }
        if (temp.length > 0) {
            setShowRemoveAge(true);
        }
        SetTag(temp);
    };
    var _q = useState(false), ifClickRemoveAgeFilter = _q[0], setIfClickRemoveAgeFilter = _q[1];
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drop-shadow-2xl" },
                React.createElement("div", { className: 'flex mb-0 divide-black' },
                    props.hideMyCollection == null && React.createElement(Text, { onClick: function () {
                            setSelectedTab2('myCollection');
                        }, isbold: true, fontSize: 'text-base', display: 'inline', additionalClass: "px-8 pt-6 pb-3 rounded-t-md cursor-pointer relative top-0 mr-4 ".concat(selectedTab2 === 'allCollection' ? 'text-c-text bg-gray-50' : 'bg-white') }, lang[LG].myCollection),
                    isClose && props.tutorialCount == 3 && React.createElement("span", { className: "absolute w-72 top-[-16rem] left-0 rounded shadow-lg text-white text-base bg-black p-4 scale-100" },
                        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColorWhite, height: 14, width: 14 }), onClick: function () { return setIsClose(false); } }),
                        React.createElement("span", { className: "flex font-semibold mb-2 font-poppins" },
                            " ",
                            React.createElement(AddDocument, { color: env.iconColorWhite, additionalClass: "mr-2" }),
                            " ",
                            lang[LG].tutorial.myCollection),
                        " ",
                        React.createElement("div", { className: "tooltip-arrow", "data-popper-arrow": true }),
                        React.createElement("span", { className: "mt-8" }, lang[LG].tutorial.myCollectionText),
                        React.createElement("div", { className: "flex mt-5 gap-2" },
                            React.createElement(IconButton, { title: "", additionalClass: "h-8 w-8", border: 'border-c-white', icon: React.createElement(BackwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    props === null || props === void 0 ? void 0 : props.updateTutorial(2);
                                    return [2 /*return*/];
                                }); }); } }),
                            React.createElement(IconButton, { title: "", additionalClass: "h-8 w-8 absolute right-0 space-x-4 mr-3", border: 'border-c-white', icon: React.createElement(ForwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    props === null || props === void 0 ? void 0 : props.updateTutorial(4);
                                    return [2 /*return*/];
                                }); }); } }))),
                    React.createElement(TextWithIcon, { display: 'inline', onClick: function () {
                            setSelectedTab2('allCollection');
                        }, isbold: true, fontSize: 'text-base', additionalClass: "px-8 pt-6 pb-3 rounded-t-md cursor-pointer relative top-0 mr-4 ".concat(selectedTab2 === 'myCollection' ? 'text-c-text bg-gray-50' : 'bg-white') },
                        React.createElement("div", { className: "flex" },
                            React.createElement(Cart, { color: "".concat(selectedTab2 === 'myCollection' ? env.iconLightGrey : env.iconGrey), additionalClass: "mr-2" }),
                            lang[LG].assesmentTool)),
                    isClose && props.tutorialCount == 2 && React.createElement("span", { className: "absolute w-72 top-[-16rem] left-48 rounded shadow-lg text-white text-base bg-black p-4 scale-100" },
                        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColorWhite, height: 14, width: 14 }), onClick: function () { return setIsClose(false); } }),
                        React.createElement("span", { className: "flex font-semibold mb-2 font-poppins" },
                            " ",
                            React.createElement(Cart, { color: env.iconColorWhite, additionalClass: "mr-2" }),
                            " ",
                            lang[LG].tutorial.getQuestionnaire),
                        " ",
                        React.createElement("div", { className: "tooltip-arrow", "data-popper-arrow": true }),
                        React.createElement("span", { className: "mt-8" }, lang[LG].tutorial.getQuestionnaireText),
                        React.createElement("div", { className: "flex mt-5 gap-2" },
                            React.createElement(IconButton, { title: "", additionalClass: "h-8 w-8", border: 'border-c-white', icon: React.createElement(BackwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    props === null || props === void 0 ? void 0 : props.updateTutorial(1);
                                    return [2 /*return*/];
                                }); }); } }),
                            React.createElement(IconButton, { title: "", additionalClass: "h-8 w-8 absolute right-0 space-x-4 mr-3", border: 'border-c-white', icon: React.createElement(ForwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    props === null || props === void 0 ? void 0 : props.updateTutorial(3);
                                    return [2 /*return*/];
                                }); }); } })))),
                React.createElement(Container, null,
                    React.createElement("div", { className: "flex justify-between mt-3" },
                        React.createElement("div", { className: "flex" },
                            React.createElement("div", { className: "mr-4" },
                                React.createElement(TextIconField, { ref: inputRef, position: 'left', icon: React.createElement(SearchIcon, { color: env.iconColor }), title: lang[LG].search, type: 'text', additionalClass: 'lg:w-80 md:40 w-fit', additionalInputClass: clsx({
                                        'bg-c-scroll': searchInputOfQuestionnaires == '',
                                        'h-10': true,
                                    }), onChange: function (event) {
                                        setShowRemoveAge(true);
                                        setSearchInputOfQuestionnaires(event.target.value);
                                    } })),
                            React.createElement("div", { className: "mr-4 relative" },
                                React.createElement(Tag, { isSelected: open, key: 'title-0', title: lang[LG].tagSearch + (tag.length > 0 ? ' (' + tag.length + ')' : ''), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            setOpen(true);
                                            return [2 /*return*/];
                                        });
                                    }); } }),
                                React.createElement("div", { className: "absolute w-200 max-h-96 h-92 px-2 pt-2 pb-5 mt-2 rounded-lg shadow-xl border border-c-gray bg-white overflow-y-scroll z-20 ".concat(open ? "block" : "hidden") },
                                    React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () { return setOpen(false); } }),
                                    React.createElement(Text, { isbold: true, additionalClass: "mb-1 pl-2" }, lang[LG].tagSSelect),
                                    React.createElement("ul", { className: " flex flex-wrap mt-1 h-60 overflow-y-scroll ".concat(open ? "block" : "hidden") }, tags.map(function (tag_1, i) { return (React.createElement("li", { className: "items-center h-fit px-1 py-1 text-sm hover:bg-gray-100", key: "tag-".concat(i) },
                                        React.createElement(Tag, { isSelected: tag.indexOf(tag_1.tag_word) !== -1, key: i, title: F.uFirst(tag_1.tag_word), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    saveTag(tag_1.tag_word);
                                                    return [2 /*return*/];
                                                });
                                            }); } }))); })),
                                    tag.length > 0 && React.createElement("div", { className: "static" },
                                        React.createElement("button", { onClick: function () {
                                                SetTag([]);
                                            } },
                                            React.createElement("p", { className: "font-poppins text-left text-sm text-c-purple underline" }, lang[LG].removeTagFilter))))),
                            React.createElement("div", { className: "grid justify-items-center items-center mr-3 ml-2" },
                                lang[LG].age,
                                ":"),
                            React.createElement("div", { className: "flex flex-col justify-center items-center" },
                                React.createElement(AgeSelector, { items: baseAgeArray, defaultValue: "Min.", onChange: function (e) {
                                        SetBigAgeArray(generateBigAgeArray(e.data));
                                        SetSmallAge(e.data);
                                        setIfClickRemoveAgeFilter(false);
                                        setShowRemoveAge(true);
                                    }, width: "lg:w-24 w-fit", ifRemoveAge: ifClickRemoveAgeFilter })),
                            React.createElement("div", { className: "grid justify-items-center items-center mx-2" }, "-"),
                            React.createElement("div", { className: "flex flex-col justify-center items-center" },
                                React.createElement(AgeSelector, { items: bigAgeArray, defaultValue: "Max.", onChange: function (e) {
                                        SetBigAge(e.data);
                                        setIfClickRemoveAgeFilter(false);
                                        setShowRemoveAge(true);
                                    }, width: "lg:w-24 w-fit", ifRemoveAge: ifClickRemoveAgeFilter })),
                            showRemoveAge && React.createElement("div", { className: "self-center min-w-fit ml-3" },
                                React.createElement("button", { onClick: function () {
                                        SetBigAge(34);
                                        SetSmallAge(0);
                                        setIfClickRemoveAgeFilter(true);
                                        setShowRemoveAge(false);
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                        }
                                        setSearchInputOfQuestionnaires('');
                                        SetTag([]);
                                    } },
                                    React.createElement("p", { className: "font-poppins font-light text-left text-sm text-c-purple underline" }, lang[LG].removeFilter)))),
                        React.createElement("div", { className: "flex items-center" },
                            user.value.isLoggedIn && React.createElement("div", null,
                                React.createElement(RedTag, { isSelected: false, title: lang[LG].favorites, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            if (favouriteTag) {
                                                setFavouriteTag(false);
                                            }
                                            else {
                                                setFavouriteTag(true);
                                            }
                                            return [2 /*return*/];
                                        });
                                    }); }, select: favouriteTag })),
                            isClose && props.tutorialCount == 6 && React.createElement("span", { className: "absolute w-64 top-[15px] right-48 rounded shadow-lg text-white text-base bg-black p-4 scale-100" },
                                React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColorWhite, height: 14, width: 14 }), onClick: function () { return setIsClose(false); } }),
                                React.createElement("span", { className: "flex font-semibold mb-2 font-poppins" },
                                    " ",
                                    React.createElement(HeartIcon, { color: env.iconColorWhite, additionalClass: "mr-2" }),
                                    " ",
                                    lang[LG].tutorial.viewFav),
                                " ",
                                React.createElement("div", { className: "tooltip-arrow", "data-popper-arrow": true }),
                                React.createElement("span", { className: "mt-8" }, lang[LG].tutorial.viewFavText),
                                React.createElement("div", { className: "flex mt-5 gap-2 space-x-2" },
                                    React.createElement(IconButton, { title: "", additionalClass: "h-8 w-8", border: 'border-c-white', icon: React.createElement(BackwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                            props === null || props === void 0 ? void 0 : props.updateTutorial(5);
                                            return [2 /*return*/];
                                        }); }); } }))))),
                    React.createElement("div", { className: "max-h-124 min-h-[30rem] mt-2 overflow-y-scroll overflow-x-hidden" },
                        questionnairesFromAPIOfSearch.map(function (questionnaire, idx) {
                            if (questionnaire.questionnaire.length === 0) {
                                return;
                            }
                            return (React.createElement(GroupQuestionnaire, { key: "group-".concat(idx), catId: questionnaire.id, searchText: searchInputOfQuestionnaires, searchTag: tag, selectedTab: selectedTab2, questionnaire: questionnaire.questionnaire, title: questionnaire.title, tagSet: questionnaire.tags, photo_url: questionnaire.photo_url, url: questionnaire.url, favourite: questionnaire.favourite, 
                                /*onClick={async () => {
                                  if (props.hideMyCollection)
                                    navigate(`${F.removeParams(env.routes.questionnairePreviewHome)}${questionnaire?.id}`)
                                  else
                                    navigate(`${F.removeParams(env.routes.questionnairePreview)}${questionnaire?.id}`)
                                }}*/
                                hideMyCollection: props.hideMyCollection, isOpen: currentIdx.indexOf(idx) >= 0, btnOnClick: function () { return btnOnClick(idx); } }));
                        }),
                        selectedTab2 == 'myCollection' && React.createElement("div", { className: 'mt-4 flex justify-center' },
                            React.createElement(SubmitButton, { title: lang[LG].moreTool, additionalClass: 'text-sm', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        setSelectedTab2('allCollection');
                                        return [2 /*return*/];
                                    });
                                }); } })))))));
    };
    return render();
};
export default AssessmentTool;
