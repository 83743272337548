import React from "react";
var CircleCheck = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() + 'px' : '24px';
        var width = props.width ? props.width.toString() + 'px' : '24px';
        return (React.createElement("svg", { className: "".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", height: height, viewBox: "0 0 24 24", width: width, fill: props.color },
            React.createElement("path", { d: "M0 0h24v24H0V0z", fill: "none" }),
            React.createElement("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z" })));
    };
    return render();
};
export default CircleCheck;
