import { useQuery } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var KEY = 'GET-QUESTIONNAIRE-TAGS';
var _getTags = function () {
    return Api.get(env.api.getTags);
};
export default function useQueryTags() {
    var getTags = useQuery(KEY, _getTags, {
        refetchOnWindowFocus: false,
        cacheTime: 0
    });
    return { getTags: getTags };
}
