import React from "react";
import clsx from "clsx";
var Tag = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: props.onClick, className: clsx({
                "border border-c-purple rounded w-fix px-4 hover:bg-c-light-rose hover:text-c-purple mr-1 my-0.5 text-sm font-poppins font-light py-2": true,
                "text-c-purple": !props.isSelected,
                "bg-c-purple text-white": props.isSelected,
            }) }, "#".concat(props.title))));
};
export default Tag;
