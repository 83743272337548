import React from "react";
var BasicTag = function (props) {
    var baseClassMouseMoveOut = "bg-c-light-rose";
    var baseClassMouseMoveIn = "bg-c-purple text-white";
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container border border-c-light-rose rounded w-fit py-2 justify-items-center mr-2 my-1 ".concat(props.isSelected ? baseClassMouseMoveIn : baseClassMouseMoveOut) },
            React.createElement("a", { className: "text-sm font-poppins font-light flex inline mx-3 ".concat(props.isSelected ? 'text-c-white' : 'text-c-purple') },
                "#",
                props.title))));
};
export default BasicTag;
