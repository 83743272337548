export var ERespondentVersion;
(function (ERespondentVersion) {
    ERespondentVersion["learner"] = "Apprenant";
    ERespondentVersion["parent"] = "Parent";
    ERespondentVersion["professional"] = "Professionnel";
})(ERespondentVersion || (ERespondentVersion = {}));
export var ERespondentVersionEnglish;
(function (ERespondentVersionEnglish) {
    ERespondentVersionEnglish["learner"] = "Learner";
    ERespondentVersionEnglish["parent"] = "Parent";
    ERespondentVersionEnglish["professional"] = "Professional";
})(ERespondentVersionEnglish || (ERespondentVersionEnglish = {}));
