import clsx from "clsx";
import React from "react";
import Text from "../Field/Text";
var LightSubmitButton = function (props) {
    var _a;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : "submit";
    var radius = props.radius ? props.radius : 'rounded-full';
    var flexClass = props.flexDisable === true ? "inline" : "flex";
    var render = function () {
        var _a;
        var _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: clsx((_a = {
                        "bg-white text-c-purple font-poppins px-5 py-2 border border-solid border-c-purple": true,
                        "items-center": true
                    },
                    _a[(_b = props.additionalClass) !== null && _b !== void 0 ? _b : ''] = true,
                    _a[radius] = true,
                    _a[flexClass] = true,
                    _a)) },
                React.createElement(Text, { additionalClass: "mx-1", fontWeight: "text-normal" }, props.title))));
    };
    return render();
};
export default LightSubmitButton;
