var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from "react";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUpdateLanguage } from "../../hooks/miscellaneous/useUpdateLanguage";
import Navigation from "./Navigation";
import DownWard from "../../assets/icons/DownWard";
import env from "../../config/env";
import { helpCenterFR, helpCenterEn } from "../../enums/helpCenter";
import DownArrow from "../../assets/icons/DownArrow";
var HelpCenter = function () {
    var LG = F.getLang();
    var _a = useState(false), flag = _a[0], setFlag = _a[1];
    var navigate = useNavigate();
    var gotoUrl = useCallback(function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(path);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var userSignUpForm = useSelector(function (store) { return store.userSignUp.value; });
    var updateLanguage = useUpdateLanguage();
    var checkLang = function (lang) {
        if (flag) {
            updateLanguage(lang);
        }
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                React.createElement(Navigation, { title: lang[LG].helpCenterFront.title }),
                React.createElement("div", { className: "grid w-3/5 mx-auto mt-8 font-poppins" },
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? helpCenterFR.title1 : helpCenterEn.title1),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn max-h-96 overflow-y-scroll" },
                                React.createElement("details", { className: "group/edit shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title11 : helpCenterEn.title11),
                                        React.createElement("span", { className: "transition group-open/edit:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/edit:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description11 : helpCenterEn.description11) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title12 : helpCenterEn.title12),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description12 : helpCenterEn.description12) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title13 : helpCenterEn.title13),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description13 : helpCenterEn.description13) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title14 : helpCenterEn.title14),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description14 : helpCenterEn.description14) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title15 : helpCenterEn.title15),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description15 : helpCenterEn.description15) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title16 : helpCenterEn.title16),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description16 : helpCenterEn.description16) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title17 : helpCenterEn.title17),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description17 : helpCenterEn.description17) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title18 : helpCenterEn.title18),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description18 : helpCenterEn.description18) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title110 : helpCenterEn.title110),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description110 : helpCenterEn.description110) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title111 : helpCenterEn.title111),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description111 : helpCenterEn.description111) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title112 : helpCenterEn.title112),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description112 : helpCenterEn.description112) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title113 : helpCenterEn.title113),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description113 : helpCenterEn.description113) } }))))),
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? helpCenterFR.title2 : helpCenterEn.title2),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn max-h-96 overflow-y-scroll" },
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title21 : helpCenterEn.title21),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description21 : helpCenterEn.description21) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title22 : helpCenterEn.title22),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description22 : helpCenterEn.description22) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title23 : helpCenterEn.title23),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description23 : helpCenterEn.description23) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title24 : helpCenterEn.title24),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description24 : helpCenterEn.description24) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title25 : helpCenterEn.title25),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description25 : helpCenterEn.description25) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title26 : helpCenterEn.title26),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description26 : helpCenterEn.description26) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title27 : helpCenterEn.title27),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description27 : helpCenterEn.description27) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title28 : helpCenterEn.title28),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description28 : helpCenterEn.description28) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title29 : helpCenterEn.title29),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description29 : helpCenterEn.description29) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title210 : helpCenterEn.title210),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description210 : helpCenterEn.description210) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title211 : helpCenterEn.title211),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description211 : helpCenterEn.description211) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title212 : helpCenterEn.title212),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description212 : helpCenterEn.description212) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title213 : helpCenterEn.title213),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description213 : helpCenterEn.description213) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title214 : helpCenterEn.title214),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description214 : helpCenterEn.description214) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title215 : helpCenterEn.title215),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description215 : helpCenterEn.description215) } })),
                                React.createElement("details", { className: "group/item21 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title216 : helpCenterEn.title216),
                                        React.createElement("span", { className: "transition group-open/item21:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item21:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description216 : helpCenterEn.description216) } }))))),
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? helpCenterFR.title3 : helpCenterEn.title3),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn max-h-96 overflow-y-scroll" },
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title31 : helpCenterEn.title31),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description31 : helpCenterEn.description31) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title32 : helpCenterEn.title32),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description32 : helpCenterEn.description32) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title33 : helpCenterEn.title33),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description33 : helpCenterEn.description33) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title34 : helpCenterEn.title34),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description34 : helpCenterEn.description34) } })),
                                React.createElement("details", { className: "group/item11 shadow-lg p-5" },
                                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                        React.createElement("span", null, LG == "fr" ? helpCenterFR.title35 : helpCenterEn.title35),
                                        React.createElement("span", { className: "transition group-open/item11:rotate-180" },
                                            React.createElement(DownArrow, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open/item11:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? helpCenterFR.description35 : helpCenterEn.description35) } })))))))));
    };
    return render();
};
export default HelpCenter;
