import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _getSendReminder = function (data) {
    return Api.getD(data, env.api.sentReminder);
};
var _getSendInvoice = function (data) {
    return Api.getD(data, env.api.sendInvoice);
};
export default function useMutationSendReminder() {
    var getSendReminder = useMutation(function (data) { return _getSendReminder(data); });
    var getSendInvoice = useMutation(function (data) { return _getSendInvoice(data); });
    return { getSendReminder: getSendReminder, getSendInvoice: getSendInvoice };
}
