import React, { memo } from 'react';
var CircularLoader = function (props) {
    var render = function () {
        var _a, _b;
        var heightCls = (_a = props.heightCls) !== null && _a !== void 0 ? _a : 'h-40';
        var widthCls = (_b = props.heightCls) !== null && _b !== void 0 ? _b : 'w-40';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(heightCls, " ").concat(widthCls) },
                React.createElement("div", { className: "\n                            border-solid \n                            border-8 \n                            border-t-c-purple \n                            animate-spin-slow \n                            rounded-full\n                            h-full\n                            " }))));
    };
    return render();
};
export default memo(CircularLoader);
