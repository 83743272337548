import React from "react";
var DownArrow = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() : '24';
        var width = props.width ? props.width.toString() : '24';
        return (React.createElement("svg", { onClick: props.onClick, className: (_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', xmlns: "http://www.w3.org/2000/svg", height: "".concat(height, "px"), width: "".concat(width, "px"), 
            // viewBox={`0 0 ${height} ${width}`}
            fill: props.color },
            React.createElement("path", { d: "M10,10L0,0h20L10,10z" })));
    };
    return render();
};
export default DownArrow;
