export var EQuestionnaireActivity;
(function (EQuestionnaireActivity) {
    EQuestionnaireActivity["n"] = "";
    EQuestionnaireActivity["na"] = "na";
    EQuestionnaireActivity["never"] = "never";
    EQuestionnaireActivity["rarely"] = "rarely";
    EQuestionnaireActivity["sometimes"] = "sometimes";
    EQuestionnaireActivity["often"] = "often";
    EQuestionnaireActivity["always"] = "always";
})(EQuestionnaireActivity || (EQuestionnaireActivity = {}));
export var EQuestionnaireAssistance;
(function (EQuestionnaireAssistance) {
    EQuestionnaireAssistance["n"] = "";
    EQuestionnaireAssistance["none"] = "none";
    EQuestionnaireAssistance["low"] = "low";
    EQuestionnaireAssistance["moderate"] = "moderate";
    EQuestionnaireAssistance["significant"] = "significant";
    EQuestionnaireAssistance["extreme"] = "extreme";
})(EQuestionnaireAssistance || (EQuestionnaireAssistance = {}));
export var EAnaQuestionRadio;
(function (EAnaQuestionRadio) {
    EAnaQuestionRadio["na"] = "na";
    EAnaQuestionRadio["oui"] = "oui";
    EAnaQuestionRadio["nan"] = "nan";
    EAnaQuestionRadio["plus"] = "+/-";
})(EAnaQuestionRadio || (EAnaQuestionRadio = {}));
