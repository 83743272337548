import React from "react";
import Href from "../../components/Href/Href";
import { politicsConditionsFR, politicsConfidentialFR, politicsCoppaFR, politicsRelativeFR, politicsProductFR, politicsCookiesFR } from "../../enums/politicsNew";
import { useParams } from "react-router-dom";
var PoliticsRelative = function () {
    var LG = 'fr';
    var params = useParams();
    var value = params.value;
    var politics = (value == 'Privacy-policies-for-Eduplan-Solutions-products') ? politicsProductFR :
        (value == 'Politique-relative-à-edpro') ? politicsRelativeFR :
            (value == 'Conditions-d\'utilisation') ? politicsConditionsFR :
                (value == 'Programme-de-gestion-de-la-confidentialité') ? politicsConfidentialFR :
                    (value == 'Conformité-réglementaire-selon-le-territoire-(COPPA)') ? politicsCoppaFR : politicsCookiesFR;
    var render = function () {
        return (React.createElement("div", { className: "grid grid-cols-6 gap-4 my-8" },
            React.createElement("div", { className: "col-span-2" },
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side1, href: "#side1" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side2, href: "#side2" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side3, href: "#side3" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side4, href: "#side4" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side5, href: "#side5" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side6, href: "#side6" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side7, href: "#side7" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side8, href: "#side8" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side9, href: "#side9" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side10, href: "#side10" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side11, href: "#side11" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side12, href: "#side12" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side13, href: "#side13" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side14, href: "#side1" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side15, href: "#side2" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side16, href: "#side3" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side17, href: "#side4" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side18, href: "#side5" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side19, href: "#side6" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side20, href: "#side7" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side21, href: "#side8" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side22, href: "#side9" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side23, href: "#side10" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side24, href: "#side11" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side25, href: "#side12" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side26, href: "#side13" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side27, href: "#side13" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side28, href: "#side13" }),
                React.createElement(Href, { className: "flex text-c-purple cursor-pointer p-1 pl-12", title: politics.side29, href: "#side13" })),
            React.createElement("div", { className: "col-span-3" },
                React.createElement("div", { className: "pt-5", id: "side1" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side1 : politics.side1)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side1Intro : politics.side1Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side2" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side2 : politics.side2)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side2Intro : politics.side2Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side3" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side3 : politics.side3)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side3Intro : politics.side3Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side4" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side4 : politics.side4)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side4Intro : politics.side4Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side5" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side5 : politics.side5)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side5Intro : politics.side5Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side6" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side6 : politics.side6)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side6Intro : politics.side6Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side7" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side7 : politics.side7)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side7Intro : politics.side7Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side8" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side8 : politics.side8)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side8Intro : politics.side8Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side9" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side9 : politics.side9)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side9Intro : politics.side9Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side10" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side10 : politics.side10)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side10Intro : politics.side10Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side11" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side11 : politics.side11)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side11Intro : politics.side11Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side12" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side12 : politics.side12)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side12Intro : politics.side12Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side13" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side13 : politics.side13)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side13Intro : politics.side13Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side14" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side14 : politics.side14)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side14Intro : politics.side14Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side15" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side15 : politics.side15)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side15Intro : politics.side15Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side16" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side16 : politics.side16)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side16Intro : politics.side16Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side17" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side17 : politics.side17)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side17Intro : politics.side17Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side18" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side18 : politics.side18)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side18Intro : politics.side18Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side19" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side19 : politics.side19)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side19Intro : politics.side19Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side20" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side20 : politics.side20)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side20Intro : politics.side20Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side21" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side21 : politics.side21)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side21Intro : politics.side21Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side22" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side22 : politics.side22)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side22Intro : politics.side22Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side23" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side23 : politics.side23)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side23Intro : politics.side23Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side24" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side24 : politics.side24)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side24Intro : politics.side24Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side25" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side25 : politics.side25)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side25Intro : politics.side25Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side26" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side26 : politics.side26)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side26Intro : politics.side26Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side27" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side27 : politics.side27)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side27Intro : politics.side27Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side28" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side28 : politics.side28)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side28Intro : politics.side28Intro) } })),
                React.createElement("div", { className: "pt-5", id: "side29" },
                    React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                        React.createElement("span", null, LG == "fr" ? politics.side29 : politics.side29)),
                    React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? politics.side29Intro : politics.side29Intro) } }))),
            React.createElement("div", null)));
    };
    return render();
};
export default PoliticsRelative;
