import { useEffect, useState } from "react";
var useAwayClick = function (ref) {
    var _a = useState(true), clickedOutside = _a[0], setClickedOutside = _a[1];
    var handleClickOutside = function (event) {
        var result = false;
        if (event.keyCode === 27) {
            // do nothing
        }
        if (ref.current && !ref.current.contains(event.target)) {
            result = true;
        }
        setClickedOutside(result);
    };
    useEffect(function () {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleClickOutside);
        };
    }, [ref]);
    return clickedOutside;
};
export default useAwayClick;
