export var validation_lang = {
    en: {
        clientFullName: "Enter the name of your client.",
        dateOfBirth: "Enter the date of birth.",
        clientNameMaxLength: "Name can't be more than 70 characters.",
        firstName: "Enter the first name of your client.",
        lastName: "Enter the last name of your client.",
        user: {
            firstName: "First name is required.",
            surname: "Surname is required.",
            email: "Email is required.",
            phoneNumber: "Phone number is required.",
            lastName: "Last name is required.",
        },
        profileSetting: {
            firstName: "Enter your name",
            surname: "Enter your surname",
        },
        nameOfCard: "Name on credit card is required.",
        billingAddress: "Billing address is required.",
        city: "City is required.",
        postcode: "Zipcode is required.",
        province: "Province is required.",
        creditCard: 'The credit card is required.',
        postcodeNotValid: 'Postcode is not valid.',
        country: 'Country is required.',
        coupon: 'Coupon is required.',
        state: 'Province/territory is required.',
        invalidZipcode: 'The zipcode is invalid.',
        gender: 'Gender is required.',
        name: 'Name is required.',
        version: 'Version is required.',
        required: 'The field is required.',
        paymentMethod: 'The payment method is required.',
        captcha: 'Captcha is a required field.',
        cvvNumber: 'Enter the credit card\'s security code',
        collaborator: {
            required: {
                name: 'Enter your collaborator\'s name',
                email: 'Enter collaborator\'s email address'
            }
        },
        clientHistoryMaxLength: 'History can\'t be more than 1000 characters.',
        messageMaxLength: 'Message can\'t be more than 500 characters.',
        messageMaxLength2: 'Message can\'t be more than 1000 characters.',
        someErrorGenerateReport: 'Some error occurred while generating report, please try after sometime.',
    },
    fr: {
        clientFullName: "Entrez un nom complet.",
        dateOfBirth: "Entrez un nom complet.",
        clientNameMaxLength: "Le nom ne peut pas comporter plus de 70 caractères.",
        firstName: "Entrez le prénom de votre client.",
        lastName: "Entrez le nom de famille de votre client.",
        user: {
            firstName: "Le prénom est requis.",
            surname: "Le nom de famille est obligatoire.",
            email: "Email est requis.",
            phoneNumber: "Le numéro de téléphone est requis.",
            lastName: "Le nom de famille est requis.",
        },
        profileSetting: {
            firstName: "Entrez votre prénom",
            surname: "Entrez votre nom",
        },
        nameOfCard: "Le nom sur la carte de crédit est requis.",
        billingAddress: "L'adresse de facturation est obligatoire.",
        city: "La ville est obligatoire.",
        postcode: "Le code postal est requis.",
        province: "La province est requise.",
        creditCard: 'La carte de crédit est obligatoire.',
        postcodeNotValid: 'Le code postal n\'est pas valide.',
        country: 'Le pays est requis.',
        coupon: 'un coupon est requis',
        state: 'La Province/territoire est requise.',
        invalidZipcode: 'Le code postal est invalide.',
        gender: 'Le sexe est requis.',
        name: 'Le nom est requis.',
        version: 'La version est requise.',
        required: 'Ce champ est requis.',
        paymentMethod: 'Le mode de paiement est requis.',
        captcha: 'Captcha est un champ obligatoire',
        cvvNumber: 'Entrez le code de sécurité de la carte de crédit',
        collaborator: {
            required: {
                name: 'Entrez le nom du collaborateur',
                email: 'Entrez l\'adresse courriel du collaborateur',
            }
        },
        clientHistoryMaxLength: 'L\'historique ne peut pas contenir plus de 1000 caractères.',
        messageMaxLength: 'Le message ne peut pas contenir plus de 500 caractères.',
        messageMaxLength2: 'Le message ne peut pas contenir plus de 1000 caractères.',
        someErrorGenerateReport: 'Une erreur s\'est produite lors de la génération du rapport, veuillez réessayer plus tard.',
    },
};
