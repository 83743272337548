import React from "react";
import env from "../../config/env";
var TickIcon = function (props) {
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "".concat(env.iconColorWhite), onClick: props.onClick, className: props.additionalClass, height: "60", viewBox: "0 -960 960 960", width: "60" },
                React.createElement("path", { d: "M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" }))));
    };
    return render();
};
export default TickIcon;
