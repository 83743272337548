import React from "react";
var CheckBoxIconDisable = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { className: props.additionalClass, onClick: props.onClick, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "20", height: "20", viewBox: "0 0 20 20" },
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip-Square_checkbox_unchecked_dissabled" },
                    React.createElement("rect", { width: "20", height: "20" }))),
            React.createElement("g", { id: "Square_checkbox_unchecked_dissabled", "data-name": "Square checkbox unchecked dissabled", clipPath: "url(#clip-Square_checkbox_unchecked_dissabled)" },
                React.createElement("g", { id: "Rectangle_2431", "data-name": "Rectangle 2431", fill: "#f2f2f5", stroke: "#c5c5c8", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1", opacity: "0.3" },
                    React.createElement("rect", { width: "20", height: "20", rx: "3", stroke: "none" }),
                    React.createElement("rect", { x: "0.5", y: "0.5", width: "19", height: "19", rx: "2.5", fill: "none" }))))));
};
export default CheckBoxIconDisable;
