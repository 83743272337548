import React from "react";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
import Navigation from "./Navigation";
import DownWard from "../../assets/icons/DownWard";
import env from "../../config/env";
import { aboutEN, aboutFR } from "../../enums/aboutUs";
var AboutUs = function () {
    var LG = F.getLang();
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                React.createElement(Navigation, { title: lang[LG].aboutFront.title }),
                React.createElement("div", { className: "flex justify-center font-poppins text-sm mt-5 font-light" },
                    React.createElement("div", { className: "ml-[20%]" },
                        React.createElement("div", { className: "w-3/4" }, lang[LG].aboutFront.intro),
                        React.createElement("div", { className: "w-3/4 mt-3" }, lang[LG].aboutFront.intro2),
                        React.createElement("div", { className: "w-3/4 mt-3" }, lang[LG].aboutFront.intro3))),
                React.createElement("div", { className: "grid w-3/5 mx-auto mt-8 font-poppins" },
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? aboutFR.title1 : aboutEN.title1),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? aboutFR.description1 : aboutEN.description1) } }))),
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg  p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? aboutFR.title2 : aboutEN.title2),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? aboutFR.description2 : aboutEN.description2) } }))),
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? aboutFR.title3 : aboutEN.title3),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? aboutFR.description3 : aboutEN.description3) } }))),
                    React.createElement("div", { className: "py-5" },
                        React.createElement("details", { className: "group shadow-lg p-5" },
                            React.createElement("summary", { className: "flex justify-between items-center font-medium cursor-pointer list-none" },
                                React.createElement("span", null, LG == "fr" ? aboutFR.title4 : aboutEN.title4),
                                React.createElement("span", { className: "transition group-open:rotate-180" },
                                    React.createElement(DownWard, { color: env.iconColor, height: 18, width: 18, additionalClass: "mr-1" }))),
                            React.createElement("div", { className: "text-neutral-600 mt-3 group-open:animate-fadeIn  max-h-96  overflow-y-scroll", dangerouslySetInnerHTML: { __html: (LG == "fr" ? aboutFR.description4 : aboutEN.description4) } })))))));
    };
    return render();
};
export default AboutUs;
