var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import LightModal from "../../components/Layouts/LightModal";
import IconContainer from "../../components/IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import env from "../../config/env";
import UserGuidePage from "./UserGuidePage";
import { lang } from "../../lang/lang";
import IconButton from "../../components/Button/IconButton";
import ForwardIcon from "../../assets/icons/ForwardIcon";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import Functions from "../../utils/functions";
import SubmitButtonWithIcon from "../../components/Button/SubmitButtonWithIcon";
import React, { useState } from "react";
var UserGuideSet = function (props) {
    var _a = useState(props.returnPage), returnPageNumber = _a[0], setReturnPageNumber = _a[1];
    var LG = Functions.getLang();
    var userguidePage = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description1, imageLink: "/images/anamn/Guide 1.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-4 pt-1" },
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(2);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagetwo = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description2, imageLink: "/images/anamn/Guide 2.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(1);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(3);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagethree = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description3, imageLink: "/images/anamn/Guide 3.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(2);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(4);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagefour = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description4, imageLink: "/images/anamn/Guide 4.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(3);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(5);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagefive = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description5, imageLink: "/images/anamn/Guide 5.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(4);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(6);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagesix = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description6, imageLink: "/images/anamn/Guide 6.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(5);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(7);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePageseven = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description7, imageLink: "/images/anamn/Guide 7.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(6);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(8);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePageEight = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
                if (props.onClick)
                    props.onClick();
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description8, imageLink: "/images/anamn/Guide 8.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(7);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: env.iconColorPurple }), title: lang[LG].answerQuestionnaire.userGuidePageOne.returnbutton, onClick: props.onSubmit })))));
    var render = function () {
        var pageController = returnPageNumber == 1 ? userguidePage
            : returnPageNumber == 2 ? userguidePagetwo
                : returnPageNumber == 3 ? userguidePagethree
                    : returnPageNumber == 4 ? userguidePagefour
                        : returnPageNumber == 5 ? userguidePagefive
                            : returnPageNumber == 6 ? userguidePagesix
                                : returnPageNumber == 7 ? userguidePageseven
                                    : returnPageNumber == 8 ? userguidePageEight : '';
        return (React.createElement(React.Fragment, null, pageController));
    };
    return render();
};
export default UserGuideSet;
