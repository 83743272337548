var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import F from "../../utils/functions";
import { useQueryRespondent } from "../../hooks/respondent/useQueryRespondent";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import Title from "../../components/Title/Title";
import { lang } from "../../lang/lang";
import Text from "../../components/Field/Text";
import IconButton from "../../components/Button/IconButton";
import env from "../../config/env";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import clsx from "clsx";
import SubmitButton from "../../components/Button/SubmitButton";
import CustomCheckbox from "../../components/Input/CustomCheckbox";
import useMutationReport from "../../hooks/report/useMutationReport";
import { saveAs } from "file-saver";
import IconContainer from "../../components/IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import ModalInClientPage from "../../components/Layouts/ModalInClientPage";
import moment from "moment";
import SuccessActionModal from "../../components/SuccessModal/SuccessActionModal";
import CircleCheck from "../../assets/icons/CircleCheck";
import DropDown from "../../components/DropDown/DropDown";
import Functions from "../../utils/functions";
import TextError from "../../components/Field/TextError";
import { validation_lang } from "../../lang/validations";
var SelectReportType = function (props) {
    var qid = useParams().qid;
    var LG = F.getLang();
    var _a = useState(false), showDownloadReportModal = _a[0], setShowDownloadReportModal = _a[1];
    var _b = useState(), respondents = _b[0], setRespondents = _b[1];
    var _c = useState([]), selectedRespondents = _c[0], setSelectedRespondents = _c[1];
    var _d = useState(), reportType = _d[0], setReportType = _d[1];
    var _e = useState('fr'), language = _e[0], setLanguage = _e[1];
    var _f = useState(), errorResponse = _f[0], setErrorResponse = _f[1];
    var getRespondents = useQueryRespondent({
        QNToClientId: qid !== null && qid !== void 0 ? qid : ''
    }).getRespondents;
    var pageLoader = useFullPageLoader();
    var navigate = useNavigate();
    var postReportFn = useMutationReport().postFn;
    useEffect(function () {
        var _a, _b, _c, _d;
        if (((_b = (_a = getRespondents.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status) == '404') {
            navigate(env.routes.pageNotFound);
        }
        else if (getRespondents.status === "success") {
            var selected = selectedRespondents;
            (_c = getRespondents.data) === null || _c === void 0 ? void 0 : _c.data.respondents.filter(function (respondent) { return respondent.status === 'completed'; }).map(function (respondent, key) {
                selected[respondent.id] = {
                    id: respondent.id,
                    graph: true,
                    table: true,
                };
            });
            setSelectedRespondents(__assign({}, selected));
            setRespondents((_d = getRespondents.data) === null || _d === void 0 ? void 0 : _d.data);
            pageLoader(false);
        }
        else {
            pageLoader(true);
        }
    }, [getRespondents.status, getRespondents.isFetching]);
    var updateGraphTreeSelection = function (respondent) {
        var _a;
        setSelectedRespondents(__assign(__assign({}, selectedRespondents), (_a = {}, _a[respondent.id] = respondent, _a)));
    };
    var correctDateFormat = function (inputString) {
        if (LG == 'en')
            return moment(inputString).format('MM/DD/YYYY');
        else
            return moment(inputString).format('DD/MM/YYYY');
    };
    var getReportTypeListing = function () {
        return (React.createElement("div", { className: "mt-4" },
            React.createElement(Text, { fontWeight: "font-normal" }, lang[LG].selectReportContent),
            React.createElement("div", { className: "mt-3.5 flex" },
                React.createElement(CustomCheckbox, { additionalClass: "mr-2", onUpdate: function (select) {
                        var _a, _b;
                        var table = (_b = (_a = selectedRespondents[0]) === null || _a === void 0 ? void 0 : _a.table) !== null && _b !== void 0 ? _b : false;
                        if (!table && !select)
                            setSelectedRespondents([]);
                        else
                            setSelectedRespondents({
                                0: {
                                    id: 0,
                                    graph: select,
                                    table: table
                                }
                            });
                    } }),
                React.createElement(Text, { additionalClass: 'my-1' }, lang[LG].reportGraph)),
            React.createElement("div", { className: "mt-3.5 flex" },
                React.createElement(CustomCheckbox, { additionalClass: "mr-2", onUpdate: function (select) {
                        var _a, _b;
                        var graph = (_b = (_a = selectedRespondents[0]) === null || _a === void 0 ? void 0 : _a.graph) !== null && _b !== void 0 ? _b : false;
                        if (!graph && !select)
                            setSelectedRespondents([]);
                        else
                            setSelectedRespondents({
                                0: {
                                    id: 0,
                                    graph: graph,
                                    table: select,
                                }
                            });
                    } }),
                React.createElement(Text, { additionalClass: 'my-1' }, lang[LG].reportTable))));
    };
    var getRespondentListing = function () {
        if (respondents == null)
            return React.createElement(React.Fragment, null);
        return respondents.respondents
            .filter(function (respondent) { return respondent.status === 'completed'; })
            .map(function (respondent, key) {
            var selectedRespondent = selectedRespondents[respondent.id];
            return (React.createElement("div", { className: clsx({
                    "flex justify-between border-b p-2": true,
                    "border-c-gray": respondent.status === "completed"
                }), key: key },
                React.createElement("div", { className: "flex items-center", title: respondent.status !== 'completed' ? lang[LG].testIsPending : '' },
                    React.createElement(CustomCheckbox, { disabled: respondent.status !== 'completed', selected: selectedRespondent != null, onUpdate: function (selected) {
                            var _a;
                            if (selected) {
                                setSelectedRespondents(__assign(__assign({}, selectedRespondents), (_a = {}, _a[respondent.id] = {
                                    id: respondent.id,
                                    graph: true,
                                    table: true,
                                }, _a)));
                            }
                            else {
                                delete selectedRespondents[respondent.id];
                                setSelectedRespondents(__assign({}, selectedRespondents));
                            }
                        }, additionalClass: "mr-2" }),
                    React.createElement(Text, { additionalClass: "" }, "".concat(F.capitalize(respondent.respondent_name), " (").concat(respondent.respondent_role, ")"))),
                React.createElement(Text, { additionalClass: "flex items-end text-c-gray" }, "".concat(lang[LG].completedOn, " ").concat(correctDateFormat(respondent === null || respondent === void 0 ? void 0 : respondent.updated_at)))));
        });
    };
    var downloadReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, url, formatDateForFrench;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    pageLoader(true);
                    return [4 /*yield*/, postReportFn.mutateAsync({
                            type: reportType,
                            questionnaireToRespondentIds: selectedRespondents,
                            qnToClientId: (_a = F.convertToNum(qid)) !== null && _a !== void 0 ? _a : 0,
                            lang: language !== null && language !== void 0 ? language : 'fr',
                        })];
                case 1:
                    response = _b.sent();
                    pageLoader(false);
                    if ((response === null || response === void 0 ? void 0 : response.status) != 200) {
                        setErrorResponse(response);
                        return [2 /*return*/];
                    }
                    setErrorResponse(undefined);
                    blob = new Blob([response.data], { type: 'application/octetstream' });
                    url = URL.createObjectURL(blob);
                    formatDateForFrench = LG == "fr" ? moment().format("DDMMYYYY") : moment().format("MMDDYYYY");
                    saveAs(url, "Edpro_".concat(lang[LG].report, "_(").concat(respondents === null || respondents === void 0 ? void 0 : respondents.clientName.name, ")_").concat(formatDateForFrench, ".pdf"));
                    setShowDownloadReportModal(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var renderDownloadReportModal = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement(SuccessActionModal, { backgroundCls: 'bg-settings bg-cover bg-center', icon: React.createElement(CircleCheck, { color: env.iconGreen }), title: lang[LG].reportThankYouText, content: lang[LG].reportThankYouDescription, actionContent: lang[LG].reportThankYouLink, actionContentFn: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, downloadReport()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, buttonTitle: lang[LG].successButtonTitle, action: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        navigate(env.routes.login);
                        return [2 /*return*/];
                    });
                }); }, back: F.createUrl(env.routes.clientPage, (_b = (_a = respondents === null || respondents === void 0 ? void 0 : respondents.clientId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '') })));
    };
    var goBack = function () { return __awaiter(void 0, void 0, void 0, function () {
        var urlBack;
        return __generator(this, function (_a) {
            urlBack = F.createUrl(env.routes.clientQuestionnaire, qid !== null && qid !== void 0 ? qid : '');
            navigate(urlBack);
            return [2 /*return*/];
        });
    }); };
    var renderLayout = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalInClientPage, { backgroundCls: props.backgroundCls, width: "w-3/5" },
                React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: goBack }),
                React.createElement("div", { className: "flex mt-3" },
                    React.createElement(Title, null, lang[LG].reportPreparation)),
                React.createElement("div", { className: "mt-5 border-b border-c-gray pb-5" },
                    React.createElement(Text, { isbold: true }, lang[LG].selectReportType)),
                getRespondentListing(),
                React.createElement("div", { className: "w-3/5" },
                    React.createElement("div", { className: "mt-5" },
                        React.createElement(Text, { isbold: true }, lang[LG].selectLanguage)),
                    React.createElement("div", { className: "w-2/5 mt-3" },
                        React.createElement(DropDown, { placeHolder: lang[LG].selectLang, selectedItem: Functions.getLangItemsReport(LG).filter(function (item) { return item.data === language; })[0], items: Functions.getLangItemsReport(LG), onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    setLanguage(e.data);
                                    return [2 /*return*/];
                                });
                            }); } }))),
                React.createElement("div", { className: "flex justify-between mt-4" },
                    React.createElement(IconButton, { title: '', alias: lang[LG].cancel, icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), additionalClass: clsx({
                            "h-10 w-10": true,
                        }), onClick: goBack }),
                    React.createElement("div", { className: "flex items-center" }, errorResponse && React.createElement(TextError, { message: validation_lang[LG].someErrorGenerateReport })),
                    React.createElement(SubmitButton, { title: lang[LG].downloadReport, disabled: !(reportType === 'general' || Object.keys(selectedRespondents).length !== 0), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, downloadReport()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })))));
    };
    var render = function () {
        return showDownloadReportModal && renderDownloadReportModal()
            ||
                respondents != null && renderLayout()
            ||
                React.createElement(React.Fragment, null);
    };
    return render();
};
export default SelectReportType;
