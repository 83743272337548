import clsx from "clsx";
import React from "react";
var CenterModal = function (props) {
    var _a;
    var itemCentered = (_a = props.itemsCentered) !== null && _a !== void 0 ? _a : true;
    var render = function () {
        var _a, _b;
        var _c, _d;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: clsx((_a = {
                        "w-full min-h-screen flex justify-center": true
                    },
                    _a[(_c = props.additionalCls) !== null && _c !== void 0 ? _c : 'bg-c-background'] = true,
                    _a["items-center"] = itemCentered,
                    _a)) },
                React.createElement("div", { className: clsx((_b = {
                            "bg-white p-8 rounded relative": true
                        },
                        _b[(_d = props.layoutClass) !== null && _d !== void 0 ? _d : 'w-200'] = true,
                        _b["h-fit mt-20"] = !itemCentered,
                        _b)) }, props.children))));
    };
    return render();
};
export default CenterModal;
