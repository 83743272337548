import React from "react";
var PurpleTitle = function (props) {
    var render = function () {
        var isUnderline = props.isUnderline == false ? '' : 'underline';
        var textColor = props.textColor ? props.textColor : 'text-c-purple';
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins text-left ".concat(textColor, " ").concat(isUnderline, " ").concat(props.additionalClass, " ").concat(props.disable ? 'opacity-75' : ''), onClick: props.onClick }, props.children)));
    };
    return render();
};
export default PurpleTitle;
