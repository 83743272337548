var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialStateValue = {
    fullPage: false,
};
export var registerUserSlice = createSlice({
    name: "loader",
    initialState: {
        value: initialStateValue
    },
    reducers: {
        saveLoader: function (state, action) {
            state.value = __assign(__assign({}, state.value), action.payload);
        },
        resetLoader: function (state) {
            state.value = __assign({}, initialStateValue);
        }
    }
});
export var saveLoader = (_a = registerUserSlice.actions, _a.saveLoader), resetLoader = _a.resetLoader;
export default registerUserSlice.reducer;
