import React from "react";
var CheckedCheckbox = function (props) {
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { onClick: props.onClick, className: props.additionalClass, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "20", height: "20", viewBox: "0 0 20 20" },
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip-Round_checkbox_checked" },
                        React.createElement("rect", { width: "20", height: "20" }))),
                React.createElement("g", { id: "Round_checkbox_checked", clipPath: "url(#clip-Round_checkbox_checked)" },
                    React.createElement("g", { id: "Group_2267" },
                        React.createElement("g", { id: "Component_81_627" },
                            React.createElement("g", { id: "Ellipse_31", fill: "#fff", stroke: "#6c0c6c", strokeWidth: "1" },
                                React.createElement("circle", { cx: "10", cy: "10", r: "10", stroke: "none" }),
                                React.createElement("circle", { cx: "10", cy: "10", r: "9.5", fill: "none" })),
                            React.createElement("circle", { id: "Ellipse_373", cx: "7", cy: "7", r: "7", transform: "translate(3 3)", fill: "#6c0c6c" })))))));
    };
    return render();
};
export default CheckedCheckbox;
