var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef } from "react";
import env from "../../config/env";
import { lang } from "../../lang/lang";
import Label from "../Field/Label";
import Text from "../Field/Text";
import IconContainer from "../IconContainer/IconContainer";
import moment from "moment";
import useAwayClick from "../../hooks/content/useAwayClick";
import Functions from "../../utils/functions";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
var DatePicker = function (props) {
    var LG = Functions.getLang();
    var dayRef = useRef();
    var monthRef = useRef();
    var yearRef = useRef();
    var dayRefClickAway = useAwayClick(dayRef);
    var monthRefClickAway = useAwayClick(monthRef);
    var yearRefClickAway = useAwayClick(yearRef);
    var getDropDownItem = function (key, title, stateKey, stateStrKey) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black px-2 py-2 hover:bg-c-scroll", display: "block", onClick: function () {
                var _a, _b;
                var _c, _d, _e, _f;
                var daysInMonth = 31;
                if (stateKey === 'month') {
                    daysInMonth = moment("".concat((_c = props.date) === null || _c === void 0 ? void 0 : _c.year, " ").concat(key), "YYYY MM").daysInMonth();
                }
                else if (stateKey === 'year') {
                    daysInMonth = moment("".concat(key, " ").concat((_d = props.date) === null || _d === void 0 ? void 0 : _d.month), "YYYY MM").daysInMonth();
                }
                var newObject;
                var selectedDate = (_f = (_e = props.date) === null || _e === void 0 ? void 0 : _e.day) !== null && _f !== void 0 ? _f : 0;
                if (!isNaN(daysInMonth) && selectedDate > daysInMonth) {
                    newObject = __assign(__assign({}, props.date), (_a = {}, _a[stateKey] = key, _a[stateStrKey] = title, _a['day'] = 0, _a['dayStr'] = '', _a));
                }
                else {
                    newObject = __assign(__assign({}, props.date), (_b = {}, _b[stateKey] = key, _b[stateStrKey] = title, _b));
                }
                props.validate(newObject);
                Functions.escape();
            }, key: key }, title);
    };
    var getYears = function () {
        var currentYear = moment().year();
        var startingYear = currentYear - 100;
        var jsxContainer = [];
        for (var year = currentYear; year >= startingYear; year--) {
            jsxContainer.push(getDropDownItem(year, year.toString(), "year", "yearStr"));
        }
        return jsxContainer;
    };
    var getMonths = function () {
        var months = lang[LG].months;
        return months.map(function (element, index) {
            return getDropDownItem(index + 1, element.name, "month", "monthStr");
        });
    };
    var getDates = function () {
        var _a, _b;
        var currentMonth = moment("".concat((_a = props.date) === null || _a === void 0 ? void 0 : _a.year, " ").concat((_b = props.date) === null || _b === void 0 ? void 0 : _b.month), "YYYY MM");
        var numberOfDays = 31;
        if (!isNaN(currentMonth.daysInMonth())) {
            numberOfDays = currentMonth.daysInMonth();
        }
        var numberOfDatesArray = __spreadArray([], Array(numberOfDays), true).map(function (x) { return 0; });
        return numberOfDatesArray.map(function (_, index) {
            var date = (index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            return getDropDownItem(index + 1, date, "day", "dayStr");
        });
    };
    var containerClass = "flex justify-between items-center p-2 border border-c-gray rounded text-c-text relative mr-4 hover:border-c-purple";
    var iconClass = "flex items-center cursor-pointer";
    var dropBoxClass = "p-text-hover-ep py-2 border border-c-gray rounded text-c-text absolute mr-4 hover:border-c-purple top-12 left-0 bg-white w-full h-40 z-10 overflow-y-scroll";
    var section = function (ctnClass, label, items, ref, isHidden, disable, state) {
        var tempLabel = state ? state : label;
        if (disable)
            ctnClass += ' bg-c-scroll hover:cursor-not-allowed ';
        return (React.createElement("div", { className: "".concat(containerClass, " ").concat(ctnClass, " ").concat(props.error ? 'border-c-red' : ''), ref: ref },
            React.createElement(Text, null, tempLabel),
            React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconColor }) }),
            React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(isHidden || disable ? 'hidden' : '') }, items)));
    };
    var render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return (React.createElement(React.Fragment, null,
            (props === null || props === void 0 ? void 0 : props.labelHide) != true && React.createElement(Label, null, lang[LG].datePicker.dob),
            React.createElement("div", { className: "flex flex-wrap basis-full justify-start mt-1" },
                section('w-28', lang[LG].datePicker.year, getYears(), yearRef, yearRefClickAway, (_a = props === null || props === void 0 ? void 0 : props.hideDate) !== null && _a !== void 0 ? _a : false, (_b = props.date) === null || _b === void 0 ? void 0 : _b.yearStr),
                section('w-60', lang[LG].datePicker.month, getMonths(), monthRef, monthRefClickAway, ((_c = props.date) === null || _c === void 0 ? void 0 : _c.year) == null || ((_d = props.date) === null || _d === void 0 ? void 0 : _d.year) == 0, (_e = props.date) === null || _e === void 0 ? void 0 : _e.monthStr),
                section('w-28', lang[LG].datePicker.day, getDates(), dayRef, dayRefClickAway, ((_f = props.date) === null || _f === void 0 ? void 0 : _f.month) == null || ((_g = props.date) === null || _g === void 0 ? void 0 : _g.month) == 0, (_h = props.date) === null || _h === void 0 ? void 0 : _h.dayStr)),
            props.error && React.createElement(TextError, { message: (_j = props.error.message) !== null && _j !== void 0 ? _j : '' })));
    };
    return render();
};
export default DatePicker;
