import React from "react";
var CloseIcon = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() + 'px' : '24px';
        var width = props.width ? props.width.toString() + 'px' : '24px';
        return (React.createElement("svg", { onClick: props.onClick, className: "".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", height: height, viewBox: "0 0 24 24", width: width, fill: props.color },
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip-Close" },
                    React.createElement("rect", { width: "24", height: "24" }))),
            React.createElement("g", { id: "Close", clipPath: "url(#clip-Close)" },
                React.createElement("g", { id: "Group_1266", "data-name": "Group 1266", transform: "translate(-825.9 -627)" },
                    React.createElement("line", { id: "Line_195", "data-name": "Line 195", x2: "15.83", y2: "15.83", transform: "translate(830.201 631)", fill: "none", stroke: props.color, strokeLinecap: "round", strokeWidth: "1" }),
                    React.createElement("line", { id: "Line_196", "data-name": "Line 196", x1: "15.83", y2: "15.83", transform: "translate(830.201 631)", fill: "none", stroke: props.color, strokeLinecap: "round", strokeWidth: "1" })))));
    };
    return render();
};
export default CloseIcon;
