import React from "react";
import CloseIcon from "../../assets/icons/CloseIcon";
import env from "../../config/env";
import NotAlignedCenterModal from "../../components/Layouts/NotAlignedCenterModal";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../utils/functions";
import ContactForm from "../../components/Forms/ContactForm";
var Contact = function (props) {
    var navigate = useNavigate();
    var hash = useParams().hash;
    var back = function () {
        var url = Functions.removeParams(env.routes.answerQuestionnaireStep);
        navigate("".concat(url).concat(hash, "/3"));
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(NotAlignedCenterModal, { additionalCls: props.backgroundCls },
                React.createElement("div", { className: "container lg:w-200 mt-10 bg-white p-9 relative h-fit" },
                    React.createElement(CloseIcon, { color: env.iconColor, additionalClass: "absolute top-2 right-2", onClick: back }),
                    React.createElement(ContactForm, { onSuccess: back, hash: hash, layout: "admin" })))));
    };
    return render();
};
export default Contact;
