import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _getQestionnairs = function () {
    return Api.get("".concat(env.api.getQuestionnaires));
};
var _searchQestionnairs = function (data) {
    return Api.post(data, "".concat(env.api.searchQuestionnaires));
};
var _getSingleQuestionnairInPreviewPage = function (data) {
    return Api.getD(data, "".concat(env.api.searchQuestionnaire));
};
export function useQueryQuestionnaires() {
    var getQuestionnaires = useMutation(function (data) { return _getQestionnairs(); });
    var searchQuestionnaires = useMutation(function (data) { return _searchQestionnairs(data); });
    var getSingleQuestionnairInPreviewPage = useMutation(function (data) { return _getSingleQuestionnairInPreviewPage(data); });
    return { getQuestionnaires: getQuestionnaires, searchQuestionnaires: searchQuestionnaires, getSingleQuestionnaireInPreviewPage: getSingleQuestionnairInPreviewPage };
}
