import React, { useEffect, useRef, useState } from 'react';
import Text from "../Field/Text";
import Functions from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import useAwayClick from "../../hooks/content/useAwayClick";
import TextError from "../Field/TextError";
import DownTriangle from "../../assets/icons/DownTrigangle";
import env from "../../config/env";
var LightDropDown = function (props) {
    var elementRef = useRef();
    var elementRefClickAway = useAwayClick(elementRef);
    var _a = useState(props.selectedItem), selectedItem = _a[0], setSelectedItem = _a[1];
    var getDropDownItems = function (key, title, data) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black px-2 py-1 hover:bg-c-scroll", display: "block", onClick: function (e) {
                setSelectedItem({
                    key: key,
                    title: title,
                    data: data
                });
                Functions.escape();
            }, key: key }, title);
    };
    // if dropdown open
    var ifDDOpen = function () {
        return !elementRefClickAway;
    };
    useEffect(function () {
        if (selectedItem != null) {
            props.onChange(selectedItem);
        }
    }, [selectedItem]);
    var render = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var containerClass = "flex justify-between items-center relative";
        var height = (_a = props.height) !== null && _a !== void 0 ? _a : 'h-10';
        var iconClass = "flex items-center cursor-pointer";
        var dropBoxClass = "border border-c-gray rounded absolute mr-4 top-12 left-0 bg-white w-40 min-w-fit max-h-48 z-21 overflow-y-auto";
        var titleCls = "".concat((_b = props.additionalClassTitle) !== null && _b !== void 0 ? _b : '', " ");
        var iconColor = (_c = props.iconColor) !== null && _c !== void 0 ? _c : (ifDDOpen() ? env.iconColorPurple : env.iconColor);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(containerClass, " ").concat(height, " ").concat(props.error ? 'border-c-red' : ''), ref: elementRef },
                React.createElement(Text, { additionalClass: titleCls }, (_d = props.placeHolder) !== null && _d !== void 0 ? _d : "".concat(props.title, "\n                        ").concat((_e = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title) !== null && _e !== void 0 ? _e : '')),
                React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DownTriangle, { color: iconColor, additionalClass: "".concat(ifDDOpen() ? 'rotate-180' : '') }) }),
                React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(!ifDDOpen() ? 'hidden' : '') }, props.items.map(function (item) { return getDropDownItems(item.key, item.title, item.data); }))),
            props.error && React.createElement(TextError, { message: (_g = (_f = props.error) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : '' })));
    };
    return render();
};
export default LightDropDown;
