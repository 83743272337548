import React from "react";
var SortAzDisabled = function (props) {
    var height = props.height ? props.height.toString() : '15';
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "28", height: "".concat(height, "px"), viewBox: "0 0 28 15" },
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip-Filter_-_AZ_-_Disabled" },
                        React.createElement("rect", { width: "28", height: "15" }))),
                React.createElement("g", { id: "Filter_-_AZ_-_Disabled", "data-name": "Filter - AZ - Disabled", clipPath: "url(#clip-Filter_-_AZ_-_Disabled)" },
                    React.createElement("g", { id: "Group_3410", "data-name": "Group 3410", transform: "translate(0.186)", opacity: "0.5" },
                        React.createElement("g", { id: "Rectangle_1280", "data-name": "Rectangle 1280", transform: "translate(-0.185)", fill: "#ebcfeb", stroke: "#6c0c6c", strokeWidth: "0.5" },
                            React.createElement("rect", { width: "28", height: "15", rx: "4", stroke: "none" }),
                            React.createElement("rect", { x: "0.25", y: "0.25", width: "27.5", height: "14.5", rx: "3.75", fill: "none" })),
                        React.createElement("text", { id: "AZ", transform: "translate(3.814 10)", fill: "#6c0c6c", fontSize: "8", fontFamily: "Poppins-Regular, Poppins" },
                            React.createElement("tspan", { x: "0", y: "0" }, "AZ")),
                        React.createElement("path", { id: "Polygon_55", "data-name": "Polygon 55", d: "M2,0,4,3H0Z", transform: "translate(18.814 4)", fill: "#6c0c6c" }),
                        React.createElement("path", { id: "Polygon_56", "data-name": "Polygon 56", d: "M2,0,4,3H0Z", transform: "translate(22.815 11) rotate(180)", fill: "#6c0c6c" }))))));
    };
    return render();
};
export default SortAzDisabled;
