import React, { useEffect, useState } from "react";
import CheckBoxIconChecked from "../../assets/icons/CheckBoxIconchecked";
import CheckBoxIconDisable from "../../assets/icons/CheckBoxIconDisable";
import clsx from "clsx";
var CustomCheckbox = function (props) {
    var _a;
    var _b = useState((_a = props.selected) !== null && _a !== void 0 ? _a : false), checked = _b[0], setChecked = _b[1];
    var onChange = function (checked, sendUpdate) {
        if (sendUpdate === void 0) { sendUpdate = true; }
        setChecked(checked);
        if (sendUpdate)
            props.onUpdate(checked);
    };
    useEffect(function () {
        var _a;
        onChange((_a = props.selected) !== null && _a !== void 0 ? _a : false, false);
    }, [props.selected]);
    var render = function () {
        var _a, _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(props.disabled ? 'cursor-not-allowed' : '', " ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') },
                checked && React.createElement(CheckBoxIconChecked, { additionalClass: clsx("".concat(props.hoverClass), {
                        'inline': (_b = props.inline) !== null && _b !== void 0 ? _b : true
                    }), onClick: function () {
                        if (props.disabled)
                            return;
                        onChange(false);
                    } }),
                !checked && React.createElement(CheckBoxIconDisable, { additionalClass: clsx("".concat(props.hoverClass), {
                        'border border-c-red': props.error,
                        'inline': (_c = props.inline) !== null && _c !== void 0 ? _c : true,
                    }), onClick: function () {
                        if (props.disabled)
                            return;
                        onChange(true);
                    } }))));
    };
    return render();
};
export default CustomCheckbox;
