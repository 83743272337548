var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clsx from "clsx";
import React from "react";
var Checkbox = function (props) {
    var render = function () {
        var _a;
        var _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({}, props.register, { checked: (_b = props.checked) !== null && _b !== void 0 ? _b : false, type: 'checkbox', autoComplete: "off", className: clsx((_a = {
                        "border border-solid rounded border-c-gray text-sm font-poppins": true,
                        "mr-2 bg-c-scroll text-c-purple focus:ring-0": true
                    },
                    _a[(_c = props.additionalClass) !== null && _c !== void 0 ? _c : ''] = true,
                    _a)), disabled: props.disabled, onChange: props.onChange }))));
    };
    return render();
};
export default Checkbox;
