import React from "react";
import env from "../../config/env";
var DownWard = function (props) {
    var render = function () {
        var _a, _b;
        var height = props.height ? props.height.toString() + 'px' : '24px';
        var width = props.width ? props.width.toString() + 'px' : '24px';
        var color = (_a = props.color) !== null && _a !== void 0 ? _a : env.iconColorPurple;
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "".concat(color), viewBox: "0 0 24 24", strokeWidth: "1.5", stroke: "currentColor", className: "".concat((_b = props.additionalClass) !== null && _b !== void 0 ? _b : '', " cursor-pointer"), height: "".concat(height), width: "".concat(width) },
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" })));
    };
    return render();
};
export default DownWard;
