import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../feature/user";
import UserSignUpReducer from "../feature/registerUser";
import LoaderReducer from "../feature/loader";
import LanguageReducer from "../feature/language";
import QuestionnaireReducer from "../feature/questionnaire";
import AnaQuestionnaireReducer from "../feature/anaquestionnaire";
import CommonReducer from "../feature/common";
var store = configureStore({
    reducer: {
        user: UserReducer,
        userSignUp: UserSignUpReducer,
        loader: LoaderReducer,
        language: LanguageReducer,
        questionnaire: QuestionnaireReducer,
        anaQuestionnaire: AnaQuestionnaireReducer,
        common: CommonReducer
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
        });
    }
});
export default store;
