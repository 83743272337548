export var dropdownFR = {
    1713: ['CPE', 'Préscolaire', '1ère année du primaire', '2e année du primaire', '3e année du primaire', '4e année du primaire', '5e année du primaire', '6e année du primaire', '1ère année du secondaire',
        '2e année du secondaire', '3e année du secondaire', '4e année du secondaire', '5e année du secondaire', 'Formation préparatoire', 'Formation métier spécialisé', 'Formation aux adultes', 'Programme CAPS',
        'Ne s`applique pas'],
    1736: ['Droitier', 'Gaucher', 'Ambidextre', 'Je ne sais pas', 'Ne s\'applique pas'],
    1743: ['Français, langue d`enseignement', 'Français, langue seconde', 'English language arts (en anglais seulement)', 'Anglais, langue seconde', 'Espagnol, langue tierce', 'Intégration linguistique, ',
        'scolaire et sociale', 'Mathématiques', 'Science et technologie', 'Applications technologiques et scientifiques', 'Science et technologie de l`environnement', 'Science et environnement', 'Chimie',
        'Physique', 'Géographie, histoire et éducation à la citoyenneté (primaire)', 'Éducation financière', 'Géographie', 'Géographie culturelle', 'Histoire du 20e siècle',
        'Histoire et éducation à la citoyenneté', 'Histoire du Québec et du Canada', 'Monde contemporain', 'Art dramatique', 'Arts plastiques', 'Danse', 'Musique', 'Éducation physique et à la santé',
        'Éthique et culture religieuse', 'Projet personnel d`orientation', 'Exploration de la formation professionnelle', 'Sensibilisation à l`entrepreneuriat', 'Projet intégrateur'],
    1744: ['3 mois ou moins', '4 mois', '5 mois', '6 mois', '7 mois', '8 mois', '9 mois', '10 mois', '11 mois', '12 mois', 'Plus de 12 mois', 'Je ne me souviens pas', 'Ne s`applique pas'],
    1745: ['5 mois ou moins', '6 mois', '7 mois', '8 mois', '9 mois', '10 mois', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', 'Plus de 15 mois', 'Je ne me souviens pas', 'Ne s\'applique pas'],
    1746: ['8 mois ou moins', '9 mois', '10 mois', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', '16 mois', '17 mois', '18 mois', '19 mois', '20 mois', '21 mois', '22 mois', '23 mois', '24 mois', 'Plus de 24 mois', 'Je ne me souviens pas', 'Ne s\'applique pas',],
    1747: ['10 mois ou moins', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', '16 mois', '17 mois', '18 mois', '19 mois', '20 mois', '21 mois', '22 mois', '23 mois', '24 mois', '25 mois', '26 mois', '27 mois', '28 mois', '29 mois', '30 mois', '31 mois', '32 mois', '33 mois', '34 mois', '35 mois', '36 mois', 'Plus de 36 mois', 'Je ne me souviens pas', 'Ne s\'applique pas'],
    1748: ['8 mois ou moins', '9 mois', '10 mois', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', '16 mois', '17 mois', '18 mois', '19 mois', '20 mois', '21 mois', '22 mois', '23 mois', '24 mois', '25 mois', '26 mois', '27 mois', '28 mois', '29 mois', '30 mois', '31 mois', '32 mois', '33 mois', '34 mois', '35 mois', '36 mois', 'Plus de 36 mois', 'Je ne me souviens pas', 'Ne s\'applique pas'],
    1749: ['10 mois ou moins', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', '16 mois', '17 mois', '18 mois', '19 mois', '20 mois', '21 mois', '22 mois', '23 mois', '24 mois', '25 mois', '26 mois', '27 mois', '28 mois', '29 mois', '30 mois', '31 mois', '32 mois', '33 mois', '34 mois', '35 mois', '36 mois', 'Plus de 36 mois', 'Je ne me souviens pas', 'Ne s\'applique pas'],
    1750: ['10 mois ou moins', '11 mois', '12 mois', '13 mois', '14 mois', '15 mois', '16 mois', '17 mois', '18 mois', '19 mois', '20 mois', '21 mois', '22 mois', '23 mois', '24 mois', '25 mois', '26 mois', '27 mois', '28 mois', '29 mois', '30 mois', '31 mois', '32 mois', '33 mois', '34 mois', '35 mois', '36 mois', 'Plus de 36 mois', 'Je ne me souviens pas', 'Ne s\'applique pas'],
    1755: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1756: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1757: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1758: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1759: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1760: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1761: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1762: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1763: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1764: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1765: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1766: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1767: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1768: ['Quotidiennement', 'Quelques fois par semaines', 'Hebdomadairement', 'Quelques fois par mois', 'Une fois par mois', 'Quelques fois par année', 'Une fois par année', 'Aux quelques années', 'Je ne sais pas'],
    1769: ['Français, langue d`enseignement', 'Français, langue seconde', 'English language arts (en anglais seulement)', 'Anglais, langue seconde', 'Espagnol, langue tierce', 'Intégration linguistique, scolaire et sociale', 'Mathématiques', 'Science et technologie', 'Applications technologiques et scientifiques', 'Science et technologie de l`environnement', 'Science et environnement', 'Chimie', 'Physique', 'Géographie, histoire et éducation à la citoyenneté (primaire)', 'Éducation financière', 'Géographie', 'Géographie culturelle', 'Histoire du 20e siècle', 'Histoire et éducation à la citoyenneté', 'Histoire du Québec et du Canada', 'Monde contemporain', 'Art dramatique', 'Arts plastiques', 'Danse', 'Musique', 'Éducation physique et à la santé', 'Éthique et culture religieuse', 'Projet personnel d`orientation', 'Exploration de la formation professionnelle', 'Sensibilisation à l`entrepreneuriat', 'Projet intégrateur'],
    1843: ['Accessible', 'Actif', 'Affectueux', 'Agité', 'Agressif', 'Aimable', 'Ambitieux', 'Amical', 'Anxieux', 'Appliqué', 'Articulé', 'Artiste',
        'Assidu', 'Attentif', 'Attentionné', 'Auto-déterminé', 'Autonome', 'Bavard', 'Bienveillant', 'Blagueur', 'Boudeur', 'Brave', 'Brillant',
        'Bruyant', 'Câlin', 'Calme', 'Capricieux', 'Casse-cou', 'Catastrophique', 'Chaleureux', 'Charismatique', 'Charmant', 'Charmeur',
        'Coléreux', 'Collaborateur', 'Communicatif', 'Compatissant', 'Compétent', 'Compétitif', 'Complaisant', 'Complexé', 'Compliqué',
        'Compréhensif', 'Confiant', 'Conformiste', 'Consciencieux', 'Conséquent', 'Constant', 'Constructif', 'Content', 'Convainquant',
        'Coopératif', 'Courageux', 'Courtois', 'Créatif', 'Critique', 'Cultivé', 'Curieux', 'Débatteur', 'Débrouillard', 'Décidé', 'Décontracté',
        'Délicat', 'Dépressif', 'Désireux', 'Désobéissant', 'Désordonné', 'Désorganisé', 'Déterminé', 'Dévoué', 'Digne de confiance', 'Diligent',
        'Diplomate', 'Direct', 'Discipliné', 'Discret', 'Disponible', 'Distant', 'Distingué', 'Distrait', 'Distrayant', 'Divertissant', 'Docile',
        'Doué', 'Drôle', 'Dynamique', 'Éblouissant', 'Économe', 'Efficace', 'Égocentrique', 'Egoïste', 'Éloquent', 'Émotif', 'Émouvant', 'Empathique',
        'Encourageant', 'Endurant', 'Énergique', 'Engagé', 'Énigmatique', 'Enjoué', 'Enthousiaste', 'Entreprenant', 'Envieux', 'Épanoui', 'Étourdi',
        'Excentrique', 'Excessif', 'Exigeant', 'Expressif', 'Extraverti', 'Fatigué', 'Fiable', 'Fidèle', 'Flexible', 'Galant', 'Gêné', 'Généreux',
        'Gentil', 'Gourmand', 'Grincheux', 'Habile', 'Hautain', 'Hésitant', 'Honnête', 'Humble', 'Humiliant', 'Humoriste', 'Hyperactif',
        'Hypersensible', 'Imaginatif', 'Imbu de lui-même', 'Immature', 'Impartial', 'Impatient', 'Impertinent', 'Impliqué', 'Imprudent',
        'Impulsif', 'Inattentif', 'Incompétent', 'Inconstant', 'Indécis', 'Indépendant', 'Infatigable', 'influençable', 'Influent', 'Ingénieux',
        'Innovateur', 'Inquiet', 'Insatisfait', 'Indiscret', 'Insolent', 'Insouciant', 'Instable', 'Intègre', 'Intelligent', 'Intéressé',
        'Intransigeant', 'Introverti', 'Intuitif', 'Inventif', 'Ironique', 'Irraisonnable', 'Irréaliste', 'Irrespectueux', 'Irresponsable',
        'Irritable', 'Jaloux', 'Jovial', 'Joyeux', 'Judicieux', 'Juste', 'Laxiste', 'Leader', 'Lent', 'Logique', 'Loyal', 'Lunatique', 'Maladroit',
        'Malhonnête', 'Malin', 'Maniéré', 'Manipulateur', 'Manuel', 'Marrant', 'Mature', 'Méchant', 'Méfiant', 'Menteur', 'Méritant', 'Méprisant',
        'Mesquin', 'Méthodique', 'Méthodique', 'Méticuleux', 'Minutieux', 'Modeste', 'Moqueur', 'Moral', 'Motivé', 'Mystérieux', 'Naïf', 'Narcissique',
        'Négatif', 'Négligeant', 'Négociateur', 'Nerveux', 'Noble', 'Nonchalant', 'Objectif', 'Obligeant', 'Observateur', 'Obsessionnel', 'Obstiné',
        'Obtus', 'Opiniâtré', 'Opposant', 'Optimiste', 'Ordonné', 'Organisé', 'Orgueilleux', 'Original', 'Ouvert d`esprit', 'Pacifique', 'Paisible',
        'Paresseux', 'Passif', 'Passionnant', 'Passionné', 'Patient', 'Pensif', 'Perfectionniste', 'Persécuteur', 'Persévérant', 'Perspicace',
        'Persuasif', 'Pessimiste', 'Peureux', 'Philosophe', 'Plaintif', 'Plaisant', 'Pleurnichard', 'Pointilleux', 'Poli', 'Polyvalent', 'Ponctuel',
        'Posé', 'Pragmatique', 'Pratique', 'Précis', 'Préoccupé', 'Présent', 'Prétentieux', 'Prévoyant', 'Procrastinateur', 'Radin', 'Raffiné',
        'Raisonnable', 'Râleur', 'Rancunier', 'Rassurant', 'Rationnel', 'Réaliste', 'Réaliste', 'Rebel', 'Réceptif', 'Réconfortant', 'Reconnaissant',
        'Réfléchi', 'Renfermé', 'Réservé', 'Résilient', 'Résistant', 'Respectueux', 'Responsable', 'Rêveur', 'Révolté', 'Rigide', 'Rigolo',
        'Rigoureux', 'Ringard', 'Romantique', 'Rouspéteur', 'Rusé', 'Sage', 'Sans gêne', 'Sarcastique', 'Sauvage', 'Savant', 'Structuré',
        'Sensible', 'Serein', 'Sérieux', 'Serviable', 'Sincère', 'Sociable', 'Social', 'Soigneux', 'Solide', 'Solitaire', 'Sombre', 'Songeur',
        'Soucieux', 'Soupçonneux', 'Souple', 'Souriant', 'Sournois', 'Spontané', 'Sportif', 'Stable', 'Stimulant', 'Stratégique', 'Stressé', 'Strict',
        'Structuré', 'Studieux', 'Subjectif', 'Superficiel', 'Susceptible', 'Sympathique', 'Talentueux', 'Tenace', 'Têtu', 'Timide', 'Tolérant',
        'Tourmenté', 'Tranquille', 'Travailleur', 'Triste', 'Trouillard', 'Unique', 'Vaillant', 'Vaniteux', 'Vantard', 'Versatile', 'Vif',
        'Vif d`esprit', 'Vigilant', 'Vigoureux', 'Volontaire', 'Vulgaire', 'Zen']
};
export var dropdownEN = {
    1713: ['CPE', 'Preschool', 'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7/ Secondary 1',
        'Grade 8/ Secondary 2', 'Grade 9/ Secondary 3', 'Grade 10/ Secondary 4', 'Grade 11/ Secondary 5', 'Preparatory training', 'Specialized Trade Training', 'Adult Training', 'CAPS Program',
        'Not Applicable'],
    1736: ['Right-Handed', 'Left-Handed', 'Ambidextrous', "I don't know", 'Not Applicable'],
    1743: ['French, language of instruction', 'French, second language', 'English Language Arts', 'English as a Second Language', 'Spanish, third language', 'Linguistic, academic',
        'social integration', 'Math', 'Science and Technology', 'Technological and Scientific Applications', 'Environmental Science and Technology', 'Science and Environment', 'Chemistry',
        'Physics', 'Geography, History and Citizenship Education (Elementary)', 'Financial Education', 'Geography', 'Cultural Geography', '20th Century History',
        'History and Citizenship Education', 'History of Quebec and Canada', 'The Contemporary World', 'Drama', 'Art', 'Dance', 'Music', 'Physical and Health Education',
        'Ethics and Religious Culture', 'Personal Orientation Project', 'Exploration of Vocational Training', 'Entrepreneurship Awareness', 'Integrative Project'],
    1744: ['3 months or less', '4 months', '5 months', '6 months', '7 months', '8 months', '9 months', '10 months', '11 months', '12 months', 'More than 12 months', 'I do not remember', 'Not Applicable'],
    1745: ['5 months or less', '6 months', '7 months', '8 months', '9 months', '10 months', '11 months', '12 months', '13 months', '14 months', '15 months', 'More than 15 months', 'I do not remember', 'Not Applicable'],
    1746: ['8 months or less', '9 months', '10 months', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months', 'More than 24 months', 'I do not remember', 'Not Applicable'],
    1747: ['10 months or less', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months', '25 months', '26 months', '27 months', '28 months', '29 months', '30 months', '31 months', '32 months', '33 months', '34 months', '35 months', '36 months', 'More than 36 months', 'I do not remember', 'Not Applicable'],
    1748: ['8 months or less', '9 months', '10 months', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months', '25 months', '26 months', '27 months', '28 months', '29 months', '30 months', '31 months', '32 months', '33 months', '34 months', '35 months', '36 months', 'More than 36 months', 'I do not remember', 'Not Applicable'],
    1749: ['10 months or less', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months', '25 months', '26 months', '27 months', '28 months', '29 months', '30 months', '31 months', '32 months', '33 months', '34 months', '35 months', '36 months', 'More than 36 months', 'I do not remember', 'Not Applicable'],
    1750: ['10 months or less', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months', '25 months', '26 months', '27 months', '28 months', '29 months', '30 months', '31 months', '32 months', '33 months', '34 months', '35 months', '36 months', 'More than 36 months', 'I do not remember', 'Not Applicable'],
    1755: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1756: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1757: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1758: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1759: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1760: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1761: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1762: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1763: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1764: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1765: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1766: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1767: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1768: ['Daily', 'A few times a week', 'Weekly', 'A few times a month', 'Once a month', 'A few times a year', 'Once a year', 'Every few years', "I don't know"],
    1769: ['French, language of instruction', 'French, second language', 'English Language Arts', 'English as a Second Language', 'Spanish, third language', 'Linguistic, academic',
        'social integration', 'Math', 'Science and Technology', 'Technological and Scientific Applications', 'Environmental Science and Technology', 'Science and Environment', 'Chemistry',
        'Physics', 'Geography, History and Citizenship Education (Elementary)', 'Financial Education', 'Geography', 'Cultural Geography', '20th Century History',
        'History and Citizenship Education', 'History of Quebec and Canada', 'The Contemporary World', 'Drama', 'Art', 'Dance', 'Music', 'Physical and Health Education',
        'Ethics and Religious Culture', 'Personal Orientation Project', 'Exploration of Vocational Training', 'Entrepreneurship Awareness', 'Integrative Project'],
    1843: [
        'Accessible',
        'Active',
        'Affectionate',
        'Restless',
        'Aggressive',
        'Lovable',
        'Ambitious',
        'Friendly',
        'Anxious',
        'Focused',
        'Articulate',
        'Artistic',
        'Diligent',
        'Prudent',
        'Attentive',
        'self-determined',
        'Autonomous',
        'Talkative',
        'Caring',
        'joker',
        'Sulky',
        'Brave',
        'Bright',
        'Noisy',
        'Cuddly',
        'Calm',
        'Capricious',
        'Daredevil',
        'Catastrophic',
        'Warm',
        'Charismatic',
        'Charming',
        'Engaging',
        'angry',
        'Collaborator',
        'Communicative',
        'Compassionate',
        'Competent',
        'Competitive',
        'complacent',
        'Complex',
        'Complicated',
        'Understanding',
        'Confident',
        'Conformist',
        'Conscientious',
        'Consistent',
        'Constant',
        'Constructive',
        'Content',
        'Persuasive',
        'Cooperative',
        'Courageous',
        'Courteous',
        'Creative',
        'Critical',
        'Cultivated',
        'Curious',
        'Debater',
        'Resourceful',
        'Decided',
        'Relaxed',
        'Delicate',
        'Depressive',
        'Eager',
        'Disobedient',
        'Messy',
        'disorganized',
        'Determined',
        'Devoted',
        'Trustworthy',
        'Diligent',
        'Diplomatic',
        'Direct',
        'Disciplined',
        'Discreet',
        'Available',
        'Distant',
        'Distinctive',
        'Distracted',
        'Distracting',
        'Entertaining',
        'Docile',
        'Gifted',
        'Funny',
        'Dynamic',
        'Dazzling',
        'Thrifty',
        'Effective',
        'Egocentric',
        'Self-centered',
        'Eloquent',
        'emotional',
        'Touching',
        'Empathetic',
        'Encouraging',
        'Enduring',
        'Energetic',
        'Engaged',
        'Enigmatic',
        'Playful',
        'Enthusiastic',
        'Entrepreneurial',
        'Envious',
        'Flourished',
        'Dizzy',
        'Eccentric',
        'Excessive',
        'Demanding',
        'Expressive',
        'Extrovert',
        'Fatigue',
        'Reliable',
        'Faithful',
        'Flexible',
        'Gallant',
        'Embarrassed',
        'Generous',
        'Kind',
        'Gourmand',
        'Grumpy',
        'Skillful',
        'Arrogant',
        'Hesitant',
        'Honest',
        'Humble',
        'Humiliating',
        'Humorist',
        'Hyperactive',
        'Hypersensitive',
        'Imaginative',
        'Full of him/herself',
        'immature',
        'Impartial',
        'Eager',
        'Sassy',
        'Implicit',
        'Careless',
        'Impulsive',
        'Inattentive',
        'Incompetent',
        'Inconstant',
        'Undecided',
        'Independent',
        'Tireless',
        'Influenced',
        'Influential',
        'Ingenious',
        'Innovator',
        'Worried',
        'Dissatisfied',
        'Indiscreet',
        'Presumptuous',
        'Carefree',
        'Unstable',
        'Honest',
        'Clever',
        'Interested',
        'Uncompromising',
        'Introvert',
        'Intuitive',
        'Inventive',
        'Ironic',
        'Unreasonable',
        'Unrealistic',
        'Disrespectful',
        'Irresponsible',
        'Irritable',
        'Jealous',
        'Jovial',
        'Cheerful',
        'Judicious',
        'Fair',
        'Negligent',
        'Leader',
        'Slow',
        'Logic',
        'Loyal',
        'Moody',
        'Awkward',
        'Dishonest',
        'Clever',
        'Methodical',
        'Manipulative',
        'Manual',
        'Funny',
        'Mature',
        'Bad',
        'Mistrustful',
        'Liar',
        'Deserving',
        'Contemptuous',
        'Petty',
        'Methodical',
        'Methodical',
        'Meticulous',
        'Thorough',
        'Modest',
        'Mocker',
        'Moral',
        'Motivated',
        'Mysterious',
        'Naive',
        'Narcissistic',
        'Negative',
        'Negligent',
        'Negotiator',
        'Nervous',
        'Noble',
        'Nonchalant',
        'Objective',
        'Obliging',
        'Observer',
        'Obsessive',
        'Obstinate',
        'Obtuse',
        'Obstinate',
        'Adversary',
        'Optimistic',
        'Ordered',
        'Organized',
        'Proud',
        'Original',
        'Open minded',
        'Pleasant',
        'Peaceful',
        'Lazy',
        'Passive',
        'Passionate',
        'Keen',
        'Patient',
        'Thoughtful',
        'Perfectionist',
        'Persecutor',
        'Persevering',
        'Insightful',
        'Persuasive',
        'Pessimistic',
        'Fearful',
        'Philosopher',
        'Plaintive',
        'Pleasant',
        'Crybaby',
        'Picky',
        'Polite',
        'Versatile',
        'Punctual',
        'Laid',
        'Pragmatic',
        'Convenient',
        'Accurate',
        'Concerned',
        'Present',
        'Pretentious',
        'Foresight',
        'Procrastinator',
        'Stingy',
        'Refined',
        'Reasonable',
        'Moaner',
        'Resentful',
        'Reassuring',
        'Rational',
        'Realistic',
        'Realistic',
        'Rebel',
        'Receptive',
        'Comforting',
        'Grateful',
        'Thoughtful',
        'Withdrawn',
        'Reserved',
        'Resilient',
        'Exhausting',
        'Respectful',
        'Responsible',
        'Dreamer',
        'Insubordinate',
        'Rigid',
        'Fun',
        'Rigorous',
        'Nerdy',
        'Romantic',
        'Grouchy',
        'Cunning',
        'Wise',
        'Shameless',
        'Sarcastic',
        'Savage',
        'Learned',
        'Structured',
        'Sensitive',
        'Serene',
        'Serious',
        'Helpful',
        'Sincere',
        'Sociable',
        'Social',
        'Careful',
        'Solid',
        'Lonely',
        'Dark',
        'Pensive',
        'Anxious',
        'Suspicious',
        'Soft',
        'Smiling',
        'Sly',
        'Spontaneous',
        'Jock',
        'Steady',
        'Stimulating',
        'Strategic',
        'Stressed',
        'Strict',
        'Structured',
        'Studious',
        'Subjective',
        'Superficial',
        'Likely',
        'Likeable',
        'Talented',
        'Tenacious',
        'Stubborn',
        'Shy',
        'Tolerant',
        'Tormented',
        'Tranquil',
        'Worker',
        'Sad',
        'Coward',
        'Unique',
        'Valiant',
        'Vain',
        'Boastful',
        'Versatile',
        'Lively',
        'Quick-witted',
        'Vigilant',
        'Vigorous',
        'Deliberate',
        'Vulgar',
        'Zen',
    ]
};
