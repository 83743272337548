import React from "react";
var Text1FromDesign = function (props) {
    var handleMouseEvent = function () {
        if (props.onHover != undefined) {
            props.onHover();
        }
    };
    var handleMouseEventLeave = function () {
        if (props.onHoverLeave != undefined) {
            props.onHoverLeave();
        }
    };
    var render = function () {
        var _a;
        var textColor = props.textColor ? props.textColor : 'text-c-text1FromDesign';
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { onMouseEnter: handleMouseEvent, onMouseLeave: handleMouseEventLeave, onClick: props === null || props === void 0 ? void 0 : props.onClick, className: "font-poppins text-left text-xs ".concat(textColor, " ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') }, props.children)));
    };
    return render();
};
export default Text1FromDesign;
