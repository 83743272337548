var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback } from 'react';
import EdproIcon from "../../assets/icons/EdproIcon";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import { useNavigate } from "react-router-dom";
import Functions from "../../utils/functions";
import { useUpdateLanguage } from "../../hooks/miscellaneous/useUpdateLanguage";
import Cookies from 'universal-cookie';
import functions from "../../utils/functions";
import Href from "../../components/Href/Href";
import SubmitButton from "../../components/Button/SubmitButton";
import F from "../../utils/functions";
import LightDropDown from "../../components/DropDown/LightDropDown";
var Navigation = function (props) {
    var cookies = new Cookies();
    var updateLanguage = useUpdateLanguage();
    var LG = Functions.getLang();
    var navigate = useNavigate();
    var gotoUrl = useCallback(function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(path);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var render = function () {
        return (React.createElement("div", { className: "bg-home-bg4 bg-cover h-96 w-full flex flex-col" },
            React.createElement("div", { className: "py-8 px-12 flex justify-start items-center relative h-10" },
                React.createElement("div", { className: "flex  w-1/12" },
                    React.createElement(EdproIcon, { color: env.iconColorWhite, onClick: function () { return gotoUrl(env.routes.home); } })),
                React.createElement("div", { className: "flex grow justify-evenly" },
                    React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline pt-3", title: lang[LG].aboutUs, onClick: function () { return gotoUrl(env.routes.aboutUs); } }),
                    React.createElement(LightDropDown, { additionalClassTitle: "pl-3 focus:text-white text-white", items: functions.getPoliticsItems(LG), placeHolder: lang[LG].politics, iconColor: env.iconGrey, onChange: function (e) {
                            var url = "".concat(Functions.removeParams(env.routes.politics)) + e.data;
                            console.log(url);
                            gotoUrl(url);
                        } }),
                    React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline pt-3", title: lang[LG].helpCenter, onClick: function () { return gotoUrl(env.routes.helpCenter); } }),
                    React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline pt-3", title: lang[LG].contactUsTitle, onClick: function () { return gotoUrl(env.routes.contactUs); } })),
                React.createElement("div", { className: "flex justify-end px-10 w-5/12" },
                    React.createElement(LightDropDown, { additionalClassTitle: "py-3 pl-3 focus:text-white text-white ", title: '', items: functions.getLangItems(LG), selectedItem: Functions.getLangSelectedItems(LG), iconColor: env.iconGrey, onChange: function (e) {
                            updateLanguage(e.data);
                        } }),
                    React.createElement(SubmitButton, { id: "login-btn", title: lang[LG].login, additionalClass: "hover:bg-c-white hover:text-c-purple rounded-full text-white font-poppins px-5 py-2 flex items-center h-10 w-fit ml-5 border-white border", onClick: function () { return gotoUrl(env.routes.login); } }))),
            React.createElement("div", { className: "absolute top-[25%] left-[50%] -translate-y-2/4 -translate-x-2/4 font-light text-c-3xl text-c-white font-poppins" }, props.title)));
    };
    return render();
};
export default Navigation;
