import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import env from '../../config/env';
import SubmitButton from '../Button/SubmitButton';
import Text from '../Field/Text';
import IconContainer from '../IconContainer/IconContainer';
import Title from '../Title/Title';
import ModalInClientPage from "../Layouts/ModalInClientPage";
var SuccessActionModal = function (props) {
    var navigate = useNavigate();
    var clickCloseIcon = useCallback(function () {
        if (props.back)
            navigate(props.back);
        else
            navigate(-1);
    }, []);
    var renderNormalContent = function (string) {
        return (React.createElement(Text, null, string));
    };
    var renderActionContent = function (string) {
        var content = string.split('{ACTION}');
        return (React.createElement("p", { className: 'font-poppins inline-block text-sm text-left font-light' },
            content[0],
            " ",
            React.createElement('span', {
                onClick: props.actionContentFn,
                className: "font-poppins inline-block text-sm text-left text-c-purple cursor-pointer hover:underline"
            }, props.actionContent),
            " ",
            content[1]));
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalInClientPage, { backgroundCls: props.backgroundCls },
                React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: clickCloseIcon }),
                React.createElement("div", { className: "flex" },
                    React.createElement(Title, null, props.title),
                    props.icon && React.createElement("div", { className: "ml-2 mt-0.5" }, props.icon)),
                props.content.map(function (string, key) {
                    var containActionString = string.includes("ACTION");
                    var content = containActionString ? renderActionContent(string) : renderNormalContent(string);
                    return (React.createElement("div", { className: "mt-4 text-left", key: key }, content));
                }),
                React.createElement("div", { className: "mt-4 flex justify-end" },
                    React.createElement(SubmitButton, { title: props.buttonTitle, onClick: props.action })))));
    };
    return render();
};
export default SuccessActionModal;
