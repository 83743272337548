import React from "react";
var Href = function (props) {
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { className: "font-poppins text-sm text-left ".concat((_a = props.className) !== null && _a !== void 0 ? _a : ''), onClick: props.onClick, href: props.href }, props.title)));
    };
    return render();
};
export default Href;
