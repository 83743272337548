import clsx from "clsx";
import React from "react";
import bg from "../../../../public/images/p1440.png";
var ModalInQuestionnairePage = function (props) {
    var imgS = {
        width: '1440 px',
        height: '900 px',
        backgroundImage: 'url(' + bg + ')',
        backgroundPosition: 'center 0',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    };
    var render = function () {
        var _a;
        var _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: clsx({
                    "w-full min-h-screen flex justify-center": true
                }), style: imgS },
                React.createElement("div", { className: clsx((_a = {
                            "bg-white p-5 rounded relative": true
                        },
                        _a[(_b = props.layoutClass) !== null && _b !== void 0 ? _b : 'w-5/6 h-fit mt-16'] = true,
                        _a)) }, props.children))));
    };
    return render();
};
export default ModalInQuestionnairePage;
