import React from "react";
var Tooltip = function (props) {
    var _a;
    var class1 = (_a = props.additionalClass) !== null && _a !== void 0 ? _a : 'w-44 top-[-15px] left-8';
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "group relative flex" },
                props.children,
                React.createElement("span", { className: "absolute scale-0 transition-all rounded shadow-lg text-gray-900 text-base bg-white p-2 group-hover:scale-100 font-poppins ".concat(class1) },
                    props.message,
                    " ",
                    React.createElement("div", { className: "tooltip-arrow", "data-popper-arrow": true })))));
    };
    return render();
};
export default Tooltip;
