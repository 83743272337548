var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import Title from "../Title/Title";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import Text from "../Field/Text";
import TextField from "../Input/TextField";
import { validation_lang } from "../../lang/validations";
import CreditCard from "./CreditCard";
import { useForm } from "react-hook-form";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import DropDown from "../DropDown/DropDown";
import Checkbox from "../Input/Checkbox";
import F from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import SubmitButtonWithIcon from "../Button/SubmitButtonWithIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
var AddPaymentMethod = function (props) {
    var _a = useForm({
        mode: "onChange",
        defaultValues: props.defaultValues
    }), register = _a.register, getValues = _a.getValues, trigger = _a.trigger, watch = _a.watch, setValue = _a.setValue, clearErrors = _a.clearErrors, errors = _a.formState.errors;
    var stripe = useStripe();
    var elements = useElements();
    var _b = useState(false), isCardValid = _b[0], setIsCardValid = _b[1];
    var _c = useState(false), isSubmitted = _c[0], setIsSubmitted = _c[1];
    var _d = useState(), stripeError = _d[0], setStripeError = _d[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValidated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (props.isProcessing)
                        return [2 /*return*/];
                    setIsSubmitted(true);
                    if (!props.skipCard) return [3 /*break*/, 1];
                    clearErrors();
                    return [3 /*break*/, 4];
                case 1: return [4 /*yield*/, trigger()];
                case 2:
                    isValidated = _a.sent();
                    if (!isValidated || !isCardValid)
                        return [2 /*return*/];
                    return [4 /*yield*/, callCardSetup()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, props.submitBtnFn(getValues())];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var callCardSetup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, paymentMethod, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, stripe.createPaymentMethod({
                        type: 'card',
                        card: elements.getElement(CardElement),
                        billing_details: {
                            address: {
                                country: getValues("country").toUpperCase(),
                                city: getValues("city"),
                                line1: getValues("address"),
                                line2: getValues("apartment"),
                                postal_code: getValues("postcode"),
                                state: getValues("province"),
                            },
                            name: getValues("nameOnCard"),
                        }
                    })];
                case 1:
                    _a = _b.sent(), paymentMethod = _a.paymentMethod, error = _a.error;
                    setStripeError(error);
                    if (error == null && paymentMethod != null) {
                        setValue("paymentMethodId", paymentMethod.id);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (props.skipCard) {
            clearErrors();
            setIsSubmitted(false);
        }
        else if (isSubmitted) {
            trigger();
        }
    }, [props.skipCard]);
    var onChange = function () {
        if (props.onChange != null)
            props.onChange(getValues());
    };
    var validatePostcode = function (postcode) {
        var response;
        var regEx = new RegExp(/^([a-zA-Z])([0-9])([a-zA-Z])([\s]?)([0-9])([a-zA-Z])([0-9]).*$/);
        if (getValues("country") === "ca" && !regEx.test(postcode)) {
            response = validation_lang[LG].invalidZipcode;
        }
        return response;
    };
    watch(['country', 'province', 'isSave', 'sameShippingAddress']);
    var LG = F.getLang();
    var getShippingAddress = function () {
        var _a, _b;
        if (!props.showShippingAddress)
            return true;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container flex my-5 items-center" },
                React.createElement("label", { htmlFor: "", onClick: function () {
                        // setValue("sameShippingAddress", !getValues('sameShippingAddress'))
                        // onChange()
                    } },
                    React.createElement(Checkbox, { checked: (_a = getValues('sameShippingAddress')) !== null && _a !== void 0 ? _a : false, onChange: function () {
                            setValue("sameShippingAddress", !getValues('sameShippingAddress'));
                            onChange();
                        } }),
                    React.createElement(Text, null, lang[LG].isSameBillingAddress))),
            !getValues('sameShippingAddress') && React.createElement(React.Fragment, null,
                React.createElement("input", __assign({ type: "hidden" }, register("country_shipping", {
                    required: validation_lang[LG].country,
                }))),
                React.createElement("input", __assign({ type: "hidden" }, register("province_shipping", {
                    required: validation_lang[LG].state,
                }))),
                React.createElement(Title, { additionalClass: 'container mt-5' }, lang[LG].deliveryAddress),
                React.createElement(Text, { additionalClass: "mb-5" }, lang[LG].deliveryAdTax),
                React.createElement("div", { className: "container inline flex" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].country),
                        React.createElement("div", { className: "w-80" },
                            React.createElement(DropDown, { items: F.getCountriesItems(), selectedItem: F.getCountriesItems().filter(function (item) { return item.data.code === 'ca'; })[0], onChange: function (item) {
                                    setValue("country_shipping", item.data.code);
                                    setValue("province_shipping", "");
                                }, error: errors.country_shipping }))),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].state),
                        React.createElement("div", { className: "w-52" },
                            React.createElement(DropDown, { placeHolder: lang[LG].select, items: F.getStateItems((_b = getValues("country_shipping")) !== null && _b !== void 0 ? _b : ""), onChange: function (item) {
                                    setValue("province_shipping", item.title);
                                    clearErrors(["province_shipping"]);
                                    onChange();
                                }, error: errors.province_shipping })))),
                React.createElement("div", { className: "container inline flex mt-5" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].city),
                        React.createElement(TextField, { register: register("city_shipping", {
                                required: validation_lang[LG].city
                            }), error: errors.city_shipping, title: lang[LG].placeHolder2, type: "text", additionalClass: "w-80" })),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].zipCode),
                        React.createElement(TextField, { register: register("postcode_shipping", {
                                required: validation_lang[LG].postcode,
                                validate: function (value) { return validatePostcode(value !== null && value !== void 0 ? value : ""); },
                            }), error: errors.postcode_shipping, title: lang[LG].examplePostCode, type: "text", additionalClass: "w-52", onChange: function (event) {
                            } }))),
                React.createElement("div", { className: "container inline flex mt-5" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].address),
                        React.createElement(TextField, { register: register("address_shipping", {
                                required: validation_lang[LG].billingAddress
                            }), error: errors.address_shipping, title: lang[LG].placeHolder3, type: "text", additionalClass: "w-80" })),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].apartment),
                        React.createElement(TextField, { register: register("apartment_shipping"), error: errors.apartment_shipping, title: lang[LG].placeHolder4, type: "text", additionalClass: "w-52" }))))));
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container", style: { minWidth: "700px" } },
                React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: props.onCancel }),
                React.createElement("input", __assign({ type: "hidden" }, register("country", {
                    required: validation_lang[LG].country,
                }))),
                React.createElement("input", __assign({ type: "hidden" }, register("province", {
                    required: validation_lang[LG].state,
                }))),
                props.hideTitle == null &&
                    React.createElement("div", null,
                        React.createElement("div", { className: "flex" },
                            React.createElement(Title, { additionalClass: "flex" }, lang[LG].paymentInformations),
                            props.leftBtn && React.createElement("a", { className: "text-sm text-left text-c-purple mt-1.5 ml-4 cursor-pointer float-left", onClick: props.onClick },
                                lang[LG].skipThisStep,
                                " > ")),
                        React.createElement("br", null),
                        React.createElement(Text, null, lang[LG].whyPaymentInformations)),
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "w-1/2" },
                        props.title,
                        React.createElement(Text, { isbold: true, additionalClass: "mt-5 mb-2" }, lang[LG].fullNameOnTheCreditCard),
                        React.createElement(TextField, { register: register("nameOnCard", {
                                required: validation_lang[LG].nameOfCard,
                            }), error: errors.nameOnCard, title: lang[LG].placeHolder1, type: "text", additionalClass: "w-80" }),
                        React.createElement(Text, { isbold: true, additionalClass: "mt-5 mb-2" }, lang[LG].cardNumber),
                        React.createElement(CreditCard, { showErrors: !getValues("skipCard") && isSubmitted && !isCardValid, isValid: function (value) { return setIsCardValid(value); } })),
                    props.invoiceDetail),
                React.createElement(Title, { additionalClass: 'container mt-10 mb-2' }, lang[LG].billingaddress),
                React.createElement("div", { className: "container inline flex" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].country),
                        React.createElement("div", { className: "w-80" },
                            React.createElement(DropDown, { items: F.getCountriesItems(), selectedItem: F.getCountriesItems().filter(function (item) { return item.data.code === 'ca'; })[0], onChange: function (item) {
                                    setValue("country", item.data.code);
                                    setValue("province", "");
                                }, error: errors.country }))),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].state),
                        React.createElement("div", { className: "w-52" },
                            React.createElement(DropDown, { placeHolder: lang[LG].select, items: F.getStateItems(getValues("country")), onChange: function (item) {
                                    setValue("province", item.title);
                                    clearErrors(['province']);
                                    onChange();
                                }, error: errors.province })))),
                React.createElement("div", { className: "container inline flex mt-5" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].city),
                        React.createElement(TextField, { register: register("city", {
                                required: validation_lang[LG].city
                            }), error: errors.city, title: lang[LG].placeHolder2, type: "text", additionalClass: "w-80" })),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].zipCode),
                        React.createElement(TextField, { register: register("postcode", {
                                required: validation_lang[LG].postcode,
                                validate: function (value) { return validatePostcode(value); },
                            }), error: errors.postcode, title: lang[LG].examplePostCode, type: "text", additionalClass: "w-52", onChange: function (event) {
                            } }))),
                React.createElement("div", { className: "container inline flex mt-5" },
                    React.createElement("div", { className: "container w-fit" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].address),
                        React.createElement(TextField, { register: register("address", {
                                required: validation_lang[LG].billingAddress
                            }), error: errors.address, title: lang[LG].placeHolder3, type: "text", additionalClass: "w-80" })),
                    React.createElement("div", { className: "container w-fit ml-10" },
                        React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].apartment),
                        React.createElement(TextField, { register: register("apartment"), error: errors.apartment, title: lang[LG].placeHolder4, type: "text", additionalClass: "w-52" }))),
                getShippingAddress(),
                props.addThisCard && React.createElement("div", { className: "container flex mt-5 mb-1.5 items-center" },
                    React.createElement("label", { htmlFor: "", onClick: function () {
                            setValue("isSave", !getValues('isSave'));
                        } },
                        React.createElement(Checkbox, { checked: getValues("isSave") === undefined ? setValue("isSave", true) : getValues("isSave"), onChange: function () {
                            } }),
                        React.createElement(Text, null, lang[LG].addCard))),
                React.createElement("div", { className: "container flex inline mt-6 flex" },
                    React.createElement("div", { className: "container inline flex justify-start" }, props === null || props === void 0 ? void 0 : props.leftBtn),
                    React.createElement("div", { className: "container inline flex justify-end" },
                        React.createElement("div", { className: "inline px-2" }, props.rightBtn),
                        React.createElement("div", { className: "inline" },
                            React.createElement(SubmitButtonWithIcon, { type: "button", title: props.submitBtnTitle, iconAdditionalClass: "mr-2", icon: React.createElement(SaveIcon, { color: "#ffffff", height: 18, width: 18 }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, handleSubmit()];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })))),
                stripeError && React.createElement("div", { className: "mt-4" },
                    React.createElement("p", { className: "text-c-red" }, stripeError.message)))));
    };
    return render();
};
export default AddPaymentMethod;
