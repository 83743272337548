var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useRef, useState } from "react";
import CenterModal from "../Layouts/CenterModal";
import Title from "../Title/Title";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import TextIconField from "../Input/TextIconField";
import SearchIcon from "../../assets/icons/SearchIcon";
import Text from "../Field/Text";
import SortIcon from "../Sorting/Icon";
import { useQueryClients } from "../../hooks/client/useQueryClient";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../utils/functions";
import IconButton from "../Button/IconButton";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import clsx from "clsx";
import { useQueryQuestionnaires } from "../../hooks/questionnair/useQueryQuestionnaires";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import StarIcon from "../../assets/icons/StarIcon";
import LightSubmitButton from "../../components/Button/LightSubmitButton";
var SelectClient = function (props) {
    var _a;
    var searchClients = useQueryClients().searchClients;
    var _b = useState(""), search = _b[0], setSearch = _b[1];
    var _c = useState([]), clients = _c[0], setClients = _c[1];
    var _d = useState(0), selectedClient = _d[0], setSelectedClient = _d[1];
    var navigate = useNavigate();
    var params = useParams();
    var questionnaireId = (_a = params === null || params === void 0 ? void 0 : params.id) !== null && _a !== void 0 ? _a : 0;
    var fullPageLoader = useFullPageLoader();
    var getSortItem = useCallback(function (key) {
        return {
            name: key,
            sort: '',
        };
    }, []);
    var _e = useState({
        name: getSortItem('name'),
        age: getSortItem('age'),
        assessmentTool: getSortItem('assessmentTool'),
        creationDate: getSortItem('creationDate'),
        priority: [],
    }), sorting = _e[0], setSorting = _e[1];
    var sort = useCallback(function (key) {
        var _a, _b, _c;
        var _d;
        var sortItem = sorting[key];
        var priority = (_d = sorting.priority) !== null && _d !== void 0 ? _d : [];
        if (sortItem.sort === '') {
            sortItem.sort = 'asc';
        }
        else if (sortItem.sort === 'asc') {
            sortItem.sort = 'desc';
        }
        else if (sortItem.sort === 'desc') {
            sortItem.sort = '';
        }
        if (sortItem.sort !== '' && priority.indexOf(key) === -1) {
            var previousElementName = priority[0];
            if (previousElementName !== key && previousElementName != null) {
                var previousElementDetail = __assign(__assign({}, sorting[priority[0]]), { sort: '' });
                priority.splice(0, 1);
                priority.push(key);
                setSorting(__assign(__assign(__assign({}, sorting), (_a = {}, _a[key] = sortItem, _a[previousElementName] = previousElementDetail, _a)), { priority: priority }));
            }
            else {
                priority.push(key);
                setSorting(__assign(__assign(__assign({}, sorting), (_b = {}, _b[key] = sortItem, _b)), { priority: priority }));
            }
        }
        else {
            if (sortItem.sort === '') {
                var index = priority.indexOf(key);
                priority.splice(index, 1);
            }
            setSorting(__assign(__assign(__assign({}, sorting), (_c = {}, _c[key] = sortItem, _c)), { priority: priority }));
        }
    }, [sorting]);
    var getQuestionnaire = useQueryQuestionnaires().getSingleQuestionnaireInPreviewPage;
    var _f = useState(), questionnaire = _f[0], setQuestionnaire = _f[1];
    var fetchQuestionnaire = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fullPageLoader(true);
                    return [4 /*yield*/, getQuestionnaire.mutateAsync({
                            questionnaireId: +questionnaireId,
                        })];
                case 1:
                    response = _a.sent();
                    fullPageLoader(false);
                    setQuestionnaire(response === null || response === void 0 ? void 0 : response.data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        // fetchQuestionnaire(params.ha);
    }, []);
    var timeout = useRef(0);
    useEffect(function () {
        window.clearTimeout(timeout.current);
        timeout.current = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullPageLoader(true);
                        return [4 /*yield*/, searchClients.mutate({
                                search: search,
                                sorting: sorting,
                                status: "active",
                            })];
                    case 1:
                        _a.sent();
                        fullPageLoader(false);
                        return [2 /*return*/];
                }
            });
        }); }, 500);
    }, [
        search,
        sorting,
    ]);
    useEffect(function () {
        var _a, _b;
        var data = (_b = (_a = searchClients === null || searchClients === void 0 ? void 0 : searchClients.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
        if (typeof data['message'] === 'undefined') {
            setClients(data);
        }
        else {
            setClients([]);
        }
    }, [searchClients.status]);
    var LG = Functions.getLang();
    var starIconClientClick = function (selectedClientId) {
        return (React.createElement(StarIcon, { color: 'text-c-purple', additionDivClass: "flex items-center", textTittle: lang[LG].newCustomer, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var url;
                return __generator(this, function (_a) {
                    setSelectedClient(selectedClientId);
                    url = "".concat(Functions.removeParams(env.routes.launchQuestionnaire)).concat(params === null || params === void 0 ? void 0 : params.id, "/").concat(selectedClientId);
                    navigate(url);
                    return [2 /*return*/];
                });
            }); } }));
    };
    var getAge = function (client) {
        if (client.age == '0') {
            return lang[LG].monthsOld.replace('{0}', client.ageMonths);
        }
        else {
            return lang[LG].yearsOld.replace('{0}', client.age);
        }
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "bg-questionnaire-bank bg-cover bg-center" },
                React.createElement(CenterModal, { layoutClass: "w-176" },
                    React.createElement(CloseIcon, { color: env.iconColor, additionalClass: "absolute m-2 right-0 top-0", onClick: function () {
                            navigate(env.routes.dashboard);
                        } }),
                    React.createElement(Title, null, lang[LG].selectClient),
                    React.createElement(TextIconField, { position: 'left', icon: React.createElement(SearchIcon, { color: env.iconColor }), title: lang[LG].search, type: 'text', additionalClass: 'my-3 w-80', additionalInputClass: clsx({
                            'bg-c-scroll': search == '',
                        }), onChange: function (e) {
                            setSearch(e.currentTarget.value);
                        } }),
                    React.createElement("div", { className: "grid grid-cols-3 gap-3 py-2" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(Text, { isbold: true }, lang[LG].name),
                            React.createElement("button", { className: "px-2", onClick: function () { return sort('name'); } },
                                React.createElement(SortIcon, { detail: sorting['name'], type: 'alpha' }))),
                        React.createElement("div", { className: "col-span-2 flex items-center" },
                            React.createElement(Text, { isbold: true }, lang[LG].age),
                            React.createElement("button", { className: "px-2", onClick: function () { return sort('age'); } },
                                React.createElement(SortIcon, { detail: sorting['age'], type: 'numeric' })))),
                    clients.map(function (client, index) {
                        return (React.createElement("div", { className: "hover:cursor-pointer hover:bg-c-scroll grid grid-cols-3 gap-3 border-t border-c-divider py-2", key: index, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var url;
                                return __generator(this, function (_a) {
                                    url = "".concat(Functions.removeParams(env.routes.launchQuestionnaire)).concat(params === null || params === void 0 ? void 0 : params.id, "/").concat(client.id);
                                    navigate(url);
                                    return [2 /*return*/];
                                });
                            }); } },
                            React.createElement("div", { className: "flex items-center" },
                                React.createElement(Text, { title: client.name, searchText: search, additionalClass: "truncate" }, client.name)),
                            React.createElement("div", { className: "flex items-center" },
                                React.createElement(Text, null, getAge(client))),
                            index == 0 ? starIconClientClick(client.id) : ""));
                    }),
                    searchClients.status === 'success' &&
                        clients.length === 0 &&
                        React.createElement("div", { className: "grid grid-cols-3 gap-3 border-t border-c-divider py-2" },
                            React.createElement("div", { className: "flex items-center" },
                                React.createElement(Text, null, lang[LG].noClientFound))),
                    React.createElement("div", { className: "grid grid-cols-3 gap-3 border-t border-c-divider py-2" }),
                    React.createElement("div", { className: "flex justify-between mt-4" },
                        React.createElement(IconButton, { title: '', alias: lang[LG].cancel, icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), additionalClass: clsx({
                                "h-10 w-10": true,
                            }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    navigate("".concat(Functions.removeParams(env.routes.questionnairePreview)).concat(params === null || params === void 0 ? void 0 : params.id));
                                    return [2 /*return*/];
                                });
                            }); } }),
                        React.createElement(LightSubmitButton, { title: lang[LG].addClient, alias: lang[LG].cancel, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    navigate("".concat(Functions.removeParams(env.routes.clientAdd)).concat(params === null || params === void 0 ? void 0 : params.id, "/").concat(1));
                                    return [2 /*return*/];
                                });
                            }); } }))))));
    };
    return render();
};
export default SelectClient;
