export var anaPlaceholderFR = {
    1713: 'Sélectionnez l’année scolaire',
    1714: 'Entrez le milieu scolaire fréquenté par l’apprenant',
    1715: 'Entrez le motif de la présente consultation',
    1716: 'Entrez une force ou une qualité de l’apprenant',
    1717: 'Entrez un défi de l’apprenant',
    1718: 'Entrez l’historique de l’apprenant',
    1736: 'Sélectionnez la préférence manuelle de l’apprenant',
    1737: 'Entrez la langue maternelle de l’apprenant',
    1738: 'Entrez les autres langues parlées par l’apprenant',
    1739: 'Entrez l’historique médicale de l’apprenant',
    1740: 'Entrez les allergies de l’apprenant',
    1741: 'Entrez la médication de l’apprenant',
    1742: 'Entrez un intérêt, un loisir ou une activité favorite de l’apprenant',
    1743: 'Sélectionnez une matière scolaire préférée',
    1744: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1745: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1746: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1747: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1748: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1749: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1750: 'Sélectionnez l’âge auquel l’apprenant a accompli cette action',
    1755: 'Sélectionnez la fréquence',
    1756: 'Sélectionnez la fréquence',
    1757: 'Sélectionnez la fréquence',
    1758: 'Sélectionnez la fréquence',
    1759: 'Sélectionnez la fréquence',
    1760: 'Sélectionnez la fréquence',
    1761: 'Sélectionnez la fréquence',
    1762: 'Sélectionnez la fréquence',
    1763: 'Sélectionnez la fréquence',
    1764: 'Sélectionnez la fréquence',
    1765: 'Sélectionnez la fréquence',
    1766: 'Sélectionnez la fréquence',
    1767: 'Sélectionnez la fréquence',
    1768: 'Sélectionnez la fréquence',
    1769: 'Sélectionnez une matière scolaire',
    1770: 'Entrez l’attitude de l’apprenant',
    1771: 'Entrez une technique ou technologie utilisée par l’apprenant',
    1844: 'Entrez les informations supplémentaires, s’il y a lieu',
};
export var anaSubsectionTitleFR = {
    51: 'Est-ce que l’apprenant a été évalué en…',
    52: 'Si oui, à quand remonte la dernière évaluation?',
    13: 'Est-ce que l’apprenant à de la difficulté à...',
    14: 'Selon vous, l’apprenant...',
    111: 'L’apprenant a-t-il reçu un ou plusieurs service(s) en...',
    112: 'Si oui, dans quelle période?',
    113: 'À quelle fréquence?',
};
export var anaPlaceholderEN = {
    1713: 'Select the school year',
    1714: 'Enter the school environment attended by the learner',
    1715: 'Enter the reason for this consultation',
    1716: 'Enter a strength or quality of the learner',
    1717: 'Enter a challenge for the learner',
    1718: "Enter the learner's history",
    1736: "Select the learner's manual preference",
    1737: "Enter the learner's native language",
    1738: 'Enter the other languages spoken by the learner',
    1739: "Enter the learner's medical history",
    1740: "Enter the learner's allergies",
    1741: "Enter the learner's medication",
    1742: "Enter a learner's favorite interest, hobby, or activity",
    1743: "Select a favorite school subject",
    1744: "Select the age at which the learner performed this action",
    1745: "Select the age at which the learner performed this action",
    1746: "Select the age at which the learner performed this action",
    1747: "Select the age at which the learner performed this action",
    1748: "Select the age at which the learner performed this action",
    1749: "Select the age at which the learner performed this action",
    1750: "Select the age at which the learner performed this action",
    1755: 'Select the frequency',
    1756: 'Select the frequency',
    1757: 'Select the frequency',
    1758: 'Select the frequency',
    1759: 'Select the frequency',
    1760: 'Select the frequency',
    1761: 'Select the frequency',
    1762: 'Select the frequency',
    1763: 'Select the frequency',
    1764: 'Select the frequency',
    1765: 'Select the frequency',
    1766: 'Select the frequency',
    1767: 'Select the frequency',
    1768: 'Select the frequency',
    1769: 'Select a school subject',
    1770: "Enter learner's attitude",
    1771: 'Enter a technique or technology used by the learner',
    1844: 'Enter additional information, if applicable',
};
export var anaSubsectionTitleEN = {
    51: 'Has the learner been assessed in/ Has the learner had',
    52: 'If yes, when was the last assessment?',
    13: 'Does the learner have difficulty…',
    14: 'According to you, the learner…',
    111: 'Did the learner receive one or more service(s) in…',
    112: 'If yes, in which period??',
    113: 'How often?',
};
