import { useQuery } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var KEY = "GET_RESPONDENTS";
var _getRespondents = function (data) {
    return Api.getD(data, env.api.getRespondents);
};
export function useQueryRespondent(data) {
    var getRespondents = useQuery(KEY, function () { return _getRespondents(data); }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
    return { getRespondents: getRespondents };
}
