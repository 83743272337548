var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestionnaire, saveAnswer, updateAnswerActivity, updateAnswerAssistance, updateCurrentPage, updateQuestionnaireResult, updateSectionAdditional, updateSectionIndex, } from "../../feature/questionnaire";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../utils/functions";
import { useQueryClients } from "../../hooks/client/useQueryClient";
import ModalInAnswerQuestionnaire from "../../components/Layouts/ModalInAnswerQuestionnaire";
import BaseTitle from "../../components/Title/BaseTitle";
import { lang } from "../../lang/lang";
import Text from "../../components/Field/Text";
import Text1FromDesign from "../../components/Title/Text1FromDesign";
import { EQuestionnaireActivity, EQuestionnaireAssistance } from "../../enums/questionnaire";
import LightSubmitButtonWithIcon from "../../components/Button/LightSubmitButtonWithIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
import HelpIcon from "../../assets/icons/HelpIcon";
import SubmitButtonWithIcon from "../../components/Button/SubmitButtonWithIcon";
import ForwardIcon from "../../assets/icons/ForwardIcon";
import { useForm } from "react-hook-form";
import SectionBar from "./SectionBar";
import ArrowButton from "../../components/Button/ArrowButton";
import env from "../../config/env";
import TextError from "../../components/Field/TextError";
import LightModal from "../../components/Layouts/LightModal";
import IconContainer from "../../components/IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import Title from "../../components/Title/Title";
import LightSubmitButton from "../../components/Button/LightSubmitButton";
import LightReverseColorButtonWithIcon from "../../components/Button/LightReverseColorButtonWithIcon";
import FormError from "../../components/Error/FormError";
import TextArea from "../../components/Input/TextArea";
import UserGuidePage from "./UserGuidePage";
import IconButton from "../../components/Button/IconButton";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import LightBoldText from "../../components/Field/LightBoldText";
import TickIcon from "../../assets/icons/TickIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import Tooltip from "../../components/Text/Tooltip";
var anaQueId = env.anaQuestionnaireId;
var DynamicAnswers = function (props) {
    var _a;
    var _b = useForm(), register = _b.register, resetField = _b.resetField, setValue = _b.setValue;
    var _c = useState(false), madeChanges = _c[0], setMadeChanges = _c[1];
    var _d = useState(), detail = _d[0], setDetail = _d[1];
    var _e = useState(0), active = _e[0], setActive = _e[1];
    var _f = useState(true), isGeneralDemo = _f[0], setIsGeneralDemo = _f[1];
    var questionnaireStore = useSelector(function (store) { return store.questionnaire; });
    var answers = useSelector(function (store) { return store.questionnaire.answers; });
    var user = useSelector(function (store) { return store.user.value; });
    var QuestionlistborderTopClass = "border-t-2 border-c-fuscous-grey";
    var _g = useState(0), returnPageNumber = _g[0], setReturnPageNumber = _g[1];
    var _h = useState({
        assistance: [],
        activity: []
    }), isPageValid = _h[0], setIsPageValid = _h[1];
    var dispatch = useDispatch();
    var params = useParams();
    var LG = (detail === null || detail === void 0 ? void 0 : detail.language) == 'english' ? 'en' : 'fr';
    var searchTitleAndName = useQueryClients().searchTitleAndName;
    var navigate = useNavigate();
    var _j = useState(false), ifRenderSaveAndQuitPage = _j[0], SetIfRenderSaveAndQuitPage = _j[1];
    var fetchDetail = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, url;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, searchTitleAndName.mutateAsync({
                        hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                        language: LG
                    })];
                case 1:
                    response = _c.sent();
                    if (response === null || response === void 0 ? void 0 : response.data.isCompletedQuestionnaire) {
                        url = Functions.removeParams(env.routes.alreadyAnsweredPage);
                        navigate("".concat(url).concat((_b = params.hash) !== null && _b !== void 0 ? _b : ''));
                    }
                    setDetail(response === null || response === void 0 ? void 0 : response.data);
                    if ((response === null || response === void 0 ? void 0 : response.data.questionnaireId) == anaQueId)
                        setIsGeneralDemo(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a;
        dispatch(fetchQuestionnaire({
            hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
        }));
        fetchDetail();
    }, []);
    var getCurrSection = function () {
        var _a;
        var index = questionnaireStore.currentSection;
        return (_a = questionnaireStore.sections[index]) !== null && _a !== void 0 ? _a : undefined;
    };
    var section = getCurrSection();
    var getPageTitle = function () {
        if (getCurrSection() == null)
            return;
        if (questionnaireStore.saved || questionnaireStore.savedAndQuit) {
            return detail === null || detail === void 0 ? void 0 : detail.questionnaireTitle;
        }
        return lang[LG].answerQuestionnaire.titleS1
            .replace('{0}', getCurrSection().index.toString())
            .replace('{1}', getCurrSection().sectionName)
            .replace('{2}', getCurrSection().subSectionName);
    };
    var selectActivity = function (question, activity) {
        var questionId = question.questionId, subSectionId = question.subSectionId;
        setMadeChanges(true);
        dispatch(updateAnswerActivity({
            questionId: questionId,
            subSectionId: subSectionId,
            activity: activity,
            sectionId: section.sectionId
        }));
        dispatch(updateQuestionnaireResult());
    };
    var selectAssistance = function (question, assistance) {
        var questionId = question.questionId, subSectionId = question.subSectionId;
        setMadeChanges(true);
        dispatch(updateAnswerAssistance({
            questionId: questionId,
            subSectionId: subSectionId,
            assistance: assistance,
            sectionId: section.sectionId
        }));
        dispatch(updateQuestionnaireResult());
    };
    var getActivityCheckbox = function (question, activity) {
        var _a;
        var answer = answers[question.questionId];
        if (answer != null && answer.activity === activity) {
            return React.createElement("div", { className: "" },
                React.createElement(TickIcon, { onClick: function () { } }));
        }
        else {
            var attributes = {};
            var classes = '';
            if (isPageValid.activity.indexOf(question.questionId) !== -1) {
                attributes.stroke = env.iconRed;
                classes = 'bg-c-pink';
            }
            if (((_a = attributes.stroke) === null || _a === void 0 ? void 0 : _a.length) === undefined) {
                attributes.stroke = "#6C0C6C";
                // @ts-ignore
                attributes.fill = "#0000";
            }
            return (React.createElement("div", __assign({ className: "w-full h-full ".concat(classes) }, attributes, { onClick: function () {
                    selectActivity(question, activity);
                } })));
        }
    };
    var getAssistanceCheckbox = function (question, assistance) {
        var _a;
        var answer = answers[question.questionId];
        if (answer != null && answer.assistance === assistance) {
            return React.createElement("div", { className: "" },
                React.createElement(TickIcon, { onClick: function () { } }));
        }
        else {
            var attributes = {};
            var classes = '';
            if (isPageValid.assistance.indexOf(question.questionId) !== -1) {
                attributes.stroke = env.iconRed;
                classes = 'bg-c-pink';
            }
            if (((_a = attributes.stroke) === null || _a === void 0 ? void 0 : _a.length) === undefined) {
                attributes.stroke = "#6C0C6C";
                // @ts-ignore
                attributes.fill = "#0000";
            }
            return (React.createElement("div", __assign({ className: "w-full h-full ".concat(classes) }, attributes, { onClick: function () {
                    selectAssistance(question, assistance);
                } })));
        }
    };
    var getActivityClass = function (question, activity) {
        var _a;
        var answer = answers[question.questionId];
        if (answer != null && answer.activity === activity) {
            return 'bg-c-purple';
        }
        else {
            var attributes = {};
            if (isPageValid.activity.indexOf(question.questionId) !== -1) {
                attributes.stroke = env.iconRed;
            }
            if (((_a = attributes.stroke) === null || _a === void 0 ? void 0 : _a.length) === undefined) {
                attributes.stroke = "#6C0C6C";
                // @ts-ignore
                attributes.fill = "#0000";
            }
            return '';
        }
    };
    var getAssistanceClass = function (question, assistance) {
        var _a;
        var answer = answers[question.questionId];
        if (answer != null && answer.assistance === assistance) {
            return 'bg-c-purple';
        }
        else {
            var attributes = {};
            if (isPageValid.assistance.indexOf(question.questionId) !== -1) {
                attributes.stroke = env.iconRed;
            }
            if (((_a = attributes.stroke) === null || _a === void 0 ? void 0 : _a.length) === undefined) {
                attributes.stroke = "#6C0C6C";
                // @ts-ignore
                attributes.fill = "#0000";
            }
            return '';
        }
    };
    var getLoopQuestions = function () {
        var questions = [];
        if (section != null) {
            var start = (section.currentPage - 1) * 5;
            var end = 5 * section.currentPage;
            questions = section.questions.slice(start, end);
        }
        return questions;
    };
    var questions = getLoopQuestions();
    var markSelectAllActivity = function (activity) {
        questions.forEach(function (question) {
            var questionId = question.questionId, subSectionId = question.subSectionId;
            setMadeChanges(true);
            dispatch(updateAnswerActivity({
                questionId: questionId,
                subSectionId: subSectionId,
                activity: activity,
                sectionId: section.sectionId
            }));
            dispatch(updateQuestionnaireResult());
        });
    };
    var markSelectAllAssistance = function (assistance) {
        questions.forEach(function (question) {
            var questionId = question.questionId, subSectionId = question.subSectionId;
            setMadeChanges(true);
            dispatch(updateAnswerAssistance({
                questionId: questionId,
                subSectionId: subSectionId,
                assistance: assistance,
                sectionId: section.sectionId
            }));
            dispatch(updateQuestionnaireResult());
        });
    };
    var checkIfPageValid = function () {
        var valid = __assign({}, isPageValid);
        valid.activity = [];
        valid.assistance = [];
        questions.forEach(function (question) {
            var _a, _b, _c, _d;
            var answer = answers[question.questionId];
            if (answer == null) {
                (_a = valid.activity) === null || _a === void 0 ? void 0 : _a.push(question.questionId);
                (_b = valid.assistance) === null || _b === void 0 ? void 0 : _b.push(question.questionId);
            }
            else if (answer.activity === EQuestionnaireActivity.na) {
                //    do nothing
            }
            else {
                if (answer.activity === EQuestionnaireActivity.n || answer.activity == null) {
                    (_c = valid.activity) === null || _c === void 0 ? void 0 : _c.push(question.questionId);
                }
                if (answer.assistance === EQuestionnaireAssistance.n || answer.assistance == null) {
                    (_d = valid.assistance) === null || _d === void 0 ? void 0 : _d.push(question.questionId);
                }
            }
        });
        setIsPageValid(valid);
        return valid.activity.length === 0 && valid.assistance.length === 0;
    };
    var clearPageValidationErrors = function () {
        setIsPageValid({
            activity: [],
            assistance: []
        });
    };
    useEffect(function () {
        updateAdditionalInformationInputBoxValue();
    }, [section === null || section === void 0 ? void 0 : section.index, section === null || section === void 0 ? void 0 : section.currentPage]);
    var additionalInformationName = "SEC-PAG";
    var updateAdditionalInformationInputBoxValue = function () {
        var _a, _b;
        resetField(additionalInformationName);
        var additionalInformation = questionnaireStore.additionalInformation;
        if (!section)
            return;
        var index = section.index, currentPage = section.currentPage;
        var sectionValue = additionalInformation[index];
        if (sectionValue) {
            var pageValue = (_b = (_a = sectionValue[currentPage]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
            setValue(additionalInformationName, pageValue);
        }
    };
    var beforeUnloadHandler = function (e) {
        e.preventDefault();
        if (madeChanges === true &&
            questionnaireStore.saved === false &&
            questionnaireStore.savedAndQuit === false) {
            e.returnValue = '';
        }
    };
    useEffect(function () {
        window.addEventListener('beforeunload', beforeUnloadHandler);
        return function () { return window.removeEventListener('beforeunload', beforeUnloadHandler); };
    }, [questionnaireStore.saved, questionnaireStore.savedAndQuit, madeChanges]);
    var gridBorderClsActivity = "".concat(isPageValid.activity.length > 0 ? 'border-c-grey' : 'border-c-grey');
    var gridBorderClsAssistance = "".concat(isPageValid.assistance.length > 0 ? 'border-c-grey' : 'border-c-grey');
    var isLastSection = function () {
        return (section === null || section === void 0 ? void 0 : section.currentPage) === (section === null || section === void 0 ? void 0 : section.noOfPages)
            && (section === null || section === void 0 ? void 0 : section.index) === (questionnaireStore === null || questionnaireStore === void 0 ? void 0 : questionnaireStore.totalSections);
    };
    var pixelAccLang = LG == "en" ? 'px-3' : '';
    var getQuestionnaireDetail = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mt-10 min-h-[640px]" },
                React.createElement("div", { className: "flex w-full" },
                    React.createElement("div", { className: "flex w-[33%] items-center " }),
                    React.createElement("div", { className: "flex w-[77%]" },
                        React.createElement(BaseTitle, { additionalClass: "text-sm ml-[10%]" }, lang[LG].answerQuestionnaire.titleOfbox1),
                        React.createElement(BaseTitle, { additionalClass: "text-sm ml-[17%]" }, lang[LG].answerQuestionnaire.titleOfbox2))),
                React.createElement("div", { className: "flex w-full" },
                    React.createElement("div", { className: "flex w-[33%] items-center" },
                        React.createElement(Text, { isbold: false, fontSize: "text-sm", fontWeight: "normal", additionalClass: "text-c-gray" }, lang[LG].answerQuestionnaire.answerInfgo),
                        " "),
                    React.createElement("div", { className: "flex w-[77%] justify-center border-y-black" },
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-r-c-purple py-4 hover:underline ", textColor: 'text-c-purple', onHover: function () { setActive(1); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.na); } }, lang[LG].answerQuestionnaire.selectedOptions[1]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple border-l-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(2); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.never); } }, lang[LG].answerQuestionnaire.selectedOptions[2]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(3); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.rarely); } }, lang[LG].answerQuestionnaire.selectedOptions[3]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(4); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.sometimes); } }, lang[LG].answerQuestionnaire.selectedOptions[4]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(5); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.often); } }, lang[LG].answerQuestionnaire.selectedOptions[5]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple border-r-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(6); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllActivity(EQuestionnaireActivity.always); } }, lang[LG].answerQuestionnaire.selectedOptions[6]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-x border-t border-t-c-purple border-l-c-purple py-4 hover:underline ml-2", textColor: 'text-c-purple', onHover: function () { setActive(7); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllAssistance(EQuestionnaireAssistance.none); } }, lang[LG].answerQuestionnaire.selectedOptions[7]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(8); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllAssistance(EQuestionnaireAssistance.low); } }, lang[LG].answerQuestionnaire.selectedOptions[8]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(9); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllAssistance(EQuestionnaireAssistance.moderate); } }, lang[LG].answerQuestionnaire.selectedOptions[9]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(10); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllAssistance(EQuestionnaireAssistance.significant); } }, lang[LG].answerQuestionnaire.selectedOptions[10]),
                        React.createElement(Text1FromDesign, { additionalClass: "w-[10%] flex justify-center items-center border-r border-t border-t-c-purple border-r-c-purple py-4 hover:underline", textColor: 'text-c-purple', onHover: function () { setActive(11); }, onHoverLeave: function () { setActive(0); }, onClick: function () { markSelectAllAssistance(EQuestionnaireAssistance.extreme); } }, lang[LG].answerQuestionnaire.selectedOptions[11]))),
                questions.map(function (question, index) {
                    var answer = answers[question.questionId];
                    return (React.createElement("div", { key: index, className: "flex w-full ".concat(QuestionlistborderTopClass, " min-h-28") },
                        React.createElement("div", { className: "flex w-[33%]  border-r  items-center" },
                            React.createElement(Text, { additionalClass: "p-7" }, question.description)),
                        React.createElement("div", { className: "flex w-[77%] justify-center border-y-black  ".concat(gridBorderClsActivity) },
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black border-r-c-purple hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.na), " ").concat((active == 1) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.na)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black  hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.never), " ").concat((active == 2) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.never)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.rarely), " ").concat((active == 3) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.rarely)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.sometimes), " ").concat((active == 4) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.sometimes)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.often), " ").concat((active == 5) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.often)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black border-r-c-purple hover:bg-c-light-rose ".concat(gridBorderClsActivity, " ").concat(getActivityClass(question, EQuestionnaireActivity.always), " ").concat((active == 6) ? 'bg-c-light-rose' : '') }, getActivityCheckbox(question, EQuestionnaireActivity.always)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-x border-y-black border-l-c-purple ml-2 ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) != 'na' ? 'hover:bg-c-light-rose' : '', " ").concat(gridBorderClsActivity, " ").concat(getAssistanceClass(question, EQuestionnaireAssistance.none), " ").concat((active == 7) ? 'bg-c-light-rose' : '') }, getAssistanceCheckbox(question, EQuestionnaireAssistance.none)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) != 'na' ? 'hover:bg-c-light-rose' : '', " ").concat(gridBorderClsActivity, "  ").concat(getAssistanceClass(question, EQuestionnaireAssistance.low), " ").concat((active == 8) ? 'bg-c-light-rose' : '') }, getAssistanceCheckbox(question, EQuestionnaireAssistance.low)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) != 'na' ? 'hover:bg-c-light-rose' : '', " ").concat(gridBorderClsActivity, "  ").concat(getAssistanceClass(question, EQuestionnaireAssistance.moderate), " ").concat((active == 9) ? 'bg-c-light-rose' : '') }, getAssistanceCheckbox(question, EQuestionnaireAssistance.moderate)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) != 'na' ? 'hover:bg-c-light-rose' : '', " ").concat(gridBorderClsActivity, "  ").concat(getAssistanceClass(question, EQuestionnaireAssistance.significant), " ").concat((active == 10) ? 'bg-c-light-rose' : '') }, getAssistanceCheckbox(question, EQuestionnaireAssistance.significant)),
                            React.createElement("div", { className: "w-[10%] flex justify-center items-center border-r border-y-black border-r-c-purple ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) != 'na' ? 'hover:bg-c-light-rose' : '', " ").concat(gridBorderClsActivity, "  ").concat(getAssistanceClass(question, EQuestionnaireAssistance.extreme), " ").concat((active == 11) ? 'bg-c-light-rose' : '') }, getAssistanceCheckbox(question, EQuestionnaireAssistance.extreme)))));
                }),
                React.createElement("div", { className: "grid grid-cols-3" },
                    React.createElement("div", { className: "border-t ".concat(QuestionlistborderTopClass) }),
                    React.createElement("div", { className: "border-t ".concat(QuestionlistborderTopClass) }),
                    React.createElement("div", { className: "border-t ".concat(QuestionlistborderTopClass) })),
                React.createElement("div", { className: "grid grid-cols-3" },
                    React.createElement("div", { className: "px-3" }),
                    React.createElement("div", { className: "px-3" }, isPageValid.activity.length !== 0 &&
                        React.createElement(TextError, { message: lang[LG].answerQuestionnaire.error.error1 })),
                    React.createElement("div", { className: "px-3" }, isPageValid.assistance.length !== 0 &&
                        React.createElement(TextError, { message: lang[LG].answerQuestionnaire.error.error2 })))),
            React.createElement("div", { className: "mt-5" },
                React.createElement(BaseTitle, { additionalClass: "text-sm" }, lang[LG].answerQuestionnaire.title2),
                React.createElement(TextArea, { register: register(additionalInformationName), additionalClass: "mt-2", title: lang[LG].answerQuestionnaire.defaultInput, onChange: function (event) {
                        var section = getCurrSection();
                        dispatch(updateSectionAdditional({
                            index: section.index,
                            value: event.target.value,
                            currentPage: section.currentPage,
                            sectionId: section.sectionId,
                        }));
                    } })),
            React.createElement("div", { className: "flex justify-between w-full mt-5" },
                React.createElement("div", null),
                React.createElement("button", { className: "flex text-c-purple mr-3", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            markSelectAllActivity(EQuestionnaireActivity.na);
                            setTimeout(function () {
                                var _a, _b;
                                if (isLastSection()) {
                                    dispatch(saveAnswer({
                                        hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                        saved: true,
                                        savedAndQuit: false,
                                        submit: true,
                                    }));
                                }
                                else if ((section === null || section === void 0 ? void 0 : section.currentPage) === (section === null || section === void 0 ? void 0 : section.noOfPages)) {
                                    dispatch(fetchQuestionnaire({
                                        hashedId: (_b = params.hash) !== null && _b !== void 0 ? _b : '',
                                        position: section.index,
                                        action: 'forward',
                                    }));
                                }
                                else {
                                    var page = section.currentPage + 1;
                                    dispatch(updateCurrentPage({
                                        currentPage: page,
                                        index: section.index,
                                    }));
                                }
                            }, 600);
                            return [2 /*return*/];
                        });
                    }); } },
                    React.createElement("a", { className: "font-poppins" }, lang[LG].answerQuestionnaire.skipStep),
                    React.createElement(Tooltip, { message: lang[LG].answerQuestionnaire.skipStepTooltip },
                        React.createElement(InfoIcon, { color: env.iconGrey })))),
            React.createElement("div", { className: "flex justify-between w-full mt-5 space-x-4" },
                React.createElement("div", null, ((section === null || section === void 0 ? void 0 : section.index) > 1 || (section === null || section === void 0 ? void 0 : section.currentPage) > 1)
                    &&
                        React.createElement(ArrowButton, { title: "", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var page, newIndex;
                                var _a;
                                return __generator(this, function (_b) {
                                    clearPageValidationErrors();
                                    if ((section === null || section === void 0 ? void 0 : section.currentPage) > 1 && section.noOfPages > 1) {
                                        page = section.currentPage - 1;
                                        dispatch(updateCurrentPage({
                                            currentPage: page,
                                            index: section.index,
                                        }));
                                    }
                                    else if ((section === null || section === void 0 ? void 0 : section.currentPage) === 1 && section.index > 0) {
                                        newIndex = section.index - 2;
                                        if (typeof questionnaireStore.sections[newIndex] !== "undefined") {
                                            dispatch(updateSectionIndex({
                                                currentSection: newIndex
                                            }));
                                        }
                                        else {
                                            dispatch(fetchQuestionnaire({
                                                hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                                position: newIndex,
                                                action: 'backward'
                                            }));
                                        }
                                    }
                                    return [2 /*return*/];
                                });
                            }); } })),
                React.createElement("div", { className: "flex space-x-4" },
                    React.createElement(LightSubmitButtonWithIcon, { type: "button", title: lang[LG].answerQuestionnaire.bt1, icon: React.createElement(SaveIcon, { color: env.iconRed }), RedTypeOrPurpleType: "red", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                SetIfRenderSaveAndQuitPage(true);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(LightSubmitButtonWithIcon, { type: "button", title: lang[LG].answerQuestionnaire.bt2, icon: React.createElement(HelpIcon, { color: env.iconColorPurple }), RedTypeOrPurpleType: "purple", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setReturnPageNumber(1);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(SubmitButtonWithIcon, { type: "button", icon: isLastSection() ? null : React.createElement(ForwardIcon, { color: env.iconColorWhite }), title: isLastSection() ? lang[LG].submit : lang[LG].answerQuestionnaire.bt3, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var page;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                if (!checkIfPageValid()) {
                                    return [2 /*return*/];
                                }
                                if (isLastSection()) {
                                    dispatch(saveAnswer({
                                        hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                        saved: true,
                                        savedAndQuit: false,
                                        submit: true,
                                    }));
                                }
                                else if ((section === null || section === void 0 ? void 0 : section.currentPage) === (section === null || section === void 0 ? void 0 : section.noOfPages)) {
                                    dispatch(fetchQuestionnaire({
                                        hashedId: (_b = params.hash) !== null && _b !== void 0 ? _b : '',
                                        position: section.index,
                                        action: 'forward',
                                    }));
                                }
                                else {
                                    page = section.currentPage + 1;
                                    dispatch(updateCurrentPage({
                                        currentPage: page,
                                        index: section.index,
                                    }));
                                }
                                return [2 /*return*/];
                            });
                        }); }, disabled: !isPageValid })))));
    };
    var getThankYouDetail = function () {
        var _a;
        var p2 = (detail === null || detail === void 0 ? void 0 : detail.isSelf) ? lang[LG].generateReportRecommendations : lang[LG].answerQuestionnaire.page3.p2;
        p2 = p2.replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : '');
        return (React.createElement("div", { className: "min-h-[30rem] flex flex-col justify-between" },
            React.createElement("div", null,
                React.createElement(BaseTitle, { additionalClass: "mt-10" }, lang[LG].answerQuestionnaire.page3.title2),
                React.createElement(Text1FromDesign, { additionalClass: "mt-5" }, lang[LG].answerQuestionnaire.page3.p1),
                React.createElement(Text1FromDesign, { additionalClass: "mt-5" }, questionnaireStore.saved ?
                    p2 :
                    lang[LG].answerQuestionnaire.page3.savedAndQuit)),
            React.createElement("div", { className: "flex justify-end" },
                React.createElement(LightSubmitButton, { type: "button", title: lang[LG].answerQuestionnaire.page3.button, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            navigate(env.routes.login);
                            return [2 /*return*/];
                        });
                    }); } }),
                (detail === null || detail === void 0 ? void 0 : detail.isSelf) != 0 && React.createElement(SubmitButtonWithIcon, { type: "button", additionalClass: 'ml-2', title: lang[LG].generateReport, onClick: generateReport }))));
    };
    var userguidePage = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description1 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description1, imageLink: (isGeneralDemo) ? "/images/Guide_1_FR.png" : "/images/anamn/Guide 1.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-4 pt-1" },
            React.createElement("div", { className: "flex-grow" }),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(2);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagetwo = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description2 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description2, imageLink: (isGeneralDemo) ? "/images/Guide_2_FR.png" : "/images/anamn/Guide 2.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(1);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(3);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagethree = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description3 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description3, imageLink: (isGeneralDemo) ? "/images/Guide_3_FR.png" : "/images/anamn/Guide 3.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(2);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(4);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagefour = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description4 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description4, imageLink: (isGeneralDemo) ? "/images/Guide_4_FR.png" : "/images/anamn/Guide 4.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(3);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(5);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagefive = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description5 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description5, imageLink: (isGeneralDemo) ? "/images/Guide_5_FR.png" : "/images/anamn/Guide 5.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(4);
                            return [2 /*return*/];
                        });
                    }); } })),
            isGeneralDemo &&
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: 'flex px-5 mt-2' },
                        React.createElement(LightBoldText, { additionalClass: "text-c-purple cursor-pointer font-poppins", onClick: function () {
                                setReturnPageNumber(0);
                                var url = Functions.removeParams(env.routes.contactInformation);
                                navigate("".concat(url).concat(params.hash));
                            } }, lang[LG].answerQuestionnaire.userGuidePageOne.contactbutton)),
                    React.createElement("div", { className: "flex" },
                        React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: "#FFFFFF" }), title: lang[LG].answerQuestionnaire.userGuidePageOne.returnbutton, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    setReturnPageNumber(0);
                                    return [2 /*return*/];
                                });
                            }); } }))),
            !isGeneralDemo && React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(6);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePagesix = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description6, imageLink: "/images/anamn/Guide 6.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(5);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(7);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePageseven = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: lang[LG].answerQuestionnaire.userNewGuidePageOne.description7, imageLink: "/images/anamn/Guide 7.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(6);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(ForwardIcon, { color: env.iconColor }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(8);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var userguidePageEight = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                setReturnPageNumber(0);
            } }),
        React.createElement(UserGuidePage, { clientName: "", questionnaireTitle: "", title: (isGeneralDemo) ? lang[LG].answerQuestionnaire.bt2 : lang[LG].guildPage.title, description: (isGeneralDemo) ? lang[LG].answerQuestionnaire.userGuidePageOne.description4 : lang[LG].answerQuestionnaire.userNewGuidePageOne.description8, imageLink: (isGeneralDemo) ? "/images/Guide_4_FR.png" : "/images/anamn/Guide 8.png", alt: "Titre de la Section", introDisable: true }),
        React.createElement("div", { className: "flex px-5 pt-1" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement(IconButton, { title: "", additionalClass: "h-10 w-10", icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(7);
                            return [2 /*return*/];
                        });
                    }); } })),
            React.createElement("div", { className: "flex" },
                React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: "#FFFFFF" }), title: lang[LG].answerQuestionnaire.userGuidePageOne.returnbutton, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setReturnPageNumber(0);
                            return [2 /*return*/];
                        });
                    }); } })))));
    var saveAndQuitPage = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                SetIfRenderSaveAndQuitPage(false);
            } }),
        React.createElement(Title, null, lang[LG].saveAndQuitPage.title),
        React.createElement("div", { className: "mt-4 text-left" },
            React.createElement(Text, null, lang[LG].saveAndQuitPage.content)),
        React.createElement("div", { className: "mt-4 text-left" },
            React.createElement(Text, { isbold: true }, lang[LG].saveAndQuitPage.content2.replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : ''))),
        React.createElement("div", { className: "w-full justify-center flex" }, questionnaireStore.error !== null && React.createElement(FormError, { response: questionnaireStore.error })),
        React.createElement("div", { className: "mt-4 inline-flex justify-end w-full" },
            React.createElement(LightSubmitButton, { additionalClass: "ml-4", title: lang[LG].saveAndQuitPage.btn, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        SetIfRenderSaveAndQuitPage(false);
                        return [2 /*return*/];
                    });
                }); } }),
            React.createElement(LightReverseColorButtonWithIcon, { additionalClass: "ml-4", type: "button", title: lang[LG].saveAndQuitPage.btn2, icon: React.createElement(SaveIcon, { color: "#ffffff" }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, dispatch(saveAnswer({
                                    hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                    saved: false,
                                    savedAndQuit: true,
                                    submit: false
                                }))];
                            case 1:
                                response = _b.sent();
                                response && navigate(env.routes.login);
                                return [2 /*return*/];
                        }
                    });
                }); } }))));
    var generateReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var id, url;
        var _a;
        return __generator(this, function (_b) {
            id = (_a = detail === null || detail === void 0 ? void 0 : detail.questionnaireToClientId.toString()) !== null && _a !== void 0 ? _a : '';
            if (user.isLoggedIn) {
                url = Functions.createUrl(env.routes.clientQuestionnaire, id.toString());
            }
            else {
                url = Functions.createUrl(env.routes.loginParam, "clientQuestionnaire-".concat(id));
            }
            navigate(url);
            return [2 /*return*/];
        });
    }); };
    var deletedPage = (React.createElement(ModalInAnswerQuestionnaire, null,
        React.createElement("div", null,
            React.createElement("div", { className: "container flex inline flex w-full" },
                React.createElement("div", { className: "container flex justify-start" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", null,
                            React.createElement(BaseTitle, { additionalClass: "text-base capitalize" }, detail === null || detail === void 0 ? void 0 : detail.questionnaireTitle)),
                        React.createElement("div", { className: "-mt-1" },
                            React.createElement(BaseTitle, { additionalClass: "text-sm capitalize" },
                                (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.introductionPage.for : lang["fr"].answerQuestionnaire.introductionPage.for,
                                " ", detail === null || detail === void 0 ? void 0 :
                                detail.clientName)))),
                React.createElement("div", { className: "container flex justify-end w-1/3" },
                    React.createElement("div", { className: "w-fit" },
                        React.createElement("div", { className: "flex justify-end" },
                            React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.page3.rightTop : lang["fr"].answerQuestionnaire.page3.rightTop)),
                        React.createElement("div", { className: "inline-flex justify-end my-2" },
                            React.createElement("div", { className: "h-2 w-66" },
                                React.createElement("div", { className: "w-full h-full bg-c-purple rounded-full" })))))),
            React.createElement("div", { className: "mt-12" },
                React.createElement(BaseTitle, { additionalClass: "text-base" }, lang[LG].answerQuestionnaire.deletedPage.title)),
            React.createElement("div", { className: "mt-7 w-2/3" },
                React.createElement(Text1FromDesign, null, lang[LG].answerQuestionnaire.deletedPage.text)),
            React.createElement("div", { className: "container inline flex pt-4 mt-72" },
                React.createElement("div", { className: "flex justify-end w-full" },
                    React.createElement("div", { className: "inline flex" },
                        React.createElement("div", { className: "ml-2" },
                            React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: "#FFFFFF" }), title: (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.page3.button : lang["fr"].answerQuestionnaire.page3.button, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        navigate(env.routes.login);
                                        return [2 /*return*/];
                                    });
                                }); } }))))))));
    var render = function () {
        var _a, _b, _c, _d, _e;
        var showSuccessPage = questionnaireStore.saved || questionnaireStore.savedAndQuit;
        var defaultPage = (React.createElement(ModalInAnswerQuestionnaire, { layoutClass: 'w-10/12 h-fit mt-16' },
            React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "w-8/12" },
                    React.createElement(BaseTitle, { additionalClass: "text-base" }, getPageTitle()),
                    React.createElement(BaseTitle, { additionalClass: "text-sm" }, lang[LG]
                        .answerQuestionnaire
                        .smallTitle
                        .replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : ''))),
                React.createElement("div", { className: "w-4/12 text-right" },
                    React.createElement(SectionBar, { currentSection: (_b = section === null || section === void 0 ? void 0 : section.index) !== null && _b !== void 0 ? _b : 1, currentPage: (_c = section === null || section === void 0 ? void 0 : section.currentPage) !== null && _c !== void 0 ? _c : 1, totalSections: (_d = questionnaireStore === null || questionnaireStore === void 0 ? void 0 : questionnaireStore.totalSections) !== null && _d !== void 0 ? _d : 1, totalPages: (_e = section === null || section === void 0 ? void 0 : section.noOfPages) !== null && _e !== void 0 ? _e : 1, sectionName: section === null || section === void 0 ? void 0 : section.sectionName, isCompleted: questionnaireStore.saved, savedAndQuit: questionnaireStore.savedAndQuit }))),
            !showSuccessPage && getQuestionnaireDetail(),
            showSuccessPage && getThankYouDetail()));
        var pageController = returnPageNumber == 1 ? userguidePage
            : returnPageNumber == 2 ? userguidePagetwo
                : returnPageNumber == 3 ? userguidePagethree
                    : returnPageNumber == 4 ? userguidePagefour
                        : returnPageNumber == 5 ? userguidePagefive
                            : returnPageNumber == 6 ? userguidePagesix
                                : returnPageNumber == 7 ? userguidePageseven
                                    : returnPageNumber == 8 ? userguidePageEight
                                        : defaultPage;
        return (React.createElement(React.Fragment, null, (detail === null || detail === void 0 ? void 0 : detail.isDeleted) ? deletedPage : (ifRenderSaveAndQuitPage ? saveAndQuitPage : (returnPageNumber ? pageController : defaultPage))));
    };
    return render();
};
export default DynamicAnswers;
