import React from "react";
import Input from "./Input";
var TextField = function (props) {
    var render = function () {
        var _a, _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat((_a = props.additionalClassCtn) !== null && _a !== void 0 ? _a : '') },
                React.createElement(Input, { register: props.register, value: (_b = props.value) !== null && _b !== void 0 ? _b : '', type: props.type, title: props.title, placeholder: (_c = props.placeholder) !== null && _c !== void 0 ? _c : props.title, autoComplete: 'off', disabled: props.disabled, error: props.error, errorMsg: props.errorMsg, additionalClass: props.additionalClass, onChange: props.onChange, onBlur: props.onBlur, disableOnChange: props.disableOnChange }))));
    };
    return render();
};
export default TextField;
