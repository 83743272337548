var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalInClientPage from "../../components/Layouts/ModalInClientPage";
import Title from "../../components/Title/Title";
import F from "../../utils/functions";
import { useQueryRespondent } from "../../hooks/respondent/useQueryRespondent";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import { lang } from "../../lang/lang";
import Text from "../../components/Field/Text";
import RespondentListing from "../../components/Respondent/RespondentListing";
import IconButton from "../../components/Button/IconButton";
import env from "../../config/env";
import BackwardIcon from "../../assets/icons/BackwardIcon";
import clsx from "clsx";
import SubmitButton from "../../components/Button/SubmitButton";
import ForwardIcon from "../../assets/icons/ForwardIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
var GenerateReport = function (props) {
    var qid = useParams().qid;
    var LG = F.getLang();
    var _a = useState(), respondents = _a[0], setRespondents = _a[1];
    var _b = useState(false), pending = _b[0], setPending = _b[1];
    var getRespondents = useQueryRespondent({
        QNToClientId: qid !== null && qid !== void 0 ? qid : ''
    }).getRespondents;
    var pageLoader = useFullPageLoader();
    var navigate = useNavigate();
    var onCancel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(env.routes.clientQuestionnaire, qid !== null && qid !== void 0 ? qid : '');
            navigate(url);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var _a, _b;
        if (getRespondents.status === "success") {
            setRespondents((_a = getRespondents.data) === null || _a === void 0 ? void 0 : _a.data);
            var hasPending_1 = false;
            (_b = getRespondents.data) === null || _b === void 0 ? void 0 : _b.data.respondents.forEach(function (respondent) {
                if (respondent.status !== 'completed')
                    hasPending_1 = true;
            });
            setPending(hasPending_1);
            pageLoader(false);
        }
        else {
            pageLoader(true);
        }
    }, [getRespondents.status, getRespondents.isFetching]);
    var render = function () {
        var _a;
        if (respondents == null)
            return React.createElement(React.Fragment, null);
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalInClientPage, { backgroundCls: props.backgroundCls },
                React.createElement(CloseIcon, { color: env.iconColor, additionalClass: "absolute m-2 right-0 top-0", onClick: onCancel }),
                React.createElement("div", { className: "flex mt-3" },
                    React.createElement(Title, { additionalClass: "mr-2" }, respondents === null || respondents === void 0 ? void 0 : respondents.clientName.name),
                    React.createElement(Title, { notBold: true, additionalClass: "mr-2 max-w-200" },
                        "- ", respondents === null || respondents === void 0 ? void 0 :
                        respondents.questionnaire,
                        "(",
                        F.getFormattedDate((_a = respondents === null || respondents === void 0 ? void 0 : respondents.created_at) !== null && _a !== void 0 ? _a : '', LG),
                        ")")),
                pending && React.createElement("div", { className: "mt-5" },
                    React.createElement(Text, null, lang[LG].generateReportInfo)),
                React.createElement("div", { className: "mt-5 min-h-40" },
                    React.createElement(RespondentListing, { params: respondents })),
                React.createElement("div", { className: "flex justify-between mt-4" },
                    React.createElement(IconButton, { title: '', alias: lang[LG].cancel, icon: React.createElement(BackwardIcon, { color: env.iconColorPurple }), additionalClass: clsx({
                            "h-10 w-10": true,
                        }), onClick: onCancel }),
                    React.createElement(SubmitButton, { title: lang[LG].nextStep, alias: lang[LG].cancel, icon: React.createElement(ForwardIcon, { color: env.iconColorWhite }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var url;
                            return __generator(this, function (_a) {
                                url = F.createUrl(env.routes.selectReportType, qid !== null && qid !== void 0 ? qid : '');
                                navigate(url);
                                return [2 /*return*/];
                            });
                        }); } })))));
    };
    return render();
};
export default GenerateReport;
