var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../classes/api";
import env from "../config/env";
import { EReducerRequestStatus } from "../enums/request";
var initialStateValue = {
    id: 0,
    status: 0,
    organization_id: 0,
    email: "",
    first_name: "",
    occuapation: "",
    phone_number: "",
    photo_url: "",
    stripe_id: "",
    surname: "",
    email_verified_at: null,
    updated_at: null,
    created_at: null,
    api_call_status: EReducerRequestStatus.NOT_INITIATED,
    token: "",
    message: "",
    isLoggedIn: false,
    credits: 0,
};
export var postLogin = createAsyncThunk("user/login", function (payload, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Api.post(payload, "".concat(env.api.login))];
                case 1:
                    response = _b.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        return [2 /*return*/, response];
                    }
                    else {
                        return [2 /*return*/, rejectWithValue(response)];
                    }
                    return [2 /*return*/];
            }
        });
    });
});
export var userSlice = createSlice({
    name: "user",
    initialState: { value: initialStateValue },
    reducers: {
        logout: function (state) {
            state.value = initialStateValue;
        },
        updateUserKeys: function (state, action) {
            state.value = __assign(__assign({}, state.value), action.payload);
        },
        postLoginToken: function (state, action) {
            var _a;
            state.value = __assign(__assign(__assign(__assign(__assign({}, action.payload.data.user), { api_call_status: EReducerRequestStatus.FULFILLED }), { token: (_a = action.payload.data.token) !== null && _a !== void 0 ? _a : state.value.token }), { message: action.payload.data.message }), { isLoggedIn: action.payload.status === 200 ? true : false });
        }
    },
    extraReducers: function (builder) {
        builder.addCase(postLogin.pending, function (state, action) {
            state.value.api_call_status = EReducerRequestStatus.PENDING;
        });
        builder.addCase(postLogin.fulfilled, function (state, action) {
            if (action.payload) {
                state.value = __assign(__assign(__assign(__assign(__assign({}, action.payload.data.user), { api_call_status: EReducerRequestStatus.FULFILLED }), { token: action.payload.data.token }), { message: action.payload.data.message }), { isLoggedIn: true });
            }
        });
        builder.addCase(postLogin.rejected, function (state, action) {
            state.value = __assign(__assign({}, state.value), { api_call_status: EReducerRequestStatus.REJECTED, message: "Failed to load.", errors: action.payload, isLoggedIn: false });
        });
    },
});
export var logout = (_a = userSlice.actions, _a.logout), postLoginToken = _a.postLoginToken, updateUserKeys = _a.updateUserKeys;
export default userSlice.reducer;
