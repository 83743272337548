import React, { useCallback } from 'react';
import Sort123Asc from '../../assets/icons/Sort123Asc';
import Sort123Desc from '../../assets/icons/Sort123Desc';
import SortAZAsc from '../../assets/icons/SortAZAsc';
import SortAZDesc from '../../assets/icons/SortAZDesc';
import SortAzDisabled from "../../assets/icons/SortAzDisabled";
import Sort123Disabled from "../../assets/icons/Sort123Disabled";
var Icon = function (props) {
    var getAZIcon = useCallback(function () {
        var _a, _b, _c;
        var element;
        switch (props.detail.sort) {
            case 'asc':
                element = React.createElement(SortAZAsc, { color: '', height: (_a = props.height) !== null && _a !== void 0 ? _a : 15 });
                break;
            case 'desc':
                element = React.createElement(SortAZDesc, { color: '', height: (_b = props.height) !== null && _b !== void 0 ? _b : 15 });
                break;
            default:
                element = React.createElement(SortAzDisabled, { color: '', height: (_c = props.height) !== null && _c !== void 0 ? _c : 15 });
                break;
        }
        return element;
    }, [props]);
    var get123Icon = useCallback(function () {
        var element;
        switch (props.detail.sort) {
            case 'asc':
                element = React.createElement(Sort123Asc, { color: '' });
                break;
            case 'desc':
                element = React.createElement(Sort123Desc, { color: '' });
                break;
            default:
                element = React.createElement(Sort123Disabled, null);
                break;
        }
        return element;
    }, [props]);
    var render = function () {
        if (props.type === 'alpha')
            return getAZIcon();
        else
            return get123Icon();
    };
    return render();
};
export default Icon;
