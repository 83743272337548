export var helpCenterFR = {
    title1: 'Questions d\'ordre générale',
    title11: 'Quelle est la mission d\'edpro?',
    description11: '<p>Edpro se distingue en tant que plateforme offrant une gamme variée d’outils de collecte de données, mettant à disposition une multitude de questionnaires et d’inventaires. L’objectif principal d’edpro réside dans son engagement à aider à identifier à la fois les atouts et les défis d’un individu ciblé, tout en facilitant le processus décisionnel relatif à son dossier.</p><br />' +
        '<br />' +
        '<p>La mission d’edpro se concentre sur la promotion de la collaboration efficace en simplifiant grandement l’utilisation des questionnaires et des inventaires grâce à une formule entièrement en ligne. L’invitation des collaborateurs à participer à la collecte de données se fait en seulement quelques clics, offrant une expérience sans tracas. Une fois les données collectées, le système les analyse méticuleusement pour générer un rapport personnalisé répondant à vos besoins spécifiques. Edpro est votre allié pour une vie simplifiée et plus productive.</p>',
    title12: 'Quelles sont les mesures mises en place pour assurer la sécurité des données confidentielles?',
    description12: '<p>Tous les produits d\'eduplan solutions sont construits avec des fonctionnalités de sécurité robustes, assurant une protection continue de vos informations. Nous utilisons l\'authentification et l\'autorisation pour vérifier l\'identité de chaque utilisateur. Toutes les données sensibles sont encryptées et stockées de manière sécurisée sur nos serveurs situés au Québec, garantissant ainsi la confidentialité de vos informations. Pendant que nous maintenons nos services, nous surveillons en permanence les menaces potentielles pour la sécurité, les détectant et les bloquant automatiquement avant qu\'elles ne puissent vous affecter. Si nous identifions une activité à risque dont nous pensons que vous devriez être informé, nous vous notifierons et vous guiderons à travers les étapes pour renforcer davantage votre protection.</p>',
    title13: 'Où sont stockées les données d\'edpro?',
    description13: '<p>Les données sont conservées dans un serveur de la compagnie Amazon Web Service (AWS) situé à Montréal. </p>',
    title14: 'Comment fonctionne la tarification sur edpro?',
    description14: '<p>Tous les outils peuvent être achetés à l\'unité. Un rabais de volume est offert à l\'achat de plus de 5 exemplaires d\'un même questionnaire. Chaque exemplaire de questionnaire devra être assigné à un dossier de client. Il est possible de générer plusieurs rapports différents avec ce même exemplaire pour un client. Cette méthode de facturation a pour but de garantir l\'intégrité de la propriété intellectuelle des produits offerts. <p><br />' +
        '<br />' +
        '<p>Vos informations de paiement sont demandées lors de la création du compte pour faciliter les futures transaction. La création du compte est gratuite.</p>',
    title15: 'Quels sont les outils d\'évaluations disponibles sur Edpro?',
    description15: '<p>Edpro offre un éventail d\'outils d\'évaluations qui prennent la forme de questionnaires et d\'inventaires. Ces outils traitent de différents sujets relatifs au comportement, aux matières scolaires, au développement, etc. Actuellement, des outils portant sur les thèmes suivants sont disponibles : </p><br/>' +
        '<br/>' +
        '<p>- Communication orale</p><br/>' +
        '<p>- Comportements adaptatifs relatifs à l\'autonomie fonctionnelle et l\'adaptation sociale</p><br/>' +
        '<p>- Écriture</p><br/>' +
        '<p>- Lecture</p><br/>' +
        '<p>- Mathématiques</p><br/>' +
        '<p>- Socio-émotionnel</p><br/>' +
        '<p>- Stratégies d\'apprentissage</p><br/>' +
        '<p>- Transition (préscolaire, scolaire, secondaire, post-secondaire...)</p><br/>' +
        '<p>- Questionnaires anamnésiques</p><br/>' +
        '<br/>' +
        '<p>Vous pouvez avoir accès à notre répertoire complet d\'outils d\'évaluation sur la page d\'accueil d\'edpro.</p>',
    title16: 'D\'où proviennent les outils d\'évaluation d\'edpro?',
    description16: '<p>Les outils d’évaluation proposés par Edpro sont développés par notre équipe pédagogique expérimentée, composée de professionnels qualifiés et compétents dans le domaine de l’évaluation. Notre équipe se compose de Nathalie Myara, Ph.D., Viorica Dobrica Tudor, Ph.D., Josianne Veilleux, MA, et Leynna Feigenbaum, BA. Ces expertes s’emploient à concevoir des outils d’évaluation de haute qualité qui sont rigoureusement étudiés, testés et adaptés pour répondre aux besoins de nos utilisateurs, tout en garantissant la fiabilité et la validité des résultats. Ainsi, nos outils d’évaluation sont le fruit d’une expertise solide et d’un engagement continu envers l’excellence dans le domaine de l’évaluation.</p><br />' +
        '<br />' +
        '<p>Il est possible de soumettre une demande pour un outil ayant une clientèle ou une thématique précise. Si vous faites partie d\'une organisation qui souhaite obtenir un outil personnalisé, <a href="https://www.eduplan.ca/contactus"> contactez-nous</a>. Il nous fera plaisir d\'en apprendre davantage sur vos projets et de collaborer avec vous!</p>',
    title17: 'À quel type de clientèle s\'adresse les outils d\'évaluation disponibles sur edpro?',
    description17: '<p>Les outils d\'évaluation disponibles actuellement s\'adressent à des clientèles âgées de plus de 4 ans. </p><br />' +
        '<br />' +
        '<p>Il est possible de soumettre une demande pour un outil ayant une clientèle ou une thématique précise. Si vous faites partie d\'une organisation qui souhaite obtenir un outil personnalisé, <a href="https://www.eduplan.ca/contactus"> contactez-nous</a>. Il nous fera plaisir d\'en apprendre davantage sur vos projets et de collaborer avec vous!</p>',
    title18: 'Qui peut participer lors de la collecte des données?',
    description18: '<p>Vous avez la liberté d\'inviter n\'importe qui à collaborer, sans frais supplémentaires. Il peut être particulièrement bénéfique d\'encourager la participation des parents ou tuteurs de l\'apprenant, ainsi que de solliciter l\'expertise d\'autres professionnels qui interagissent ou travaillent avec lui, en fonction du type d\'évaluation que vous envisagez d\'entreprendre.</p>',
    title110: 'Y a-t-il une limite au nombre de collaborateurs qui peuvent être invités?',
    description110: '<p>Il n\'y a pas de limite au nombre de collaborateurs invités à participer au questionnaire. Cependant, il est recommandé de bien choisir ses collaborateurs. Priorisez la qualité plutôt que la quantité; optez pour des collaborateurs ayant une bonne connaissance de la situation du client ou de l\'apprenant.</p>',
    title111: 'Comment seront utilisé les données récoltés?',
    description111: '<p>Les données sont collectées parmi tous les collaborateurs répondants, puis elles sont acheminées vers le professionnel chargé de la création du rapport. Celui-ci pourra analyser l\'information recueillie dans un rapport personnalisé et créer un diagnostic à partir de celui-ci.</p>',
    title112: 'Que contiennent les rapports générés par edpro?',
    description112: '<p>Les rapports contiennent les sections suivantes : Une introduction présentant l’outil d’évaluation et son objectif, une description de chaque section de l’outil d’évaluation, les résultats de l’évaluation et une analyse de ces derniers accompagnés des commentaires de chacun des répondants, puis une liste de recommandations personnalisées en lien avec les résultats.</p><br/>' +
        '<br />' +
        '<p>Il est également possible de sélectionner les graphiques ou tableaux à ajouter en annexe du document. Les options suivantes sont possibles : un graphique à bandes qui montre la moyenne des réponses obtenues pour chaque catégories et sous-catégories de l’outil d’évaluation, un graphique à quadrants montrant les résultats obtenus pour chaque catégories et sous-catégories qui a pour but de mettre l\'accent sur les domaines à travailler, un tableau détaillé des données collectées qui présente les réponses de chaque répondants aux énoncé de l’outil d’évaluation et un tableau des pourcentages de chaque réponse collectée qui a pour but de visualiser le consensus entre les répondants.</p>',
    title113: 'Est-ce possible de créer son propre questionnaire et l\'ajouter sur edpro?',
    description113: '<p>Il est possible de soumettre une demande pour un outil ayant une clientèle ou une thématique précise. Si vous faites partie d\'une entreprise qui souhaite obtenir un outil personnalisé, <a href="https://www.eduplan.ca/contactus"> contactez-nous</a>. Il nous fera plaisir d\'en apprendre davantage sur vos projets et de collaborer avec vous!</p>',
    title2: 'Je suis un professionnel',
    title21: 'Comment créer un compte sur edpro?',
    description21: '<p>Pour créer un compte sur la plateforme edpro, vous devez simplement remplir le formulaire disponible sur la page d\'accueil du site web. Vous pourrez ensuite entrer vos informations de paiement si vous le souhaitez afin de faciliter les futures transactions. La création du compte est complètement gratuite.</p><br/>' +
        '<br />' +
        '<p>Une fois ces étapes complétées, un courriel vous sera envoyé afin de valider votre compte. Cliquez sur le lien présent dans le courriel et vous pourrez accéder à edpro. </p><br/>' +
        '<br />' +
        '<p>Pour des raisons de sécurité, vous pouvez entrer un numéro de téléphone associé à votre compte. Celui-ci sera utilisé en cas d\'oubli de votre adresse courriel lors de la connexion. Rendez vous dans vos paramètres afin d\'ajouter un numéro de téléphone. Ce numéro sera validé grâce à un code unique qui vous sera envoyé par message texte.</p>',
    title22: 'Comment récupérer un compte lorsque j\'ai oublié mon courriel?',
    description22: 'Votre adresse courriel associée à votre compte edpro peut être récupérée seulement si un numéro de téléphone a été ajouté à votre compte. Si vous n\'avez pas de numéro de téléphone lié à votre compte, communiquez avec nous.</p><br/>' +
        '<br />' +
        '<p>Sur la page de connexion, cliquez sur "J\'ai oublié mon adresse courriel". Edpro vous demandera le numéro de téléphone associé au compte. Entrez le numéro et un code unique vous sera envoyé par message texte afin de confirmer votre identité. Vous pourrez alors voir l\'adresse courriel associée au compte, puis procéder à la connexion.</p><br/>' +
        '<br />' +
        '<p>Pour des raisons de sécurité, il est préférable d\'ajouter un numéro de téléphone à votre compte le plus rapidement possible. Rendez vous dans vos paramètres afin d\'ajouter un numéro de téléphone. Ce numéro sera validé grâce à un code unique qui vous sera envoyé par message texte.</p>',
    title23: 'Comment récupérer un compte lorsque j\'ai oublié mon mot de passe?',
    description23: '<p>Sur la page de connexion, cliquez sur "J\'ai oublié mon mot de passe". Vous vous ferez demander l\'adresse courriel associée au compte. Entrez l\'adresse et un lien vous sera envoyé par courriel. </p>',
    title24: 'Quel est le modèle d\'affaire d\'edpro?',
    description24: '<p>Tous les outils peuvent être achetés à l\'unité. Un rabais de volume est offert à l\'achat de plus de 5 exemplaires d\'un même questionnaire. Chaque exemplaire de questionnaire devra être assigné à un dossier de client. Il est possible de générer plusieurs rapports différents avec ce même exemplaire pour un client. Cette méthode de facturation a pour but de garantir l\'intégrité de la propriété intellectuelle des produits offerts. </p><br />' +
        '<br />' +
        '<p>Vos informations de paiement sont demandées lors de la création du compte pour faciliter les futures transaction. La création du compte est gratuite.</p>',
    title25: 'Comment ajouter un client à ma liste?',
    description25: '<p>Sur votre page d\'accueil, cliquez sur "+ Ajouter un client\'. Vous devrez remplir un court formulaire pour créer le dossier de votre nouveau client. Les champs suivants sont obligatoires; prénom, nom, pronom utilisé et date de naissance. Ces informations serviront à personnaliser le questionnaire et le rapport pour chaque client. Le numéro de dossier et l\'historique du client sont facultatifs, mais peuvent être pertinents pour la recherche de vos clients dans la liste ou la création de certains rapports.</p><br />',
    title26: 'Comment modifier un dossier de client existant?',
    description26: '<p>Pour modifier un dossier existant, vous devez d\'abord le repérer dans la liste de dossier, puis cliquer dessus pour ouvrir la fiche. Une fois la fiche ouverte, cliquez sur le champ à modifier. Une fois la modification complétée, cliquez sur le bouton pour sauvegarder les modifications. Cliquez sur le "X" pour annuler la modification.</p><br />',
    title27: 'Comment archiver ou supprimer un dossier existant?',
    description27: '<p>Pour archiver ou supprimer un dossier existant, vous devez d\'abord le repérer dans la liste de dossier, puis cliquer dessus pour ouvrir la fiche. Une fois la fiche ouverte, cliquez sur "Supprimer le dossier" ou "Archiver le dossier".</p><br />' +
        '<br />' +
        '<p>Les dossiers archivés seront conservés dans l\'onglet "Clients archivés" du tableau de bord. Il sera possible de télécharger les rapports des questionnaires et inventaires passés, mais vous ne pourrez pas lancer de nouveaux questionnaires pour ce client. Les dossiers supprimés disparaitront de manière définitive.</p><br />' +
        '<br />' +
        '<p>Attention! Il est requis par certains milieux de conserver les dossiers dans vos archives pour une période déterminée. Informez-vous des normes présentes dans votre milieu avant de supprimer définitivement un dossier.</p><br />',
    title28: 'Comment rechercher dans la banque d\'outils?',
    description28: '<p>La banque d\'outils comporte plusieurs filtres utiles pouvant faciliter votre recherche. Vous pouvez rechercher par mots clés, par tranche d\'âge ou par l\'intermédiaire de la barre de recherche. Il est possible de mettre certains questionnaires dans vos favoris en cliquant sur l\'icône de cœur. Pour voir votre répertoire de favoris, cliquez sur le filtre "Favoris".\n' +
        '<br />' +
        '<p>Pour en apprendre davantage sur un questionnaire, cliquez sur celui-ci. Il est possible de télécharger un guide détaillé qui vous donnera encore plus d\'information sur ce dernier.',
    title29: 'Comment acheter un questionnaire ou un inventaire?',
    description29: '<p>Repérez l\'outil souhaité dans le répertoire disponible sur edpro. Si vous souhaitez en apprendre davantage sur un questionnaire, cliquez sur celui-ci pour voir une fiche informative. Sélectionnez la quantité désirée, puis passez au paiement.</p><br />' +
        '<br />' +
        '<p>À l\'aide du menu déroulant, sélectionnez la carte de crédit à utiliser. Si vous souhaitez utiliser une carte qui n\'est pas déjà enregistrée sur votre compte, appuyez sur "Utiliser un autre mode de paiement" et un formulaire apparaitra. Vous pouvez enregistrer cette nouvelle carte dans vos modes de paiement existants. Entrez votre adresse de livraison ou cochez la case "L’adresse de livraison est la même que celle qui apparait sur la carte de crédit". L\'adresse de livraison sert à calculer les taxes dans votre région.</p><br />' +
        '<br />' +
        '<p>À la droite de l\'écran, vous pouvez entrer un code pour un coupon rabais. Celui-ci sera automatiquement déduis de la facture. Cliquez sur "Payez le montant" pour finaliser le processus. Si vous avez des crédits à votre compte, vous pouvez également sélectionner la quantité à utiliser à cet endroit.</p><br />' +
        '<br />' +
        '<p>Pour un processus plus rapide, cliquez sur "Acheter" à partir de la page d\'accueil. Vous serez rediriger sur la page de paiement où vous pourrez ajuster la quantité de copies au besoin.</p><br />',
    title210: 'Comment lancer un questionnaire?',
    description210: '<p>Tout d\'abord, repérez le questionnaire à lancer dans votre collection ou achetez-le à partir du répertoire. Cliquez sur le questionnaire en question, puis sur "Lancer le questionnaire".</p><br />' +
        '<br />' +
        '<p>Choisissez votre client dans la liste. Si vous n\'avez toujours pas créé de dossier pour ce client, cliquez sur "+ Ajouter un client". Son dossier apparaitra dans la liste avec l\'indication "Nouveau client".</p><br />' +
        '<br />' +
        '<p>Ensuite, invitez vos collaborateurs en cliquant sur "+ Ajouter un collaborateur". Selon le contexte, il peut s\'agir du client, d\'un parent, tuteur, enseignant ou autre professionnel. Les collaborateurs recevront une invitation par courriel accompagnée d\'un message que vous pouvez modifier.</p><br />' +
        '<br />' +
        '<p>Une fois l\'invitation envoyée, vous pouvez vous-même remplir le questionnaire. Pour remplir un questionnaire plus tard, cliquez sur le dossier du client, puis sur le questionnaire. Vous serez invité à y répondre. Vous pouvez aussi passez par le lien unique envoyé par courriel pour y répondre.</p>',
    title211: 'Comment collaborer avec un autre professionnel, un parent, un tuteur ou un client?',
    description211: '<p>En tant que professionnel, vous pouvez collaborer avec d\'autres professionnels, des parents, des tuteurs et des clients en les invitant à répondre à un questionnaire. </p><br />' +
        '<br />' +
        '<p>Choisissez votre client dans la liste. Si vous n\'avez toujours pas créer de dossier pour ce client, cliquez sur "+ Ajouter un client". Son dossier apparaitra dans la liste avec l\'indication "Nouveau client".</p><br />' +
        '<br />' +
        '<p>Ensuite, invitez vos collaborateurs en cliquant sur "+ Ajouter un collaborateur". Selon le contexte, il peut s\'agir du client, d\'un parent, tuteur, enseignant ou autre professionnel. Les collaborateurs recevront une invitation par courriel accompagnée d\'un message personnalisé. Il est possible de modifier le message.</p>',
    title212: 'Combien de temps dure l\'administration du questionnaire?',
    description212: 'La durée de l\'évaluation dépend de chaque outil et de la personne qui l\'administre. De façon générale, l\'administration peut durer entre 20 minutes et une heure en moyenne. Des versions plus courtes de certains outils d\'évaluation sont également disponibles.',
    title213: 'Comment répondre à un questionnaire?',
    description213: '<p>Pour la majorité des outils, le questionnaire suivant sera divisé en plusieurs sections. Dans chacune de ces sections des énoncés vous seront présentés. Pour chaque énoncé, cochez la case appropriée qui correspond au niveau de réalisation de l’action. Cochez ensuite la case qui correspond au niveau d’assistance requis pour effectuer l’action. Répondez au meilleur de vos capacités.</p><br />' +
        '<br />' +
        '<p>Il vous sera possible d’ajouter des commentaires supplémentaires en bas de page en lien avec chaque section. Ces commentaires apparaitront dans le rapport.</p><br />' +
        '<br />' +
        '<p>Dans le doute, utilisez le guide d’utilisation en bas de page comme aide-mémoire sur le bon déroulement du questionnaire. Des instructions vous serons présentées en haut de page lorsque le questionnaire varie.</p>',
    title214: 'Est-ce possible de quitter un questionnaire et y revenir plus tard?',
    description214: '<p>Oui. Il est possible de quitter le formulaire et de le reprendre plus tard. Pour se faire, vous n\'avez qu\'à cliquer sur "Sauvegarder et quitter". Il est très important de terminer les questionnaires auxquels vous êtes invités afin d\'assurer le meilleur rapport possible.</p>',
    title215: 'Comment générer un rapport?',
    description215: '<p>Pour générer un rapport, cliquez sur votre client, puis sur le questionnaire souhaité. Vous pourrez envoyer des rappel aux collaborateurs si ceux-ci n\'ont toujours pas répondu au questionnaire. C\'est aussi à partir de cette page que vous pourrez inviter de nouveaux collaborateurs au besoin. Cliquez sur "Prochaine étape".</p><br />' +
        '<br />' +
        '<p>Sélectionnez les répondants qui affecteront le rapport et la langue du rapport (certains outils sont seulement disponibles en français pour l\'instant). Cliquez ensuite sur "Générer un rapport" pour obtenir votre rapport sous format PDF.</p>',
    title216: 'Est-ce que les rapports peuvent être utilisés tels quels?',
    description2126: '<p>Les rapports sont interprétatifs et à l’intention unique de professionnels qualifiés. Ils doivent être utilisés pour déterminer les besoins de l’apprenant et planifier l’intervention. Les résultats d’edpro peuvent être combinés avec des informations recueillies à partir d’autres instruments d’évaluation, mesures, entretiens, observations et informations disponibles. Les résultats sont basés sur des algorithmes qui fournissent des interprétations communes à partir des moyennes arithmétiques ou des moyennes harmoniques obtenues. Ce rapport est destiné à être utilisé par un professionnel qualifié. Généralement, le rapport doit être interprété par un professionnel afin de garantir sa qualité.</p>',
    title3: 'Je suis un parent, un client ou un apprenant.',
    title31: 'Comment collaborer avec un professionnel?',
    description31: '<p>En tant que parent, tuteur ou apprenant, communiquez avec votre professionnel afin de leur proposer l\'usage des outils d\'edpro. Les rapports générés par edpro sont destinés aux professionnels et nécessitent leur interprétation.</p>',
    title32: 'Combien de temps dure l\'évaluation avec un questionnaire?',
    description32: '<p>La durée de l\'évaluation dépend de chaque outils et de la personne qui l\'administre. De façon générale, l\'administration peut durer entre 20 minutes et une heure en moyenne.</p>',
    title33: 'Comment répondre à un questionnaire?',
    description33: '<p>Pour la majorité des outils, le questionnaire est divisé en plusieurs sections. Dans chacune de ces sections des énoncés vous seront présentés. Pour chaque énoncé, cochez la case appropriée qui correspond au niveau de réalisation de l’action. Cochez ensuite la case qui correspond au niveau d’assistance requis pour effectuer l’action. Répondez au meilleur de vos capacités.</p><br />' +
        '<br />' +
        '<p>Il vous sera possible d’ajouter des commentaires supplémentaires en bas de page en lien avec chaque section. Ces commentaires apparaitront dans le rapport.</p><br>' +
        '<br />' +
        '<p>Dans le doute, utilisez le guide d’utilisation en bas de page comme aide-mémoire sur le bon déroulement du questionnaire.</p>',
    title34: 'Est-ce possible de quitter un questionnaire et y revenir plus tard?',
    description34: '<p>Oui. Il est possible de quitter le questionnaire et de le reprendre plus tard. Pour se faire, vous n\'avez qu\'à cliquer sur "Sauvegarder et quitter". En cliquant à nouveau sur le lien dans le courriel, vous serez acheminé là où vous avez arrêté la dernière fois.</p>',
    title35: 'Comment seront utilisé mes données ou les données de mon enfant?',
    description35: '<p>Les données sont collectées puis acheminées vers le professionnel chargé de la création du rapport. Celui-ci pourra analyser l\'information et créer un diagnostic personnalisé. Les données sont confidentielles et réservées à l\'usager du professionnel. </p>',
};
export var helpCenterEn = {
    title1: 'General Questions',
    title11: 'What is edpro\'s mission?',
    description11: '<p>Edpro stands out as a platform offering a diverse range of data collection tools, providing a wide array of questionnaires and inventories. Its primary objective is to assist in identifying both the strengths and challenges of a targeted individual while streamlining the decision-making process regarding their case.</p><br />' +
        '<br />' +
        '<p>Edpro\'s mission focuses on promoting efficient collaboration by greatly simplifying the use of questionnaires and inventories through an entirely online formula. Inviting collaborators to participate in data collection is a matter of just a few clicks, offering a hassle-free experience. Once the data is collected, the system meticulously analyzes it to generate a customized report tailored to your specific needs. Edpro is your ally for a simplified and more productive life.</p>',
    title12: 'What measures are in place to ensure the security of confidential data?',
    description12: '<p>All Eduplan Solutions products are built with robust security features that continuously protect your information. We use authentication and authorization to verify each user\'s identity. All sensitive data is encrypted and securely stored on our servers located in Canada, ensuring the confidentiality of your information. As we maintain our services, we continuously monitor potential security threats, detecting and automatically blocking them before they can impact you. If we identify any risky activity that we believe you should be aware of, we will notify you and guide you through the steps to further enhance your protection.</p>',
    title13: 'Where is edpro data stocked?',
    description13: '<p>The data is stored on a server of the company Amazon Web Service (AWS) located in Montreal.</p>',
    title14: 'How does pricing work on edpro?',
    description14: '<p>All tools can be purchased individually. A volume discount is offered on the purchase of more than 5 copies of the same questionnaire. Each copy of the questionnaire must be assigned to a client file. It is possible to generate several different reports with the same copy for a customer. This billing method aims to guarantee the integrity of the intellectual property of the products offered.<p><br />' +
        '<br />' +
        '<p>Your payment information is requested during account creation to facilitate future transactions. Account creation is free.</p>',
    title15: 'What assessment tools are available on Edpro?',
    description15: '<p>Edpro offers a variety of assessment tools in the form of questionnaires and inventories. These tools cover a variety of topics related to behavior, academic subjects, development, etc. As of right now, evaluation tools for the following themes are available:</p><br/>' +
        '<br/>' +
        '<p>- Oral communication</p><br/>' +
        '<p>- Adaptive behaviors related to functional autonomy and social adaptation</p><br/>' +
        '<p>- Reading</p><br/>' +
        '<p>- Mathematics</p><br/>' +
        '<p>- Socio-emotional</p><br/>' +
        '<p>- Learning strategies</p><br/>' +
        '<p>- Transition (preschool, school, secondary, post-secondary...)</p><br/>' +
        '<p>- Writing</p><br/>' +
        '<p>- Anamnesic questionnaires</p><br/>' +
        '<br/>' +
        '<p>You can access our complete directory of assessment tools on the home page of edpro.</p>',
    title16: 'Where do edpro\'s evaluation tools come from?',
    description16: '<p>The assessment tools offered by Edpro are developed by our experienced educational team, consisting of qualified and skilled professionals in the field of assessment. Our team includes Nathalie Myara, Ph.D., Viorica Dobrica Tudor, Ph.D., Josianne Veilleux, MA, and Leynna Feigenbaum, BA. These experts are dedicated to designing high-quality assessment tools that undergo rigorous study, testing, and customization to meet the needs of our users, while ensuring the reliability and validity of the results. Thus, our assessment tools are the result of strong expertise and a continuous commitment to excellence in the field of assessment.</p><br />' +
        '<br />' +
        '<p>It is possible to submit a request for a tool tailored to a specific audience or theme. If you represent an organization interested in obtaining a customized tool, please<a href="https://www.eduplan.ca/contactus" class="text-c-purple">contact us</a></p>. We would be delighted to learn more about your projects and collaborate with you!</p>',
    title17: 'What type of clientele are the available questionnaires and inventories aimed at?',
    description17: '<p>The questionnaires and inventories currently available are aimed at young people aged 4 and up. </p><br />' +
        '<br />' +
        '<p>It is possible to submit a request for a tool with a specific clientele or theme. If you are part of an organization that would like to obtain a customized tool,<a href="https://www.eduplan.ca/contactus" class="text-c-purple">contact us</a></p>. We will be pleased to learn more about your projects and to collaborate with you!</p>',
    title18: 'Who can participate in data collection?',
    description18: '<p>You are free to invite anyone to collaborate at no additional cost. Encouraging the participation of the student\'s parents or guardians, as well as seeking the expertise of other professionals who interact or work with them, can be particularly beneficial, depending on the type of assessment you plan to undertake.</p>',
    title110: 'Is there a limit to the number of collaborators  that can be invited?',
    description110: '<p>There is no limit to the number of collaborators invited to participate in the questionnaire. However, it is recommended to choose your collaborators carefully. Prioritize quality over quantity; opt for collaborators with a good knowledge of the situation of the client or the learner.</p>',
    title111: 'How will the collected data be used?',
    description111: '<p>The data is collected from all the collaborators who responded to the questionnaire or the inventory, then it is sent to the professional responsible for creating the report. He will be able to analyze the information collected in a personalized report and create a diagnosis from it.</p>',
    title112: 'What do the reports generated by edpro contain?',
    description112: '<p>The reports contain the following sections: An introduction to the assessment tool and its purpose, a description of each section of the assessment tool, the assessment results and an analysis of the results with comments from each respondent, and then a list of customized recommendations related to the results.</p><br/>' +
        '<br />' +
        '<p>It is also possible to select which graphs or tables to add as an appendix to the document. The following options are available: a bar graph that shows the average of the responses obtained for each sub-category of the assessment tool, a quadrant graph showing the results obtained for each category and sub-category meant to highlight the areas to be worked on, a detailed table of the data collected that presents the responses of each respondent to the statements of the assessment tool, and a table of the percentages of each response collected that is intended to visualize the consensus among the respondents.</p>',
    title113: 'Is it possible to create your own questionnaire and add it to edpro?',
    description113: '<p>It is possible to submit a request for a tool with a specific clientele or theme. If you are part of a company that wants a custom assessment tool,<a href="https://www.eduplan.ca/contactus" class="text-c-purple">contact us</a>. It will be our pleasure to learn more about your projects and to collaborate with you!</p>',
    title2: 'I am a professional',
    title21: 'How to create an account on edpro?',
    description21: '<p>To create an account on the edpro platform, simply fill the form available on the home page of the website. You can then enter your payment information if you wish. This will facilitate future transactions. Account creation is completely free.</p><br/>' +
        '<br />' +
        '<p>Once these steps have been completed, an email will be sent to you to validate your account. Click on the link in the email and you will be able to access edpro.</p><br/>' +
        '<br />' +
        '<p>For security reasons, you can enter a phone number associated with your account. This will be used in case you forget your email address when logging in. Go to your settings to add a phone number. This number will be validated using a one-time use code that will be sent to you by text message.</p>',
    title22: 'How do I recover an account when I forgot my email?',
    description22: 'Your email address associated with your edpro account can only be retrieved if a phone number has been added to your account. If you don\'t have a phone number linked to your account,<a href="https://www.eduplan.ca/contactus" class="text-c-purple">contact us</a>.</p><br/>' +
        '<br />' +
        '<p>On the login page, click on "I forgot my email address". You will be asked for the phone number associated with the account. Enter the number and a unique code will be sent to you by text message to confirm your identity. You will then be able to see the email address associated with the account and proceed to login.</p><br/>' +
        '<br />' +
        '<p>For security reasons, it\'s best to add a phone number to your account as soon as possible. Go to your settings to add a phone number. This number will be validated using a unique code that will be sent to you by text message.</p>',
    title23: 'How do I recover an account when I forgot my password?',
    description23: '<p>On the login page, click on "I forgot my password". You will be asked for the email address associated with the account. Enter the email address and a link will be sent to it.</p>',
    title24: 'What is edpro\'s business model?',
    description24: '<p>All tools can be purchased individually. A volume discount is offered on the purchase of more than 5 copies of the same questionnaire. Each copy of the questionnaire must be assigned to a client file. It is possible to generate several different reports with the same copy for a client. This billing method aims to guarantee the integrity of the intellectual property of the products offered.</p><br />' +
        '<br />' +
        '<p>Your payment information is requested during account creation to facilitate future transactions. Account creation is free.</p>',
    title25: 'How do I add a client to my list?',
    description25: '<p>On your homepage, click on \'+ Add a client\'. You will need to fill out a short form to create your new client\'s file. The following questions are mandatory fields; first name, last name, pronoun used and date of birth. This information will be used to personalize the questionnaire and the report for each client. The file number and the client\'s history are optional, but may be relevant for finding your clients in the list or creating certain reports.</p><br />',
    title26: 'How do I modify an existing client file?',
    description26: '<p>To modify an existing file, you must first locate it in the list of files, then click on it to open the file. Once the form is open, click on the field to modify. The field should automatically become editable (text box or drop-down menu). Once the modification is completed, click on the "save" button to save the modifications. Click on the "X" to cancel the change.</p><br />',
    title27: 'How do I archive or delete an existing file?',
    description27: '<p>To archive or delete an existing file, you must first locate it in the file list, then click on it to open the record. Once the file is open, click on "Delete File" or "Archive File". </p><br />' +
        '<br />' +
        '<p>Archived files will be kept in the "Archived Clients" tab of the dashboard. It will be possible to download reports of past questionnaires and inventories, but you will not be able to start new questionnaires for this client. Deleted files will be permanently deleted.</p><br />' +
        '<br />' +
        '<p>Please note! It is required by some environments to keep records in your archives for a specific period of time. Find out what the standards are in your environment before you permanently delete a file.</p><br />',
    title28: 'How to search in the tool bank?',
    description28: '<p>The tool bank has several useful filters that can make your search easier. You can search by keywords, by age group or through the search bar. It is possible to put certain questionnaires in your favorites by clicking on the heart icon. To see your favorites directory, click on the "Favorites" filter.' +
        '<br />' +
        '<p>To learn more about a questionnaire, click on it. It is possible to download a detailed guide containing even more information.',
    title29: 'How to buy a questionnaire or an inventory?',
    description29: '<p>Find the desired assessment tool in the directory available on edpro. If you want to learn more about a questionnaire, click on it to see an information sheet. Select the desired quantity, then proceed to payment. </p><br />' +
        '<br />' +
        '<p>Using the drop-down menu, select the credit card to be used. If you wish to use a card that is not already registered on your account, click on "Use another payment method" and a form will appear. You can register this new card in your existing payment methods. Enter your shipping address or check the box "Shipping address is the same as the one on the credit card". The shipping address is used to calculate taxes in your area.</p><br />' +
        '<br />' +
        '<p>On the right side of the screen, you can enter a code for a discount coupon. This will be automatically deducted from the invoice. Click on "Pay the amount" to finalize the process. If you have credits to your account, you will also be able to select the quantity you want to use.' +
        '<br />' +
        '<p>For a faster process, click on "Buy" from the home page. You will be redirected to the payment page where you can adjust the quantity of copies as needed.</p><br />',
    title210: 'How to launch a questionnaire?',
    description210: '<p>First, find the survey you want to launch in your collection or purchase it from the directory. Click on the questionnaire in question, then on "Launch questionnaire".</p><br />' +
        '<br />' +
        '<p>Choose your client from the list. If you have not yet created a file for this client, click on "+ Add Client". His file will appear in the list with the indication "New customer".</p><br />' +
        '<br />' +
        '<p>Then, invite your collaborators by clicking on "+ Add a collaborator". Depending on the context, this can be the client, a parent, guardian, teacher or other professional. Collaborators will receive an email invitation with a message that you can edit.</p><br />' +
        '<br />' +
        '<p>Once the invitation is sent, you can fill out the questionnaire yourself. To complete a survey later, click on the client record and then on the survey. You will be prompted to respond. You can also use the unique link sent by email to answer the questionnaire.</p>',
    title211: 'How do I collaborate with another professional, parent, guardian or client?',
    description211: '<p>As a professional, you can collaborate with other professionals, parents, guardians and clients by inviting them to complete a questionnaire.</p><br />' +
        '<br />' +
        '<p>Choose your client from the list. If you still haven\'t created a file for this customer, click on "+ Add a customer". His file will appear in the list with the indication "New client".\n</p><br />' +
        '<br />' +
        '<p>\n' +
        'Then, invite your collaborators by clicking on "+ Add a collaborator". Depending on the context, this may be the client, a parent, guardian, teacher or other professional. Collaborators will receive an email invitation with a personalized message. It is possible to modify the message.</p>',
    title212: 'How long does the administration of the questionnaire take?',
    description212: 'The duration of the evaluation depends on each tool and the person who administers it. In general, the evaluation can last between 20 minutes and one hour on average. Shorter versions of some evaluation tools are also available.',
    title213: 'How to answer a questionnaire?',
    description213: '<p>For the majority of the tools, the following questionnaire will be divided into several sections. In each of these sections statements will be presented to you. For each statement, check the appropriate box that corresponds to the level of achievement of the action. Then check the box that corresponds to the level of assistance required to perform the action. Respond to the best of your ability.</p><br />' +
        '<br />' +
        '<p>You will be able to add additional comments at the bottom of the page related to each section. These comments will appear in the report.</p><br />' +
        '<br />' +
        '<p>If in doubt, use the user guide at the bottom of the page as a reminder on how to run the questionnaire correctly. Instructions will be presented to you at the top of the page when the questionnaire varies.</p>',
    title214: 'Is it possible to leave a questionnaire and come back to it later?',
    description214: '<p>Yes. It is possible to exit the questionnaire and resume it later. To do so, you just have to click on "Save and exit". It is very important to complete the questionnaires to which you are invited in order to ensure an optimal result.</p>',
    title215: 'How to generate a report?',
    description215: '<p>To generate a report, click on your client, then on the desired questionnaire. You can send reminders to collaborators if they have not yet responded to the questionnaire. You can also invite new collaborators from this page if needed. Click on "Next step."</p><br />' +
        '<br />' +
        '<p>Select the respondents who will affect the report and the report language (some tools are only available in French for now). Then click on "Generate a report" to obtain your report in PDF format.</p>',
    title216: 'Can the reports be used as is?',
    description216: '<p>The reports are interpretative and intended only for qualified professionals. They should be used to determine learner needs and plan intervention. The results of edpro may be combined with information gathered from other available assessment instruments, measurements, interviews, observations and information. The results are based on algorithms which provide common interpretations from the arithmetic means or the harmonic means obtained. This report is intended for use by a qualified professional. Generally, the report must be interpreted by a professional in order to guarantee its quality.</p>',
    title3: 'I am a parent, client or learner.',
    title31: 'How to collaborate with a professional?',
    description31: '<p>En tant que parent, tuteur ou apprenant, communiquez avec votre professionnel afin de leur proposer l\'usage des outils d\'edpro. Les rapports générés par edpro sont destinés aux professionnels et nécessitent leur interprétation.</p>',
    title32: 'Combien de temps dure l\'évaluation avec un questionnaire?',
    description32: '<p>La durée de l\'évaluation dépend de chaque outils et de la personne qui l\'administre. De façon générale, l\'administration peut durer entre 20 minutes et une heure en moyenne.</p>',
    title33: 'Comment répondre à un questionnaire?',
    description33: '<p>Pour la majorité des outils, le questionnaire est divisé en plusieurs sections. Dans chacune de ces sections des énoncés vous seront présentés. Pour chaque énoncé, cochez la case appropriée qui correspond au niveau de réalisation de l’action. Cochez ensuite la case qui correspond au niveau d’assistance requis pour effectuer l’action. Répondez au meilleur de vos capacités.</p><br />' +
        '<br />' +
        '<p>Il vous sera possible d’ajouter des commentaires supplémentaires en bas de page en lien avec chaque section. Ces commentaires apparaitront dans le rapport.</p><br>' +
        '<br />' +
        '<p>Dans le doute, utilisez le guide d’utilisation en bas de page comme aide-mémoire sur le bon déroulement du questionnaire.</p>',
    title34: 'Est-ce possible de quitter un questionnaire et y revenir plus tard?',
    description34: '<p>Oui. Il est possible de quitter le questionnaire et de le reprendre plus tard. Pour se faire, vous n\'avez qu\'à cliquer sur "Sauvegarder et quitter". En cliquant à nouveau sur le lien dans le courriel, vous serez acheminé là où vous avez arrêté la dernière fois.</p>',
    title35: 'Comment seront utilisé mes données ou les données de mon enfant?',
    description35: '<p>Les données sont collectées puis acheminées vers le professionnel chargé de la création du rapport. Celui-ci pourra analyser l\'information et créer un diagnostic personnalisé. Les données sont confidentielles et réservées à l\'usager du professionnel. </p>',
};
