var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from "react";
import ElementsContainer from "../../components/Stripe/ElementsContainer";
import AddPaymentMethod from "../../components/Stripe/AddPaymentMethod";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import Title from "../../components/Title/Title";
import Functions from "../../utils/functions";
import { useNavigate, useParams } from "react-router-dom";
import ArrowButton from "../../components/Button/ArrowButton";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import { useCardMutation } from "../../hooks/payment/stripe/useCardMutation";
import FormError from "../../components/Error/FormError";
import useQuestionnaireInfo from "../../hooks/questionnaire/useQuestionnaireInfo";
import Invoice from "../../components/Payment/Invoice";
import F from "../../utils/functions";
import IconContainer from "../../components/IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import ModalInPaymentPage from "../../components/Layouts/ModalInPaymentPage";
import Text from "../../components/Field/Text";
import { useForm } from "react-hook-form";
var PayWithNewCard = function (props) {
    var loader = useFullPageLoader();
    var _a = useParams(), qid = _a.qid, qty = _a.qty;
    var navigate = useNavigate();
    var _b = useForm({
        mode: 'onChange',
    }), register = _b.register, getValues = _b.getValues, setValue = _b.setValue, watch = _b.watch, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var _c = useState({
        questionnaireId: Functions.convertToNum(qid),
        quantity: Functions.convertToNum(qty),
        coupon: '',
        credits: 0,
        shippingAddress: {
            country: '',
            province: '',
            city: '',
            postcode: '',
            address: '',
            apartment: '',
            sameShippingAddress: false,
        }
    }), qInfoState = _c[0], setQInfoState = _c[1];
    var getQuestionnaireInfo = useQuestionnaireInfo(qInfoState).getQuestionnaireInfo;
    var _d = useState(), questionnaireInfo = _d[0], setQuestionnaireInfo = _d[1];
    var payWithNewCard = useCardMutation().payWithNewCard;
    var _e = useState(), errorsResponse = _e[0], setErrorsResponse = _e[1];
    var _f = useState(qid), quantityValue = _f[0], getQuantityValue = _f[1];
    var _g = useState(0), creditError = _g[0], setCreditLimitError = _g[1];
    useEffect(function () {
        var _a;
        if (getQuestionnaireInfo.status === 'success') {
            setQuestionnaireInfo((_a = getQuestionnaireInfo.data) === null || _a === void 0 ? void 0 : _a.data);
        }
        else {
            setQuestionnaireInfo(undefined);
        }
    }, [getQuestionnaireInfo.status, getQuestionnaireInfo.isFetching]);
    useEffect(function () {
        getQuestionnaireInfo.refetch();
    }, [qInfoState]);
    var closePage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.removeParams(env.routes.paymentPage);
            navigate("".concat(url).concat(qid, "/").concat(qty));
            return [2 /*return*/];
        });
    }); };
    var getUpdatedQuantity = useCallback(function (qty) {
        getQuantityValue(qty);
    }, []);
    var checkDisableCreditError = useCallback(function (valueCredit) {
        setCreditLimitError(valueCredit);
    }, []);
    var setData = function (title, value) {
        if (title == 'credits') {
            setValue('credits', value);
            //setValue('coupon', '')
            setQInfoState(__assign(__assign({}, qInfoState), { credits: value, coupon: '' }));
        }
        else if (title == 'coupon') {
            setValue('coupon', value);
            //setValue('credits', 0)
            setQInfoState(__assign(__assign({}, qInfoState), { coupon: value, credits: 0 }));
        }
    };
    var updateQuantity = function (quantity) {
        setQInfoState(__assign(__assign({}, qInfoState), { quantity: quantity }));
    };
    var getShippingAddress = function (data) {
        var _a, _b, _c, _d, _e, _f;
        var address;
        if (data.sameShippingAddress) {
            address = {
                country: data.country,
                province: data.province,
                city: data.city,
                postcode: data.postcode,
                address: data.address,
                apartment: data.apartment,
                sameShippingAddress: data.sameShippingAddress,
            };
        }
        else {
            address = {
                country: (_a = data.country_shipping) !== null && _a !== void 0 ? _a : '',
                province: (_b = data.province_shipping) !== null && _b !== void 0 ? _b : '',
                city: (_c = data.city_shipping) !== null && _c !== void 0 ? _c : '',
                postcode: (_d = data.postcode_shipping) !== null && _d !== void 0 ? _d : '',
                address: (_e = data.address_shipping) !== null && _e !== void 0 ? _e : '',
                apartment: (_f = data.apartment_shipping) !== null && _f !== void 0 ? _f : '',
                sameShippingAddress: data.sameShippingAddress,
            };
        }
        return address;
    };
    var LG = Functions.getLang();
    var render = function () {
        var errorMsg = (questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.message) !== undefined ? questionnaireInfo : '';
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalInPaymentPage, { additionalCls: "bg-client-list bg-cover bg-center", layoutClass: "h-fit mt-16 p-10 lg:w-4/5" },
                React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: closePage }),
                React.createElement(Title, { additionalClass: "max-w-fit mb-8" },
                    lang[LG].paymentInformations, "".concat((questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.questionnaireTitle) != null ? '- ' + questionnaireInfo.questionnaireTitle : '')),
                React.createElement(Text, { isbold: false, additionalClass: "mt-2" }, lang[LG].paymentMethodInfo),
                React.createElement(ElementsContainer, null,
                    React.createElement(AddPaymentMethod, { title: React.createElement("div", { className: "flex items-center" },
                            React.createElement(Text, { additionalClass: "text-xl font-semibold" }, lang[LG].addPaymentMethod),
                            React.createElement(Text, { onClick: closePage, additionalClass: "text-sm text-c-purple ml-2 underline cursor-pointer" }, lang[LG].cancel)), showShippingAddress: true, onChange: function (data) {
                            setQInfoState(__assign(__assign({}, qInfoState), { shippingAddress: getShippingAddress(data) }));
                        }, isProcessing: false, submitBtnTitle: lang[LG].completeTransaction, skipCard: false, submitBtnFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                            var response, url, url;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        loader(true);
                                        return [4 /*yield*/, payWithNewCard.mutateAsync({
                                                questionnaireId: Functions.convertToNum(qid),
                                                quantity: Functions.convertToNum(qty),
                                                credits: qInfoState === null || qInfoState === void 0 ? void 0 : qInfoState.credits,
                                                coupon: qInfoState === null || qInfoState === void 0 ? void 0 : qInfoState.coupon,
                                                paymentMethodId: data.paymentMethodId,
                                                isSave: data.isSave,
                                                shippingAddress: getShippingAddress(data),
                                                lang: LG
                                            })];
                                    case 1:
                                        response = _a.sent();
                                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                            setErrorsResponse(response);
                                        }
                                        else {
                                            setErrorsResponse(undefined);
                                        }
                                        loader(false);
                                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                                            url = F.removeParams(env.routes.paymentSuccess);
                                            navigate("".concat(url).concat(qid));
                                        }
                                        else {
                                            url = F.removeParams(env.routes.paymentFailNewCard);
                                            navigate("".concat(url).concat(qid, "/").concat(qty));
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); }, leftBtn: React.createElement(ArrowButton, { title: "none", onClick: closePage }), rightBtn: null, hideTitle: true, addThisCard: true, invoiceDetail: React.createElement(Invoice
                        /*prices={questionnaireInfo?.prices} qty={qty ?? ''}
                        updateQuantity={updateQuantity}*/
                        , { 
                            /*prices={questionnaireInfo?.prices} qty={qty ?? ''}
                            updateQuantity={updateQuantity}*/
                            questionnaireId: Functions.convertToNum(qid), prices: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.prices, quantity: Functions.convertToNum(qty), setData: setData, credits: qInfoState.credits, coupon: qInfoState.coupon, updateQuantity: updateQuantity, qty: qty !== null && qty !== void 0 ? qty : '', errorCoupon: errorMsg, creditUser: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.creditsUser, successCouponMessage: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.successCouponMessage, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    checkDisableCreditError(e);
                                    return [2 /*return*/];
                                });
                            }); }, onClickCallbackAction: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    getUpdatedQuantity(e);
                                    return [2 /*return*/];
                                });
                            }); } }), onCancel: closePage })),
                errorsResponse !== null && React.createElement(FormError, { response: errorsResponse }))));
    };
    return render();
};
export default PayWithNewCard;
