var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import Text from "../Field/Text";
import { lang } from "../../lang/lang";
import Functions from "../../utils/functions";
import PointerPopover from "../Popover/PointerPopover";
import F from "../../utils/functions";
import BasicPurpleUnderlineText from "../Text/BasicPurpleUnderlineText";
import Check from "../../assets/icons/Check";
import DropDown from "../DropDown/DropDown";
import clsx from "clsx";
import { validation_lang } from "../../lang/validations";
import MinusIcon from "../../assets/icons/MinusIcon";
import ButtonWithIcon from "../../assets/icons/ButtonWithIcon";
import AddIcon from "../../assets/icons/AddIcon";
import Validations from "../../utils/validations";
import TextField from "../Input/TextField";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useCardMutation } from "../../hooks/payment/stripe/useCardMutation";
import SubmitButton from "../Button/SubmitButton";
import TextFieldWithIconClick from "../Input/TextFieldWithIconClick";
var Invoice = function (props) {
    var LG = Functions.getLang();
    var user = useSelector(function (store) { return store.user.value; });
    var payWithNewCard = useCardMutation().payWithNewCard;
    var _a = useForm(), register = _a.register, watch = _a.watch, setValue = _a.setValue, getValues = _a.getValues, setError = _a.setError, clearErrors = _a.clearErrors, errors = _a.formState.errors;
    var _b = useState(false), creditBox = _b[0], setCreditBox = _b[1];
    var _c = useState(false), disableCoupon = _c[0], setDisableCoupon = _c[1];
    var buttonCss = 'w-5 h-9 border rounded-none border-c-gray hover:bg-c-white';
    var _d = useState(+props.quantity), count = _d[0], setCount = _d[1];
    var _e = useState('pr-1.5 pl-1'), additionInputCls = _e[0], setAdditionInputCls = _e[1];
    var _f = useState(false), crossCoupon = _f[0], enableCrossCoupon = _f[1];
    var userCredit = props.creditUser == undefined || props.creditUser.length == 0 ? user.credits : props.creditUser;
    useEffect(function () {
        props.updateQuantity(count);
        props.onClickCallbackAction(count);
    }, [count]);
    var setCouponValue = function (e) {
        enableCrossCoupon(false);
        setValue('coupon', '');
        setAdditionInputCls('pr-1.5 pl-1');
        props.setData('coupon', '');
    };
    var handleChangeCredit = function (value) {
        setValue('coupon', '');
        props.setData('credits', value);
        if (value > count) {
            props.onClick(1);
        }
        else {
            props.onClick(0);
        }
    };
    var couponAppliedText = function () {
        if (props.successCouponMessage != null && getValues('coupon') != '') {
            return (React.createElement(Text, { additionalClass: clsx({
                    'text-green-500': true,
                    'mt-2': creditBox,
                    'pb-2': true,
                }) }, props.successCouponMessage.message));
        }
    };
    var handleCrossCoupon = function (e) {
        var couponValue = e.target.value;
        if (couponValue == '' || couponValue == undefined) {
            enableCrossCoupon(false);
        }
        else {
            enableCrossCoupon(true);
            setAdditionInputCls('text-ellipsis whitespace-nowrap overflow-hidden pl-1 pr-5');
        }
    };
    var handleChangeCoupon = function (e) {
        if (e == '') {
            setError('coupon', { type: 'custom', message: validation_lang[LG].coupon });
        }
        else {
            clearErrors('coupon');
        }
        setValue('credits', 0);
        props.setData('coupon', e);
    };
    var render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        var errorCreditMsg = ((_b = (_a = props.errorCoupon) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.credits) !== undefined ? props.errorCoupon : '';
        var errorCouponMsg = getValues('coupon') != '' && ((_d = (_c = props.errorCoupon) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d.coupon) !== undefined ? (_e = props.errorCoupon) === null || _e === void 0 ? void 0 : _e.message : '';
        var bottomPadding = props.errorCoupon || props.successCouponMessage != null ? 'pb-6' : '';
        var topCouponPadding = ((_g = (_f = props.errorCoupon) === null || _f === void 0 ? void 0 : _f.errors) === null || _g === void 0 ? void 0 : _g.credits) !== undefined ? 'p-6' : '';
        var heightCoupon = props.errorCoupon || props.successCouponMessage != null ? 'h-18' : 'h-16';
        var paddingForCreditText = LG == 'en' ? 'pl-11' : '';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-1/2 mt-4 border-l border-c-divider pl-5" },
                userCredit > 0 &&
                    React.createElement("div", { className: "h-12 flex pr-2 align-middle" },
                        React.createElement("div", { className: "flex w-1/2" },
                            React.createElement(Check, { width: 20, height: 20, additionalClass: clsx({
                                    'mr-1': true,
                                    'mt-2': creditBox
                                }), color: "" }),
                            React.createElement(Text, { isbold: false, additionalClass: clsx({
                                    'text-green-500': true,
                                    'mt-2': creditBox
                                }) }, lang[LG].paymentPage.creditText1 + (userCredit > 1 ? userCredit + lang[LG].paymentPage.creditText2 : lang[LG].paymentPage.creditText3))),
                        React.createElement("div", { className: "w-1/2" },
                            !creditBox && (props === null || props === void 0 ? void 0 : props.isDisable) && React.createElement(BasicPurpleUnderlineText, { additionalClass: "text-sm cursor-pointer ".concat(paddingForCreditText), disable: props === null || props === void 0 ? void 0 : props.isDisable }, lang[LG].paymentPage.creditLink),
                            !creditBox && !(props === null || props === void 0 ? void 0 : props.isDisable) && React.createElement(BasicPurpleUnderlineText, { additionalClass: "text-sm cursor-pointer ".concat(paddingForCreditText), onClick: function () { return setCreditBox(true); } }, lang[LG].paymentPage.creditLink),
                            creditBox &&
                                React.createElement(DropDown, { items: F.getCredit(userCredit), selectedItem: F.getCredit(userCredit).filter(function (item) { return item.data == props.credits; })[0], error: errorCreditMsg, disabled: props === null || props === void 0 ? void 0 : props.isDisable, onChange: function (item) {
                                        if (item.data > 0) {
                                            setDisableCoupon(true);
                                        }
                                        else {
                                            setDisableCoupon(false);
                                        }
                                        handleChangeCredit(item.title);
                                    } }))),
                React.createElement("div", { className: " border-black flex pr-2 align-middle ".concat(heightCoupon, " ").concat(topCouponPadding, " ").concat(bottomPadding) },
                    React.createElement("div", { className: "flex w-1/2" },
                        React.createElement("div", { className: "flex w-1/3" },
                            React.createElement(Text, { isbold: true, additionalClass: "mt-2" }, lang[LG].paymentPage.quantity)),
                        React.createElement("div", { className: 'flex' },
                            React.createElement(ButtonWithIcon, { type: "button", icon: React.createElement(MinusIcon, { color: "#989799" }), title: '', additionalClass: "".concat(buttonCss, " rounded-l  border-r-0"), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        count == 1 ? setCount(1) : setCount(count - 1);
                                        return [2 /*return*/];
                                    });
                                }); } }),
                            React.createElement(TextField, { title: count.toString(), type: "text", additionalClass: "w-11 text-center rounded-none\tborder-c-gray h-9\t", disableOnChange: true }),
                            React.createElement(ButtonWithIcon, { type: "button", icon: React.createElement(AddIcon, { color: "#989799" }), title: '', iconAdditionalClass: 'm-0 m-auto', additionalClass: "".concat(buttonCss, " border-l-0 rounded-r"), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        setCount(count + 1);
                                        return [2 /*return*/];
                                    });
                                }); } }))),
                    React.createElement("div", { className: "w-1/2 " },
                        React.createElement("div", { className: "flex" },
                            React.createElement(TextFieldWithIconClick, { register: register("coupon", Validations.coupon(LG)), title: lang[LG].email, placeholder: lang[LG].paymentPage.couponPlaceholder, type: "text", disabled: disableCoupon || (props === null || props === void 0 ? void 0 : props.isDisable), error: errors.coupon, additionalClassCtn: 'relative', additionalClass: '', additionalClassInput: additionInputCls, errorMsg: errorCouponMsg, disabledCross: crossCoupon, onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        handleCrossCoupon(e);
                                        return [2 /*return*/];
                                    });
                                }); }, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        setCouponValue(e);
                                        return [2 /*return*/];
                                    });
                                }); } }),
                            React.createElement("div", { className: 'ml-2' },
                                React.createElement(SubmitButton, { type: 'button', title: lang[LG].apply, additionalClass: 'px-2 text-small', disabled: props === null || props === void 0 ? void 0 : props.isDisable, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            handleChangeCoupon(getValues('coupon'));
                                            return [2 /*return*/];
                                        });
                                    }); } }))),
                        couponAppliedText())),
                React.createElement("div", { className: "border-b border-black h-8" },
                    React.createElement(Text, { isbold: true }, lang[LG].invoice)),
                React.createElement("div", { className: "flex h-8 items-center mt-2" },
                    React.createElement(Text, { additionalClass: "pr-1" }, lang[LG].cost),
                    React.createElement("div", { className: "flex-grow border-b border-black border-dotted mt-2" }),
                    React.createElement(Text, { additionalClass: "pl-1" }, "".concat(count, " x ").concat(Functions.getFormattedPrice((_j = (_h = props.prices) === null || _h === void 0 ? void 0 : _h.unitPrice) !== null && _j !== void 0 ? _j : 0, LG)))),
                ((_k = props.prices) === null || _k === void 0 ? void 0 : _k.discount) != null &&
                    props.prices.discount > 0 &&
                    React.createElement("div", { className: "flex h-8 items-center" },
                        React.createElement(Text, { additionalClass: "pr-1" }, lang[LG].discount),
                        React.createElement("div", { className: "flex-grow border-b border-black border-dotted mt-2" }),
                        React.createElement(Text, { additionalClass: "pl-1" }, Functions.getFormattedPrice((_m = (_l = props.prices) === null || _l === void 0 ? void 0 : _l.discount) !== null && _m !== void 0 ? _m : 0, LG))),
                ((_o = props.prices) === null || _o === void 0 ? void 0 : _o.couponDiscount) != null &&
                    props.prices.couponDiscount > 0 &&
                    React.createElement("div", { className: "flex h-8 items-center" },
                        React.createElement(Text, { additionalClass: "pr-1" }, lang[LG].couponDiscount),
                        React.createElement("div", { className: "flex-grow border-b border-black border-dotted mt-2" }),
                        React.createElement(Text, { additionalClass: "pl-1" }, Functions.getFormattedPrice((_q = (_p = props.prices) === null || _p === void 0 ? void 0 : _p.couponDiscount) !== null && _q !== void 0 ? _q : 0, LG))),
                React.createElement("div", { className: "flex h-8 items-center" },
                    React.createElement(Text, { isbold: true, additionalClass: "pr-2" }, lang[LG].tax),
                    React.createElement(PointerPopover, { position: 'RIGHT', content: lang[LG].taxInfo, element: React.createElement("div", { className: "w-3.5 h-3.5 bg-c-gray text-xs text-white rounded-full flex justify-center items-center" }, "i"), additionalCls: "w-80" })), (_r = props.prices) === null || _r === void 0 ? void 0 :
                _r.taxes.map(function (item) {
                    var _a;
                    return React.createElement("div", { className: "flex h-8 items-center", key: item.title },
                        React.createElement(Text, { additionalClass: "pr-1" }, item.title),
                        React.createElement("div", { className: "flex-grow border-b border-black border-dotted mt-2" }),
                        React.createElement(Text, { additionalClass: "pl-1" }, Functions.getFormattedPrice((_a = item.value) !== null && _a !== void 0 ? _a : 0, LG)));
                }),
                React.createElement("div", { className: "flex h-12 items-center mt-3 border-t border-black" },
                    React.createElement(Text, { additionalClass: "pr-1", isbold: true }, lang[LG].total),
                    React.createElement("div", { className: "flex-grow border-b border-black border-dotted mt-2" }),
                    React.createElement(Text, { additionalClass: "pl-1" }, Functions.getFormattedPrice((_t = (_s = props.prices) === null || _s === void 0 ? void 0 : _s.total) !== null && _t !== void 0 ? _t : 0, LG))))));
    };
    return render();
};
export default Invoice;
