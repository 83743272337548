import React from "react";
var UncheckedCheckbox = function (props) {
    var _a, _b;
    var fill = (_a = props.fill) !== null && _a !== void 0 ? _a : '#f2f2f5';
    var stroke = (_b = props.stroke) !== null && _b !== void 0 ? _b : '#c5c5c8';
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { onClick: props.onClick, className: props.additionalClass, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "20", height: "20", viewBox: "0 0 20 20" },
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip-Round_checkbox_unchecked" },
                        React.createElement("rect", { width: "20", height: "20" }))),
                React.createElement("g", { id: "Round_checkbox_unchecked", clipPath: "url(#clip-Round_checkbox_unchecked)" },
                    React.createElement("g", { id: "Rectangle_2431", fill: fill, stroke: stroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1" },
                        React.createElement("rect", { width: "20", height: "20", rx: "10", stroke: "none" }),
                        React.createElement("rect", { x: "0.5", y: "0.5", width: "19", height: "19", rx: "9.5", fill: "none" }))))));
    };
    return render();
};
export default UncheckedCheckbox;
