import React from "react";
var Sort123Desc = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "29", height: "14", viewBox: "0 0 29 14" },
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip-Filter_-_321" },
                    React.createElement("rect", { width: "29", height: "14" }))),
            React.createElement("g", { id: "Filter_-_321", "data-name": "Filter - 321", clipPath: "url(#clip-Filter_-_321)" },
                React.createElement("g", { id: "Group_3415", "data-name": "Group 3415", transform: "translate(0.42 -0.138)" },
                    React.createElement("g", { id: "Rectangle_1280", "data-name": "Rectangle 1280", transform: "translate(-0.42 0.138)", fill: "#ebcfeb", stroke: "#6c0c6c", strokeWidth: "0.5" },
                        React.createElement("rect", { width: "29", height: "14", rx: "4", stroke: "none" }),
                        React.createElement("rect", { x: "0.25", y: "0.25", width: "28.5", height: "13.5", rx: "3.75", fill: "none" })),
                    React.createElement("text", { id: "_321", "data-name": "321", transform: "translate(3.58 10.138)", fill: "#6c0c6c", fontSize: "8", fontFamily: "Poppins-Regular, Poppins" },
                        React.createElement("tspan", { x: "0", y: "0" }, "321")),
                    React.createElement("path", { id: "Polygon_55", "data-name": "Polygon 55", d: "M2,0,4,3H0Z", transform: "translate(19.58 4.138)", fill: "#6c0c6c" }),
                    React.createElement("path", { id: "Polygon_56", "data-name": "Polygon 56", d: "M2,0,4,2H0Z", transform: "translate(23.58 10.138) rotate(180)", fill: "#6c0c6c" }))))));
};
export default Sort123Desc;
