var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import Input from "./Input";
import env from "../../config/env";
var TextField = function (props) {
    var render = function () {
        var _a, _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat((_a = props.additionalClassCtn) !== null && _a !== void 0 ? _a : '') },
                React.createElement(Input, { register: props.register, type: props.type, title: props.title, placeholder: (_b = props.placeholder) !== null && _b !== void 0 ? _b : props.title, autoComplete: 'off', disabled: props.disabled, error: props.error, errorMsg: props.errorMsg, additionalClass: props.additionalClassInput, onChange: props.onChange, disableOnChange: props.disableOnChange }),
                props.disabledCross &&
                    React.createElement("div", { className: "absolute inset-y-0 flex p-2 right-0 pt-3" },
                        React.createElement("svg", { onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
                                    return [2 /*return*/];
                                });
                            }); }, className: "".concat((_c = props.additionalClass) !== null && _c !== void 0 ? _c : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", height: 15, viewBox: "0 0 24 24", width: 20, fill: env.iconColor },
                            React.createElement("defs", null,
                                React.createElement("clipPath", { id: "clip-Close" },
                                    React.createElement("rect", { width: "24", height: "24" }))),
                            React.createElement("g", { id: "Close", clipPath: "url(#clip-Close)" },
                                React.createElement("g", { id: "Group_1266", "data-name": "Group 1266", transform: "translate(-825.9 -627)" },
                                    React.createElement("line", { id: "Line_195", "data-name": "Line 195", x2: "15.83", y2: "15.83", transform: "translate(830.201 631)", fill: "none", stroke: env.iconColor, strokeLinecap: "round", strokeWidth: "1" }),
                                    React.createElement("line", { id: "Line_196", "data-name": "Line 196", x1: "15.83", y2: "15.83", transform: "translate(830.201 631)", fill: "none", stroke: env.iconColor, strokeLinecap: "round", strokeWidth: "1" }))))))));
    };
    return render();
};
export default TextField;
