import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _getFn = function () {
    return Api.get("".concat(env.api.user));
};
export function useQueryUser() {
    var getUser = useMutation(function () { return _getFn(); });
    return { getUser: getUser };
}
