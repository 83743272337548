import React from "react";
import Text from "../Field/Text";
var SubmitButton = function (props) {
    var _a;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : "submit";
    var renderClickableButton = function () {
        var _a;
        var btnClass = "bg-c-purple hover:bg-c-button-hover";
        if (props.disabled) {
            btnClass = "bg-c-button-disabled cursor-not-allowed";
        }
        var radius = props.radius ? props.radius : 'rounded-full';
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { id: props.id, type: type, onClick: props.onClick, className: "".concat(btnClass, " ").concat(radius, " text-white font-poppins px-5 py-2 flex items-center h-10 ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), disabled: props.disabled },
                props.icon,
                React.createElement(Text, { additionalClass: "mx-1", fontWeight: "font-normal" }, props.title))));
    };
    var renderNonClickableButton = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: "bg-c-purple-500 rounded-2xl text-white font-poppins px-5 py-1 cursor-not-allowed ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') }, props.title)));
    };
    var render = function () {
        if (props.clickable === false) {
            return renderNonClickableButton();
        }
        else {
            return renderClickableButton();
        }
    };
    return render();
};
export default SubmitButton;
