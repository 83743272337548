var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import TextError from "../Field/TextError";
import Functions from "../../utils/functions";
import { lang } from "../../lang/lang";
var FormError = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var LG = Functions.getLang();
    var _g = useState([]), errorResponse = _g[0], setErrorResponse = _g[1];
    var _h = useState(''), errorResponseMessage = _h[0], setErrorResponseMessage = _h[1];
    var errors = (_b = (_a = props.response) === null || _a === void 0 ? void 0 : _a.data.errors) !== null && _b !== void 0 ? _b : {};
    var errorMessage = (_e = (_d = (_c = props.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) !== null && _e !== void 0 ? _e : '';
    var margin = (_f = props.margin) !== null && _f !== void 0 ? _f : 'mt-4';
    useEffect(function () {
        if (errorMessage !== '') {
            setErrorResponse([]);
            setErrorResponseMessage(errorMessage);
        }
    }, [errorMessage]);
    useEffect(function () {
        var _a;
        var errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            var errorsContainer = [];
            for (var x in errors) {
                if (((_a = props.response) === null || _a === void 0 ? void 0 : _a.status) === StatusCodes.LOCKED && x === 'seconds') {
                    continue; // removing seconds to be rendered as error
                }
                errorsContainer = __spreadArray(__spreadArray([], errorsContainer, true), errors[x], true);
            }
            setErrorResponse(errorsContainer);
            setErrorResponseMessage('');
        }
    }, [errors]);
    var getErrors = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: props.margin }, errorResponse.map(function (error, key) {
                var _a;
                return React.createElement(TextError, { extraSmall: (_a = props.extraSmall) !== null && _a !== void 0 ? _a : true, key: key, message: error });
            }))));
    };
    var getErrorMessage = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: props.margin },
                errorResponseMessage == 'passwords.token' &&
                    React.createElement(TextError, { extraSmall: (_a = props.extraSmall) !== null && _a !== void 0 ? _a : true, message: lang[LG].linkHasExpired }),
                errorResponseMessage !== 'passwords.token' &&
                    React.createElement(TextError, { extraSmall: (_b = props.extraSmall) !== null && _b !== void 0 ? _b : true, message: errorResponseMessage }))));
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            errorResponse.length > 0 && getErrors(),
            errorResponse.length === 0 && errorResponseMessage !== '' && getErrorMessage()));
    };
    return render();
};
export default FormError;
