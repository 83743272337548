export var en = {
    placeholderMobile: "Add a phone number",
    placeholderConfirmNewPassword: "Confirm new password",
    emailAddress: "Email Address",
    forgotEmail: "I forgot my email address",
    password: "Password",
    forgotPassword: "I forgot my password",
    login: "Login",
    loginTitle: "Login",
    requiredEmail: "Enter your email",
    invalidEmail: "Enter a valid email",
    requiredPassword: "Enter a password that contains a minimum of 8 characters including an uppercase and a number",
    passwordMustBe: "Enter a password that contains a minimum of 8 characters including an uppercase letter and a number.",
    register: "Register",
    registeration: "Registration",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    firstNamePlaceholder: "ex. John",
    lastNamePlaceholder: "ex. Doe",
    emailPlaceholder: "example@gmail.com",
    confirmEmailPlaceholder: "Confirm email",
    confirmEmail: "Confirm email",
    confirmPassword: "Confirm password",
    phone: "Phone number",
    phoneNo: "Phone number",
    requiredFirstName: "Enter your first name",
    requiredLastName: "Enter your last name",
    requiredConfirmEmail: "Confirm your email",
    requiredPhone: "Phone is required.",
    requiredConfirmPassword: "Confirm password is required.",
    minPasswordLength: "Password must have at least 8 characters.",
    phoneLength: "The phone number is invalid. Please try again.",
    confirmPasswordNotMatch: "The two entries must match.",
    confirmEmailNotMatch: "Both entries must match",
    phoneDigitsOnly: "Only digits are allowed.",
    sendTheLink: "Send the Link",
    p1_inForgotPasswordtsx: "Enter your email address and a link with instructions will be sent to you to reset your password",
    p1_inForgotEmailtsx: "Enter your phone number and a link with the instructions to retrieve your email address will be sent to you.",
    t1_inForgotPasswordtsx: "Forget Password",
    t1_inForgotEmailtsx: "Forgotten Email Address",
    thankYou: "Thank you!",
    reportThankYouText: "The report is generated",
    reportThankYouDescription: ["The download could take a couple seconds to finish. If the download doesn't start automatically, {ACTION}"],
    reportThankYouLink: " click here.",
    passwordSentOnEmail1: "An email containing the link to reset your password will be sent to you shortly. If you do not receive the email,",
    passwordSentOnEmail2: "click here",
    passwordSentOnEmail3: "to resend it.",
    backToHome: "Back to home",
    findEmail: "Find Email",
    p1_inFindEmailtsx: "Your email address is: ",
    resetPassword: "Reset Password",
    successContent: [
        "An email containing the link to reset your password will be sent to you shortly.",
        "If you do not receive the email, {ACTION} to resend it.",
    ],
    successButtonTitle: "Back to home",
    accountCreatedSuccess: [
        "Thank you! You must now validate your account by following the instructions sent by email in order to be able to access edpro.",
        "If you haven't received anything in the next few minutes, check your junk mail. Click {ACTION} to resend the validation email.",
    ],
    accountCreatedSuccessWithoutCard: [
        "Thank you! You must now validate your account by following the instructions sent by email in order to access edpro. We encourage you to add a credit card in your account settings to facilitate future transactions.",
        "If you haven't received anything in the next few minutes, check your junk emails. Click {ACTION} to resend the validation email.",
    ],
    politicsDrop: {
        policy: "Privacy policy",
        products: "Privacy policies for Eduplan Solutions products",
        relative: "Terms of use",
        program: "Privacy Management Program",
        coppa: "Regulatory compliance by jurisdiction (COPPA)",
        coppa1: "COPPA\n" +
            "Conformité réglementaire selon le territoire",
        coppa2: "Cookies",
    },
    accountCreateTitle: "Account created successfully",
    clickHere: "here",
    end: "Back",
    backToHomePage: "Back to homepage",
    resetPasswordContent: "You can now access Edpro using the new password.",
    messageSentSuccessfully: "Message successfully sent.",
    passwordPlaceholder: "********",
    confirmPasswordPlaceholder: "********",
    phonePlaceholder: "ex : 1-123-456-7890",
    client: "Client",
    clientModal: {
        addTitle: "Create a new file",
        clientFullName: "Complete name",
        FullName: "Complete Name",
        example: "ex: John Doe",
        firstName: "First name",
        lastName: "Last name",
        editClient: "Edit file",
        clientFullNamePlaceholder: 'Enter the full name associated with this file',
    },
    datePicker: {
        dob: "Birth date",
        day: "Day",
        month: "Month",
        year: "Year",
        begining: 'Beginning',
        end: "End"
    },
    months: [
        { index: 1, name: "January" },
        { index: 2, name: "February" },
        { index: 3, name: "March" },
        { index: 4, name: "April" },
        { index: 5, name: "May" },
        { index: 6, name: "June" },
        { index: 7, name: "July" },
        { index: 8, name: "August" },
        { index: 9, name: "September" },
        { index: 10, name: "October" },
        { index: 11, name: "November" },
        { index: 12, name: "December" },
    ],
    languageSelect: [
        { index: 1, name: "English" },
        { index: 2, name: "Français" },
    ],
    apply: "Apply",
    save: "Save",
    cancel: "Cancel",
    clientListActive: " Active files",
    archiveInTabPanel: "Archived files",
    clientAdded: "Your client has been successfully added.",
    name: "Name",
    namePlaceholder: "Enter your collaborator's name",
    emailCollaboratorPlaceholder: "Enter your collaborator's email address",
    age: "Age",
    age2: "years old",
    minAge: "years old",
    ageToText: 'to',
    toBuy: 'Buy',
    assesmentTool: "Evaluation",
    versionDahboard: 'Version',
    creationDate: "Creation date",
    respondentStatus: "Respondent status",
    search: "Search",
    tagSearch: "Tags",
    tagSSelect: "Select key words :",
    addClient: "+ Create a new file",
    addClientConfirmTitle: "New file added successfully",
    addClientConfirmMsg: "Your file list has been updated.",
    clientAssessment: "Assessment",
    addANewClient: "Add a new client",
    languageBtn: "English",
    close: "Close",
    editProfile: "Edit profile",
    questionnaire: "Questionnaire",
    date: "Date",
    status: "Status",
    newAssessment: "New Assessment",
    deleteClient: "Delete this file",
    archiveClient: "Archive this file",
    hello: 'Hi, ',
    archiveClientTitle: "Archive Client",
    unarchiveClient: "Unarchive Client",
    archiveConfirmation: "Archive Confirmation",
    deleteConfirmation: "Delete questionnaire",
    deleteThisClient: "Delete this client",
    archiveThisClient: "Archive this client",
    pInAchiveConfirmationPage1: "Are you sure you want to archive your client",
    pInAchiveConfirmationPage2: "? Their file will be transferred in the archive section of your dashboard. You can restore the client file anytime. ",
    pInDeleteConfirmationPage1: "Are you sure you want to delete your the file ",
    pInDeleteConfirmationPage2: "from your list?",
    pInDeleteConfirmationPage3: " This action is irreversible.",
    pInDeleteConfirmationPage4: " Use the \"Archive\" function to temporarily archive your file. You will be able to restore it if necessary.",
    dob: "Date of birth",
    unArchiveThisClient: "Unarchive this client",
    unArchiveConfirmation1: "Are you sure you want to unarchive",
    unArchiveConfirmation2: "? Their file will be transferred in the \"Active\" section of your dashboard. You can archive the client file again anytime.",
    okay: "Okay",
    clientUpdated: "Your client list has been updated.",
    clientDeleted: "Your client list has been updated.",
    archiveDate: "Archive date",
    editProfilePopover: "Unarchive user to enable this action.",
    clientEdited: "Client edited successfully",
    completeName: "Complete name",
    edit: "Edit",
    profileSettings: "Profile settings",
    modifyPassword: "Edit password",
    preferencesAndNotifications: "Preferences and notifications",
    whyPaymentInformations: "Payment information is requested to complete your file and facilitate future transactions. Your confirmation and consent will always be requested when making a transaction.",
    paymentInformations: "Payment informations",
    deleteAccount: "Delete account",
    settings: "Settings",
    resources: "Resources",
    teachingMaterial: 'Teaching materials',
    fileName: 'File name',
    categoryR: 'Category',
    langFrench: 'French',
    langEnglish: 'English',
    download: 'Download',
    tooltipCategory: 'Unavailable',
    notAccessible: 'To access this resource, you must purchase one or multiple assessment tool(s) from this category : {0}',
    logout: 'Logout',
    savedChanges: 'Changes saved',
    newPassword: "New password",
    currentPassword: "Current password",
    confirmNewPassword: "Confirm new password",
    pInDeleteAccountPage: "Deleting an account is permanent. You will not be able to recover your account one the process is complete. Enter your password to proceed.",
    accountDeletedSuccessfully: 'Account deleted successfully',
    verifyPhoneNumber: 'Verify the phone number',
    verifyPhoneNumberDetail: 'Please enter the verification code sent on the mobile. It may take a few minutes.',
    verifyCodeText: 'Enter the code sent to the phone number',
    verifyEmail: 'Validate by following the instructions sent to this email address.',
    verifyPhone: 'Validate by following the instructions sent to this phone number.',
    resendEmail: 'Resend email',
    cancelEmail: 'Cancel Edit',
    resendCode: 'Resend code',
    cancelCode: 'Cancel code',
    verify: 'Verify',
    verifyEmailSent: 'Verification email sent',
    verifyCodeSent: 'Verification code sent.',
    back: 'Back',
    emailVerified: [
        'Your email address has been validated and your account has been successfully created!',
        'We welcome you to our platform. Use your email to login on edpro.'
    ],
    paymentInformation: "Payment information",
    paymentMethod: "Payment method",
    paymentMethodInfo: "To complete the transaction, select your payment method and enter your delivery address. <span class = 'font-semibold'>It is essential to enter the delivery address before using your credits.</span>",
    addACard: "+ Add a card",
    paymentHistory: "Payment history",
    research: "Research",
    questionnaireName: "Questionnaire name",
    quality: "Quantity",
    costInPaymentInformation: "Cost",
    dateOfPayment: "Date of payment",
    pInPaymentSetting: "A confirmation will be requested before each payment. The purpose of payment information is to facilitate the process.",
    fullNameOnTheCreditCard: "Full name one the credit card",
    cardNumber: "Card information",
    expirationDate: "Expiration date",
    billingaddress: "Billing address",
    address: "Address",
    apartment: "Apartment (optional)",
    city: "City",
    zipCode: "Zip code",
    province: "Province/Territory",
    remove: "Remove",
    skipThisStep: "Skip this step",
    nextStep: "Save",
    delete: "Delete",
    emptyCard: "You don't have any saved cards",
    state: "Province/territories",
    country: "Country/Region",
    language: "Language",
    languageLaunchQuestionnaire: 'Language(s) available\n',
    notifications: "Notifications",
    email2: "E-mail",
    pInNotificationPage1: "Questionnaire completed by a collaborator",
    pInNotificationPage2: "Deals and promotions",
    select: 'Select',
    sortBy: 'Sort by :',
    selected: 'Selected',
    evaluationTools: "Evaluation Tools",
    myCollection: "My collection",
    favorites: "Favorites",
    moreTool: "+ Get more evaluation tools",
    price: "Price",
    category: "Category",
    notSpecified: "Not specified",
    behavioral: "behavioral",
    units: "units",
    gender: {
        he: 'he/him',
        she: 'she/her',
        noSay: 'prefer not to say',
        label: 'Pronouns of choice',
        they: 'They/Their',
        theyFr: 'They'
    },
    noResponse: "No results",
    version: 'Questionnaire version',
    addCollaborator: '+ Add a collaborator',
    creditHelpText: "The security code (CVV) is a 3-4 digits code printed on the back of your credit card (typically right above the signature). The security code won’t be saved and will have to be re-entered for every new payment.",
    inCollection: 'in collection',
    learnMore: 'learn more',
    viewDescription: 'See Detailed Description',
    collectionQuantityText: ' in your collection',
    accordionText: 'See all versions on the questionnaire',
    buyMoreCopies: 'Buy more copies',
    numberOfQuestionnaires: 'You have {1} copies of this questionnaire',
    launchQuestionnaire: 'Launch the questionnaire',
    selectClient: 'Select a file',
    newCustomer: 'Newly added',
    noClientFound: 'No client found.',
    sendQuestionnaire: 'Send the questionnaire',
    selectMyselfQuestionnaires: 'I want to fill out the questionnaire myself',
    selectMyselfAnaQuestionnaires: 'I want to fill out the questionnaire myself',
    or: 'OR',
    collaboratorAna: 'Faire remplir le questionnaire par un collaborateur',
    msgCollaboratorAna: 'Custom message(optional)',
    guestCollaborators: 'Guest collaborators',
    customMessage: 'Custom message(optional)',
    questionnaireSuccess: {
        title: 'The questionnaire was successfully launched',
        content: 'Participants will receive an invitation by email. You can fill out the questionnaire right away.',
        okayTitle: 'Back to home',
        cancelTitle: 'Complete the questionnaire'
    },
    notFound: {
        client: 'Client not found.',
        questionnaire: 'Questionnaire not found.',
    },
    anaQuestionnaire: {
        addItem: '+ Add an item',
        uploadfile: 'Upload  PDF',
        titleS1: "Section {0}: {1}",
    },
    answerQuestionnaire: {
        titleS1: "Section {0}: {1} > {2}",
        titleS2: "Section 2: Oral expression > Producing a message",
        smallTitle: "For {0}",
        explanation: "For each of the statements if the learner performs the following tasks or activities, please specify the level of assistance by checking the appropriate box. You should answer each statement. If in doubt, please select N/A.",
        p1: "Jérémy reformulates a message in his own words",
        p2: "After listening to a verbal instruction or after listening to questions, Jérémy answers",
        p3: "Jérémy expresses his incomprehension to his interlocutor",
        p4: "Jérémy asks his interlocutor to repeat",
        p5: "Jérémy asks questions or asks for clarification",
        p6: "Jérémy uses common words related to his environment",
        p7: "Jérémy expresses his needs",
        p8: "Jérémy describes the events",
        p9: "Jérémy tells stories",
        p10: "Jérémy uses polite formulas",
        title2: "Additional information(optional)",
        defaultInput: "Enter additional information as needed",
        bt1: "Save and quit",
        bt2: "User manual",
        bt3: "Next step",
        titleOfbox1: "1. This task or activity is performed...",
        titleOfbox2: "2. Specify the level of assistance",
        topRight: {
            one: "Section {0} of {1}: {2}",
            two: "Page {0} of {1}",
        },
        topRight2: {
            one: "Section 2 of 2: Oral expression",
            two: "Page 2 of 2",
        },
        page3: {
            title1: "The Inventory of Adaptive Behaviors related to Functional Autonomy (CAF)",
            title2: "Thank you",
            p1: "Thank you for answering the questionnaire.",
            p2: "Answers will be forwarded to the test administrator who can generate a report containing recommendations for {0}.",
            a2: "Answers will be forwarded to the test administrator who can generate a report containing recommendations for {0}.",
            savedAndQuit: 'Answers are saved you can come back later.',
            button: "Exit",
            rightTop: "Conclusion",
        },
        deletedPage: {
            title: "Le questionnaire n'existe plus",
            text: "Nous nous excusons de l’inconvénient. Il semblerait que ce questionnaire n’existe plus ou que le lien de partage ait été annulé par la personne à l’origine de l’invitation.",
        },
        error: {
            error1: "Answer each of the statements. If in doubt, answer N/A",
            error2: "Specify the level.",
            error3: "Respond to the statement. If in doubt, answer “N/A”.",
        },
        selectLanPage: {
            selectLan: "Choisissez la langue / Select language",
            p1: "You {ACTION} change the language afterwards.",
            p11: "will not be able to",
            p2: "You {ACTION} be able to change this later.",
            p22: "won't",
            btn1: "Français",
            btn2: "English",
        },
        answerInfgo: 'Click on the rating level to tick the entire column. If in doubt, select N/A.',
        skipStep: 'Skip this page',
        skipStepTooltip: 'All statements on this page will be rated N/A',
        selectedOptions: {
            1: "N/A",
            2: "Never",
            3: "Rarely",
            4: "Sometimes",
            5: "Often",
            6: "Always",
            7: "None",
            8: "Low",
            9: "Moderate",
            10: "Significant",
            11: "Extreme",
        },
        radioOption: {
            1: "Yes",
            2: "No",
            3: "N/A",
            4: "+/-"
        },
        introductionPageLegal: {
            introduction: "Introduction",
            title1: "Terms of Use and Privacy Policies",
            btn: "Next step",
            p1: "We only collect your personal information after obtaining your informed consent.",
            p2: "When you complete an assessment on edpro, you must enter personal information. We only use your identifying information for personalization purposes and to communicate with the questionnaire administrator who sent you the invitation link. We are not responsible for the use of this information by the authorized user. We do not sell or redistribute your individual information to third parties.",
            p3: "Attention! ",
            p31: "If you are the administrator of the assessment tool, carefully read the terms of service for the administrator. If you are the respondent of the assessment tool, carefully read the terms of use for respondents.",
            p4: "Warning! If you are the respondent of the assessment tool, carefully read the ",
            p5: "if you have any questions or comments about our privacy policy and practices, please contact us.",
            acceptPolicy: "I accept the terms of use and the privacy policies",
            underline1: "https://www.eduplan.ca/our-politics",
            underline31: "Terms of Use for Respondents.",
            underline2: "privacy policies",
            underline5: "please contact us.",
            error: "You must accept privacy policies by checking this box.",
            title2: "Introduction to the questionnaire",
            p21: "Hello",
            p22: "Thank you for taking the time to answer the questionnaire. This questionnaire lasts approximately 20 minutes. You can pause it at any time and return to it at another time.",
            p23: "Please answer to the best of your knowledge.",
            for: "For ",
            for2: "for",
            startbutton: "Start Questionnaire",
        },
        introductionPage: {
            introduction: "introduction",
            title1: "Privacy policies",
            btn: "Next step",
            p1: "We only collect your personal information after obtaining your informed consent.",
            p2: "When you complete an assessment on edpro, you must enter personal information. We only use your identifying information for personalization purposes and to communicate with the questionnaire administrator who sent you the invitation link. We are not responsible for the use of this information by the authorized user. We do not sell or redistribute your individual information to third parties.",
            p3: "If you have any questions or comments about our privacy policy and practices, ",
            p4: "I accept the {ACTION}.",
            underline1: "please contact us.",
            underline2: "privacy policies",
            error: "Accept the privacy policies to continue",
            title2: "Introduction to the questionnaire",
            p21: "Hello",
            p22: "Thank you for taking the time to answer the questionnaire. This questionnaire lasts approximately 20 minutes. You can pause it at any time and return to it at another time.",
            g22: "Thank you for taking the time to answer the questionnaire. This questionnaire lasts approximately 20 minutes. You can pause it at any time and return to it at another time.",
            p23: "Please answer to the best of your knowledge.",
            for: "For",
            for2: "for",
        },
        descPage: {
            title: 'Procedure',
            d1: 'For each question, clear instructions will be presented to guide you.',
            d2: 'Some questions require a text response. In this case, a text block will allow you to enter the desired answer. In some cases, drop-down menus may offer you response options. Select the appropriate item(s). To add custom items to the list, use the “Other” option present in the drop-down menu. This will allow you to enter a response in a text block. For multiple-choice questions, check the appropriate boxes. Respond to the best of your ability. All questions are optional.',
            d3: 'Throughout the questionnaire you can save and exit to continue later. An email will be sent to you with the link.'
        },
        userGuidePageOne: {
            title: "Procedure",
            description1: "The following questionnaire will be divided into several sections. In each of these sections statements will be presented to you. For each statement, check the appropriate box that corresponds to the level of achievement of the action. Then check the box that corresponds to the level of assistance required to perform the action. Respond to the best of your ability.",
            description2: "To speed up the process, you can select an entire column by clicking on the column title. In the case you don\"t know the answers for a certain section or if it does not apply to your assessment needs, use the “Skip this page” button. All entries will be rated N/A.",
            description3: "Some statements will be accompanied by additional information. Just hover over the information icon to learn more.",
            description4: "You will be able to add additional comments at the bottom of the page related to each section. These comments will appear in the report.",
            description5: "Throughout the questionnaire you can save and exit to continue later. An email will be sent to you with a unique link to continue where you left off.",
            returnbutton: "Return to questionnaire",
            contactbutton: "Contact us",
        },
        userNewGuidePageOne: {
            description1: "The following questionnaire has various interactive elements. For drop-down menus, simply select the appropriate response from the options provided.",
            description2: "For text blocks, simply select the box, then enter your personalized response in the box provided.",
            description3: "Some items offer the option to add an item to the list. Click on “Add an item” to add a text block or a drop-down menu. These items will be presented as a bulleted list in the report.",
            description4: "Other questions will be presented in the form of statements to which an answer must be provided by clicking on the radio button. For each statement, check the appropriate box. If in doubt, select N/A.",
            description5: "In some cases, your answer will need to be further clarified through a second question. If you don't know the answer, you can choose to skip these questions.",
            description6: "You may be asked for more details in some cases. Know that all the details are relevant and will feed into the report. Again, these questions remain optional.",
            description7: "Other questions will be presented in the form of items to be selected. Check the appropriate box(es). If no item matches, you can decide to skip this step.",
            description8: "Throughout the questionnaire you can save and exit to continue later. An email will be sent to you with a unique link to continue where you left off.",
            returnQuestionnaire: 'Start questionnaire',
        },
        alreadyAnsweredPage: {
            title: "You have already completed this questionnaire",
        },
    },
    launchQuestionnaireSuccess: {
        title: "The questionnaire has been launched successfully",
        content: "Participants will receive an invitation by email. You can fill out the questionnaire right away if you wish.",
        btn1: "Complete the questionnaire",
        btn2: "Return to Home",
    },
    guildPage: {
        title: "User Guide",
        content: "This section is under construction! Thank you for your understanding. We are working hard to offer you an optimal experience.",
        btn: "Back",
    },
    saveAndQuitPage: {
        title: "Quit the questionnaire",
        content: "Answers will be saved. You will receive an email with a unique link to continue and complete your questionnaire.",
        content2: "It is important to complete the questionnaire with the most suitable recommendations to {0}.",
        btn: "Return to the questionnaire",
        btn2: "Save and exit",
    },
    general: 'All ages',
    placeHolder1: "e.g. John Doe",
    placeHolder2: "e.g. Montreal",
    placeHolder3: "e.g. 123 Victoria Street",
    placeHolder4: "e.g. 101",
    Active: "Active",
    Archive: "Archive",
    Resume: "Resume",
    removeAgeFilter: "Remove Age Filter",
    removeFilter: 'Reset search',
    removeTagFilter: 'Remove filters',
    tagSet: {
        tag1: "Adaptation",
        tag2: "Autism",
        tag3: "Compartment",
        tag4: "Dyslexia",
        tag5: "School",
        tag6: "Social",
        tag7: "Transition",
    },
    sortItem: {
        pertinence: "Relevance",
        ascendingPrice: "Ascending Price",
        decreasingPrice: "Decreasing Price",
        alphabetic1: "Alphabetic(A-Z)",
        alphabetic2: "Alphabetic(Z-A)",
        recentlyAdded: "Recently Added",
    },
    unarchiveUserFirst: 'Please unarchive the user first before making a new evaluation.',
    partnerLogo: "Partner logo",
    english: 'English',
    french: 'Français',
    role: 'Role',
    you: 'You',
    progressing: 'progressing',
    completed: 'Complete',
    noRespondent: 'No invited collaborator',
    fillQuestionnaire: 'Fill the questionnaire',
    sendAReminder: 'Send a reminder',
    questionnaireActivity: {
        na: 'N/A',
        never: 'Never',
        rarely: 'Rarely',
        sometimes: 'Sometimes',
        often: 'Often',
        always: 'Always',
    },
    questionnaireAssistance: {
        none: 'None',
        low: 'Low',
        moderate: 'Moderate',
        significant: 'Significant',
        extreme: 'Extreme',
    },
    allResponded: "All collaborators responded",
    noRespondents: "No respondents",
    noAnswer: " respondent(s) did not answer",
    submit: 'Submit',
    contact: 'Contact',
    subject: 'Subject',
    message: 'Message',
    enterSubject: 'Enter the subject of the message',
    enterMessage: 'Enter message',
    send: 'Send',
    questionnairePreview: {
        detail: "See the detailed description of the questionnaire",
        buyMore: "Buy additional copies",
        selectQuantity: "Select quantity",
        button: "Proceed to payment",
        error: "Select quantity",
        quantity: "Qty.",
        quantity2: "Qty.",
        text1: "Get 5% off when you buy 5 or more",
        text2: "Get 7% off when you buy 10 or more",
    },
    paymentPage: {
        title: "Payment Information {0}",
        selectQuantity: "Select quantity",
        invoice: "Invoice",
        cost: "Cost",
        discount: "Discount",
        discountCoupon: "Discount code",
        taxes: "Taxes",
        total: "Total",
        paymentMethod: "Payment method",
        useANewMethod: "Add a credit card",
        creditText1: 'You have ',
        creditText2: ' credits available. ',
        creditText3: 'a credit available.',
        creditLink: 'Apply on the transaction',
        quantity: 'Quantity',
        couponPlaceholder: 'Coupon Code',
        securityCode: "Security Code",
        details: "The card security code (CSC) is a 3 or 4 digit code printed on the back of the credit card (normally above the signature section). The security code will not be saved and will have to be re-entered for future payments.",
        term: "By paying this amount, I accept Edpro's {ACTION}.",
        button: "Complete the transaction",
        purpleText: "terms of use",
        sameAddress: "The billing address is the same as the one that appears on the credit card.",
        billingAddress: "Billing address",
    },
    completeTransaction: 'Complete transaction',
    invoice: 'Invoice',
    cost: 'Cost',
    tax: 'Tax',
    addCard: 'Add this card to my saved payment methods',
    termsOfUse: 'terms of use',
    discount: 'Discount',
    couponDiscount: 'Coupon Discount',
    total: 'Total',
    taxInfo: 'The tax amount depends upon the selected province',
    isSameBillingAddress: 'The delivery address is the same as the one that appears on the credit card',
    paymentResult: {
        success: {
            title: "Questionnaire(s) added to your collection",
            text: "Payment made successfully. Your collection has been updated.",
            button1: "Back to home",
            button2: "Launch the questionnaire"
        },
        error: {
            title: "Payment failed",
            text: "There was an error with the payment. Please use another payment method or contact your bank.",
            button: "Return to payment",
        }
    },
    deliveryAddress: 'Delivery address',
    deliveryAdTax: 'This address is used for the tax calculation according to your region.',
    cancelQuestionnaire: 'Delete questionnaire',
    generateReport: 'Generate report',
    forClientPage: 'For {0}',
    generateReportInfo: 'Are you sure you want to generate the report? The following contributors did not complete the questionnaire:',
    reportPreparation: 'Report preparation',
    selectReportType: 'Select the participants who will affect the report :',
    selectLanguage: 'Select language of report',
    selectLang: 'Select language',
    generalReport: 'General report',
    selectAllRespondents: 'The results of all respondents will be aggregated.',
    reportByRespondents: 'Reports by respondents',
    reportByRespondentsInfo: 'The results of all respondents will be separated into different sections.',
    graph: 'Graph',
    table: 'Table',
    completedOn: 'Completed On ',
    selectReportContent: 'Select the content of the report :',
    reportGraph: 'Normative graph',
    reportTable: 'Results compilation table',
    downloadReport: 'Download the report',
    downloadReceipt: 'Download receipt',
    clientHistory: "History (optional)",
    enterHistory: "Enter this person’s history",
    lastUpdate: "Last update : {0}",
    testIsPending: 'The respondent has not completed the test',
    report: 'Report',
    reportName: 'edpro_invoice_{0}',
    ourAssessmentTools: 'Our assessment tools',
    politics: 'Privacy policy',
    helpCenter: 'Help center',
    features: 'Features',
    aboutUs: 'About us',
    contactUsTitle: 'Contact us',
    simplifyAssessmentTool: "Make your assessment process<br/> better and easier!",
    bannerContent: 'Edpro offers you a range of assessment and inventory tools to produce personalized reports.',
    alreadyHaveAccount: 'I already have an account',
    acceptThe: 'I accept the',
    phonemsg: 'By entering your number, you agree to receive mobile messages.',
    termsConditions: 'terms and conditions of use',
    exploreAssessmentTools: 'Explore our assessment tools',
    edproAdvantages: 'The advantages of edpro',
    createACustomerList: 'Create a customer list',
    clientListDetail: 'Quickly and efficiently view your customer list and priority information. Edpro allows you to keep the list of your customers up to date and to stay informed about each new modification. See responders\' status in real time and quickly send reminders with a single click.',
    sendQuestionnaireToEmployees: 'Send questionnaires to your employees',
    quesToEmpDetail: 'Collaboration is key to obtaining a relevant report. Edpro allows you to send online questionnaires to your various collaborators. Choose from three versions of the questionnaire; for professionals, parents/guardians and learners. Artificial intelligence will take care of interpreting the data effectively in your report.',
    generateDetailReport: 'Generate detailed reports',
    generateReportDetail: 'By generating a report, the artificial intelligence makes sure to take into account the questionnaires of each participant in order to create a relevant and fair report. The data will be interpreted, and additional notes added by your participants will allow you to push the analysis and learn more about your customers.',
    eduplanProduct: 'A product of Eduplan solutions',
    eduplanDetail: 'The mission of Eduplan solutions is to transform the planning and management practices of plans, projects or intervention programs in the education, health and social services sector in order to contribute to the actualization of the adaptive potential and learning of each person or student with a disability, or with learning or adaptation difficulties, and this in the development of an inclusive society.',
    ceo: '- Dr. Nathalie Myara, CEO d\'Eduplan Solutions',
    haveAQuestion: 'You have questions? Write us!',
    contactUs: {
        title: 'Contact-us',
        intro: 'Do you have questions about our services, products, policies or conditions? Do you have any comments to send us? Do you want to participate in one of our research and development projects?',
        intro2: 'Fill out the form below to contact us! It will be our pleasure to answer you as soon as possible.'
    },
    politicsFront: {
        title: 'Privacy policy',
        intro: 'The protection of your confidential or personal data is essential; this is why eduplan solutions makes sure to take it into account at the very beginning of its product research and development process, with the aim of innovating products, having by default, functions that comply with the legal requirements and framework relating to the protection of confidential data.',
        intro2: 'In addition, eduplan solutions makes its privacy policies available to you so that you can understand our policies and practices regarding the management of personal data. These policies, conditions and program have been drawn up with the aim of describing the practices of eduplan solutions with regard to the management of personal information: either the type of information collected, or the way in which it is processed, or how it is used.',
        intro3: '<p>En somme, vous trouverez dans les pages suivantes:</p><br/>',
        link1: '1. Politique de confidentialité relative au site Web d\'eduplan solutions / edpro',
        link2: '2. Politique de confidentialité relative aux produits d\'eduplan solutions / edpro',
        link3: '3. Conditions d\'utilisation',
        link4: '4.Programme de gestion de la confidentialité',
        link5: '5. COPPA',
        link6: '6. Cookies',
        intro4: '<p>Ces politiques, conditions et programmes ont été élaborés dans le but de décrire les pratiques d\'eduplan solutions en matière de gestion des informations personnelles : soit le type d\'informations recueillies, soit la façon dont elles sont traitées, soit comment elles sont utilisées.</p><br/>' +
            '<p>eduplan solutions s\'engage à protéger votre vie privée. Ces politiques de confidentialité ("Politique") sont conçues pour vous informer sur les données personnellement identifiables que nous recueillons auprès de vous via nos produits. Ces données personnellement identifiables sont des données qui pourraient être utilisées, seules ou en combinaison avec d/\'autres données, pour vous identifier en tant qu/\'individu.</p><br/>',
    },
    helpCenterFront: {
        title: 'Help center',
    },
    aboutFront: {
        title: 'About-us',
        intro: 'edpro is a platform that makes available assessment instruments consisting of questionnaires and inventories, on distinct themes, which help to determine the strengths and challenges of a target person, as well as to help with decision-making.',
        intro2: 'The evaluation instruments consist of collecting information on a targeted person based on the perception of one or more respondents, with regard to the level of achievement of the targeted tasks in one or more areas.',
        intro3: 'edpro helps professionals determine learner needs.'
    },
    yourEmailAddress: 'Your email address',
    topic: 'Topic',
    contactUsSuccessMessage: 'Your message has been sent successfully! A member of our team will respond to you as soon as possible.',
    edproMakesEasier: 'Edpro makes your life easier!',
    edproEasierContent: 'Want to improve the way you work with assessment tools? Edpro is there for you! Collaborate and generate detailed reports with Edpro!',
    createYourAccount: 'Create your account',
    eduplan: 'Eduplan solutions',
    eduplanWebsite: 'Eduplan website',
    smartplan: 'Smartplan',
    banqO: 'BanqO',
    iplan: 'Iplan',
    sitemap: 'Sitemap',
    privacyPolicy: 'Privacy policy',
    termsCondition: 'Terms and conditions',
    assistance: 'Assistance',
    questionnaireCompleted: 'Questionnaire(s) complete(s)',
    respondent: 'Respondent',
    answerOnHold: 'answering on hold.',
    moreAssessment: ' more assessments...',
    answerMoreThanOnHold: '',
    noRespondentAssigned: 'No respondents assigned.',
    phoneInfo: 'The phone number will be used in case the email address is forgotten.',
    backToLandingPage: 'Back to landing page',
    return: 'Return',
    enterNewPassword: 'Enter new password',
    enterCurrentPassword: 'Enter your current password',
    receiveByEmail: 'Receive by email',
    enterPassword: 'Enter your password',
    sorry: 'Sorry',
    expiredLink: 'The link has expired',
    reminderSent: 'Reminder sent',
    deleteQuestionnaire: [
        'Are you sure you want to delete ',
        ' assigned to',
        'This action is irreversible. If invitations to respond have been sent, they will be cancelled.',
        'As you have not yet used this questionnaire, it will be returned in your inventory.'
    ],
    removeRespodent: [
        'Are you sure you want to delete this collaborator?',
        'By deleting this participant, all data collected so far for this collaborator will be lost for this questionnaire. It will be impossible to recover them.',
        'If you want to generate a report excluding this collaborator\'s responses, deselect their name from the list when generating the report.',
        'Keep this collaborator',
        'Delete this collaborator',
        'Back to questionnaire'
    ],
    year: 'Year',
    examplePostCode: 'ex: A1A 1A1',
    selectCollaboratorRole: 'Select your collaborator\'s role',
    passwordResetSuccess: 'Password reset successfully',
    monthsOld: '{0} MO',
    yearsOld: '{0} YO',
    listIsEmpty: 'This list is empty',
    roleOfCollaborator: 'Role of the collaborator',
    addMessageForCollaborator: 'Add a message for collaborators',
    addPaymentMethod: 'Add a payment method',
    customerHistory: 'History',
    someIssueOnServer: 'There is some issue try again later',
    generateReportRecommendations: 'Your answers will allow us to generate a report containing recommendations for {0}.',
    wrongPassword: 'The provided email or password is incorrect',
    linkHasExpired: 'The link has expired.',
    noRecords: 'No files available!',
    noRecordsForAssessmentTool: 'No tools available!',
    assessmentToolTextLink: 'Click here to access the assessment tools bank',
    createClientFile: 'Create your first client file',
    visitUs: 'Visit us regularly to try out our new questionnaires and inventories!',
    clickHere2: 'Click here',
    paymentCompliance: 'edpro solutions complies with the PCI-DSSS standard for payment cards. To view the standard declaration {ACTION}',
    pageNotFound: 'Page not found',
    siteUsesCookies: 'This website uses cookies to enhance the user experience.',
    tutorial: {
        addClient: 'Creation of new files',
        addClientText: 'The first step to use edpro is to create a file for each of your clients or students.',
        viewArchive: 'Your archived files',
        viewArchiveText: 'Here, you’ll be able to view your archived files and restore them if needed.',
        getQuestionnaire: 'The store',
        getQuestionnaireText: 'Our assessment tools can be found here. You can purchase them and assign them to one of your file or keep them in your collection for later use.',
        viewFav: 'Your favorite assessment tools',
        viewFavText: 'Click here to view your favorites assessment tools ',
        generateReport: 'File list and evaluation',
        generateReportText: 'Once you have begun an assessment, you will be able to see its status next to the corresponding file. You can click on it to add new collaborators or remove existing ones, send reminders or generate a report. You can also archive or delete files.',
        myCollection: 'Your collection',
        myCollectionText: 'The assessment tools that you will purchase will be stored here. You’ll be able to assign them to a file from here or purchase additional copies.',
    },
};
