export var aboutFR = {
    title1: 'À propos d\'Eduplan Solutions',
    description1: '<p>Notre mission <\p>' +
        '<br />' +
        '<p>Transformer les pratiques de planification et de gestion de plans, de projets ou de programmes d’intervention, de santé ou de  traitement, et, outiller les acteurs concernés, dans le secteur de l’éducation, de la santé et des services sociaux et outiller les différentes organisations dans leur processus de création et d’innovation de projets cliniques ou pédagogiques.<\p>' +
        '<br />' +
        '<p>Notre vision<\p>' +
        '<br />' +
        '<p>Contribuer au développement personnel et professionnel des personnes en situation de handicap, ou, en difficulté d’apprentissage ou d’adaptation, améliorer le parcours de santé et le suivi d’un patient en situation complexe et mettre à profit notre expertise et nos compétences auprès des différentes organisations en vue d’améliorer la qualité des différents services qu’elles offrent.<\p>' +
        '<br />' +
        '<br />' +
        '<p>Pour en apprendre davantage sur Eduplan Solutions, consultez notre site web : <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
    title2: 'Recherche et innovations',
    description2: '<p>Eduplan Solutions est le fruit d’un projet d’innovation entrepreneuriale qui s’appuie sur plus de 15 ans de données de recherche. Depuis la recherche et l’innovation sont au cœur de notre stratégie. </p>' +
        '<br/>' +
        '<p>Notre équipe multidisciplinaire travaille au quotidien pour améliorer et innover des outils de gestions de plans, entre autres. L’objectif étant de mieux répondre aux besoins des milieux scolaires, de la santé et des services sociaux, des parents et des apprenants à besoins particuliers. </p>' +
        '<br/>' +
        '<p>Afin de s’assurer de l’efficacité de nos produits, Eduplan Solutions a poursuivie une méthode de recherche rigoureuse. Celle-ci vise à développer des procédés qui répondent aux besoins des milieux en tenant compte des fonctions que ceux-ci doivent remplir, des coûts et des bénéfices. </p>' +
        '<br/>' +
        '<p>Eduplan Solutions a pour ambition de transformer les pratiques de planification et de gestion des plans, programmes d’intervention dans les secteurs de l’éducation, de la santé et des services sociaux afin de contribuer à une harmonisation des pratiques de planification. Le but étant de développer le potentiel adaptatif et d’apprentissage de chaque individu en situation de handicap, en difficulté d\'adaptation ou d’apprentissage, et ainsi assurer l’essor d’une société inclusive. </p>' +
        '<br/>' +
        '<p>Dans cette logique inclusive, Eduplan Solutions souhaite répondre à la diversité des besoins des milieux en matière de planification grâce à la recherche et l’utilisation de l’intelligence artificielle, des data et des algorithmes. La recherche et l’innovation chez Eduplan Solutions c’est le progrès continu au service de l’éducation. </p>' +
        '<br/>' +
        '<p>Chez nous, la recherche scientifique occupe une place centrale dans tout ce que nous réalisons et offrons. D’ailleurs, nous croyons fermement que l\'innovation et l\'excellence ne peuvent être atteintes qu\'en s\'appuyant sur une solide base de connaissances, de découvertes, de méthodologie reconnue et de données probantes. Notre entreprise s\'investit dans un programme de recherche de pointe, mené par une équipe compétente et talentueuse de chercheurs et d\'experts dans leur domaine respectif. Consentir nos efforts dans la recherche et le développement, en collaboration avec des experts et des chercheurs de divers domaines et de différentes parties du monde, nous permet de rester à jour sur les dernières avancées scientifiques et technologiques, de comprendre les besoins changeants de nos clients, de répondre aux défis émergents, d’anticiper les tendances du marché et de rester à l\'avant-garde de notre secteur d\'activité.</p>' +
        '<br/>' +
        '<p>D’emblée, nous nous engageons à fournir des solutions durables, efficaces et fiables. Nos produits et services sont, désormais, le fruit de recherches rigoureuses, d\'expertises et de pratiques éprouvées.</p>',
    title3: 'Programme de recherche',
    description3: '<p>Le programme de recherche d’Eduplan Solutions, se base sur les fondements du programme de recherche élaboré et réalisé au sein du Centre d’informations et de ressources pour les plans (CIRP) (www.planintervention.com) et s\'articule autour de quatre axes de recherche :</p>' +
        '<br />' +
        '<p>Axe 1- Fondements</p>' +
        '<p>Axe 2 - Innovation</p>' +
        '<p>Axe 3 - Évaluation</p>' +
        '<p>Axe 4 – Portrait repère</p>' +
        '<br />' +
        '<p>Il s’agit d’une posture de recherche qui se base sur :</p>' +
        '<br />' +
        '<p>1.    Une approche écologique ou écosystémique (Bronfenbrenner, 1979; Fougeyrollas et ses collaborateurs, 1998; Langevin et Rocque, 1995; Pabœuf, 2014; Rocque, 1999) qui vise l’étude des interactions et des interrelations, et de leur influence sur l’écosystème</p>' +
        '<br />' +
        '<p>2.    L’Analyse de la Valeur Pédagogique qui est une méthode de recherche et développement (Rocque, Langevin & Riopel, 1998; Langevin et Myara, à paraître) qui a pour objet l’étude et le développement des produits, procédés, outils ou services pédagogiques à travers une démarche itérative, rigoureuse et systématique. </p>' +
        '<br />' +
        '<p>Les activités de recherche se font au sein d’une équipe interdisciplinaire, multidisciplinaire et transdisciplinaire (droit, ingénierie, psychologie, santé, sciences de l’éducation, social) en partenariat avec les milieux de pratique (école-famille-communauté) et les milieux industriels. En parallèle, cela nous a permis de mettre au point notre communauté de personnes sous l\'intitulé d\'Inclulab.</p>' +
        '<br />' +
        '<p>Pour en apprendre davantage sur nos projets réalisés et nos projets en cours, consultez notre site web : <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
    title4: 'Méthodologie',
    description4: '<p>L\'analyse de la valeur entrepreneuriale (AVE*) est la principale méthodologie de recherche qui structure notre démarche  de recherche scientifique et de développement ainsi qui oriente les différentes méthodologie de recherche employées pour chacune des étapes de ce long processus.</p>' +
        '<br />' +
        '<p>L\'AVE  est une méthode de recherche et développement  itérative et agile dont le but est de concevoir des innovations (produits, procédés ou services) pédagogiques ou cliniques, qui tiennent compte des besoins des différents utilisateurs, des fonctions que doivent remplir les innovations dans le but de satisfaire leurs besoins à moindre coûts et à plus de bénéfices. </p>' +
        '<br />' +
        '<p>*L\'AVE est une adaptation de l\'AVP; une méthode qui tire ses origines de l\'ingénierie (Langevin et Rocque, 1998) </p>' +
        '<br />' +
        '<p>L\'AVE se compose de quatre grandes étapes décrites ci-dessous: </p>' +
        '<br />' +
        '<p>1. Préconception</p>' +
        '<br />' +
        '<p>Cette première étape vise, à partir d’un cadre de référence et de recherche sur le terrain, à répondre à l’ensemble de ces questionnements dans le but de développer des solutions qui sont utilisables et en mesure de satisfaire les besoins de chaque personne. C\'est une phase qui permet d\'identifier tous les différents types d\'utilisateurs et de déterminer leurs différents besoins.</p>' +
        '<br />' +
        '<p>L’AVE une démarche qui s’inscrit dans une structure organisationnelle permettant de mettre en lumière la dynamique d’une entreprise apprenante.  </p>' +
        '<br />' +
        '<p>En outre, l\'AVE a adopté, déjà en phase de préconception, l\'approche Privacy by Design (Pbd) qui prend en compte la vie privée tout au long du processus de recherche et de développement tel que prévu par le rapport publié en 1995 par du Commissaire à l’Information et la Vie Privée de l’Ontario et de Ann Cavoukian de l’Autorité de protection des données néerlandaise et de l’Organisation néerlandaise pour la recherche scientifique appliquée. </p>' +
        '<br />' +
        '<p>Ainsi que l\'approche Accessibility by Design, qui prend en compte les besoins des différents utilisateurs ayant des limitations auditives, cognitives, physiques ou visuelles. </p>' +
        '<br />' +
        '<p>En entretenant des collaborations avec différents utilisateurs, Eduplan Solutions a réussi à réunir des talents dans 4 grands domaines qui constituent nos communautés. Tous les savoirs mobilisés grâce à ses talents se font dans le but d’atteindre nos objectifs. </p>' +
        '<br />' +
        '<p>2. Analyse fonctionnelle</p>' +
        '<br />' +
        '<p>Suite à cette analyse, un cahier des charges fonctionnel (CdCF) vient préciser les fonctionnalités que la solution devrait remplir. </p>' +
        '<br />' +
        '<p>Dans le CdCF, chacune des fonctions sont hiérarchisées catégorisées, caractérisées et, pour certaines, un coût leur sont attribuées ou un bénéfice sont est escomptées. </p>' +
        '<br />' +
        '<p>Le CdCF devient un outil d\'aide à la recherche et au développement de l\'innovation incontournable; il sert à diriger la conception du futur dispositif, produit, procédé ou service et il sert à vérifier son efficacité après sa conception. </p>' +
        '<br />' +
        '<br />' +
        '<p>3. Conception</p>' +
        '<br />' +
        '<p>À partir des solutions retenues s’ensuit la conception du premier prototype du futur dispositif, procédé, produit ou service; c\'est l\'étape du prototypage de l\'innovation. </p>' +
        '<br />' +
        '<p>Le prototypage est segmenté en tronçons, de manière à pouvoir exécuter et vérifier, auprès de nos collaborateurs, seulement un tronçon à la fois, à des fins de validation et de réaliser un premier prototype de l\'innovation prévue. </p>' +
        '<br />' +
        '<p>4. Implantation, évaluation et diffusion</p>' +
        '<br />' +
        '<p>La mise à l\'essai se réalise en tenant compte des différentes composantes et conditions du milieu et avec la planification de stratégies d\'implantation. </p>' +
        '<br />' +
        '<p>L\'évaluation de l\'innovation consiste à évaluer : 1) les propriétés de l\'innovation déployée (l\'efficacité et l\'efficience de l\'innovation; 2) la valeur accordée et perçue de l\'innovation; 3) ainsi que, l\'impact social de l\'innovation. </p>' +
        '<br />' +
        '<p>Il est important de préciser que nos produits font l’objet d’évaluation systématique, et ce même après la diffusion. Cette pratique vise à conserver les communautés d’utilisateurs, à s’adapter aux évolutions, aux changements et à enrichir la mobilisation de divers savoirs contribuant ainsi au développement de l’innovation. </p>' +
        '<br />' +
        '<p>L’application de cette démarche a amené Eduplan Solutions à développer l’Inclulab, un espace d’enrichissement et de partage de connaissances entre personnes des différentes communautés.</p>' +
        '<br />' +
        '<p>Pour en apprendre davantage sur notre mthodologie, consultez notre site web : <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
};
export var aboutEN = {
    title1: 'About Eduplan Solutions',
    description1: '<p>Our mission</p>' +
        '<br/>' +
        '<p>Transform planning and management practices for plans, projects or intervention, health or treatment programs, and equip the people concerned, in the education, health and social services sector and equip the different organizations in their process of creating and innovating clinical or educational projects.</p>' +
        '<br/>' +
        '<p>Our vision</p>' +
        '<br/>' +
        '<p>Contribute to the personal and professional development of people with disabilities, or, with learning or adaptation difficulties, improve the health pathway and follow-up of a patient in a complex situation and make use of our expertise and our skills with different organizations with a view to improving the quality of the different services they offer.</p>' +
        '<br/>' +
        '<br/>' +
        '<p>To learn more about Eduplan Solutions, visit our website: <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
    title2: 'Research and innovation',
    description2: '<p>Eduplan Solutions is the result of an entrepreneurial innovation project based on more than 15 years of research data. Since then, research and innovation have been at the heart of our strategy.<\p>' +
        '<br />' +
        '<p>Our multidisciplinary team works daily to improve and innovate plan management tools, among others. The objective is to better meet the needs of schools, health and social services, parents and learners with special needs.<\p>' +
        '<br />' +
        '<p>In order to ensure the effectiveness of our products, Eduplan Solutions has pursued a rigorous research method. This aims to develop processes that meet the needs of the environments, taking into account the functions that they must fulfill, the costs and the benefits.<\p>' +
        '<br />' +
        '<p>Eduplan Solutions aims to transform planning and management practices for plans, intervention programs in the education, health and social services sectors in order to contribute to the harmonization of planning practices. The goal is to develop the adaptive and learning potential of each individual with a disability, adjustment or learning difficulty, and thus ensure the development of an inclusive society.<\p>' +
        '<br />' +
        '<p>In this inclusive logic, Eduplan Solutions wishes to meet the diversity of community planning needs through research and the use of artificial intelligence, data and algorithms. Research and innovation at Eduplan Solutions means continuous progress in the service of education.<\p>' +
        '<br />' +
        '<p>For us, scientific research is central to everything we do and offer. Moreover, we firmly believe that innovation and excellence can only be achieved by relying on a solid base of knowledge, discoveries, recognized methodology and proven data. Our company is committed to a cutting-edge research program, led by a competent and talented team of researchers and experts in their respective fields. Concentrating our efforts in research and development, in collaboration with experts and researchers from various fields and from different parts of the world, allows us to stay up to date on the latest scientific and technological advances, to understand the changing needs of our customers, respond to emerging challenges, anticipate market trends and stay at the forefront of our industry.<\p>' +
        '<br />' +
        '<p>From the outset, we are committed to providing durable, efficient and reliable solutions. Our products and services are now the result of rigorous research, expertise and proven practices.</p>',
    title3: 'Research program',
    description3: '<p>The research program of Eduplan Solutions is based on the foundations of the research program developed and carried out within the Information and Resource Center for Plans (CIRP) (www.planintervention.com) and is structured around four lines of research:</p>' +
        '<br />' +
        '<p>Axis 1- Foundations</p>' +
        '<p>Axis 2 - Innovation</p>' +
        '<p>Axis 3 - Evaluation</p>' +
        '<p>Axis 4 – Benchmark portrait</p>' +
        '<br />' +
        '<p>It is a research posture based on:</p>' +
        '<br />' +
        '<p>1. An ecological or ecosystem approach (Bronfenbrenner, 1979; Fougeyrollas et al., 1998; Langevin and Rocque, 1995; Pabœuf, 2014; Rocque, 1999) which aims to study interactions and interrelations, and their influence on the \'ecosystem</p>' +
        '<br />' +
        '<p>2. Pedagogical Value Analysis which is a research and development method (Rocque, Langevin & Riopel, 1998; Langevin and Myara, to be published) which aims to study and develop products, processes, tools or educational services through an iterative, rigorous and systematic approach.</p>' +
        '<br />' +
        '<p>Research activities are carried out within an interdisciplinary, multidisciplinary and transdisciplinary team (law, engineering, psychology, health, education sciences, social) in partnership with the practice settings (school-family-community) and industrial settings. At the same time, it allowed us to develop our community of people under the name of Inclulab.</p>' +
        '<br />' +
        '<p>To learn more about our completed and ongoing projects, visit our website: n cours, consultez notre site web : <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
    title4: 'Methodology',
    description4: '<p>The entrepreneurial value analysis (AVE*) is the main research methodology that structures our scientific research and development approach and guides the different research methodologies used for each step of this long process.</p>' +
        '<br />' +
        '<p>AVE is an iterative and agile research and development method whose aim is to design educational or clinical innovations (products, processes or services), which take into account the needs of different users, the functions that innovations must fulfill in the aim of satisfying their needs at lower costs and with greater benefits.</p>' +
        '<br />' +
        '<p>*The AVE is an adaptation of the AVP; a method that has its origins in engineering (Langevin and Rocque, 1998)</p>' +
        '<br />' +
        '<p>The AVE consists of four major steps described below:</p>' +
        '<br />' +
        '<p>1. Preconception</p>' +
        '<br />' +
        '<p>This first step aims, from a framework of reference and field research, to answer all of these questions in order to develop solutions that are usable and able to meet the needs of each person. This is a phase that identifies all the different types of users and determines their different needs.</p>' +
        '<br />' +
        '<p>The AVE is an approach that is part of an organizational structure that highlights the dynamics of a learning company.</p>' +
        '<br />' +
        '<p>In addition, AVE has adopted, already in the pre-design phase, the Privacy by Design (Pbd) approach which takes privacy into account throughout the research and development process as provided for by the report published in 1995 by the Information and Privacy Commissioner of Ontario and Ann Cavoukian of the Dutch Data Protection Authority and the Netherlands Organization for Applied Scientific Research.</p>' +
        '<br />' +
        '<p>As well as the Accessibility by Design approach, which takes into account the needs of different users with auditory, cognitive, physical or visual limitations.</p>' +
        '<br />' +
        '<p>By maintaining collaborations with different users, Eduplan Solutions has succeeded in bringing together talents in 4 major areas that make up our communities. All the knowledge mobilized thanks to his talents is done with the aim of achieving our objectives.</p>' +
        '<br />' +
        '<p>2. Functional analysis</p>' +
        '<br />' +
        '<p>Following this analysis, a functional specification (CdCF) specifies the functionalities that the solution should fulfill.</p>' +
        '<br />' +
        '<p>In the CdCF, each of the functions are hierarchically categorized, characterized and, for some, a cost is attributed to them or a benefit is expected.</p>' +
        '<br />' +
        '<p>The CdCF is becoming an indispensable research and development tool for innovation; it serves to direct the design of the future device, product, process or service and it serves to verify its effectiveness after its design.</p>' +
        '<p><br </p>' +
        '<br />' +
        '<p>3. Design</p>' +
        '<br />' +
        '<p>From the chosen solutions follows the design of the first prototype of the future device, process, product or service; this is the innovation prototyping stage.</p>' +
        '<br />' +
        '<p>The prototyping is segmented into sections, so that we can execute and verify, with our collaborators, only one section at a time, for validation purposes and to produce a first prototype of the planned innovation.</p>' +
        '<p><br </p>' +
        '<br />' +
        '<p>4. Implementation, evaluation and dissemination</p>' +
        '<br />' +
        '<p>The testing is carried out taking into account the different components and conditions of the environment and with the planning of implementation strategies.</p>' +
        '<br />' +
        '<p>Innovation evaluation consists of evaluating: 1) the properties of the innovation deployed (the effectiveness and efficiency of the innovation; 2) the granted and perceived value of the innovation; 3) as well as the social impact of the innovation.</p>' +
        '<br />' +
        '<p>It is important to specify that our products are subject to systematic evaluation, even after distribution. This practice aims to maintain user communities, to adapt to developments, to changes and to enrich the mobilization of various knowledge, thus contributing to the development of innovation.</p>' +
        '<br />' +
        '<p>The application of this approach has led Eduplan Solutions to develop the Inclulab, a space for enriching and sharing knowledge between people from different communities.</p>' +
        '<br />' +
        '<p>To learn more about our methodology, visit our website: <a href="https://www.eduplan.ca">https://www.eduplan.ca/</a></p>',
};
