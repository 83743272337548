import React, { useEffect, useRef, useState } from 'react';
import Text from "../Field/Text";
import Functions from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import useAwayClick from "../../hooks/content/useAwayClick";
import env from "../../config/env";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
var AgeSelector = function (props) {
    var elementRef = useRef();
    var elementRefClickAway = useAwayClick(elementRef);
    var _a = useState(props.selectedItem), selectedItem = _a[0], setSelectedItem = _a[1];
    var _b = useState(false), ifclick = _b[0], setifclick = _b[1];
    useEffect(function () {
        props.ifRemoveAge ? setifclick(false) : null;
    }, [props.ifRemoveAge]);
    var getDropDownItems = function (key, title, data) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black", display: "block", onClick: function (e) {
                setSelectedItem({
                    key: key,
                    title: title,
                    data: data
                });
                setifclick(true);
                Functions.escape();
            }, key: key }, title);
    };
    var checkIfSelectedItemExits = function () {
        if (selectedItem != null) {
            var item = props.items.filter(function (item) { return selectedItem.title == item.title; });
            if (item.length === 0) {
                setSelectedItem(undefined);
            }
        }
    };
    useEffect(function () {
        if (selectedItem != null) {
            props.onChange(selectedItem);
        }
    }, [selectedItem]);
    var render = function () {
        var _a, _b;
        checkIfSelectedItemExits();
        var containerClass = "flex justify-between items-center px-3 py-2 border border-c-gray rounded relative bg-c-scroll";
        var iconClass = "flex items-center cursor-pointer";
        var dropBoxClass = "p-2 border border-c-gray rounded absolute mr-4 top-12 left-0 bg-white h-40 z-10 overflow-y-scroll " + props.width;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(containerClass, " w-full ").concat(props.width, " ").concat(props.error ? 'border-c-red' : ''), ref: elementRef },
                ifclick ? React.createElement(Text, null, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title) : React.createElement(Text, { additionalClass: "text-c-text" }, props.defaultValue),
                React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconColor }) }),
                React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(elementRefClickAway ? 'hidden' : '') }, props.items.map(function (item) { return getDropDownItems(item.key, item.title, item.data); }))),
            props.error && React.createElement(TextError, { message: (_b = (_a = props.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : '' })));
    };
    return render();
};
export default AgeSelector;
