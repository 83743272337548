import React from "react";
import FavoritIcon from "../../assets/icons/FavoritIcon";
import env from "../../config/env";
var Tag = function (props) {
    var baseClassMouseMoveOut = "text-sm font-poppins justify-center items-center flex inline mx-5 p-2 text-c-red font-light";
    var baseClassMouseMoveIn = "text-sm font-poppins justify-center items-center flex inline mx-5 p-2 text-white font-light";
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "border border-c-red rounded w-fit h-fit mr-1 my-0.5 ".concat(props.select ? 'bg-c-red' : ''), onClick: function (e) {
                if (props.onClick == null)
                    return;
                var response = props.onClick(e);
            } },
            React.createElement("a", { className: props.select ? baseClassMouseMoveIn : baseClassMouseMoveOut },
                props.select ? React.createElement(FavoritIcon, { color: env.iconColorWhite }) : React.createElement(FavoritIcon, { color: env.iconRed }),
                React.createElement("div", { className: "pl-0.5" }, props.title)))));
};
export default Tag;
