import React, { useState } from 'react';
import { CardCvcElement } from "@stripe/react-stripe-js";
import TextError from "../Field/TextError";
import clsx from "clsx";
import { validation_lang } from "../../lang/validations";
import F from "../../utils/functions";
var CardCvcNumber = function (props) {
    var _a = useState(), error = _a[0], setError = _a[1];
    var _b = useState(false), focus = _b[0], setFocus = _b[1];
    var LG = F.getLang();
    var render = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement(CardCvcElement, { onChange: function (event) {
                    if (event.error == null && event.complete) {
                        if (props.isValid)
                            props.isValid(true);
                    }
                    else {
                        if (props.isValid)
                            props.isValid(false);
                    }
                    setError({ error: event.error });
                }, className: clsx({
                    "border border-solid rounded border-c-gray  w-16 h-10 px-2 py-1": true,
                    "border-c-red": props.showErrors,
                    "border-purple": focus
                }), onFocus: function () { return setFocus(true); }, onBlur: function () { return setFocus(false); }, options: {
                    placeholder: "",
                    style: {
                        base: {
                            lineHeight: "2rem",
                            fontSize: "16px",
                            fontFamily: "poppins",
                            color: "#0D0C0C"
                        },
                    },
                } }),
            (props === null || props === void 0 ? void 0 : props.showErrors) &&
                React.createElement(TextError, { message: (_b = (_a = error === null || error === void 0 ? void 0 : error.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : validation_lang[LG].cvvNumber })));
    };
    return render();
};
export default CardCvcNumber;
