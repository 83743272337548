import React, { memo } from 'react';
import CircularLoader from "./CircularLoader";
var FullPageLoader = function (props) {
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "\n                        flex \n                        flex-col \n                        justify-center \n                        items-center \n                        h-screen \n                        w-screen \n                        bg-c-scroll \n                        fixed\n                        top-0 \n                        opacity-90\n                        z-20\n                        " },
                React.createElement(CircularLoader, null))));
    };
    return render();
};
export default memo(FullPageLoader);
