import clsx from "clsx";
import React from "react";
var LightReverseColorButtonWithIcon = function (props) {
    var _a;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : "submit";
    var render = function () {
        var _a;
        var _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: clsx((_a = {
                        "bg-c-red rounded-full text-white font-poppins text-sm px-4 py-2 border border-solid border-c-red": true
                    },
                    _a[(_b = props.additionalClass) !== null && _b !== void 0 ? _b : ''] = true,
                    _a)) },
                React.createElement("div", { className: "flex inline" },
                    React.createElement("div", { className: "mr-2" }, props.icon),
                    React.createElement("div", { className: "mt-0.5" }, props.title)))));
    };
    return render();
};
export default LightReverseColorButtonWithIcon;
