import { useDispatch } from "react-redux";
import { updateLanguage } from "../../feature/language";
import { useFullPageLoader } from "./useFullPageLoader";
export function useUpdateLanguage() {
    var dispatch = useDispatch();
    var fullPageLoader = useFullPageLoader();
    var useUpdateLanguage = function (language) {
        fullPageLoader(true);
        dispatch(updateLanguage(language));
        window.setTimeout(function () { return fullPageLoader(false); }, 1000);
    };
    return useUpdateLanguage;
}
