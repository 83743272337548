import clsx from "clsx";
import React from "react";
var ModalInClientPage = function (props) {
    var render = function () {
        var _a, _b;
        var _c, _d, _e, _f;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: clsx((_a = {
                        "w-full min-h-screen flex justify-center": true
                    },
                    _a[(_c = props.backgroundCls) !== null && _c !== void 0 ? _c : 'bg-c-background'] = true,
                    _a)) },
                React.createElement("div", { className: clsx((_b = {
                            "bg-white rounded relative": true
                        },
                        _b[(_d = props.layoutClass) !== null && _d !== void 0 ? _d : 'h-fit mt-20 p-5'] = true,
                        _b[(_e = props.additionalCls) !== null && _e !== void 0 ? _e : ''] = true,
                        _b[(_f = props.width) !== null && _f !== void 0 ? _f : 'w-4/5'] = true,
                        _b)) }, props.children))));
    };
    return render();
};
export default ModalInClientPage;
