import React from "react";
import MasterCardIcon from "../../assets/icons/banks/MasterCardIcon";
import VisaIcon from "../../assets/icons/banks/VisaIcon";
import AmericanExpressIcon from "../../assets/icons/banks/AmericanExpressIcon";
var BaseCreditCard = function (props) {
    var encode = function (input) {
        return "**** **** **** " + (input === null || input === void 0 ? void 0 : input.slice(input.length - 4));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container inline-flex border rounded-lg w-72 h-12 shadow-md my-1 mr-4 hover:bg-c-scroll " + props.additionalClass },
            React.createElement("div", { className: "h-10 pt-0.5 mt-0.5 pl-4 min-w-fit ml-3" }, props.company === "master" ? React.createElement(MasterCardIcon, { color: "bg-black" }) : props.company === "visa" ?
                React.createElement(VisaIcon, { color: "bg-black" }) : React.createElement(AmericanExpressIcon, { color: "bg-black" })),
            React.createElement("div", { className: "ml-3 border-l border-black h-8 mt-2 pl-2.5 pt-1 min-w-fit" }, encode(props.code)),
            React.createElement("div", { className: "w-full justify-end flex" },
                React.createElement("div", { className: "mr-2 self-center" }, props.div)))));
};
export default BaseCreditCard;
