import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _searchClients = function (data) {
    return Api.post(data, "".concat(env.api.searchClients));
};
var _getClient = function (data) {
    return Api.post(data, "".concat(env.api.getClient));
};
var _searchQuestionnaires = function (data) {
    return Api.post(data, "".concat(env.api.searchQuestionnaires));
};
var _searchTitleAndName = function (data) {
    return Api.post(data, "".concat(env.api.searchTitleAndName));
};
var _getClientProfile = function (data) {
    return Api.post(data, "".concat(env.api.getClientProfile));
};
export function useQueryClients() {
    var searchClients = useMutation(function (data) { return _searchClients(data); });
    var getClient = useMutation(function (data) { return _getClient(data); });
    var searchQuestionnaires = useMutation(function (data) { return _searchQuestionnaires(data); });
    var searchTitleAndName = useMutation(function (data) { return _searchTitleAndName(data); });
    var getClientProfile = useMutation(function (data) { return _getClientProfile(data); });
    return { searchClients: searchClients, getClient: getClient, searchQuestionnaires: searchQuestionnaires, searchTitleAndName: searchTitleAndName, getClientProfile: getClientProfile };
}
