import React from "react";
import BaseTitle from "../../components/Title/BaseTitle";
import { lang } from "../../lang/lang";
import LightUnderlineText from "../../components/Field/LightUnderlineText";
import Functions from "../../utils/functions";
import BaseText from "../../components/Title/BaseText";
var UserGuidePage = function (props) {
    var LG = Functions.getLang();
    var ClassMargin = props.introDisable === true ? "mt-1" : "mt-10";
    var ClassPadding = props.introDisable === true ? "p-2 px-4" : "p-5";
    var ClassWidth = props.introDisable === true ? "w-full" : " w-8/12";
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ClassPadding },
            React.createElement("div", { className: "container  flex inline flex w-full" },
                React.createElement("div", { className: "container flex justify-start" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", { className: "mt-1" },
                            React.createElement(BaseTitle, { additionalClass: "text-base leading-6" }, props === null || props === void 0 ? void 0 : props.category)),
                        React.createElement("div", { className: "mt-1" },
                            React.createElement(BaseText, { additionalClass: "font-semibold" }, props.questionnaireTitle)),
                        React.createElement("div", { className: "mt-3" }, forText(lang[LG].answerQuestionnaire.introductionPageLegal.for, props === null || props === void 0 ? void 0 : props.clientName)))),
                props.introDisable === true ? "" : introductionText(lang[LG].answerQuestionnaire.introductionPageLegal.introduction)),
            React.createElement("div", { className: "mt-6 ".concat(ClassMargin) },
                React.createElement(BaseTitle, { additionalClass: "text-base" }, props.title)),
            React.createElement("div", { className: "mt-4 ".concat(ClassWidth) },
                React.createElement(LightUnderlineText, { additionalClass: 'font-poppins inline-block text-sm text-left font-light leading-5 text-purple text-left' }, props.description)),
            React.createElement("div", { className: 'grid grid-cols-6' },
                React.createElement("div", { className: 'col-span-1' }),
                React.createElement("div", { className: 'col-span-4' },
                    React.createElement("div", { className: "mt-6 w-full shadow-2xl " },
                        React.createElement("img", { src: props.imageLink, alt: props.alt }))),
                React.createElement("div", { className: 'col-span-1' })))));
};
var introductionText = function (introTitle) {
    return (React.createElement("div", { className: "container flex justify-end w-1/3" },
        React.createElement("div", { className: "w-fit" },
            React.createElement("div", { className: "flex justify-end" },
                React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, introTitle)),
            React.createElement("div", { className: "inline-flex justify-end my-2" },
                React.createElement("div", { className: "h-2 w-66" },
                    React.createElement("div", { className: "w-full h-full bg-c-purple-500 rounded-full" }))))));
};
var forText = function (fortext, clientName) {
    return (React.createElement("div", { className: "-mt-1 " },
        React.createElement(BaseText, { additionalClass: "text-sm" },
            fortext,
            clientName)));
};
export default UserGuidePage;
