import React from "react";
var IconButton = function (props) {
    var _a, _b;
    var styleForTitleBtn = "rounded-2xl px-3 py-1 bg-white";
    if (props.title === '') {
        styleForTitleBtn = "p-2 rounded-full";
    }
    var disableCss = props.disabled ? 'cursor-not-allowed' : '';
    var border = (_a = props.border) !== null && _a !== void 0 ? _a : 'border-c-purple';
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { disabled: props.disabled, onClick: props.onClick, className: "".concat(styleForTitleBtn, " text-c-purple font-poppins border border-solid ").concat(border, " ").concat(disableCss, " ").concat((_b = props.additionalClass) !== null && _b !== void 0 ? _b : ''), title: props.alias },
            React.createElement("div", { className: "container flex inline justify-center" },
                props.icon,
                props.title !== '' &&
                    React.createElement("a", { className: "text-base mx-2" }, props.title)))));
};
export default IconButton;
