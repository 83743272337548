var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
var AbsApi = /** @class */ (function () {
    function AbsApi(token, lang) {
        AbsApi.token = token;
        AbsApi.lang = lang;
    }
    AbsApi.token = "";
    return AbsApi;
}());
export { AbsApi };
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api(token, lang) {
        return _super.call(this, token, lang) || this;
    }
    Api.get = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'GET',
                    headers: this.getHeaders(),
                    url: url,
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.post = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'POST',
                    data: data,
                    url: url,
                    headers: this.getHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.postD = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'POST',
                    data: data,
                    url: url,
                    headers: this.getFileHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.getD = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'GET',
                    params: data,
                    url: url,
                    headers: this.getHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.put = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'PUT',
                    data: data,
                    url: url,
                    headers: this.getHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.delete = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'DELETE',
                    data: data,
                    url: url,
                    headers: this.getHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.blobP = function (data, url) {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                config = {
                    method: 'POST',
                    data: data,
                    url: url,
                    responseType: 'blob',
                    headers: this.getHeaders(),
                    validateStatus: function () {
                        return true;
                    },
                };
                return [2 /*return*/, this.call(config)];
            });
        });
    };
    Api.call = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, Axios.request(config)];
                }
                catch (err) {
                    console.log('this.props err', err);
                }
                return [2 /*return*/];
            });
        });
    };
    Api.initiate = function (token, lang) {
        Api.instance = new Api(token, lang);
    };
    Api.getHeaders = function () {
        return {
            Authorization: "Bearer ".concat(Api.token),
            'Access-Control-Allow-Origin': '*',
            'Lang': Api.lang,
        };
    };
    Api.getFileHeaders = function () {
        return {
            Authorization: "Bearer ".concat(Api.token),
            'Access-Control-Allow-Origin': '*',
            'Lang': Api.lang,
            //"Content-Length": '1000000',
            "Content-Type": "multipart/form-data",
            "Accept": '*/*',
        };
    };
    return Api;
}(AbsApi));
export { Api };
