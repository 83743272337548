import { Api } from "../../classes/api";
import env from "../../config/env";
import { useQuery } from "react-query";
var _getInfo = function (data) {
    return Api.getD(data, env.api.questionnaireInfo);
};
var KEY = 'QUESTIONNAIRE-INFO';
export default function useQuestionnaireInfo(data) {
    var getQuestionnaireInfo = useQuery(KEY, function () { return _getInfo(data); }, {
        refetchOnWindowFocus: false,
        cacheTime: 0
    });
    return { getQuestionnaireInfo: getQuestionnaireInfo };
}
