var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from "react";
import EdproIcon from "../../assets/icons/EdproIcon";
import env from "../../config/env";
import Text from "../../components/Field/Text";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
import SubmitButton from "../../components/Button/SubmitButton";
import { useNavigate } from "react-router-dom";
import RegisterForm from "../../components/Forms/RegisterForm";
import { useSelector } from "react-redux";
import DownArrow from "../../assets/icons/DownArrow";
import AssessmentTool from "../../components/AssessmentTool/AssessmentTool";
import Href from "../../components/Href/Href";
import { useUpdateLanguage } from "../../hooks/miscellaneous/useUpdateLanguage";
import Functions from "../../utils/functions";
import functions from "../../utils/functions";
import LightDropDown from "../../components/DropDown/LightDropDown";
var LandingPage = function (props) {
    var LG = F.getLang();
    var _a = useState(false), flag = _a[0], setFlag = _a[1];
    var navigate = useNavigate();
    var gotoLogin = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(env.routes.login);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var userSignUpForm = useSelector(function (store) { return store.userSignUp.value; });
    var updateLanguage = useUpdateLanguage();
    var checkLang = function (lang) {
        if (flag) {
            updateLanguage(lang);
        }
    };
    var gotoUrl = useCallback(function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(path);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var _b = useState(0), tutorialCount = _b[0], setTutorialCount = _b[1];
    var updateTutorial = function (num) {
        setTutorialCount(0);
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "bg-home-bg1 bg-cover h-200 w-3/5 flex flex-col" },
                        React.createElement("div", { className: "py-8 px-12 flex space-x-4 justify-between" },
                            React.createElement("div", { className: "" },
                                React.createElement(EdproIcon, { color: env.iconColorWhite, onClick: function () { return gotoUrl(env.routes.home); } })),
                            React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline", title: lang[LG].aboutUs, onClick: function () { return gotoUrl(env.routes.aboutUs); } }),
                            React.createElement(LightDropDown, { additionalClassTitle: "pl-3 focus:text-white text-white", items: functions.getPoliticsItems(LG), height: "h-fit", placeHolder: lang[LG].politics, iconColor: env.iconGrey, onChange: function (e) {
                                    var url = "".concat(Functions.removeParams(env.routes.politics)) + e.data;
                                    gotoUrl(url);
                                } }),
                            React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline", title: lang[LG].helpCenter, onClick: function () { return gotoUrl(env.routes.helpCenter); } }),
                            React.createElement(Href, { className: "text-c-white cursor-pointer hover:underline", title: lang[LG].contactUsTitle, onClick: function () { return gotoUrl(env.routes.contactUs); } })),
                        React.createElement("div", { className: "flex flex-col items-center mt-20" },
                            React.createElement("div", { className: "flex flex-col space-y-8" },
                                React.createElement("div", null,
                                    React.createElement(Text, { additionalClass: "text-c-white text-5xl leading-14 w-128 font-medium" }, lang[LG].simplifyAssessmentTool)),
                                React.createElement("div", null,
                                    React.createElement(Text, { additionalClass: "text-c-white w-128 text-xl" }, lang[LG].bannerContent)),
                                React.createElement("div", { className: "w-112 bg-c-purple-light rounded-lg p-6 self-start" },
                                    React.createElement(Text, { additionalClass: "text-c-white text-xl" }, lang[LG].visitUs)))),
                        React.createElement("div", { className: "flex flex-grow justify-center items-end" },
                            React.createElement("div", { className: "flex flex-col items-center" },
                                React.createElement(Text, { additionalClass: "text-white text-xl" }, lang[LG].learnMore),
                                React.createElement(DownArrow, { color: env.iconColorWhite })))),
                    React.createElement("div", { className: "w-2/5 flex flex-col" },
                        React.createElement("div", { className: "flex justify-end px-10 py-5" },
                            React.createElement(LightDropDown, { additionalClassTitle: "py-3 pl-3 focus:text-c-grey text-c-grey ", title: '', items: functions.getLangItems(LG), selectedItem: Functions.getLangSelectedItems(LG), iconColor: env.iconGrey, onChange: function (e) {
                                    updateLanguage(e.data);
                                    /*checkLang(LG === "en" ? "fr" : "en");
                                    setFlag(true)*/
                                } }),
                            React.createElement(SubmitButton, { id: "login-btn", title: lang[LG].login, additionalClass: "w-fit ml-5", onClick: gotoLogin })),
                        React.createElement("div", { className: "p-10" },
                            React.createElement(RegisterForm, { defaultValues: userSignUpForm, onSuccess: function () {
                                    var url = F.createUrl(env.routes.paymentSignUpParam, 'home');
                                    navigate(url);
                                }, homePage: true, showLoginBtn: true })))),
                React.createElement("div", { className: "flex justify-center" },
                    React.createElement(Text, { additionalClass: "mt-10", id: "assessment-tools", fontSize: "text-3xl", isbold: true }, lang[LG].exploreAssessmentTools)),
                React.createElement("div", { className: "p-10 mx-20" },
                    React.createElement(AssessmentTool, { hideMyCollection: true, updateTutorial: updateTutorial })),
                React.createElement("div", { className: "bg-center bg-cover bg-home-bg3 flex flex-col h-96 items-center justify-center space-y-5 p-10 mt-10" },
                    React.createElement(Text, { additionalClass: "text-c-3xl text-white", isbold: true }, lang[LG].edproMakesEasier),
                    React.createElement(Text, { additionalClass: "text-white" }, lang[LG].edproEasierContent),
                    React.createElement("a", { href: "#login-btn", className: "bg-white font-poppins px-5 py-2 rounded-full text-c-purple" }, lang[LG].createYourAccount)),
                React.createElement("div", { className: "flex justify-center" },
                    React.createElement(Text, { additionalClass: "mt-10", id: "features", fontSize: "text-3xl", isbold: true }, lang[LG].edproAdvantages)),
                React.createElement("div", { className: "grid grid-cols-2 gap-y-16 mt-14" },
                    React.createElement("div", { className: "flex flex-col items-center justify-center" },
                        React.createElement("div", { className: "w-112" },
                            React.createElement(Text, { additionalClass: "text-c-3xl text-c-purple", fontSize: "text-xl", isbold: true }, lang[LG].createACustomerList),
                            React.createElement(Text, { additionalClass: "mt-5" }, lang[LG].clientListDetail))),
                    React.createElement("div", { className: "" },
                        React.createElement("img", { src: "/images/questionnaire.png", alt: "questionnaire" })),
                    React.createElement("div", { className: "" },
                        React.createElement("img", { src: "/images/students.png", alt: "dashboard" })),
                    React.createElement("div", { className: "flex flex-col items-center justify-center" },
                        React.createElement("div", { className: "w-112" },
                            React.createElement(Text, { additionalClass: "text-c-3xl text-c-purple", fontSize: "text-xl", isbold: true }, lang[LG].sendQuestionnaireToEmployees),
                            React.createElement(Text, { additionalClass: "mt-5" }, lang[LG].quesToEmpDetail))),
                    React.createElement("div", { className: "flex flex-col items-center justify-center" },
                        React.createElement("div", { className: "w-112" },
                            React.createElement(Text, { additionalClass: " text-c-purple", fontSize: "text-c-3xl", isbold: true }, lang[LG].generateDetailReport),
                            React.createElement(Text, { additionalClass: "mt-5", fontSize: "text-sm" }, lang[LG].generateReportDetail))),
                    React.createElement("div", { className: "" },
                        React.createElement("img", { src: "/images/reports.png", alt: "reporting table design" }))),
                React.createElement("div", { className: "bg-home-bg2 h-168 flex flex-col bg-cover bg-center justify-center", id: "about-us" },
                    React.createElement("div", { className: "flex flex-col pt-20 space-y-5 ml-5 w-128 lg:ml-5 lg:w-144 xl:ml-20 xl:w-176" },
                        React.createElement(Text, { additionalClass: "text-white text-c-3xl", isbold: true }, lang[LG].eduplanProduct),
                        React.createElement(Text, { additionalClass: "text-white text-xl" }, lang[LG].eduplanDetail),
                        React.createElement(Text, { additionalClass: "text-white italic text-xl text-right" }, lang[LG].ceo)),
                    React.createElement("div", { className: "flex flex-grow justify-center mt-20" },
                        React.createElement("a", { href: "https://eduplan.ca/", target: '_blank' },
                            React.createElement("img", { src: "images/eduplan-logo-white.png", className: "w-48 h-14 cursor-pointer", alt: "Eduplan" })))))));
    };
    return render();
};
export default LandingPage;
