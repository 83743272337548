import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _post = function (data) {
    return Api.post(data, env.api.contactInformation);
};
export default function useMutationContact() {
    var postContact = useMutation(function (data) { return _post(data); });
    return { postContact: postContact };
}
