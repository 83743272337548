import React, { useEffect, useRef, useState } from 'react';
import Text from "../Field/Text";
import Functions from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import useAwayClick from "../../hooks/content/useAwayClick";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
import env from "../../config/env";
var DropDownArray = function (props) {
    var elementRef = useRef(null);
    var elementRefClickAway = useAwayClick(elementRef);
    var _a = useState(props.selectedItem), selectedItem = _a[0], setSelectedItem = _a[1];
    var getDropDownItems = function (title, index) {
        return React.createElement(Text, { additionalClass: "cursor-pointer text-black px-2 py-1 hover:bg-c-scroll", display: "block", key: index, onClick: function (e) {
                setSelectedItem(title);
                Functions.escape();
            } }, title);
    };
    var checkIfSelectedItemExits = function () {
        if (selectedItem != null) {
            var item = props.items.filter(function (item) { return selectedItem == item; });
            if (item.length === 0) {
                setSelectedItem(undefined);
            }
        }
    };
    // if dropdown open
    var ifDDOpen = function () {
        return !elementRefClickAway;
    };
    useEffect(function () {
        if (selectedItem != null) {
            props.onChange(selectedItem);
        }
    }, [selectedItem]);
    var render = function () {
        var _a, _b, _c;
        checkIfSelectedItemExits();
        var containerClass = "flex justify-between items-center px-3 border ".concat(ifDDOpen() ? 'border-c-purple' : 'border-c-gray', " rounded relative bg-c-scroll");
        var iconClass = "flex items-center cursor-pointer";
        var dropBoxClass = "border border-c-gray rounded absolute mr-4 top-12 left-0 bg-white w-full h-36 z-10 overflow-y-scroll";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), key: 'd-' + (props === null || props === void 0 ? void 0 : props.ikey) },
                React.createElement("div", { className: "".concat(containerClass, " w-full h-10 ").concat(props.error ? 'border-c-red' : '', " ").concat(props.disabled ? 'bg-c-scroll hover:cursor-not-allowed' : ''), ref: elementRef },
                    React.createElement(Text, { additionalClass: "truncate", fontWeight: "font-extralight" }, selectedItem !== null && selectedItem !== void 0 ? selectedItem : props === null || props === void 0 ? void 0 : props.placeHolder),
                    React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconGrey, additionalClass: ifDDOpen() ? 'rotate-180' : '' }) }),
                    React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(elementRefClickAway || props.disabled ? 'hidden' : '') }, props.items.map(function (item, index) { return getDropDownItems(item, index); }))),
                props.error && React.createElement(TextError, { addtionalClass: 'text-xs', message: (_c = (_b = props.error) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : '' }))));
    };
    return render();
};
export default DropDownArray;
