import React from "react";
var InfoIcon = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() : '24';
        var width = props.width ? props.width.toString() : '24';
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 ".concat(height, " ").concat(width), fill: props.color, onClick: props.onClick, height: "".concat(height, "px"), width: "".concat(width, "px"), className: "".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', " z-2") },
            React.createElement("path", { "fill-rule": "evenodd", d: "M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z", "clip-rule": "evenodd" })));
    };
    return render();
};
export default InfoIcon;
