import React from "react";
var VisibilityOn = function (props) {
    var _a;
    var height = props.height ? props.height.toString() + 'px' : '24px';
    var width = props.width ? props.width.toString() + 'px' : '24px';
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { onClick: props.onClick, className: "".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", height: height, viewBox: "0 0 24 24", width: width, fill: props.color },
            React.createElement("path", { d: "M0 0h24v24H0V0z", fill: "none" }),
            React.createElement("path", { d: "M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" }))));
};
export default VisibilityOn;
