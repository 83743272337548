import React from 'react';
var Arrow = function (props) {
    var render = function () {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "18", height: "12", viewBox: "0 0 18 12" },
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip-Back_arrow" },
                    React.createElement("rect", { width: "18", height: "12" }))),
            React.createElement("g", { id: "Back_arrow", clipPath: "url(#clip-Back_arrow)" },
                React.createElement("g", { id: "Group_1225", transform: "translate(16.462 1) rotate(90)" },
                    React.createElement("line", { id: "Line_192", y2: "15.462", transform: "translate(5.001 0)", fill: "none", stroke: "#6C0C6C", strokeLinecap: "round", strokeWidth: "1" }),
                    React.createElement("line", { id: "Line_193", x1: "5.001", y1: "5.323", transform: "translate(0 10.14)", fill: "none", stroke: "#6C0C6C", strokeLinecap: "round", strokeWidth: "1" }),
                    React.createElement("line", { id: "Line_194", y1: "5.323", x2: "5.001", transform: "translate(5.001 10.14)", fill: "none", stroke: "#6C0C6C", strokeLinecap: "round", strokeWidth: "1" })))));
    };
    return render();
};
export default Arrow;
