import React from "react";
var TextError = function (props) {
    var fontSize = props.extraSmall ? 'text-xs' : 'text-sm';
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins ".concat(fontSize, " text-c-red ").concat(props.addtionalClass), dangerouslySetInnerHTML: { __html: props.message } })));
    };
    return render();
};
export default TextError;
