var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createElement, memo } from "react";
import Functions from "../../utils/functions";
var TextComponent = function (props) {
    var tempProps = __assign({}, props);
    Functions.removeKeys(tempProps, [
        'hasActions',
        'actionClasses',
        'actionOnClicks',
        'actionReplacements',
        'title'
    ]);
    var classicRenderer = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", __assign({}, tempProps, { className: "font-poppins ".concat((_a = props.className) !== null && _a !== void 0 ? _a : '') }), props.title)));
    };
    var actionRenderer = function () {
        var _a, _b, _c;
        var title = (_a = props.title) !== null && _a !== void 0 ? _a : '';
        var multipleContent = title.split('{ACTION}');
        var lastIndex = multipleContent.length - 1;
        if (props.dangerouslySetInnerHTML != null) {
            var content = multipleContent.join('');
            return (React.createElement(React.Fragment, null,
                React.createElement("p", __assign({}, tempProps, { className: "font-poppins ".concat((_b = props.className) !== null && _b !== void 0 ? _b : ''), dangerouslySetInnerHTML: { __html: content } }))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("p", __assign({}, tempProps, { className: "font-poppins ".concat((_c = props.className) !== null && _c !== void 0 ? _c : '') }), multipleContent.map(function (content, i) {
                return React.createElement("span", { key: i },
                    content,
                    i !== lastIndex &&
                        createElement('span', {
                            onClick: props === null || props === void 0 ? void 0 : props.actionOnClicks[i],
                            className: props === null || props === void 0 ? void 0 : props.actionClasses[i],
                        }, props === null || props === void 0 ? void 0 : props.actionReplacements[i]));
            }))));
    };
    var render = function () {
        return props.hasActions ? actionRenderer() : classicRenderer();
    };
    return render();
};
export default memo(TextComponent);
