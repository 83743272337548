var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../classes/api";
import axios from "axios";
import env from "../config/env";
import { saveLoader } from "./loader";
import { updateError } from "./questionnaire";
var initialValue = {
    currentSection: 0,
    totalSections: 0,
    sections: [],
    answers: {},
    saved: false,
    savedAndQuit: false,
    sectionId: 0,
    subSectionId: 0,
    progress: 0
};
export var registerQuestionnaireSlice = createSlice({
    name: "anaQuestionnaire",
    initialState: initialValue,
    reducers: {
        updateQuestionnaire: function (state, action) {
            state = action.payload;
            return state;
        },
        updateProgress: function (state, action) {
            var progress = action.payload.progress;
            state.progress = progress;
            return state;
        },
        updateCurrentPage: function (state, action) {
            var _a = action.payload, index = _a.index, currentPage = _a.currentPage;
            var section = state.sections[index];
            section.currentPage = currentPage;
            state.sections[index] = section;
            return state;
        },
        updateAnswerText: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, answer = _a.answer, subSectionId = _a.subSectionId;
            var ans = state.answers[questionId];
            if (ans != null) {
                ans.answer = answer;
                ans.subSectionId = subSectionId;
            }
            else {
                ans = __assign(__assign({}, action.payload), { answer: answer });
            }
            state.answers[questionId] = ans;
            return state;
        },
        updateAnswerArray: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, multitext = _a.multitext, subSectionId = _a.subSectionId;
            var ans = state.answers[questionId];
            if (ans != null) {
                ans.multitext = multitext;
                ans.subSectionId = subSectionId;
            }
            else {
                ans = __assign(__assign({}, action.payload), { multitext: multitext });
            }
            state.answers[questionId] = ans;
            return state;
        },
        updateDateArray: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, dates = _a.dates, subSectionId = _a.subSectionId;
            var ans = state.answers[questionId];
            if (ans != null) {
                ans.dates = dates;
                ans.subSectionId = subSectionId;
            }
            else {
                ans = __assign(__assign({}, action.payload), { dates: dates });
            }
            state.answers[questionId] = ans;
            return state;
        },
        updateAnswerActivity: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, activity = _a.activity, subSectionId = _a.subSectionId;
            var answer = state.answers[questionId];
            if (answer != null) {
                answer.activity = activity;
                answer.subSectionId = subSectionId;
            }
            else {
                answer = __assign({}, action.payload);
            }
            state.answers[questionId] = answer;
            return state;
        },
        updateFileAns: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, file = _a.file, subSectionId = _a.subSectionId;
            console.log(file);
            var answer = state.answers[questionId];
            if (answer != null) {
                answer.file = file;
                answer.subSectionId = subSectionId;
            }
            else {
                answer = __assign({}, action.payload);
            }
            state.answers[questionId] = answer;
            return state;
        },
        questionnaireSaved: function (state, action) {
            state.saved = action.payload.saved;
            state.savedAndQuit = action.payload.savedAndQuit;
            return state;
        },
    }
});
export var updateQuestionnaire = (_a = registerQuestionnaireSlice.actions, _a.updateQuestionnaire), updateCurrentPage = _a.updateCurrentPage, updateAnswerText = _a.updateAnswerText, updateAnswerArray = _a.updateAnswerArray, updateDateArray = _a.updateDateArray, updateAnswerActivity = _a.updateAnswerActivity, updateFileAns = _a.updateFileAns, updateProgress = _a.updateProgress, questionnaireSaved = _a.questionnaireSaved;
export function saveAnswer(params) {
    return function (dispatch, getState) {
        return __awaiter(this, void 0, void 0, function () {
            var hashedId, saved, savedAndQuit, submit, store, answers, payload, errorOccurred, qid, _a, assistance, activity, sectionId, subSectionId, answer, multitext, dates, file, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        hashedId = params.hashedId, saved = params.saved, savedAndQuit = params.savedAndQuit, submit = params.submit;
                        store = getState();
                        answers = store.anaQuestionnaire.answers;
                        payload = {
                            answers: {},
                            hashedId: hashedId,
                            submit: submit
                        };
                        errorOccurred = true;
                        for (qid in answers) {
                            _a = answers[qid], assistance = _a.assistance, activity = _a.activity, sectionId = _a.sectionId, subSectionId = _a.subSectionId, answer = _a.answer, multitext = _a.multitext, dates = _a.dates, file = _a.file;
                            payload.answers[qid] = {
                                activity: activity,
                                assistance: assistance,
                                sectionId: sectionId,
                                subSectionId: subSectionId,
                                answer: answer,
                                multitext: multitext,
                                dates: dates,
                                file: file
                            };
                        }
                        dispatch(saveLoader({
                            fullPage: true,
                        }));
                        return [4 /*yield*/, Api.post(payload, env.api.updateAnswers)];
                    case 1:
                        response = _b.sent();
                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                            dispatch(questionnaireSaved({
                                saved: saved,
                                savedAndQuit: savedAndQuit,
                            }));
                        }
                        else {
                            dispatch(updateError({
                                error: response
                            }));
                            errorOccurred = false;
                        }
                        dispatch(saveLoader({
                            fullPage: false,
                        }));
                        return [2 /*return*/, errorOccurred];
                }
            });
        });
    };
}
export function fetchQuestionnaire(payload) {
    return function (dispatch, getState) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var store, response, responseAnswers, stateAnswersContainer, qid, questions, noOfPages, sectionNumber, currentPage, findQuestionIndex, counter_1, subsectionId_1, sections, value;
            var _l;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        store = getState();
                        return [4 /*yield*/, Api.getD(__assign({}, payload), env.api.loadSectionQuestion)];
                    case 1:
                        response = _m.sent();
                        responseAnswers = JSON.parse((_a = response === null || response === void 0 ? void 0 : response.data.answers) !== null && _a !== void 0 ? _a : "{}");
                        stateAnswersContainer = {};
                        for (qid in responseAnswers) {
                            stateAnswersContainer[qid] = __assign(__assign({}, responseAnswers[qid]), { questionId: +qid });
                        }
                        questions = (_b = response === null || response === void 0 ? void 0 : response.data.questions) !== null && _b !== void 0 ? _b : [];
                        noOfPages = (_c = response === null || response === void 0 ? void 0 : response.data.noOfPages) !== null && _c !== void 0 ? _c : 1;
                        sectionNumber = (_d = response === null || response === void 0 ? void 0 : response.data.currentSection) !== null && _d !== void 0 ? _d : 0;
                        sectionNumber = +sectionNumber + 1;
                        currentPage = 1;
                        if ((_e = response === null || response === void 0 ? void 0 : response.data.lastAnswer) === null || _e === void 0 ? void 0 : _e.questionId) {
                            findQuestionIndex = questions.findIndex(function (q) { return q.questionId === response.data.lastAnswer.questionId; });
                            if (findQuestionIndex !== -1 && typeof payload.action === "undefined") {
                                currentPage = noOfPages;
                                counter_1 = 0;
                                subsectionId_1 = 0;
                                questions.map(function (question, index) {
                                    if (question.subSectionId != subsectionId_1) {
                                        counter_1 = counter_1 + 1;
                                        subsectionId_1 = question.subSectionId;
                                    }
                                    if (question.questionId == response.data.lastAnswer.questionId) {
                                        currentPage = counter_1;
                                        return;
                                    }
                                });
                            }
                            else if (payload.action === 'backward') {
                                currentPage = noOfPages;
                            }
                            else if (payload.action === 'forward') {
                                currentPage = 1;
                            }
                            currentPage = currentPage === 0 ? 1 : currentPage;
                        }
                        sections = {
                            index: sectionNumber,
                            questions: questions,
                            isCompleted: false,
                            sectionName: (_f = response === null || response === void 0 ? void 0 : response.data.sectionName) !== null && _f !== void 0 ? _f : '',
                            sectionDesc: (_g = response === null || response === void 0 ? void 0 : response.data.sectionDesc) !== null && _g !== void 0 ? _g : '',
                            subSectionName: '',
                            noOfPages: noOfPages,
                            currentPage: currentPage,
                            sectionId: (_h = response === null || response === void 0 ? void 0 : response.data.sectionId) !== null && _h !== void 0 ? _h : 0,
                        };
                        value = {
                            currentSection: sectionNumber,
                            totalSections: (_j = response === null || response === void 0 ? void 0 : response.data.totalSections) !== null && _j !== void 0 ? _j : 0,
                            sections: __assign(__assign({}, store.questionnaire.sections), (_l = {}, _l[sectionNumber] = sections, _l)),
                            answers: Object.keys(stateAnswersContainer).length > 0 ? stateAnswersContainer : store.anaQuestionnaire.answers,
                            saved: store.questionnaire.saved,
                            savedAndQuit: store.questionnaire.savedAndQuit,
                            sectionId: (_k = response === null || response === void 0 ? void 0 : response.data.sectionId) !== null && _k !== void 0 ? _k : 0,
                        };
                        dispatch(updateQuestionnaire(value));
                        return [2 /*return*/];
                }
            });
        });
    };
}
export function uploadFile(question, payload, sectionId) {
    return function (dispatch, getState) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var store, questionId, subSectionId, formData, response, file;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        store = getState();
                        questionId = question.questionId, subSectionId = question.subSectionId;
                        formData = new FormData();
                        formData.append("file", payload.file);
                        return [4 /*yield*/, axios.post(env.api.uploadFile, formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                                onUploadProgress: function (data) {
                                    var tyme = Math.round((100 * data.loaded) / data.total);
                                    var file = {
                                        displayName: '',
                                        filePath: '',
                                        error: 'progress'
                                    };
                                    dispatch(updateFileAns({
                                        questionId: questionId,
                                        subSectionId: subSectionId,
                                        file: file,
                                        sectionId: sectionId
                                    }));
                                    dispatch(updateProgress({
                                        progress: tyme
                                    }));
                                },
                            })
                            /*const response = await Api.postD<any, any>(formData, env.api.uploadFile);*/
                        ];
                    case 1:
                        response = _e.sent();
                        file = {
                            displayName: (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : '',
                            filePath: (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.storageName) !== null && _d !== void 0 ? _d : '',
                            error: 'false'
                        };
                        dispatch(updateFileAns({
                            questionId: questionId,
                            subSectionId: subSectionId,
                            file: file,
                            sectionId: sectionId
                        }));
                        return [2 /*return*/];
                }
            });
        });
    };
}
export default registerQuestionnaireSlice.reducer;
