import React from "react";
import { lang } from "../../lang/lang";
import Functions from "../../utils/functions";
var SectionBar = function (props) {
    var LG = Functions.getLang();
    var getSectionNumbering = function () {
        return lang[LG].answerQuestionnaire.topRight.one
            .replace('{0}', props.currentSection.toString())
            .replace('{1}', props.totalSections.toString())
            .replace('{2}', props.sectionName);
    };
    var getPageNumbering = function () {
        return lang[LG].answerQuestionnaire.topRight.two
            .replace('{0}', props.currentPage.toString())
            .replace('{1}', props.totalPages.toString());
    };
    var render = function () {
        var tSections = props.totalSections === 0 ? 1 : props.totalSections;
        var sectionWidth = 100.00 / tSections * 1.00;
        var pageWidth = sectionWidth / props.totalPages;
        var result = (props.currentSection - 1) * sectionWidth + (props.currentPage - 1) * pageWidth;
        if (props.isCompleted)
            result = 100;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                (props.isCompleted || props.savedAndQuit) && React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, lang[LG].answerQuestionnaire.page3.rightTop),
                !(props.isCompleted || props.savedAndQuit) && React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, getSectionNumbering()),
                React.createElement("div", { className: "w-full bg-c-purple-500 rounded-full h-2.5 my-2" },
                    React.createElement("div", { className: "bg-c-purple h-full rounded-full", style: {
                            width: "".concat(result, "%")
                        } })),
                !props.isCompleted && React.createElement("div", null,
                    React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, getPageNumbering())))));
    };
    return render();
};
export default SectionBar;
