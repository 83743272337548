var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from "react";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ContactForm from "../../components/Forms/ContactForm";
import { useUpdateLanguage } from "../../hooks/miscellaneous/useUpdateLanguage";
import Navigation from "./Navigation";
var ContactUs = function () {
    var LG = F.getLang();
    var _a = useState(false), flag = _a[0], setFlag = _a[1];
    var navigate = useNavigate();
    var gotoUrl = useCallback(function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(path);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var userSignUpForm = useSelector(function (store) { return store.userSignUp.value; });
    var updateLanguage = useUpdateLanguage();
    var checkLang = function (lang) {
        if (flag) {
            updateLanguage(lang);
        }
    };
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                React.createElement(Navigation, { title: lang[LG].contactUs.title }),
                React.createElement("div", { className: "flex justify-center font-poppins text-sm mt-5 font-light" },
                    React.createElement("div", { className: "ml-[20%]" },
                        React.createElement("div", { className: "w-3/4" }, lang[LG].contactUs.intro),
                        React.createElement("div", { className: "w-3/4 mt-3" }, lang[LG].contactUs.intro2))),
                React.createElement("div", { className: "flex justify-center mb-20" },
                    React.createElement(ContactForm, { onSuccess: function () {
                        }, layout: "home" })))));
    };
    return render();
};
export default ContactUs;
