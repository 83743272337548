import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import F from "../../utils/functions";
import env from "../../config/env";
import { useQueryUser } from "../../hooks/user/useQueryUser";
import { postLoginToken } from "../../feature/user";
import { useDispatch } from "react-redux";
var CheckAuth = function (props) {
    var navigate = useNavigate();
    var isLoggedIn = F.isLoggedIn();
    var user = F.user();
    var getUser = useQueryUser().getUser;
    var dispatch = useDispatch();
    useEffect(function () {
        getUser.mutate();
    }, []);
    useEffect(function () {
        var _a;
        if (user.value.id === 0 && getUser.status === 'success') {
            dispatch(postLoginToken(getUser.data));
            if (((_a = getUser.data) === null || _a === void 0 ? void 0 : _a.status) === 401 && !(props === null || props === void 0 ? void 0 : props.allowed)) {
                navigate(env.routes.login);
            }
        }
    }, [getUser.status]);
    return React.createElement(React.Fragment, null, (isLoggedIn || (props === null || props === void 0 ? void 0 : props.allowed)) ? props.children : null);
};
export default CheckAuth;
