import { Api } from "../../classes/api";
import env from "../../config/env";
import { useMutation } from "react-query";
var _postFn = function (data) {
    return Api.blobP(data, env.api.generateReport);
};
var _postAnaFn = function (data) {
    return Api.blobP(data, env.api.anaReport);
};
export default function useMutationReport() {
    var postFn = useMutation(function (data) { return _postFn(data); });
    var postAnaFn = useMutation(function (data) { return _postAnaFn(data); });
    return { postFn: postFn, postAnaFn: postAnaFn };
}
