import React from "react";
import Text from "../Field/Text";
var SubmitButtonWithIcon = function (props) {
    var _a;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : "submit";
    var renderClickableButton = function () {
        var _a, _b;
        var btnClass = "bg-c-purple hover:bg-c-button-hover";
        if (props.disabled) {
            btnClass = "bg-c-button-disabled cursor-not-allowed";
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: "".concat(btnClass, " rounded-full text-white font-poppins px-5 py-2.5 ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), disabled: props.disabled },
                React.createElement("div", { className: "flex inline" },
                    props.icon && React.createElement("div", { className: "flex ".concat((_b = props.iconAdditionalClass) !== null && _b !== void 0 ? _b : 'mr-2 mt-1') }, props.icon),
                    React.createElement("div", { className: "flex" },
                        React.createElement(Text, null, props.title))))));
    };
    var renderNonClickableButton = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: type, onClick: props.onClick, className: "bg-c-purple-500 rounded-2xl text-white font-poppins px-5 py-1 cursor-not-allowed ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') },
                React.createElement("div", { className: "flex inline" },
                    React.createElement("div", { className: "flex mr-2 mt-1.5" }, props.icon),
                    React.createElement("div", null,
                        React.createElement(Text, null, props.title))))));
    };
    var render = function () {
        if (props.clickable === false) {
            return renderNonClickableButton();
        }
        else {
            return renderClickableButton();
        }
    };
    return render();
};
export default SubmitButtonWithIcon;
