import React from 'react';
var DropDownIcon = function (props) {
    var render = function () {
        var height = props.height ? props.height.toString() : '24';
        var width = props.width ? props.width.toString() : '24';
        return (React.createElement("svg", { className: props.additionalClass, xmlns: "http://www.w3.org/2000/svg", height: "".concat(height, "px"), width: "".concat(width, "px"), viewBox: "0 0 ".concat(height, " ").concat(width), fill: props.color },
            React.createElement("rect", { fill: "none", height: height, width: width }),
            React.createElement("path", { d: "M19,15l-1.41-1.41L13,18.17V2H11v16.17l-4.59-4.59L5,15l7,7L19,15z" })));
    };
    return render();
};
export default DropDownIcon;
