var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import PriceButton from "../Button/PriceButton";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import Text from "../Field/Text";
import LightText from "../Field/LightText";
import F from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import LightUnderlineText from "../Field/LightUnderlineText";
var SingleQuestionnaire = function (props) {
    var _a;
    var _b = useState((_a = props.favourite) !== null && _a !== void 0 ? _a : 0), favourite = _b[0], setFavourite = _b[1];
    var contentRef = useRef(null);
    var _c = useState(0), height = _c[0], setHeight = _c[1];
    var isOpen = props.isOpen;
    useEffect(function () {
        if (isOpen) {
            var contentEl = contentRef.current;
            setHeight(contentEl.scrollHeight);
        }
        else {
            setHeight(0);
        }
    }, [isOpen]);
    var navigate = useNavigate();
    var LG = F.getLang();
    var quantityText = props.quantity + lang[LG].collectionQuantityText;
    var ageText = props.general == 1 ? lang[LG].general : (props.ageLarge != undefined && props.ageLarge != 100 ? props.ageSmall + " " + lang[LG].ageToText + " " + props.ageLarge + " " + lang[LG].age2 : props.ageSmall + '+ ' + lang[LG].minAge);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex justify-between h-fit min-h-[6rem] relative border-t border-c-gray hover:bg-c-scroll w-full z-[1]', style: { height: height } },
            " ",
            React.createElement("div", { className: "flex inline px-4 py-4 w-4/5 relative z-[2]", onClick: props.onClick, ref: contentRef },
                React.createElement("div", { className: "container pl-6" },
                    React.createElement("div", { className: "container relative z-[3]" },
                        React.createElement("div", { className: " flex-grow w-full" },
                            React.createElement(Text, { fontSize: "text-md", additionalClass: "flex-grow text-c-purple text-xl", searchText: props.searchText }, props.title + ' (' + ageText + ')')),
                        React.createElement("div", { className: " flex-grow z-[4] relative" },
                            React.createElement(LightText, { additionalClass: "flex-grow text-c-gray font-poppins text-md font-light", searchText: props.searchText }, quantityText))))),
            React.createElement("div", { className: ' w-1/5 block relative z-[1000] mr-5 hover:cursor-pointer text-right mt-4' },
                React.createElement(PriceButton, { title: "", additionalClass: "text-xl", priceTitle: props.price }),
                React.createElement(LightUnderlineText, { additionalClass: " text-black text-sm font-light cursor-pointer inline", isUnderLine: true, onClickAction: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            navigate("".concat(F.removeParams(env.routes.paymentPage)).concat(props.questionnaireId) + '/' + 1);
                            return [2 /*return*/];
                        });
                    }); }, searchText: lang[LG].buyMoreCopies + ' >' }, '  ')))));
};
export default SingleQuestionnaire;
