import React, { useEffect, useRef, useState } from 'react';
import Functions from "../../utils/functions";
import IconContainer from "../IconContainer/IconContainer";
import useAwayClick from "../../hooks/content/useAwayClick";
import TextError from "../Field/TextError";
import DropDownIcon from "../../assets/icons/DropDownIcon";
import env from "../../config/env";
import BaseCreditCard from "../CreditCard/BaseCreditCard";
import CreditCardAsDropDownListItem from "../CreditCard/CreditCardAsDropDownListItem";
var CreditCardSelector = function (props) {
    var elementRef = useRef(null);
    var elementRefClickAway = useAwayClick(elementRef);
    var _a = useState(props.selectedItem), selectedCreditCardItem = _a[0], setSelectedCreditCardItem = _a[1];
    var _b = useState(false), ifClick = _b[0], setIfClick = _b[1];
    var getDropDownItems = function (key, card, data) {
        return React.createElement("div", { className: "cursor-pointer text-black", onClick: function (e) {
                setSelectedCreditCardItem({
                    key: key,
                    card: card,
                    data: data
                });
                setIfClick(true);
                Functions.escape();
            }, key: key },
            React.createElement(CreditCardAsDropDownListItem, { company: card.company, code: card.code }));
    };
    // if dropdown open
    var ifDDOpen = function () {
        return !elementRefClickAway;
    };
    useEffect(function () {
        if (selectedCreditCardItem != null) {
            props.onChange(selectedCreditCardItem);
        }
    }, [selectedCreditCardItem]);
    var render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var dropBoxClass = "absolute z-10 top-16 left-0 max-h-40 overflow-y-scroll border border-c-gray bg-white rounded shadow";
        var iconClass = "flex cursor-pointer";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '') },
                React.createElement("div", { className: "relative", ref: elementRef },
                    React.createElement(BaseCreditCard, { additionalClass: ifDDOpen() ? "border-c-purple" : "border-c-gray", company: ifClick ? (_b = selectedCreditCardItem.card) === null || _b === void 0 ? void 0 : _b.company : (_d = (_c = props.selectedItem) === null || _c === void 0 ? void 0 : _c.card) === null || _d === void 0 ? void 0 : _d.company, code: ifClick ? (_e = selectedCreditCardItem.card) === null || _e === void 0 ? void 0 : _e.code : (_g = (_f = props.selectedItem) === null || _f === void 0 ? void 0 : _f.card) === null || _g === void 0 ? void 0 : _g.code, div: React.createElement(IconContainer, { cssClasses: iconClass, icon: React.createElement(DropDownIcon, { color: env.iconColor, additionalClass: ifDDOpen() ? 'rotate-180' : '' }) }) }),
                    React.createElement("div", { className: "".concat(dropBoxClass, " ").concat(elementRefClickAway ? 'hidden' : '') }, props.items.map(function (item) { return getDropDownItems(item.key, item.card, item.data); }))),
                props.error && React.createElement(TextError, { message: (_j = (_h = props.error) === null || _h === void 0 ? void 0 : _h.message) !== null && _j !== void 0 ? _j : '' }))));
    };
    return render();
};
export default CreditCardSelector;
