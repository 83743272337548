import React from "react";
var Container = function (props) {
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "rounded p-10 bg-white ".concat((_a = props.layoutClass) !== null && _a !== void 0 ? _a : '') }, props.children)));
    };
    return render();
};
export default Container;
