import React, { useEffect, useState } from "react";
import BasicTag from "./BasicTag";
var TagSet = function (props) {
    var stringToArray = function (input) {
        return input.split(",");
    };
    var _a = useState(), tags = _a[0], SetTags = _a[1];
    //const [searchTags,SetSearchTags]=useState<string[]>([]);
    useEffect(function () {
        var tagsArray = stringToArray(props.titleSet);
        SetTags(tagsArray);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container inline-flex w-full mt-2 flex-wrap" }, tags === null || tags === void 0 ? void 0 : tags.map(function (tag, index) {
            return (React.createElement(BasicTag, { isSelected: (props.searchTag != undefined && props.searchTag.indexOf(tag) >= 0), title: tag, key: index }));
        }))));
};
export default TagSet;
