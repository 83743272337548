var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import Text from "../Field/Text";
import { lang } from "../../lang/lang";
import ErrorOutline from "../../assets/icons/ErrorOutline";
import env from "../../config/env";
import CircleCheck from "../../assets/icons/CircleCheck";
import F from "../../utils/functions";
import useMutationSendReminder from "../../hooks/evaluation/useMutationSendReminder";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
var RespondentListing = function (props) {
    var LG = F.getLang();
    var gridCls = "grid grid-cols-4 gap-4";
    var getSendReminder = useMutationSendReminder().getSendReminder;
    var _a = useState([]), reminders = _a[0], setReminders = _a[1];
    var loader = useFullPageLoader();
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null, (_a = props.params.respondents) === null || _a === void 0 ? void 0 : _a.map(function (respondent, index) {
            return (React.createElement("div", { key: index, className: "".concat(gridCls, " border-t border-c-divider py-2") },
                React.createElement("div", null,
                    React.createElement(Text, { additionalClass: "w-full truncate pt-1" }, respondent.self === 1 ? lang[LG].you : respondent.respondent_name)),
                React.createElement("div", null,
                    React.createElement(Text, null, respondent.respondent_role)),
                React.createElement("div", { className: "flex space-x-2" },
                    respondent.status === 'progressing' && React.createElement(ErrorOutline, { color: env.iconRed }),
                    respondent.status === 'completed' && React.createElement(CircleCheck, { color: env.iconGreen }),
                    React.createElement(Text, null, respondent.status === 'progressing' ? lang[LG].progressing : respondent.status)),
                React.createElement("div", { className: "text-right" },
                    respondent.self === 1 &&
                        respondent.status !== "completed" &&
                        React.createElement(Text, { isbold: true, additionalClass: "text-c-purple underline font-normal cursor-pointer", onClick: function () {
                                var url = F.createUrl(env.routes.answerQuestionnaire, respondent.hashed_id);
                                window.open(url, '_blank');
                            } }, lang[LG].fillQuestionnaire),
                    respondent.self === 0 &&
                        respondent.status !== "completed" &&
                        React.createElement("div", { className: "flex items-center justify-end" },
                            reminders.indexOf(respondent.id) !== -1 &&
                                React.createElement(React.Fragment, null,
                                    React.createElement(CircleCheck, { color: env.iconGreen }),
                                    React.createElement(Text, { isbold: true, additionalClass: "text-c-purple font-normal ml-1" }, lang[LG].reminderSent)),
                            reminders.indexOf(respondent.id) === -1 &&
                                React.createElement(Text, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var id, request, response;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    loader(true);
                                                    id = respondent.id;
                                                    request = {
                                                        questionnaireToResId: id
                                                    };
                                                    return [4 /*yield*/, getSendReminder.mutateAsync(request)];
                                                case 1:
                                                    response = _a.sent();
                                                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                                                        setReminders(__spreadArray(__spreadArray([], reminders, true), [id], false));
                                                    }
                                                    loader(false);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }, isbold: true, additionalClass: "text-c-purple underline font-normal ml-1 cursor-pointer" }, lang[LG].sendAReminder)))));
        })));
    };
    return render();
};
export default RespondentListing;
