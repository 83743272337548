export var fr = {
    placeholderMobile: "Ajouter un numéro de téléphone",
    placeholderConfirmNewPassword: "Confirmez le nouveau mot de passe",
    emailAddress: "Adresse courriel",
    forgotEmail: "J'ai oublié mon adresse courriel",
    password: "Mot de passe",
    forgotPassword: "J'ai oublié mon mot de passe",
    login: "Se connecter",
    loginTitle: "Connexion",
    //requiredEmail: "Email is required.",
    requiredEmail: "Entrez votre adresse courriel",
    invalidEmail: "Entrez une adresse courriel valide",
    requiredPassword: "Entrez un mot de passe qui contient au moins 8 caractères incluant une lettre majuscule et un chiffre.",
    passwordMustBe: "Entrez un mot de passe qui contient au moins 8 caractères incluant une lettre majuscule et un chiffre.",
    register: "S'inscrire",
    registeration: "Création de compte",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Adresse courriel",
    firstNamePlaceholder: "ex. John",
    lastNamePlaceholder: "ex. Doe",
    emailPlaceholder: "example@gmail.com",
    confirmEmailPlaceholder: "Confirmer l'adresse courriel",
    confirmEmail: "Confirmez votre adresse courriel",
    confirmPassword: "Confirmez le mot de passe.",
    phone: "Numéro de téléphone",
    phoneNo: "Numéro de téléphone",
    requiredFirstName: "Entrez votre prénom",
    requiredLastName: "Entrez votre nom",
    requiredConfirmEmail: "Confirmez votre adresse courriel",
    requiredPhone: "Le téléphone est requis.",
    requiredConfirmPassword: "Confirmer le mot de passe est requis.",
    minPasswordLength: "Le mot de passe doit comporter au moins 8 caractères.",
    phoneLength: "Ce numéro de téléphone est invalide. S'il-vous-plait, réessayez.",
    confirmPasswordNotMatch: "Les deux entrées doivent correspondrent.",
    confirmEmailNotMatch: "Les deux entrées doivent correspondre",
    phoneDigitsOnly: "Seuls les chiffres sont autorisés.",
    sendTheLink: "envoyer le lien",
    p1_inForgotPasswordtsx: "Entrez votre adresse courriel et un lien vous indiquant le processus de changement de mot de passe vous sera envoyé.",
    p1_inForgotEmailtsx: "Entrez votre numéro de téléphone et un lien avec les instructions pour récupérer votre adresse e-mail vous sera envoyé.",
    t1_inForgotPasswordtsx: "Mot de passe oublié",
    t1_inForgotEmailtsx: "Oublier l'adresse e-mail",
    thankYou: "Merci!",
    reportThankYouText: "Le rapport a été généré",
    reportThankYouDescription: ["Le téléchargement pourrait prendre quelques secondes. Si le téléchargement ne débute pas automatiquement,{ACTION}"],
    reportThankYouLink: " cliquez ici.",
    passwordSentOnEmail1: "Un courriel contenant le lien pour réinitialiser votre mot de passe vous sera envoyé sous peu. Si vous ne recevez pas le courriel,",
    passwordSentOnEmail2: "cliquez ici pour le renvoyer",
    passwordSentOnEmail3: ".",
    backToHome: "Retour à l’accueil",
    findEmail: "Rechercher un e-mail",
    p1_inFindEmailtsx: "Votre adresse e-mail est: ",
    resetPassword: "Réinitialiser le mot de passe",
    successContent: [
        "Un courriel contenant le lien pour réinitialiser votre mot de passe vous sera envoyé sous peu.",
        "Si vous ne recevez pas le courriel, {ACTION} .",
    ],
    successButtonTitle: "Retourner à l’accueil",
    accountCreatedSuccess: [
        "Merci! Vous devez maintenant valider votre compte en suivant les instructions envoyées par courriel afin de pouvoir accéder à edpro.",
        "Si vous n’avez rien reçu dans les prochaines minutes, vérifier vos courriels indésirables. Cliquez {ACTION} pour renvoyer le courriel de validation.",
    ],
    accountCreatedSuccessWithoutCard: [
        "Merci! Vous devez maintenant valider votre compte en suivant les instructions envoyées par courriel afin d'accéder à edpro. Nous vous encourageons à ajouter une carte de crédit dans les paramètres de votre compte afin de faciliter les transactions futures.",
        "Si vous n'avez rien reçu dans les prochaines minutes, vérifiez vos courriers indésirables. Cliquez {ACTION} pour renvoyer l'e-mail de validation.",
    ],
    politicsDrop: {
        policy: "Politique",
        products: "Politique relative au site d’eduplan solutions et edpro",
        relative: "Politique relative à edpro",
        program: "Conditions d'utilisation",
        coppa: "Programme de gestion de la confidentialité",
        coppa1: "COPPA\n" +
            "Conformité réglementaire selon le territoire",
        coppa2: "Cookies",
    },
    accountCreateTitle: "Compte créé avec succès",
    clickHere: "cliquez ici pour le renvoyer",
    end: "Terminer",
    backToHomePage: "Retour à l'accueil",
    resetPasswordContent: "Vous pouvez maintenant accéder à Edpro en utilisant le nouveau mot de passe.",
    messageSentSuccessfully: "Message envoyé avec succès.",
    passwordPlaceholder: "********",
    confirmPasswordPlaceholder: "********",
    phonePlaceholder: "ex : 1-123-456-7890",
    client: "Client",
    clientModal: {
        addTitle: "Créer un nouveau dossier",
        clientFullName: "Nom complet",
        FullName: "Nom complet",
        example: "ex: John Doe",
        firstName: "Prénom",
        lastName: "Nom de famille",
        editClient: "Modifier le dossier",
        clientFullNamePlaceholder: 'Entrez le nom complet associé à ce dossier',
    },
    datePicker: {
        dob: "Date de naissance",
        day: "Jour",
        month: "Mois",
        year: "Année",
        begining: 'Début',
        end: "Fin"
    },
    months: [
        { index: 1, name: "janvier" },
        { index: 2, name: "février" },
        { index: 3, name: "mars" },
        { index: 4, name: "avril" },
        { index: 5, name: "mai" },
        { index: 6, name: "juin" },
        { index: 7, name: "juillet" },
        { index: 8, name: "août" },
        { index: 9, name: "septembre" },
        { index: 10, name: "octobre" },
        { index: 11, name: "novembre" },
        { index: 12, name: "décembre" },
    ],
    languageSelect: [
        { index: 1, name: "English" },
        { index: 2, name: "Français" },
    ],
    apply: "Appliquer",
    save: "Sauvegarder",
    cancel: "Annuler",
    clientListActive: "Dossiers actifs",
    archiveInTabPanel: "Dossiers archivés",
    clientAdded: "Votre client a été ajouté avec succès.",
    name: "Nom",
    namePlaceholder: 'Entrez le nom de votre collaborateur',
    emailCollaboratorPlaceholder: "Entrez l'adresse courriel du collaborateur",
    age: "Âge",
    age2: "ans",
    minAge: "ans et plus",
    ageToText: 'à',
    toBuy: 'Acheter',
    assesmentTool: "Évaluation",
    versionDahboard: "Version",
    creationDate: "Date d’ajout",
    respondentStatus: "Statut de répondant",
    search: "Rechercher",
    tagSearch: "Mots-clés",
    tagSSelect: "Sélectionner des mots :",
    addClient: "+ Créer un nouveau dossier",
    addClientConfirmTitle: "Nouveau dossier créé avec succès",
    addClientConfirmMsg: "Votre liste de dossiers a été mise à jour.",
    clientAssessment: "Questionnaires et inventaires",
    addANewClient: "Ajouter un nouveau client",
    languageBtn: "English",
    close: "Fermer",
    editProfile: "Modifier le profil",
    questionnaire: "Questionnaire",
    date: "Date",
    status: "Statut",
    newAssessment: "Nouvelle évaluation",
    deleteClient: "Supprimer ce dossier",
    archiveClient: "Archiver ce dossier",
    hello: 'Bonjour,  ',
    archiveClientTitle: "Confirmation de l'archivage",
    unarchiveClient: "Réactiver le dossier",
    archiveConfirmation: "Confirmation de l'archivage",
    deleteConfirmation: "Supprimer le questionnaire",
    deleteThisClient: "Supprimer le client",
    archiveThisClient: "Archiver le client",
    pInAchiveConfirmationPage1: "Êtes-vous certain(e) de vouloir archiver le client",
    pInAchiveConfirmationPage2: "? Son dossier apparaîtra dans la section “Archive” du tableau de bord. Vous pouvez le restaurer à tout moment.",
    pInDeleteConfirmationPage1: "Êtes-vous certain(e) de vouloir supprimer le client",
    pInDeleteConfirmationPage2: " de votre liste de client?",
    pInDeleteConfirmationPage3: "Cette action est irréversible.",
    pInDeleteConfirmationPage4: "Utilisez la fonction \"Archiver\" pour archiver temporairement votre fichier. Vous pourrez le restaurer si nécessaire.",
    dob: "Date de naissance",
    unArchiveThisClient: "Désarchiver ce client",
    unArchiveConfirmation1: "Voulez-vous vraiment désarchiver le dossier de",
    unArchiveConfirmation2: "? Son profil sera transféré dans la section \"Ma liste de clients\" de votre tableau de bord. Vous pouvez réarchiver le dossier à tout moment.",
    okay: "D'accord",
    clientUpdated: "Votre liste de clients a été mise à jour.",
    clientDeleted: "Votre liste de clients a été mise à jour.",
    archiveDate: 'Date d\'archivage',
    editProfilePopover: "Désarchiver l'utilisateur pour activer cette action.",
    clientEdited: "Client modifié avec succès",
    completeName: "Nom complet",
    edit: "Éditer",
    profileSettings: "Profil",
    modifyPassword: "Modifier le mot de passe",
    preferencesAndNotifications: "Préférences et notifications",
    whyPaymentInformations: "Les informations de paiements sont demandées afin de compléter votre dossier et faciliter vos futures transactions. Votre confirmation et votre consentement vous seront toujours demandés au moment d’effectuer une transaction.",
    paymentInformations: "Informations de paiement",
    paymentMethodInfo: "Pour procéder à la transaction, sélectionnez votre méthode de paiement et entrer votre adresse de livraison.<span class = 'font-semibold'> Il est essentiel d’entrer l’adresse de livraison avant d’utiliser vos crédits.</span>",
    deleteAccount: "Supprimer votre compte",
    settings: "Réglages",
    resources: "Ressources",
    teachingMaterial: 'Matériel pédagogique',
    fileName: 'Nom du fichier',
    categoryR: 'Catégorie',
    langFrench: 'Français',
    langEnglish: 'Anglais',
    download: 'Télécharger',
    tooltipCategory: 'Indisponible',
    notAccessible: 'Pour accéder à ce contenu, vous devez acheter un ou plusieurs outils provenant de cette catégorie: {0}',
    logout: 'Déconnexion',
    savedChanges: 'Modifications enregistrées',
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmation du nouveau mot de passe",
    currentPassword: "Ancien mot de passe",
    pInDeleteAccountPage: "La suppression d'un compte est définitive. Vous ne pourrez pas récupérer votre compte une fois le processus terminé. Entrez votre mot de passe pour continuer.",
    accountDeletedSuccessfully: 'Compte supprimé avec succès',
    verifyPhoneNumber: 'Vérification du numéro de téléphone',
    verifyPhoneNumberDetail: "Entrez le code de v\u00E9rification envoy\u00E9 sur votre t\u00E9l\u00E9phone mobile. Le processus pourrait prendre quelques minutes.",
    verifyCodeText: 'Entrez le code envoyé au numéro de téléphone',
    verifyEmail: 'Valider en suivant les instructions envoyés à cette adresse courriel.',
    verifyPhone: 'Valider en suivant entrant le code unique.',
    resendEmail: 'Renvoyez le courriel',
    cancelEmail: 'Annuler la modification',
    resendCode: 'Renvoyer le code',
    cancelCode: 'Annuler le code',
    verify: 'Vérifier',
    verifyEmailSent: 'L\'email de vérification a été envoyé',
    verifyCodeSent: 'Code de vérification envoyé.',
    back: 'Arrière',
    emailVerified: [
        'Votre adresse courriel a été validée et votre compte a été créé avec succès!.',
        'Nous vous souhaitons la bienvenue sur notre plateforme. Utiisez votre adresse courriel pour vous connecter à edpro.'
    ],
    paymentInformation: "Informations de paiement",
    paymentMethod: "Méthode de paiement",
    addACard: "+ Ajouter une carte",
    paymentHistory: "Historique des paiements",
    research: "Rechercher",
    questionnaireName: "Nom du questionnaire",
    quality: "Qté",
    costInPaymentInformation: "Coût",
    dateOfPayment: "Date du paiement",
    pInPaymentSetting: "A confirmation will be requested before each payment. The purpose of payment information is to facilitate the process.",
    fullNameOnTheCreditCard: "Nom complet sur la carte",
    cardNumber: "Informations de la carte",
    expirationDate: "Date d'expiration",
    billingaddress: "Adresse de facturation",
    address: "Adresse",
    apartment: "Appartement (facultatif)",
    city: "Ville",
    zipCode: "Code postal",
    province: "Province/territoire",
    remove: "Supprimer",
    skipThisStep: "Sauter cette étape",
    nextStep: "Prochaine étape",
    delete: "Supprimer",
    emptyCard: "Vous n'avez pas de cartes sauvegardées",
    country: "Pays/Région",
    state: "Province/territoire",
    language: "Langue",
    languageLaunchQuestionnaire: 'Langue(s) disponible(s)',
    notifications: "Notifications",
    email2: "Courriel",
    pInNotificationPage1: "Questionnaire complété par un collaborateur",
    pInNotificationPage2: "Offres et promotion",
    select: 'Sélectionner',
    sortBy: 'Trier par : ',
    selected: 'Choisi',
    evaluationTools: "Outils d'évaluation",
    myCollection: "Ma collection",
    favorites: "Favoris",
    moreTool: "+ Obtenir plus d’outils d’évaluation",
    price: "Prix",
    category: "Category",
    notSpecified: "Non spécifiée",
    behavioral: "Comportemental",
    units: "unités",
    gender: {
        he: 'il/lui',
        she: 'elle/elle',
        noSay: 'préfère ne pas dire',
        label: 'Pronom utilisé',
        they: 'iel/iel',
        theyFr: 'ille/ellui'
    },
    noResponse: "Aucun résultat",
    version: 'Version du questionnaire',
    addCollaborator: '+ Ajouter un collaborateur',
    creditHelpText: "Le code de sécurité de la carte (CSC) est un code a 3 ou 4 chiffres imprimés à l’endos de la carte de crédit (normalement au-dessus de la section réservée à la signature). Le code de sécurité ne sera pas enregistré et devra être entré à nouveau pour de futurs paiements.",
    inCollection: 'dans la collection',
    learnMore: 'En apprendre plus',
    viewDescription: ' Voir la description détaillé',
    collectionQuantityText: ' dans votre collection',
    accordionText: 'Voir les versions du questionnaire',
    buyMoreCopies: 'Acheter des copies supplémentaires',
    numberOfQuestionnaires: 'Vous avez {1} exemplaires de ce questionnaire.',
    launchQuestionnaire: 'Lancer le questionnaire',
    selectClient: 'Sélectionnez un dossier',
    newCustomer: 'Nouvellement ajouté',
    noClientFound: 'Aucun client trouvé.',
    sendQuestionnaire: 'Envoyer le questionnaire',
    selectMyselfQuestionnaires: 'Je réponds au questionnaire moi-même',
    selectMyselfAnaQuestionnaires: 'Je remplis le questionnaire moi-même',
    collaboratorAna: 'Faire remplir le questionnaire par un collaborateur',
    msgCollaboratorAna: 'Message personnalisé (optionnel)',
    or: 'ou',
    guestCollaborators: 'Collaborateurs invités',
    customMessage: 'Message personnalisé (optionnel)',
    questionnaireSuccess: {
        title: 'Le questionnaire a été lancé avec succès',
        content: 'Les participants recevront une invitation par courriel. Vous pouvez remplir le questionnaire tout de suite si vous le désirez.',
        okayTitle: 'Retourner à l’accueil',
        cancelTitle: 'Remplir le questionnaire'
    },
    notFound: {
        client: 'Client introuvable.',
        questionnaire: 'Questionnaire introuvable.',
    },
    anaQuestionnaire: {
        addItem: '+ Ajouter un item',
        uploadfile: 'Téléverser un PDF',
        titleS1: "Section {0}: {1}",
        fileError: "Fichier trop gros (limite de 200mo)",
        fileProgress: "Téléchargement en cours...",
    },
    answerQuestionnaire: {
        titleS1: "Section {0}: {1} > {2}",
        titleS2: "Section 2: Expression orale > Produire un message",
        smallTitle: "Pour {0}",
        explanation: "Pour chacun des énoncés, précisez si l’apprenant réalise les tâches ou les activités suivantes. Précisez le niveau d’assistance en cochant la case appropriée. Vous devez répondre à chacun des énoncés. Dans le doute, sélectionnez N/A.",
        p1: "Jérémy reformule un message dans ses propres mots",
        p2: "Suite à l'écoute d'une consigne verbale ou suite à l'écoute de questions, Jeremy répond",
        p3: "Jérémy manifeste son incompréhension à son interlocuteur",
        p4: "Jérémy demande à son interlocuteur de répéter",
        p5: "Jérémy pose des questions ou demande des précisions",
        p6: "Jérémy utilise des mots usuels liés à son environnement",
        p7: "Jérémy exprime ses besoins",
        p8: "Jérémy décrit les événements",
        p9: "Jérémy raconte des histoires",
        p10: "Jérémy utilise des formules de politesse",
        title2: "Information additionnelle (optionnel)",
        defaultInput: "Entrez de l'information additionnelle au besoin",
        bt1: "Sauvegarder et quitter",
        bt2: "Guide d'utilisation",
        bt3: "Prochaine étape",
        titleOfbox1: "1. Cette tâche ou activité est réalisée...",
        titleOfbox2: "2. Précisez le niveau d'assistance",
        topRight: {
            one: "Section {0} sur {1} : {2}",
            two: "Page {0} sur {1}",
        },
        topRight2: {
            one: "Section 2 sur 2 : Expression orale",
            two: "Page 2 sur 2",
        },
        page3: {
            title1: "L'Inventaire de Comportements Adaptatifs relatifs à l'Autonomie Fonctionnelle(CAF)",
            title2: "Merci",
            p1: "Merci d’avoir répondu au questionnaire.",
            p2: "Les réponses seront acheminées à l’administrateur du test qui pourra générer un rapport contenant les recommandations pour {0}.",
            a2: "Les réponses seront acheminées à l’administrateur du test sous forme de rapport qui l’aidera a dresser le portrait de (apprenant) et orienter la prise en charge et l’évolution de son dossier.",
            savedAndQuit: 'Les réponses sont enregistrées, vous pouvez revenir plus tard.',
            button: "Quitter",
            rightTop: "Conclusion",
        },
        deletedPage: {
            title: "Le questionnaire n'existe plus",
            text: "Nous nous excusons de l’inconvénient. Il semblerait que ce questionnaire n’existe plus ou que le lien de partage ait été annulé par la personne à l’origine de l’invitation.",
        },
        error: {
            error1: "Répondez à chacune des énoncés. En cas de doute, répondez N/A",
            error3: "Répondez à l’énoncé. En cas de doute, répondez “N/A”.",
            error2: "Précisez le niveau d'assistance.",
        },
        selectLanPage: {
            selectLan: "Choisissez la langue / Select language",
            p1: "Vous ne pourrez pas changer la langue apres.",
            p11: "11",
            p2: "You won't be able to change this later",
            p22: "22",
            btn1: "Français",
            btn2: "English",
        },
        answerInfgo: 'Cliquez sur le niveau d\'appréciation pour cocher la colonne entière. En cas de doute, sélectionnez N/A.',
        skipStep: 'Sauter cette page',
        skipStepTooltip: 'Tous les énoncés de cette page recevront l\'évaluation N/A',
        selectedOptions: {
            1: "N/A",
            2: "Jamais",
            3: "Rarement",
            4: "Parfois",
            5: "Souvent",
            6: "Toujours",
            7: "Aucune",
            8: "Faible",
            9: "Modérée",
            10: "Significative",
            11: "Extrême",
        },
        radioOption: {
            1: "Oui",
            2: "Non",
            3: "N/A",
            4: "+/-"
        },
        introductionPageLegal: {
            introduction: "Introduction",
            title1: "Conditions d’utilisation et politiques de protection de la vie privée",
            btn: "Prochaine étape",
            p1: "Nous ne recueillons vos renseignements personnels qu’après avoir obtenu votre consentement éclairé.",
            p2: "Lorsque vous remplissez une échelle d’évaluation sur edpro, vous devez inscrire des renseignements personnels. Nous n’utilisons vos renseignements d’identification qu’à des fins de personnalisation et de communication avec l’administrateur du questionnaire vous ayant envoyé le lien d’invitation. Nous ne sommes pas responsables de l’utilisation qui est faite de ces renseignements par l’utilisateur autorisé. Nous ne vendons pas et ne redistribuons pas vos renseignements individuels à des tiers.",
            p3: "Attention! ",
            p31: "Si vous êtes l’administrateur de l’outil d’évaluation, lisez attentivement les conditions d’utilisation à l’intention de l’administrateur. Si vous êtes le répondant de l’outil d’évaluation, lisez attentivement les conditions d’utilisation à l’intention des répondants.",
            p4: "Vous pouvez retrouvez toutes nos politiques sur notre site web : ",
            p5: "Si vous avez des questions ou des commentaires sur notre politique et nos pratiques de respect de la vie privée, veuillez ",
            acceptPolicy: "J’accepte les conditions d’utilisation et les politiques de protection de la vie privée.",
            underline1: "https://www.eduplan.ca/our-politics",
            underline31: "conditions d’utilisation à l’intention des répondants.",
            underline2: "les politiques de confidentialité",
            underline5: "communiquer avec nous. ",
            title2: "Introduction au questionnaire",
            p21: "Bonjour,",
            p22: "Merci d'avoir pris le temps de répondre au questionnaire. Ce questionnaire dure environ 20 minutes. Vous pouvez le mettre en pause à tout moment et y revenir à un autre moment.",
            p23: "Sil-vous plait, répondez au mieux de vos connaissances.",
            error: "Acceptez les politiques de protection de la vie privée pour poursuivre",
            for: "Pour ",
            for2: "pour",
            startbutton: "Débuter Questionnaire",
        },
        introductionPage: {
            introduction: "introduction",
            title1: "Politiques de protection de la vie privée",
            btn: "Prochaine étape",
            p1: "Nous ne recueillons vos renseignements personnels qu'après avoir obtenu votre consentement éclairé.",
            p2: "Lorsque vous remplissez une échelle d'évaluation sur edpro, vous devez inscrire des renseignements personnels. Nous n'utilisons vos renseignements d'identification qu'à des fins de personnalisation et de communication avec l'administrateur du questionnaire vous ayant envoyé le lien d'invitation. Nous ne sommes pas responsables de l'utilisation qui est faite de ces renseignements par l'utilisateur autorisé. Nous ne vendons pas et ne redistribuons pas vos renseignements individuels à des tiers.",
            p3: "Si vous avez des questions ou des commentaires sur notre politique et nos pratiques de respect de la vie privée, ",
            p4: "J'accepte {ACTION}.",
            underline1: "communiquer avec nous.",
            underline2: "les politiques de confidentialité",
            title2: "Introduction au questionnaire",
            p21: "Bonjour,",
            p22: "Merci d'avoir pris le temps de répondre au questionnaire. Ce questionnaire dure environ 20 minutes. Vous pouvez le mettre en pause à tout moment et y revenir à un autre moment.",
            g22: "Merci de prendre le temps de répondre au questionnaire. Le temps d’administration ou de passation de cet inventaire ou questionnaire dure approximativement 20 minutes. Vous pouvez en tout temps le mettre en pause et y retourner à un autre moment.",
            p23: "Sil-vous plait, répondez au mieux de vos connaissances.",
            error: "Acceptez les politiques de protection de la vie privée pour poursuivre",
            for: "Pour",
            for2: "pour",
        },
        descPage: {
            title: 'Déroulement',
            d1: 'Pour chaque question, des instructions claires vous seront présentées afin de vous guider.',
            d2: 'Certaines questions requierent une réponse textuelle. Dans ce cas, un bloc texte vous permettra d’entrer la réponse souhaitée. Dans certains cas, des menus déroulants peuvent vous offrir des options de réponses. Sélectionnez le ou les items approprié(s). Pour ajouter des éléments personnalisés à la liste, utilisez l’option “Autre” présente dans le menu déroulant. Celle-ci vous permettra d’entrer une réponse dans un bloc texte. Pour les questions à choix multiples, cochez les cases appropriées. Répondez au meilleur de vos capacités. Toutes les questions sont optionnelles.',
            d3: 'Tout au long du questionnaire vous pourrez enregistrer et quitter pour poursuivre plus tard. Un courriel vous sera envoyé avec le lien.'
        },
        userGuidePageOne: {
            title: "Déroulement",
            description1: "Le questionnaire suivant sera divisé en plusieurs sections. Dans chacune de ces sections, des énoncés vous seront présentés. Pour chaque énoncé, cochez la case appropriée qui correspond au niveau de réalisation de l’action. Cochez ensuite la case qui correspond au niveau d’assistance requis pour effectuer l’action.  Répondez au meilleur de vos capacités.",
            description2: "Afin d’accélérer le processus, vous pouvez sélectionnez une colonne complète en cliquant sur le titre de la colonne. Dans le cas où vous ignorez les réponses d’une certaine section où qu’elle ne s’applique pas à vos besoins d’évaluation, utilisez le bouton “Sauter cette page”. Tous les énoncés recevront l’évaluation N/A.",
            description3: "Certains énoncés seront accompagnés d’informations complémentaires. Vous n’avez qu’à survoler l’icône d’information pour en apprendre davantage.",
            description4: "Il vous sera possible d’ajouter des commentaires supplémentaires en bas de page en lien avec chaque section. Ces commentaires apparaitront dans le rapport.",
            description5: "Tout au long du questionnaire vous pourrez enregistrer et quitter pour poursuivre plus tard. Un courriel vous sera envoyé avec un lien unique pour poursuivre là où vous avez quitté.",
            returnbutton: "Retour au questionnaire",
            contactbutton: "Communiquez avec nous",
        },
        userNewGuidePageOne: {
            description1: "Le questionnaire suivant comporte divers éléments interactifs. Pour les menus déroulants, il suffit de sélectionner la réponse appropriée parmi les options proposées.",
            description2: "Pour les blocs de texte, il suffit de sélectionner la case, puis d’entrer votre réponse personnalisée dans la case prévue à cet effet.",
            description3: "Certains éléments proposent l’option d’ajouter un item à la liste. Cliquez sur “Ajouter un item” pour ajouter un bloc texte ou un menu déroulant. Ces éléments seront présentés sous forme de liste à point dans le rapport.",
            description4: "D’autres questions seront présentées sous forme d’énoncés auxquels il faudra fournir une réponse en cliquant sur le bouton radio. Pour chaque énoncé, cochez la case appropriée. En cas de doute, sélectionnez N/A.",
            description5: "Dans certains cas,  votre réponse devra être précisée davantage par le biais d’une deuxième question. Si vous ignorez la réponse, vous pouvez décider de sauter ces questions.",
            description6: "Il se peut que l’on vous demande davantage de précision dans certains cas. Sachez que tous les détails sont pertinents et alimenteront le rapport. Encore une fois, ces questions demeurent optionnelles.",
            description7: "D’autres questions seront présentés sous forme d’items qu’il faudra sélectionner. Cochez la ou les cases appropriée(s). Si aucun élément ne correspond, vous pouvez décider de sauter cette étape.",
            description8: "Tout au long du questionnaire vous pourrez enregistrer et quitter pour poursuivre plus tard. Un courriel vous sera envoyé avec un lien unique pour poursuivre là où vous avez quitté.",
            returnQuestionnaire: 'Débuter le questionnaire',
        },
        alreadyAnsweredPage: {
            title: "Vous avez déjà rempli ce questionnaire",
        },
    },
    launchQuestionnaireSuccess: {
        title: "Le questionnaire a été lancé avec succès",
        content: "Les participants recevront une invitation par courriel. Vous pouvez remplir le questionnaire tout de suite si vous le desirez.",
        btn1: "Remplir le questionnaire",
        btn2: "Retourner à l'accueil",
    },
    guildPage: {
        title: "Guide d'utilisation",
        content: "Cette section est en construction! Merci de votre comprehension. Nous travaillons fort pour vous offrir une experience optimale.",
        btn: "Retour",
    },
    saveAndQuitPage: {
        title: "Quitter le questionnaire",
        content: "Les réponses données jusqu’à maintenant seront sauvegardées. Vous recevrez un courriel vous donnant un lien unique pour poursuivre et terminer votre questionnaire.",
        content2: "Il est important de terminer le questionnaire pour donner les recommandations les plus adaptées à {0}.",
        btn: "Revenir au questionnaire",
        btn2: "Sauvegarder et quitter",
    },
    general: 'Tous âges',
    placeHolder1: "Ex: John Doe",
    placeHolder2: "Ex: Montreal",
    placeHolder3: "Ex: 123 Avenue Victoria",
    placeHolder4: "Ex: 101",
    Active: "Actif",
    Archive: "Archive",
    Resume: "Résumé",
    removeAgeFilter: "Supprimer le filtre d'âge",
    removeFilter: 'Réinitialiser la recherche',
    removeTagFilter: 'Retirer tous les filters',
    tagSet: {
        tag1: "Adaptation",
        tag2: "Autisme",
        tag3: "Comportement",
        tag4: "Dyslexie",
        tag5: "Scolaire",
        tag6: "Social",
        tag7: "Transition",
    },
    sortItem: {
        pertinence: "Pertinence",
        ascendingPrice: "Prix croissants",
        decreasingPrice: "Prix décroissants",
        alphabetic1: "Alphabétique (A-Z)",
        alphabetic2: "Alphabétique (Z-A)",
        recentlyAdded: "Ajoutés récemment",
    },
    unarchiveUserFirst: 'Veuillez d\'abord désarchiver l\'utilisateur avant de procéder à une nouvelle évaluation.',
    partnerLogo: "Logo du partenaire",
    english: 'English',
    french: 'Français',
    role: 'Rôle',
    you: 'Vous',
    progressing: 'En cours',
    completed: 'Complété',
    noRespondent: 'Aucun collaborateur invité',
    fillQuestionnaire: 'Remplir le questionnaire',
    sendAReminder: 'Envoyer un rappel',
    questionnaireActivity: {
        na: 'N/A',
        never: 'Jamais',
        rarely: 'Rarement',
        sometimes: 'Parfois',
        often: 'Souvent',
        always: 'Toujours',
    },
    questionnaireAssistance: {
        none: 'Aucune',
        low: 'Faible',
        moderate: 'Modérée',
        significant: 'significative',
        extreme: 'Extrême',
    },
    allResponded: "Tous les collaborateurs ont répondu",
    noRespondents: "Aucun répondant",
    noAnswer1: " répondant sur ",
    noAnswerOnly1: " répondant ",
    noAnswermultiOnly1: " répondants sur ",
    noAnswer2: " en attente",
    submit: 'Soumettre',
    contact: 'Contact',
    subject: 'Sujet',
    message: 'Message',
    enterSubject: 'Entrez le sujet du message',
    enterMessage: 'Entrez le message',
    send: 'Envoyer',
    questionnairePreview: {
        detail: "Voir le descriptif détaillé du questionnaire",
        buyMore: "Acheter des exemplaires supplémentaires",
        selectQuantity: "Sélectionnez la quantité",
        button: "Procéder au paiement",
        error: "Sélectionnez la quantité",
        quantity: "Quantité",
        quantity2: "Qte.",
        text1: "Obtenez 5% de rabais à l'achat de 5 exemplaires et +",
        text2: "Obtenez 7% de rabais à l'achat de 10 exemplaires et +",
    },
    paymentPage: {
        title: "Informations de paiement {0}",
        selectQuantity: "Sélectionnez la quantité",
        invoice: "Facture",
        cost: "Coût",
        discount: "Rabais",
        discountCoupon: "Coupon rabais",
        taxes: "Taxes",
        total: "Total",
        paymentMethod: "Méthode de paiement",
        useANewMethod: "Ajouter une carte de crédit",
        //securityCode: "Entrez le code de sécurité de la carte de crédit",
        creditText1: 'Vous avez ',
        creditText2: ' crédits disponibles.',
        creditText3: 'un crédit disponible.',
        creditLink: 'Appliquer sur la transaction',
        quantity: 'Quantité',
        couponPlaceholder: 'Coupon Rabais',
        securityCode: "Code de sécurité",
        details: "Le code de sécurité de la carte (CSC) est un code a 3 ou 4 chiffres imprimés à l'endos de la carte de crédit(normalement au-dessus de la section réservée à la signature). Le code de sécurité ne sera pas enregistré et devra étre entré à nouveau pour de futurs paiements",
        term: "En payant ce montant, j'accepte les conditons d'utilisation d'edpro.",
        button: "Compléter la transaction",
        purpleText: "conditons d'utilisation",
        sameAddress: "L'adresse de facturation est la méme que celle qui apparait sur la carte de crédit.",
        billingAddress: "Adresse de facturation",
    },
    completeTransaction: 'Complete transaction',
    invoice: 'Facture',
    cost: 'Coût',
    tax: 'Taxes',
    addCard: 'Ajouter cette carte à mes modes de paiement enregistrés',
    discount: 'Rabais',
    couponDiscount: 'Bon de réduction',
    total: 'Total',
    taxInfo: 'Le montant de la taxe dépend de la province sélectionnée',
    isSameBillingAddress: 'L\'adresse de livraison est la même que celle qui apparait sur la carte de crédit',
    paymentResult: {
        success: {
            title: "Questionnaire(s) ajouté(s) à votre collection",
            text: "Paiement effectué avec succés. Le(s) questionnaire(s) ont été ajoutés avec succés à votre collection.",
            button1: "Retour à l'accueil",
            button2: "Lancer le questionnaire"
        },
        error: {
            title: "Échec du paiement",
            text: "Une erreur est survenue avec le paiement. S'il-vous-plait, utillsez un autre mode de paiement ou contactez votre banque.",
            button: "Retour au paiement",
        }
    },
    deliveryAddress: 'Adresse de livraison',
    deliveryAdTax: 'Cette adresse est utilisée pour le calcul des taxes selon votre région.',
    cancelQuestionnaire: 'Supprimer le questionnaire',
    generateReport: 'Générer un rapport',
    forClientPage: 'Pour {0}',
    generateReportInfo: 'Êtes-vous certains de vouloir générer le rapport? Les collaborateurs suivants n\'ont pas complété le questionnaire:',
    reportPreparation: 'Préparation du rapport',
    selectReportType: 'Sélectionnez les participants qui affecteront le rapport :',
    selectLanguage: 'Sélectionnez la langue du rapport :',
    selectLang: 'Sélectionner la langue',
    generalReport: 'Rapport général',
    selectAllRespondents: 'Les résultats de tous les répondants seront agglomérés',
    reportByRespondents: 'Rapports par répondants',
    reportByRespondentsInfo: 'Les résutats de tous les répondants seront séparés dans différentes sections',
    graph: 'Graphique',
    table: 'Tableau',
    completedOn: 'Complété le ',
    selectReportContent: 'Sélectionnez le contenu du rapport :',
    reportGraph: 'Graphique normatif',
    reportTable: 'Tableau de compilation des résultats',
    downloadReport: 'Télécharger le rapport',
    downloadReceipt: 'Télécharger le reçu',
    clientHistory: "Historique (facultatif)",
    enterHistory: "Entrez l’historique de cette personne",
    lastUpdate: "Dernière mise à jour : {0}",
    testIsPending: 'Le répondant n\'a pas terminé le test',
    report: 'Rapport',
    reportName: 'edpro_facture_{0}',
    ourAssessmentTools: 'Nos outils d\'évaluation',
    politics: 'Nos politiques',
    helpCenter: 'Centre d\'aide',
    features: 'Fonctionnalités',
    aboutUs: 'À propos',
    contactUsTitle: 'Contactez-nous',
    simplifyAssessmentTool: "Améliorez et simplifiez votre<br/> processus d'évaluation",
    bannerContent: 'Edpro vous offre un évantail d’outils d’évaluation et d’inventaires afin de produire des rapports personnalisés.',
    alreadyHaveAccount: 'J\'ai déjà un compte',
    acceptThe: 'J\'accepte les ',
    phonemsg: 'En entrant votre numéro de téléphone, vous acceptez de recevoir des messages textes',
    termsConditions: 'termes et conditions d\'utilisation',
    exploreAssessmentTools: 'Explorez nos outils d’évaluation',
    edproAdvantages: 'Les avantages d\'edpro',
    createACustomerList: 'Créer une liste de clients',
    clientListDetail: 'Visualiser rapidement et efficacement votre liste de client et ses informations prioritaires. Edpro vous permet de garder à jour la liste de vos clients et rester informer sur chaque nouvelle modifications. Voyez en temps réel le statut des répondants et envoyer rapidement des rappels en un simple clic.',
    sendQuestionnaireToEmployees: 'Envoyer les questionnaires à vos collaborateurs',
    quesToEmpDetail: 'La collaboration est primordiale pour l\'obtention d\'un rapport pertinent. Edpro vous permet d\'envoyer les questionnaires en ligne à vos différents collaborateurs. Choisissez parmi trois versions du questionnaire; pour professionnels, parents/tuteurs et apprenants. L\'intelligence artificielle se chargera d\'interpréter les données de façon efficace dans votre rapport.',
    generateDetailReport: 'Générer des rapports détaillés',
    generateReportDetail: 'En générant un rapport, l\'intelligence artificielle s\'assure de prendre en compte les questionnaires de chaque participants afin de créer un rapport pertinent et juste. Les données seront interprétées, et des notes additionnelles ajoutées par vos participants vous permettront de pousser l\'analyse et d\'en apprendre davantage sur vos clients.',
    eduplanProduct: 'Un produit d\'Eduplan solutions',
    eduplanDetail: 'La mission d\'Eduplan solutions est de transformer les pratiques de planification et de gestion de plans, projets ou programmes d’intervention dans le secteur de l’éducation, de la santé et des services sociaux afin de contribuer à l’actualisation du potentiel adaptatif et d’apprentissage de chaque personne ou d’élève en situation de handicap, ou, en difficulté d’apprentissage ou d’adaptation, et ce dans l’essor d’une société inclusive.',
    ceo: '- Dr. Nathalie Myara, CEO d\'Eduplan Solutions',
    haveAQuestion: 'Vous avez des questions? Écrivez-nous!',
    contactUs: {
        title: 'Contactez-nous',
        intro: 'Vous avez des questions sur nos services, nos produits, nos politiques ou conditions? Vous avez des commentaires à nous transmettre? Vous voulez participer à un de nos projets de recherche et de développement?',
        intro2: ' Remplissez le formulaire ci-dessous pour nous contactez! Il nous fera plaisir de vous répondre dans les plus brefs délais.'
    },
    politicsFront: {
        title: 'Nos politiques',
        intro: 'La protection de vos données confidentielles ou personnelles est primordiale., c\'est pourquoi eduplan solutions s\'assure d\'en tenir compte au début même de son processus de recherche et de développement de produit, dans le but d\'innover des produits, ayant par défaut, des fonctions qui respectent les exigences et encadrements légaux relatifs à la protection des données confidentielles.',
        intro2: 'De plus,eduplan solutions met à votre disposition ses politiques de confidentialité afin que vous puissiez comprendre nos politiques et nos pratiques concernant la gestion des données personnelles.',
        intro3: '<p>En somme, vous trouverez dans les pages suivantes:</p><br/>',
        link1: '1. Politique de confidentialité relative au site Web d\'eduplan solutions / edpro',
        link2: '2. Politique de confidentialité relative aux produits d\'eduplan solutions / edpro',
        link3: '3. Conditions d\'utilisation',
        link4: '4.Programme de gestion de la confidentialité',
        link5: '5. COPPA',
        link6: '6. Cookies',
        intro4: '<p>Ces politiques, conditions et programmes ont été élaborés dans le but de décrire les pratiques d\'eduplan solutions en matière de gestion des informations personnelles : soit le type d\'informations recueillies, soit la façon dont elles sont traitées, soit comment elles sont utilisées.</p><br/>' +
            '<p>eduplan solutions s\'engage à protéger votre vie privée. Ces politiques de confidentialité ("Politique") sont conçues pour vous informer sur les données personnellement identifiables que nous recueillons auprès de vous via nos produits. Ces données personnellement identifiables sont des données qui pourraient être utilisées, seules ou en combinaison avec d/\'autres données, pour vous identifier en tant qu/\'individu.</p><br/>',
    },
    helpCenterFront: {
        title: 'Centre d\'aide',
    },
    aboutFront: {
        title: 'À propos',
        intro: 'edpro est une plateforme qui rend disponible des instruments d’évaluation composés de questionnaires et d’inventaires, sur des thématiques distinctes, qui aident à la détermination des forces et des défis d’une personne visée, ainsi qu’à la prise de décision.',
        intro2: 'Les instruments d’évaluation consistent à recueillir de l’information sur une personne visée en fonction de la perception d’une ou de plusieurs répondants, en regard du niveau de réalisation des tâches visées dans un ou plusieurs domaines.',
        intro3: 'edpro aide les professionnels à déterminer les besoins des apprenants.'
    },
    yourEmailAddress: 'Votre adresse courriel',
    topic: 'Sujet',
    contactUsSuccessMessage: 'Votre message a été envoyés avec succès! Un membre de notre équipe vous répondra dans les plus brefs délais.',
    edproMakesEasier: 'Edpro vous simplifie la vie!',
    edproEasierContent: 'Vous désirez améliorer la façon dont vous travaillez avec les outils d\'évaluation? Edpro est là pour vous! Collaborez et générez des rapports détaillés avec Edpro!',
    createYourAccount: 'Créer votre compte',
    eduplan: 'Eduplan solutions',
    eduplanWebsite: 'Site web d\'Eduplan',
    smartplan: 'Smartplan',
    banqO: 'BanqO',
    iplan: 'Iplan',
    sitemap: 'Plan du site',
    privacyPolicy: 'Politique de confidentialité',
    termsCondition: 'Termes et conditions',
    assistance: 'Assistance',
    questionnaireCompleted: 'Questionnaire(s) complété(s)',
    respondent: 'Répondant',
    answerOnHold: 'répondant en attente',
    moreAssessment: ' évaluations...\n',
    answerMoreThanOnHold: '',
    noRespondentAssigned: 'Aucun répondant n\'a été attribué.',
    phoneInfo: 'Le numéro de téléphone sera utilisé en cas d\'oubli de l\'adresse courriel',
    backToLandingPage: 'Retour à la page d\'accueil',
    return: 'Retour',
    enterNewPassword: 'Entrez le nouveau mot de passe',
    enterCurrentPassword: 'Entrez votre mot de passe actuel',
    receiveByEmail: 'Recevoir par courriel',
    enterPassword: 'Entrez votre mot de passe',
    sorry: 'Pardon',
    expiredLink: 'Le lien a expiré',
    reminderSent: 'Rappel envoyé',
    deleteQuestionnaire: [
        'Êtes-vous certain(e) de vouloir supprimer ',
        ' attribué à',
        'Cette action est irréversible. Si des invitations à répondre ont été envoyées, elles seront annulées.',
        'Comme vous n’avez pas encore utilisé ce questionnaire, celui-ci sera remis dans votre inventaire.'
    ],
    removeRespodent: [
        'Êtes-vous certain de vouloir supprimer ce collaborateur?',
        'En supprimant ce participant, toutes les données recueillies jusqu’à présent pour ce collaborateur seront perdues pour ce questionnaire. Il sera impossible de les récupérer.',
        'Si vous souhaitez générer un rapport en excluant les réponses de ce collaborateur, désélectionnez son nom de la liste lors de la génération du rapport.',
        'Garder le collaborateur',
        'Supprimer le collaborateur',
        'Retour au questionnaire'
    ],
    year: 'Ans',
    examplePostCode: 'ex: A1A 1A1',
    selectCollaboratorRole: 'Sélectionnez le rôle',
    passwordResetSuccess: 'Mot de passe réinitialisé avec succès',
    monthsOld: '{0} mois',
    yearsOld: '{0} ans',
    listIsEmpty: 'Cette liste est vide',
    roleOfCollaborator: 'Rôle du collaborateur',
    addMessageForCollaborator: 'Ajouter un message pour les collaborateurs',
    addPaymentMethod: 'Ajouter un mode de paiement',
    customerHistory: 'Historique',
    someIssueOnServer: 'Il y a un problème, réessayez plus tard',
    generateReportRecommendations: 'Vos réponses nous permettront de generer un rapport contenant les recommandations pour {0}.',
    wrongPassword: 'Adresse courriel et/ou mot de passe invalide',
    linkHasExpired: 'Le lien a expiré.',
    noRecords: 'Aucun dossier disponible!',
    noRecordsForAssessmentTool: 'Aucun outil disponible!',
    assessmentToolTextLink: 'Cliquez ici pour accéder au répertoire d\'outils',
    createClientFile: 'Créez votre premier dossier client',
    visitUs: 'Visitez régulièrement pour essayer nos nouveaux inventaires et questionnaires!',
    clickHere2: 'cliquez ici',
    paymentCompliance: 'edpro solutions se conforme à la norme PCI-DSSS pour les cartes de paiement. Pour consulter la déclaration de la norme {ACTION}',
    pageNotFound: 'Page introuvable',
    siteUsesCookies: 'Ce site Web utilise des cookies pour améliorer l\'expérience utilisateur.',
    tutorial: {
        addClient: 'Création de nouveaux dossiers',
        addClientText: 'La première étape pour utiliser edpro est de créer un dossier pour chacun de vos clients ou élèves.',
        viewArchive: 'Vos dossiers archivés',
        viewArchiveText: 'Ici, vous pouvez visualiser les dossiers que vous avez archivés et les restaurer au besoin',
        getQuestionnaire: 'La boutique',
        getQuestionnaireText: 'Nos outils d’évaluation sont disponibles ici. Vous pouvez les acheter et les attribuer à l’un de vos dossier ou les conserver dans votre collection pour les utiliser plus tard.',
        viewFav: 'Vos outils d\'évaluation favoris',
        viewFavText: 'Cliquez ici pour affichez vos outils d\'évaluation favoris',
        generateReport: 'Clients et évaluations',
        generateReportText: 'Une fois que vous avez commencé une évaluation, vous serez en mesure de voir son statut à côté du dossier correspondant. Vous pouvez cliquer dessus pour ajouter des collaborateurs ou en retirer, envoyer des rappels ou générer des rapports. Vous pouvez aussi archiver ou supprimer un dossier.',
        myCollection: 'Votre collection',
        myCollectionText: 'Les outils d’évaluation que vous achetez serons disponibles ici. Vous pourrez les assigner à quelqu’un ou achetez des copies supplémentaires.',
    },
};
