import React from "react";
var Title = function (props) {
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins text-left font-semibold  text-black ".concat(props.display, " ").concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), onClick: props.onClick }, props.children)));
    };
    return render();
};
export default Title;
