var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import InputMask from 'react-input-mask';
import clsx from "clsx";
import TextError from "../Field/TextError";
var PhoneMaskField = function (props) {
    var hasErrors = useRef();
    var render = function () {
        var _a;
        var _b, _c, _d, _e, _f;
        hasErrors.current = props.error ? true : false;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat((_b = props.additionalClassCtn) !== null && _b !== void 0 ? _b : '') },
                React.createElement(InputMask, __assign({ type: "text", mask: '+1 -999-999-9999', maskPlaceholder: "", placeholder: props.placeholder, alwaysShowMask: true }, props.register, { autoComplete: "off", disabled: props.disabled, className: clsx((_a = {
                            "focus:ring-c-purple focus:border-c-purple border border-solid rounded border-c-gray h-10 w-full text-sm pr-10 pl-2.5": true,
                            "border-c-red": hasErrors.current
                        },
                        _a[(_c = props.additionalClass) !== null && _c !== void 0 ? _c : ''] = true,
                        _a)) })),
                props.error &&
                    React.createElement(TextError, { extraSmall: true, addtionalClass: (_d = props.errorClass) !== null && _d !== void 0 ? _d : '', message: (_f = (_e = props.error) === null || _e === void 0 ? void 0 : _e.message) !== null && _f !== void 0 ? _f : '' }))));
    };
    return render();
};
export default PhoneMaskField;
