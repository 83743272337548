import { useMutation } from "react-query";
import { Api } from "../../classes/api";
import env from "../../config/env";
var _postFavourite = function (data) {
    return Api.post(data, env.api.updateFavouriteQuestionnaire);
};
export default function useMutationFavourite() {
    var postFavourite = useMutation(function (data) { return _postFavourite(data); });
    return { postFavourite: postFavourite };
}
