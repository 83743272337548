var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from "react";
import Title from "../Title/Title";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import Label from "../Field/Label";
import TextField from "../Input/TextField";
import Validations from "../../utils/validations";
import PhoneMaskField from "../Input/PhoneMaskField";
import Functions from "../../utils/functions";
import SubmitButton from "../Button/SubmitButton";
import { useForm } from "react-hook-form";
import { save } from "../../feature/registerUser";
import { useDispatch } from "react-redux";
import useMutationUser from "../../hooks/user/useMutationUser";
import clsx from "clsx";
import CustomCheckbox from "../Input/CustomCheckbox";
import Text from "../Field/Text";
import { validation_lang } from "../../lang/validations";
import LightSubmitButton from "../Button/LightSubmitButton";
import { useNavigate } from "react-router-dom";
import F from "../../utils/functions";
import TextIconField from "../Input/TextIconField";
import VisibilityOn from "../../assets/icons/VisibilityOn";
import VisibilityOff from "../../assets/icons/VisibilityOff";
import FormError from "../Error/FormError";
var RegisterForm = function (props) {
    var _a = useForm({
        defaultValues: props.defaultValues
    }), register = _a.register, handleSubmit = _a.handleSubmit, getValues = _a.getValues, setValue = _a.setValue, errors = _a.formState.errors;
    var dispatch = useDispatch();
    var LG = Functions.getLang();
    var postValidateRegisterFn = useMutationUser().postValidateRegisterFn;
    var _b = useState(), errorResponse = _b[0], setErrorResponse = _b[1];
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = __assign({}, data);
                    return [4 /*yield*/, postValidateRegisterFn.mutateAsync(payload)];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        dispatch(save(payload));
                        props.onSuccess();
                    }
                    else {
                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                            setErrorResponse(response);
                            return [2 /*return*/, false];
                        }
                        else {
                            setErrorResponse(undefined);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var navigate = useNavigate();
    var gotoLogin = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = F.createUrl(env.routes.login);
            navigate(url);
            return [2 /*return*/];
        });
    }); }, []);
    var openLink = function () {
        var url = "/asset/".concat(LG.toLowerCase(), "/Terms-of-Use.pdf");
        window.open(url, '_blank');
    };
    var openPolitics = function () {
        var url = F.createUrl(env.routes.politics);
        navigate(url);
    };
    var _c = useState({
        password: false,
        confirmPassword: false
    }), showPassword = _c[0], setShowPassword = _c[1];
    var getInputType = useCallback(function (field) {
        var isVisible = showPassword[field];
        return isVisible ? 'text' : 'password';
    }, [showPassword]);
    var getVisibilityIcon = useCallback(function (field) {
        var isVisible = showPassword[field];
        if (isVisible) {
            return React.createElement(VisibilityOn, { color: env.iconColor, onClick: function () {
                    var _a;
                    setShowPassword(__assign(__assign({}, showPassword), (_a = {}, _a[field] = false, _a)));
                } });
        }
        else {
            return React.createElement(VisibilityOff, { color: env.iconGrey, onClick: function () {
                    var _a;
                    setShowPassword(__assign(__assign({}, showPassword), (_a = {}, _a[field] = true, _a)));
                } });
        }
    }, [showPassword]);
    var render = function () {
        var _a, _b, _c;
        return (React.createElement(React.Fragment, null,
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement("input", __assign({ type: "hidden" }, register("readTermConditions", { required: validation_lang[LG].required }))),
                React.createElement("input", __assign({ type: "hidden" }, register("readPolicy", { required: validation_lang[LG].required }))),
                React.createElement("input", __assign({ type: "hidden" }, register("phonemsg"))),
                React.createElement("div", { className: "flex" },
                    React.createElement(Title, { id: "register" }, lang[LG].registeration)),
                React.createElement("div", { className: "mt-4" },
                    React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                        React.createElement(Label, null, lang[LG].firstName),
                        React.createElement(Label, null, lang[LG].lastName)),
                    React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                        React.createElement(TextField, { register: register("firstname", {
                                required: lang[LG].requiredFirstName,
                            }), error: errors.firstname, title: lang[LG].firstName, placeholder: lang[LG].firstNamePlaceholder, type: "text", onChange: function (event) {
                                setValue("firstname", event.target.value);
                            } }),
                        React.createElement(TextField, { register: register("lastname", {
                                required: lang[LG].requiredLastName,
                            }), error: errors.lastname, title: lang[LG].lastName, placeholder: lang[LG].lastNamePlaceholder, type: "text", onChange: function (event) {
                                setValue("lastname", event.target.value);
                            } })),
                    React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                        React.createElement(Label, null, lang[LG].email)),
                    React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                        React.createElement(TextField, { register: register("email", Validations.email(LG)), error: errors.email, title: lang[LG].email, placeholder: lang[LG].emailPlaceholder, type: "text", onChange: function (event) {
                                setValue("email", event.target.value);
                            } }),
                        React.createElement(TextField, { register: register("email_confirmation", {
                                required: lang[LG].requiredConfirmEmail,
                                validate: function (value) { return value.toLowerCase() == getValues("email").toLowerCase() || lang[LG].confirmEmailNotMatch; }
                            }), error: errors.email_confirmation, title: lang[LG].confirmEmail, placeholder: lang[LG].confirmEmailPlaceholder, type: "text", onChange: function (event) {
                                setValue("email_confirmation", event.target.value);
                            } }))),
                React.createElement("div", { className: "mt-4" },
                    React.createElement("div", { className: "flex justify-between" },
                        React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                            React.createElement(Label, null, lang[LG].password))),
                    React.createElement("div", { className: "mt-1 grid grid-cols-2 gap-2" },
                        React.createElement(TextIconField, { position: 'right', additionalInputClass: 'placeholder-c-gray', icon: getVisibilityIcon("password"), type: getInputType("password"), register: register("password", Validations.password()), title: lang[LG].passwordPlaceholder, error: errors.password, disableBgColor: true, onChange: function (event) {
                                setValue("password", event.target.value);
                            } }),
                        React.createElement(TextIconField, { register: register("password_confirmation", {
                                required: lang[LG].requiredConfirmPassword,
                                validate: function (value) { return value === getValues("password") || lang[LG].confirmPasswordNotMatch; }
                            }), position: 'right', additionalInputClass: 'placeholder-c-gray', error: errors.password_confirmation, title: lang[LG].confirmPasswordPlaceholder, icon: getVisibilityIcon("confirmPassword"), type: getInputType("confirmPassword"), disableBgColor: true, onChange: function (event) {
                                setValue("password_confirmation", event.target.value);
                            } })),
                    errors.password == null &&
                        errors.password_confirmation == null &&
                        React.createElement("div", null,
                            React.createElement(Text, { fontSize: "text-xs", additionalClass: "text-c-black-l" }, lang[LG].passwordMustBe))),
                React.createElement("div", { className: "mt-4" },
                    React.createElement("div", { className: "flex justify-between" },
                        React.createElement("div", { className: clsx({
                                "mt-1": true,
                                "grid grid-cols-2 gap-2": !props.homePage
                            }) },
                            React.createElement(Label, null, lang[LG].phone))),
                    React.createElement("div", { className: clsx({
                            "mt-1 grid gap-2": true,
                            "grid-cols-2": !props.homePage
                        }) },
                        React.createElement(PhoneMaskField, { value: getValues("phone"), onChange: function (event) {
                                setValue("phone", Functions.replaceNonDigits(event.target.value));
                            }, register: register("phone", Validations.phone()), error: errors.phone, title: lang[LG].phone, placeholder: lang[LG].phonePlaceholder, onValidate: function () {
                            } })),
                    errors.phone == null &&
                        React.createElement("div", null,
                            React.createElement(Text, { fontSize: "text-xs", additionalClass: "text-c-black-l" }, lang[LG].phoneInfo))),
                React.createElement("div", { className: "mt-4" },
                    React.createElement("div", { className: clsx({
                            "flex": true,
                            "flex-col space-y-5": props.homePage
                        }) },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(CustomCheckbox, { selected: (_a = getValues('readTermConditions')) !== null && _a !== void 0 ? _a : false, hoverClass: "hover:border-purple-800 hover:border hover:rounded", onUpdate: function (select) {
                                    setValue("readTermConditions", select);
                                }, error: errors.readTermConditions != null, inline: false }),
                            React.createElement("p", { className: "font-poppins text-sm font-light mt-1 ml-1" },
                                React.createElement("span", null,
                                    " ",
                                    lang[LG].acceptThe,
                                    " "),
                                React.createElement("span", { className: "text-c-purple underline cursor-pointer", onClick: openPolitics },
                                    " ",
                                    lang[LG].termsConditions,
                                    " "))),
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(CustomCheckbox, { selected: (_b = getValues('readPolicy')) !== null && _b !== void 0 ? _b : false, hoverClass: "hover:border-purple-800 hover:border hover:rounded", onUpdate: function (select) {
                                    setValue("readPolicy", select);
                                }, error: errors.readPolicy != null, inline: false }),
                            React.createElement("p", { className: "font-poppins text-sm font-light mt-1 ml-1" },
                                React.createElement("span", null,
                                    " ",
                                    lang[LG].acceptThe,
                                    " "),
                                React.createElement("span", { className: "text-c-purple underline cursor-pointer", onClick: openPolitics },
                                    " ",
                                    lang[LG].privacyPolicy,
                                    " "))),
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement(CustomCheckbox, { selected: (_c = getValues('phonemsg')) !== null && _c !== void 0 ? _c : false, hoverClass: "hover:border-purple-800 hover:border hover:rounded", onUpdate: function (select) {
                                    setValue("phonemsg", select);
                                }, error: errors.phonemsg != null, inline: false }),
                            React.createElement("p", { className: "font-poppins text-sm font-light mt-1 ml-1" },
                                React.createElement("span", null,
                                    " ",
                                    lang[LG].phonemsg))),
                        React.createElement(FormError, { response: errorResponse, extraSmall: false }),
                        React.createElement("div", { className: clsx({
                                'flex': true,
                                'flex-grow justify-end': !props.homePage,
                            }) },
                            React.createElement(SubmitButton, { title: lang[LG].register, radius: clsx({
                                    'rounded-md': props.homePage
                                }), additionalClass: clsx({
                                    'w-full justify-center': props.homePage,
                                }) })),
                        props.showLoginBtn && React.createElement("div", { className: clsx({
                                'flex': true,
                                'flex-grow justify-end': !props.homePage,
                            }) },
                            React.createElement(LightSubmitButton, { onClick: gotoLogin, title: lang[LG].alreadyHaveAccount, radius: clsx({
                                    'rounded-md': props.homePage
                                }), additionalClass: clsx({
                                    'w-full justify-center': props.homePage,
                                }) })))))));
    };
    return render();
};
export default RegisterForm;
