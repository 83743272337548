import React, { useState } from 'react';
import { CardElement } from "@stripe/react-stripe-js";
import clsx from "clsx";
import TextError from "../Field/TextError";
import { validation_lang } from "../../lang/validations";
import env from "../../config/env";
import HelpIcon from "../../assets/icons/HelpIcon";
import PointerPopover from "../Popover/PointerPopover";
import { lang } from "../../lang/lang";
import F from "../../utils/functions";
var CreditCard = function (props) {
    var _a = useState(), error = _a[0], setError = _a[1];
    var _b = useState(false), showStyle = _b[0], setShowStyle = _b[1];
    var LG = F.getLang();
    var render = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center" },
                React.createElement(CardElement, { onChange: function (event) {
                        if (event.error == null && event.complete) {
                            if (props.isValid)
                                props.isValid(true);
                        }
                        else {
                            if (props.isValid)
                                props.isValid(false);
                        }
                        setError({ error: event.error });
                    }, onFocus: function () {
                        setShowStyle(true);
                    }, onBlur: function () {
                        setShowStyle(false);
                    }, className: clsx({
                        "border-solid rounded w-80 h-10 px-2 py-1": true,
                        "border-c-red": props.showErrors,
                        "border-2 ring-c-purple border-c-purple": showStyle,
                        "border-c-gray border": !showStyle,
                    }), options: {
                        style: {
                            base: {
                                lineHeight: "2rem",
                                fontSize: "14px",
                                fontFamily: "poppins",
                                color: "#0D0C0C",
                            },
                        },
                        iconStyle: 'solid',
                        hidePostalCode: true,
                    } }),
                React.createElement(PointerPopover, { position: 'RIGHT', content: lang[LG].creditHelpText, element: React.createElement(HelpIcon, { color: env.iconColorPurple }), elementCls: "ml-2", additionalCls: "w-80" })),
            props.showErrors &&
                React.createElement(TextError, { message: (_b = (_a = error === null || error === void 0 ? void 0 : error.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : validation_lang[LG].creditCard })));
    };
    return render();
};
export default CreditCard;
