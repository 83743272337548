import React from "react";
var LightUnderlineText = React.forwardRef(function (props, ref) {
    var render = function () {
        var _a;
        var content = props.children;
        var isUnderLine = props.isUnderLine == true ? "" : "underline";
        var contentArr = content.split('{ACTION}');
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-poppins inline-block text-sm text-left font-light ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), onClick: props.onClick },
                contentArr[0],
                " ",
                React.createElement('span', {
                    onClick: props.onClickAction,
                    className: "font-poppins inline-block text-sm text-left text-c-purple cursor-pointer ".concat(isUnderLine)
                }, props.searchText),
                " ",
                contentArr[1])));
    };
    return render();
});
export default LightUnderlineText;
