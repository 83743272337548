import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';
import env from "../config/env";
var getCookieLang = function () {
    var _a;
    var cookies = new Cookies();
    var cookieLang = (_a = cookies.get(env.cookies.lang)) !== null && _a !== void 0 ? _a : "en";
    return cookieLang;
};
export var registerLanguageSlice = createSlice({
    name: "language",
    initialState: getCookieLang(),
    reducers: {
        updateLanguage: function (state, action) {
            state = action.payload;
            return state;
        },
    }
});
export var updateLanguage = registerLanguageSlice.actions.updateLanguage;
export default registerLanguageSlice.reducer;
