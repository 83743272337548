import React from "react";
import MasterCardIcon from "../../assets/icons/banks/MasterCardIcon";
import VisaIcon from "../../assets/icons/banks/VisaIcon";
import AmericanExpressIcon from "../../assets/icons/banks/AmericanExpressIcon";
var CreditCard = function (props) {
    var encode = function (input) {
        return "**** **** **** " + (input === null || input === void 0 ? void 0 : input.slice(input.length - 4));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "w-72 h-12 bg-white" },
            React.createElement("div", { className: "flex border-b h-12 mx-3" },
                React.createElement("div", { className: "h-10 pt-0.5 mt-0.5 pl-4" }, props.company === "master" ? React.createElement(MasterCardIcon, { color: "bg-black" }) : props.company === "visa" ?
                    React.createElement(VisaIcon, { color: "bg-black" }) : React.createElement(AmericanExpressIcon, { color: "bg-black" })),
                React.createElement("div", { className: "ml-3 border-l border-black h-8 mt-2 pl-2.5 pt-1" }, encode(props.code))))));
};
export default CreditCard;
