var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import ModalInAnswerQuestionnaire from "../../components/Layouts/ModalInAnswerQuestionnaire";
import BaseTitle from "../../components/Title/BaseTitle";
import { lang } from "../../lang/lang";
import env from "../../config/env";
import Text1FromDesign from "../../components/Title/Text1FromDesign";
import ForwardIcon from "../../assets/icons/ForwardIcon";
import SubmitButtonWithIcon from "../../components/Button/SubmitButtonWithIcon";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../utils/functions";
import { useQueryClients } from "../../hooks/client/useQueryClient";
var AnswerQuestionnaire = function () {
    var _a;
    var navigate = useNavigate();
    var _b = useState(), titleAndName = _b[0], setTitleAndName = _b[1];
    var _c = useState(""), languageState = _c[0], setLanguageState = _c[1];
    var LG = Functions.getLang();
    var hash = useParams().hash;
    var searchTitleAndName = useQueryClients().searchTitleAndName;
    var fetchTitleAndName = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, searchTitleAndName.mutateAsync({
                        hashedId: hash,
                        language: languageState
                    })];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) == 200) {
                        setTitleAndName(response === null || response === void 0 ? void 0 : response.data);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchTitleAndName();
    }, [hash, languageState]);
    var p2 = (titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.language) === "english" ?
        ((titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.questionnaireId) == env.anaQuestionnaireId) ? lang["en"].answerQuestionnaire.page3.a2 : lang["en"].answerQuestionnaire.page3.p2 :
        ((titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.questionnaireId) == env.anaQuestionnaireId) ? lang["fr"].answerQuestionnaire.page3.a2 : lang["fr"].answerQuestionnaire.page3.p2;
    p2 = p2.replace('{0}', (_a = titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.clientName) !== null && _a !== void 0 ? _a : '');
    var alreadyAnswerPage = (React.createElement(ModalInAnswerQuestionnaire, null,
        React.createElement("div", null,
            React.createElement("div", { className: "container flex inline flex w-full" },
                React.createElement("div", { className: "container flex justify-start" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", null,
                            React.createElement(BaseTitle, { additionalClass: "text-base capitalize" }, titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.questionnaireTitle)),
                        React.createElement("div", { className: "-mt-1" },
                            React.createElement(BaseTitle, { additionalClass: "text-sm capitalize" },
                                (titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.language) === "english" ? lang["en"].answerQuestionnaire.introductionPage.for : lang["fr"].answerQuestionnaire.introductionPage.for,
                                " ", titleAndName === null || titleAndName === void 0 ? void 0 :
                                titleAndName.clientName)))),
                React.createElement("div", { className: "container flex justify-end w-1/3" },
                    React.createElement("div", { className: "w-fit" },
                        React.createElement("div", { className: "flex justify-end" },
                            React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, (titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.language) === "english" ? lang["en"].answerQuestionnaire.page3.rightTop : lang["fr"].answerQuestionnaire.page3.rightTop)),
                        React.createElement("div", { className: "inline-flex justify-end my-2" },
                            React.createElement("div", { className: "h-2 w-66" },
                                React.createElement("div", { className: "w-full h-full bg-c-purple rounded-full" })))))),
            React.createElement("div", { className: "mt-12" },
                React.createElement(BaseTitle, { additionalClass: "text-base" }, (titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.language) === "english" ? lang["en"].answerQuestionnaire.alreadyAnsweredPage.title : lang["fr"].answerQuestionnaire.alreadyAnsweredPage.title)),
            React.createElement("div", { className: "mt-7 w-2/3" },
                React.createElement(Text1FromDesign, null, p2)),
            React.createElement("div", { className: "container inline flex pt-4 mt-72" },
                React.createElement("div", { className: "flex justify-end w-full" },
                    React.createElement("div", { className: "inline flex" },
                        React.createElement("div", { className: "ml-2" },
                            React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: "#FFFFFF" }), title: (titleAndName === null || titleAndName === void 0 ? void 0 : titleAndName.language) === "english" ? lang["en"].answerQuestionnaire.page3.button : lang["fr"].answerQuestionnaire.page3.button, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        navigate(env.routes.login);
                                        return [2 /*return*/];
                                    });
                                }); } }))))))));
    var render = function () {
        return (React.createElement(React.Fragment, null, alreadyAnswerPage));
    };
    return render();
};
export default AnswerQuestionnaire;
