export var politicsProductFR = {
    side1: 'Introduction',
    side2: 'Renseignements que nous recueillons et la façon dont nous les utilisons',
    side3: 'Liens vers des services tiers',
    side4: 'Fournisseurs de services tiers',
    side5: 'Rôles et fonctions des médias sociaux',
    side6: 'Conformité réglementaire selon le territoire',
    side7: 'Politique en matière de sécurité',
    side8: 'Changements et accès aux renseignements personnels',
    side9: 'Changement de contrôle',
    side10: 'Communication de renseignements personnels exigée par la loi',
    side11: 'Changements et mises à jour de la politique de confidentialité',
    side12: 'Consentement à la collecte et au traitement des renseignements',
    side13: 'Coordonnées',
    mainTitle1: 'Politique relative au site d’eduplan solutions et edpro',
    side1Intro: '<p>La protection de vos données confidentielles ou personnelles est primordiale., c\'est pourquoieduplan solutions(9201-8688 Québec Inc.), entreprise responsable deses Produits dont edpro, s\'assure d\'en tenir compte au début même de son processus de recherche et de développement de produit, dans le but d\'innover des produits, ayant par défaut, des fonctions qui respectent les exigences et encadrements légaux relatifs à la protection des données confidentielles. De plus,eduplan solutionsmet à votre disposition ses politiques de confidentialité afin que vous puissiez comprendre nos politiques et nos pratiques concernant la gestion des données personnelles.</p><br/>' +
        '<p>Cette politique a été élaborée dans le but de décrire les pratiques d\'eduplan solutions(notamment son site Web : <a href="www.eduplan.ca">www.eduplan.ca</a>) en matière de gestion des informations personnelles : soit le type d\'informations recueillies, soit la façon dont elles sont traitées, soit comment elles sont utilisées.</p><br>' +
        '<p>La présente Politique s’applique seulement au Site Web accessible à l’adresse <a href="www.eduplan.ca">https://eduplan.ca</a>, et non aux produits d\'eduplan solutions(le « Produit »). Bien que le Site Web d\'eduplan solutions fournit des liens menant à ses Produits, l’accès aux Produits et l’utilisation de ces Produits sont régis par une politique de confidentialité et des conditions d’utilisation distinctes.</p>' +
        '<p>eduplan solutions s\'engage à protéger votre vie privée. Cette politique de confidentialité ("Politique") est conçue pour vous informer sur les données personnellement identifiables que nous recueillons auprès de vous via nos produits. Ces données personnellement identifiables sont des données qui pourraient être utilisées, seules ou en combinaison avec d\'autres données, pour vous identifier en tant qu\'individu. Cette politique ne s\'applique pas aux informations que vous nous fournissez hors ligne ou de toute autre manière ni aux informations que vous soumettez via une évaluation que nous administrons au nom d\'une autre entité ou personne (par exemple, votre psychologue, votre psychoéducateur, votre orthopédagogue, votre employeur, votre conseiller en orientation ou tout autre professionnel qui utilisent nos produits).</p>' +
        '<p>Les informations soumises via une évaluation administrée au nom d\'une autre entité ou personne ne sont régies par la politique de confidentialité de cette entité ou personne, par son code de déontologie, et vous devez contacter cette entité ou cette personne pour toute question ou demande de confidentialité.</p>' +
        '<p>En plus des informations personnelles que nous collectons à votre sujet, nous pouvons également collecter, utiliser et partager des informations vous concernant qui ne sont pas personnellement identifiables ou qui sont anonymisées ou agrégées de sorte qu\'elles ne peuvent pas vous identifier.</p>' +
        '<p>En accédant au Site Web ou en l’utilisant, vous acceptez la présente Politique. Si vous n’acceptez pas la présente Politique, veuillez ne pas accéder au Site Web ni l’utiliser. Si vous avez des questions au sujet de la Politique, vous pouvez communiquer avec nous à l’adresse info@eduplan.ca.</p>' +
        '<p>Renseignements personnels des enfants, des dossiers scolaires ou des dossiers d\'aide particulière ou des dossiers professionnels</p>' +
        '<p>Le Site Web d\'eduplan solutions n’est pas destiné aux enfants de moins de 13 ans et n’est pas conçu pour servir récolter des données personnelles relatives à un enfant ou aux élèves (selon la définition du terme student personal information dans la loi américaine Children’s Online Privacy Protection Act [COPPA]) ou de dossiers éducatifs d’élèves (selon la définition du terme student education records dans la loi américaine Family Educational Rights and Privacy Act [FERPA]).</p>' +
        '<p>Le Site Web d\'eduplan solutions ne récolte pas de renseignements personnels auprès d’enfants ou tirés de dossiers éducatifs d’élèves.</p>' +
        '<p>Si vous êtes un enfant (vous êtes âgé (e) de 13 ans ou moins), n\'utilisez pas le Site Web d\'eduplan solutions et ne fournissez pas vos informations personnelles ou confidentielles par le biais du Site Web d\'eduplan solutions.</p>' +
        '<p>Si vous êtes un adulte, ne fournissez pas des informations personnelles ou confidentielles d\'un enfant ou d\'un élève par le biais du Site Web d\'eduplan solutions.</p>' +
        '<p>Si, par mégarde, des renseignements ou des dossiers personnels sont fournis par l\'intermédiaire du Site Web d\'eduplan solutions, nous les supprimerons automatiquement.</p>' +
        '<p>Si vous découvrez des renseignements personnels ou confidentiels sur le Site Web d\'eduplan solutions, veuillez-nous en aviser rapidement à l’adresse info@eduplan.ca.</p>' +
        '<p>Pour en savoir plus sur nos politiques et pratiques relatives aux lois COPPA et FERPA pour l’utilisation de nos Produits, veuillez consulter nos Conditions d\'utilisation et la Politique de confidentialité relative aux Produits.</p>',
    side2Intro: '<p>Les types de renseignements que nous recueillons</p><br/>' +
        '<p>Lors de votre utilisation du Site Web, vous pouvez nous fournir directement des renseignements à votre sujet pour profiter de certaines fonctionnalités offertes. Celles-ci comprennent notamment les renseignements fournis par le biais de différents formulaires sur le Site Web, notamment quand vous nous envoyez une demande d’information ou que vous remplissez un formulaire d\'adhésion à notre infolettre ou de participation à nos projets de recherche et de développement.</p><br/>' +
        '<p>Vous pourriez devoir fournir votre adresse courriel, vos prénom et nom, votre adresse de livraison, votre numéro de téléphone ou vos renseignements de paiement.</p>' +
        '<p>En fournissant votre numéro de téléphone vous consentez automatiquement à ce qu’Eduplan Solutions vous contacte par message texte à des fins de connexion par authentification à deux facteurs ou à des fins d’accès à votre compte en case d’oubli de vos identifiants de connexion.</p><br/>',
    side3Intro: '<p>Nous pouvons utiliser des technologies qui recueillent automatiquement certaines données au sujet de votre navigateur ou de votre appareil pendant que vous utilisez le Site Web. Ces données peuvent comprendre : des données de navigation, dont les pages consultées, les liens sur lesquels vous avez cliqué, les sources qui vous ont amené sur le Site Web, l’heure, la date et les renseignements de localisation (pays et ville), ainsi que des données relatives à l’appareil, dont l’adresse IP, le système d’exploitation, l’interface, la langue et la version du navigateur.</p><br/>' +
        '<p>Les technologies que nous utilisons pour la collecte automatique de données peuvent comprendre les témoins de connexion et d’autres solutions semblables. Les témoins de connexion sont de petits fichiers de données qui sont communément stockés sur votre appareil lorsque vous visitez des sites Web et utilisez des services en ligne. Ils servent généralement à faire fonctionner les sites Web ou à en améliorer le fonctionnement, ainsi qu’à fournir des renseignements et aider avec la personnalisation. D’autres technologies semblables aux témoins (les « technologies de stockage local ») peuvent aussi stocker de petites quantités de données sur votre appareil. eduplan solutions utilise les types suivants de témoins et de technologies de stockage local (ces deux technologies étant ensemble désignées par le terme « témoins » ci-après).</p><br/>' +
        '<p>Les témoins de performance et de fonctionnalité. Bien que ces témoins ne soient pas essentiels, ils nous aident à personnaliser et améliorer l’expérience de l’utilisateur.</p><br/>' +
        '<p>Les témoins publicitaires. Ces témoins utilisent les renseignements relatifs à votre visite du Site Web, ils nous aident à vous offrir une expérience utilisateur qui correspond à vos intérêts.</p><br/>' +
        '<p>Si vous souhaitez désactiver l’utilisation des témoins sur votre appareil, veuillez vous rendre dans le menu des préférences de votre navigateur et désactivez les témoins. Vous pouvez, également, utiliser le mode privé lorsque vous utilisez nos services.</p><br/>',
    side4Intro: '<p>eduplan solutions récolte et utilise uniquement les renseignements dont nous avons besoin pour remplir nos fonctions et nos obligations envers nos clients.</p><br/>' +
        '<p>En règle générale, eduplan solutions utilise les données nécessaires pour répondre aux intérêts, pour satisfaire les besoins, pour répondre aux préférences des utilisateurs, pour fournir nos produits et nos services, pour établir et maintenir des comptes d\'utilisateurs, pour traiter les commandes et les paiements, pour soutenir les clients, pour communiquer avec les membres à propos d\'événements, de programmes, de produits, de services et d\'offres, ainsi que répondre aux demandes d\'abonnement ou d\'enregistrement.</p><br/>' +
        '<p>Nous utilisons également vos informations pour analyser les préférences, les tendances et les statistiques afin d\'améliorer nos sites et nos services. Ce faisant, nous pouvons utiliser et partager des informations statistiques ou démographiques sous forme agrégée qui ne vous identifient pas personnellement avec des tiers afin d\'améliorer les Sites pour nos utilisateurs.</p><br/>' +
        '<p>Nous pouvons pareillement utiliser vos informations à des fins de communications marketing, promotionnelles ou autres transactions limitées.</p><br/>' +
        '<p>Si vous ne refusez pas de recevoir des communications marketing, promotionnelles ou d\'autres informations de la part de ou au nom d\'eduplan solutions, nous pouvons utiliser votre adresse courriel pour vous contacter occasionnellement pour annoncer des fonctionnalités, des produits ou des services qui peuvent être disponibles sur nos sites ou pour envoyer des nouvelles et des informations concernant Eduplan solutions, ses produits ou ses services.</p><br/>' +
        '<p>Vous pouvez refuser de recevoir des communications promotionnelles à tout moment en suivant les instructions contenues dans la communication par courriel que vous recevez.</p><br/>' +
        '<p>Il est possible que votre adresse courriel soit utilisée pour communiquer avec vous et mettre à jour vos informations relatives aux services, aux fonctionnalités ou aux transactions que vous avez demandées via nos sites, ainsi que pour vous informer des nouvelles versions de produits et/ou des mises à jour logicielles.</p><br/>' +
        '<p>https://www.un.org/sustainabledevelopment/fr/peace-justice/</p>' +
        '<p>Il est aussi possible que votre numéro de téléphone soit utilisé afin de communiquer avec vous et cela uniquement à des fins d’identification à deux facteurs ou pour accéder à votre compte en cas d’oubli de vos identifiants de connexion. </p><br/>',
    side5Intro: '<p>Le Site Web peut contenir des liens menant vers d’autres pages. eduplan solutions s’assure de mettre à votre disposition des liens menant vers des sites Web qui satisfont aux exigences strictes de décence, d’équité et d’intégrité. eduplan solutions essaie de faire en sorte que ces liens soient sécuritaires pour tous, mais les pratiques de ces sites Web en matière de confidentialité et de collecte des données sont entièrement distinctes de celles d\'eduplan solutions. Par conséquent, leurs pratiques ne sont pas couvertes par la présente Politique.</p><br/>' +
        '<p>D’autres sites Web externes pourraient mettre à votre disposition un lien vers eduplan solutions, et ce avec ou sans le consentement d\'eduplan solutions. C\'est pourquoi, eduplan solutions n’est pas responsable du contenu et des politiques de ces sites Web externes.</p>',
    side6Intro: '<p>Le Site Web peut comprendre des liens vers des médias sociaux, tels que des icônes « J’aime » de Facebook ou d\'Instagram ou inclure des options pour vous permettre d’interagir avec le service par l’entremise de vos comptes sur certains réseaux sociaux tiers ou sites de stockage réseau tiers. Ces fonctionnalités de médias sociaux peuvent configurer un témoin pour assurer leur bon fonctionnement, ainsi que sauvegarder votre adresse IP et la page du Site Web que vous visitez. Les fonctionnalités de médias sociaux pourraient aussi vous permettre de partager avec votre réseau, par le biais de votre page de profil fournie par un réseau social tiers, des renseignements sur vos activités sur le Site Web. Les fonctionnalités des médias sociaux sont hébergées par un tiers ou directement sur notre Site Web. Vos interactions avec les fonctionnalités des médias sociaux sont régies par la politique de confidentialité de l’entreprise qui fournit la fonctionnalité respective. Si vous n’êtes pas à l’aise avec ces réseaux sociaux et les fonctionnalités qui s’y rapportent, vous devez vous assurer de ne pas les utiliser ni interagir avec ces derniers.</p><br/>',
    side7Intro: '<p>Union européenne</p><br/>' +
        '<p>Depuis le 24 mai 2018, le Règlement général sur la protection des données de l’Union européenne (RGPD) établit les règles relatives à la collecte, à l’utilisation et à la conservation des données personnelles des citoyens de l’Union européenne. Eduplan solutions se conforme au RGPD.</p><br/>' +
        '<p>eduplan solutions est une incorporation canadienne dont le siège se trouve à Montréal, au Canada. Pour exploiter ses services, eduplan solutions peut recueillir, utiliser et conserver des données personnelles d’utilisateurs du Site Web qui se trouvent dans l’Union européenne, le tout conformément à la présente Politique. La Commission européenne a décrété que les entités commerciales au Canada satisfont aux exigences du RGPD, ou encore fournissent déjà un niveau de protection adéquat des données. Si vous souhaitez consulter une liste à jour des pays qui fournissent un niveau de protection adéquat des données, veuillez visiter le site de l’Union européenne.</p><br/>' +
        '<p>De plus, afin d’exploiter ses services, eduplan solutions fait affaire avec des sous-traitants tiers aux États-Unis. Par conséquent, quand eduplan solutions transfère des renseignements personnels hors de l’espace économique européen et vers un pays ou un cadre législatif n’assurant pas, selon la Commission européenne, un niveau de protection suffisant des données personnelles (ce qui comprend les États-Unis), ces transferts sont régis par des ententes de traitement des données qui sont conformes aux exigences de l’Union européenne en matière de protection des données.</p><br/>',
    side8Intro: '<p>eduplan solutions utilise une approche, des stratégies et des ressources conformes aux normes de l’industrie afin de protéger la confidentialité, la sécurité et l’intégrité des données de ses clients contre toute forme non autorisée d’utilisation, de consultation, de communication, de modification ou de perte, ainsi que de destruction illégale ou accidentelle. Nous faisons en sorte que ces données protégées sont chiffrées tant à l’arrêt qu’en transit, et qu’elles sont conservées ou supprimées uniquement de façon conforme à la présente Politique ou à toute entente ayant préséance conclue avec un organisme d’enseignement ou un organisme de santé.</p><br/>' +
        '<p>eduplan solutions ne partage pas de façon informelle avec ses employés les données couvertes par la présente politique. Seuls les employés qui ont besoin des données protégées pour s’acquitter de leurs fonctions peuvent y accéder. Dans ces cas, c’est le principe du droit d’accès minimal qui s’applique, c’est-à-dire que chaque programme ou employé se voit accorder l’accès le plus restreint possible permettant la réalisation de ses tâches. Les employés qui ont accès à des données protégées reçoivent sont soumis à une politique, à un respect de code de déontologie établit par eduplan solutions pour ses employés, de la formation au sujet de leurs responsabilités lorsqu’ils traitent ces données et des diverses lois et ententes en matière de sécurité des données.</p><br/>' +
        '<p>Ces employés reçoivent aussi un identifiant d’utilisateur unique à des fins de reddition de comptes.</p><br/>' +
        '<p>eduplan solutions effectue périodiquement des évaluations des risques et des vulnérabilités, ainsi que des vérifications de conformité relatives à la confidentialité et à la sécurité des données.</p><br/>' +
        '<p>eduplan solutions doit rectifier rapidement toute vulnérabilité de sécurité qui est relevée. Nous avons également adopté un programme de gestion de confidentialité qui précise et guide les procédures en cas d\'incidents ainsi que des pratiques exemplaires en matière de réaction à la compromission de renseignements personnels.</p><br/>' +
        '<p>Bien qu\'eduplan solutions s’engage à mettre en œuvre des pratiques exemplaires relatives à la sécurité de l’information et des données, il nous est impossible de garantir de façon totale la sécurité sur ce programme, puisque les technologies des virus et du piratage évoluent constamment et qu’il existe d’autres facteurs de risque, comme les défaillances imprévisibles touchant le matériel ou les logiciels. Par conséquent, eduplan solutions ne peut être tenue responsable des pertes ou des altérations de données. eduplan solutions notifiera par courriel tout utilisateur d’une brèche, le cas échéant, de sécurité le touchant dans les trois jours suivant la découverte de la situation.</p><br/>' +
        '<p>Si vous souhaitez en savoir plus sur nos mesures de sécurité, veuillez nous écrire à l’adresse info@eduplan.ca.</p>',
    side9Intro: '<p>Les utilisateurs peuvent aussi demander une copie de leurs renseignements personnels ou modifier tout renseignement erroné en écrivant à l’adresse info@eduplan.ca. Un représentant d\'eduplan solutions communiquera ensuite avec l’utilisateur pour vérifier son identité.</p><br/>' +
        '<p>Les utilisateurs peuvent aussi corriger et mettre à jour leurs renseignements personnels par la méthode décrite ci-dessus.</p>',
    side10Intro: '<p>Si tout ou partie d\'eduplan solutions ou de ses actifs venait à être acquis par un tiers ou fusionné avec lui, les renseignements personnels que nous avons recueillis des utilisateurs feraient partie des actifs transférés à ce tiers ou acquis par lui. La présente Politique continue de s’appliquer à vos renseignements, et l’acquéreur serait dans l’obligation de traiter ceux-ci conformément aux présentes.</p><br/>' +
        '<p>Dans l’éventualité peu probable qu\'eduplan solutions fasse faillite, tous les renseignements des utilisateurs seront supprimés de façon sécuritaire dans les 12 mois suivant la faillite.</p>',
    side11Intro: '<p>Nous pouvons communiquer des renseignements personnels si nous pensons, de bonne foi, qu’il est nécessaire de le faire pour se conformer à la loi, notamment à une assignation à comparaître ou à toute autre procédure judiciaire. Nous pourrions aussi devoir communiquer des renseignements personnels si nous pensons, de bonne foi, qu’il est nécessaire de le faire pour protéger les droits, les biens ou la sécurité d\'eduplan solutions, de nos employés, de notre communauté ou de toute autre personne, ou alors pour prévenir des violations de nos Conditions d’utilisation ou de tout autre contrat. Ces communications comprennent l’échange d’information avec d’autres entreprises et organisations à des fins de protection contre la fraude ou pour répondre à des demandes du gouvernement.</p>',
    side12Intro: '<p>La présente Politique entre en vigueur le 1er janvier 2022. eduplan solutions peut, à son entière discrétion, mettre à jour ou modifier la présente Politique. Si nous apportons des changements à la Politique ou aux conditions d’utilisation qui s’y rapportent, nous publierons la version modifiée sur le Site Web.</p><br/>' +
        '<p>eduplan solutions demande aux utilisateurs de prendre connaissance de la version à jour de la politique de confidentialité ou des conditions d’utilisation. Si les utilisateurs n’acceptent pas la nouvelle version de la Politique, ils ne doivent pas accéder au Site Web ou l’utiliser. Le fait pour l’utilisateur de continuer à utiliser le Site Web après l’entrée en vigueur de la nouvelle version de la politique de confidentialité constitue son acceptation de celle-ci.</p>',
    side13Intro: '<p>En accédant au Site Web, les utilisateurs déclarent explicitement qu’ils permettent de leur propre gré à eduplan solutions de recueillir, de traiter et d’utiliser les renseignements personnels indiqués dans la présente Politique, de la façon qui y est précisée. Les utilisateurs déclarent aussi être conscients des raisons pour lesquelles eduplan solutions recueille, traite et utilise ces renseignements, de la manière dont ces renseignements sont recueillis, de la manière dont la confidentialité des utilisateurs est protégée et du choix qu’ont les utilisateurs de retirer leur consentement.</p><br/>',
    side14Intro: '<p>Nous accordons de l\'importance au service que nous offrons à nos utilisateurs et au lien de confiance nous établissons avec eux. Les utilisateurs qui ont des questions au sujet de la présente Politique concernant le Site Web peuvent communiquer avec eduplan solutions par courriel pour par téléphone :</p><br/>' +
        '<p> •    Courriel: info@eduplan.ca</p><br/>' +
        '<p> •    Téléphone : 514-814-PLAN</p><br/>' +
        '<p> •    Bureau 3440,</p><br/>' +
        '<p> •    6900 Boulevard Décarie, Montréal</p><br/>' +
        '<p>Copyright © 2022 Eduplan solutions - All rights reserved.</p>',
};
export var politicsRelativeFR = {
    mainTitle: 'Politique relative à edpro',
    side1: 'Introduction',
    side2: 'L’Environnement scolaire, clinique ou pédagogique',
    side3: 'Renseignements personnels recueillis',
    side4: 'Témoins',
    side5: 'À quoi servent les renseignements recueillis?',
    side6: 'Accès aux données',
    side7: 'Suppression des dossiers d’élèves ou de clients',
    side8: 'Politique en matière de sécurité',
    side9: 'Conformité réglementaire selon le territoire',
    side1Intro: '<p>eduplan solutions / edpro prend très au sérieux la confidentialité de ses utilisateurs, qui comprennent des enfants, des jeunes, des élèves, des adultes, des professionnels de l\'éducation, la santé, des services sociaux et des parents ou tuteurs d\'enfants. eduplan solutions / edpro s’engage à protéger la vie privée de ses utilisateurs dans le cadre de l’offre, par ses produits technologiques cliniques ou pédagogiques (le « Produit »), d’une expérience personnalisée et enrichissante.</p><br />' +
        '<p>Puisque les Produits ou Services sont conçus pour être utilisés, entre autres, dans un environnement scolaire, clinique et pédagogique, et, que ces Produits ou Services sont conçus pour être utilisés pour des adultes, de jeunes adultes, des adolescents, et des enfants, eduplan solutions / edpro s’est engagé, pour l’ensemble de ses Produits ou Services, par la version 2020 du Student Privacy Pledge, de respecter la vie privée des élèves. Le Student Privacy Pledge est un engagement public pour la collecte et l\'utilisation responsables des données des élèves pour les organismes ou entreprises qui offrent des services aux élèves.</p><br />' +
        '<p>Le Student Pivacy Pledge 2020 est le résultat d’un effort public collaboratif visant à mettre à jour le Pledge. Depuis la création du Legacy Pledge en 2014, au regard de l\'utilisation des données des élèves, de la technologie et des lois américaines. Enfin, les entreprises edtech, les groupes de la société civile, les experts en matière de confidentialité des étudiants et au grand public ont été demandés de se prononcer sur la manière dont l\'engagement peut être amélioré pour protéger efficacement les informations des élèves en 2020 et au-delà.</p><br />' +
        '<p>En somme, les entités engagées assument la responsabilité à la fois de soutenir l\'utilisation efficace des informations sur les élèves et de protéger la confidentialité et la sécurité des informations des élèves.</p><br />' +
        '<p>Nous nous engageons à assurer une gestion responsable et une utilisation appropriée des données confidentielles conformément aux engagements prévus par le Student Privacy Pledge et dans le respect de toutes les lois qui nous sont applicables en tant que fournisseurs de services scolaires.</p><br />' +
        '<p>Cet engagement prévoit notamment ce qui suit :</p><br />' +
        '<p> •    Nous ne recueillons, stockons, traitons et partageons que les renseignements relatifs aux élèves qui sont nécessaires à des fins éducatives ou scolaires et autorisées par le parent ou l’élève.</p><br />' +
        '<p> •    Nous ne vendons pas de renseignements personnels relatifs aux élèves.</p><br />' +
        '<p> •    Nous n’utilisons et ne communiquons pas les renseignements relatifs aux élèves à des fins de publicités comportementales ciblées.</p><br />' +
        '<p> •    Nous ne conservons pas de renseignements personnels relatifs aux élèves au-delà de la période nécessaire pour les fins éducatives ou scolaires en question.</p><br />' +
        '<p>Vous pouvez lire la version complète de 2020 du Student Privacy Pledge ici.</p><br />' +
        '<p>Les utilisateurs qui ont des questions au sujet de la présente politique de confidentialité (la « Politique ») peuvent communiquer avec eduplan solutions / edpro en écrivant à info@eduplan.ca.</p>',
    side2Intro: '<p>Le terme « Environnement scolaire » sert à désigner, dans la présente Politique, la salle de classe, les salles qui offrent des services spécialisés, individualisés ou en sous-groupes, où les professionnels non enseignants ou intervenants, les professionnels-enseignants et l’administration de l’école ou du centre scolaire se retrouvent.</p><br />' +
        '<p>Le terme « Environnement clinique ou pédagogique » sert à désigner, dans la présente Politique, les centres, les cliniques ou les différents organismes du secteur de la santé et des services sociaux où les professionnels non-enseignants ou intervenants et l’administration du dit milieu s’y retrouvent.</p><br />' +
        '<p>Le terme « Client » se rapporte à une personne recevant les services d’un Intervenant qui utilisent nos Produits ou Services.</p><br />' +
        '<p>Le terme « Intervenant » se réfère à une personne qui offre des services spécialisés ou de relation d’aide comme par exemple: auxiliaire d\'intégration, conseiller d\'orientation, enseignant en adaptation scolaire, éducateur spécialisé, ergothérapeute, orthophoniste, orthopédagogue, neuropsychologue, personne-ressource, physiothérapeute, préposé aux personnes en situation de handicap, psychoéducateur, psychologue, psychothérapeute, technicien en éducation spécialisée, tuteur, travailleur social, etc.).</p><br />' +
        '<p>Le terme « Élève » fait référence à un enfant scolarisé au préscolaire, primaire ou secondaire âgé de moins de 18 ans, ou, un élève ayant plus de 18 ans scolarisé dans une école spéciale.</p><br />' +
        '<p>Le terme « Parent » sert à désigner le ou les parents ou le ou les tuteurs d’un enfant scolarisé au préscolaire, primaire ou secondaire âgé de moins de 18 ans, un enfant ayant plus de 18 ans scolarisés dans une école spéciale, ou, un adulte en situation de handicap dont le parent ou le tuteur a été désigné par un juge comme curateur ou tuteur qui pourra assumer la curatelle ou tutelle handicap.</p><br />' +
        '<p>Le terme « Professionnel enseignant » sert à désigner les enseignants du préscolaire, primaire et secondaire, les enseignants de classe alternative, spéciale ou autre.</p><br />' +
        '<p>Le terme « Professionnel non enseignant » sert à désigner tout autre professionnel comme les conseillers pédagogiques, les coordonnateurs de services, les cadres intermédiaires ou les directions.</p><br />' +
        '<p>Le terme « Utilisateurs » sert à désigner les différents types d\'utilisateurs ; client, intervenant, élève, parent, professionnel enseignant et professionnel non enseignant.</p><br />' +
        '<p>Puisque les Produits ou Services sont conçus pour être utilisés, entre autres, dans un environnement scolaire, clinique et pédagogique, une grande partie des renseignements collectés sont destinés à être communiqués dans le cadre de l’Environnement scolaire, clinique ou pédagogique; par conséquent, les activités, les renseignements ou les communications concernant l’élève via les Produits ou Services pourraient être visibles aux personnes présentes (sous certaines conditions et avec consentement des parents ou tuteurs de l’élève) dans son environnement scolaire, clinique ou pédagogique, soit toute personne qui est appelée à offrir des services à l’élève.</p><br />' +
        '<p>De plus, certains renseignements seront accessibles aux autres professionnels non enseignants, professionnels, enseignants et aux administrateurs ou employés de l’école, du centre scolaire, de la clinique, du centre pédagogique ou de l’organisation (voir la rubrique « Accès aux données des utilisateurs des Produits d\'eduplan solutions / edpro » ci-dessous).</p><br />' +
        '<p>Le partage des renseignements mentionnés ci-dessus est assujetti à la présente Politique et aux politiques de confidentialité des établissements du secteur de l’éducation, de la santé et des services sociaux en question. Si les utilisateurs ont des questions au sujet du partage de ces renseignements en vertu de ces dernières politiques, eduplan solutions / edpro leur suggère de communiquer avec l’établissement d’enseignement concerné ou l’établissement issu du secteur de la santé et des services sociaux.</p><br />' +
        '<p>eduplan solutions / edpro a mis en œuvre des mesures de sécurité visant à assurer que ces données ne soient pas partagées à l’extérieur de l’Environnement scolaire, clinique ou pédagogique, ou, que ces données soient partagées à l’extérieur de l’Environnement scolaire, clinique ou pédagogique, selon les conditions établies par la présente Politique ainsi que la politique de l’établissement scolaire, du centre, de la clinique ou de l’organisation.</p>',
    side3Intro: '<p>De manière générale, les renseignements recueillis sont les suivants :</p><br />' +
        '<p>pour les Produits ou Services d\'eduplan solutions / banqo, smartplan ou proplan</p><br />' +
        '<p> •    Nom</p><br />' +
        '<p> •    Prénom</p><br />' +
        '<p> •    Portrait</p><br />' +
        '<p> •    Besoins prioritaires</p><br />' +
        '<p> •    Code permanent</p><br />' +
        '<p> •    Année scolaire</p><br />' +
        '<p> •    École</p><br />' +
        '<p> •    Forme de différenciation</p><br />' +
        '<p> •    Mesure d\'adaptation</p><br />' +
        '<p> •    Objectifs personnalisés</p><br />' +
        '<p> •    Moyens</p><br />' +
        '<p> •    Stratégies</p><br />' +
        '<p> •    Ressources</p><br />' +
        '<p> •    Services</p><br />' +
        '<p> •    Progression</p><br />' +
        '<p>Pour l\'ensemble des Produits ou Services d\'eduplan solutions / edpro, c’est le type d’utilisateur qui détermine les renseignements spécifiques qui sont collectés.</p><br />' +
        '<p>Les Produits ou Services d\'eduplan solutions / edpro NE RECUEILLE PAS les données suivantes :</p><br />' +
        '<p> •    Données biométriques physiologiques;</p><br />' +
        '<p> •    Données portant sur le statut des élèves relativement aux repas gratuits ou à prix réduit;</p><br />' +
        '<p> •    Listes de contacts ou d’amis des utilisateurs;</p><br />' +
        '<p> •    Adresses des élèves ou des parents.</p>',
    side4Intro: '<p>Les témoins de connexion sont de petits fichiers de données qui sont communément stockés sur votre appareil lorsque vous visitez des sites Web et utilisez des services en ligne. Ils servent généralement à faire fonctionner les sites Web ou à en améliorer le fonctionnement, ainsi qu’à fournir des renseignements et aider avec la personnalisation. D’autres technologies semblables aux témoins (les « technologies de stockage local ») peuvent aussi stocker de petites quantités de données sur votre appareil.</p><br />' +
        '<p>Les Produits ou Services d\'eduplan solutions / edpro utilisent les types suivants de témoins et de technologies de stockage local (ces deux technologies étant ensemble désignées par le terme « témoins » ci-après);</p><br />' +
        '<p>Témoins de performance et de fonctionnalité : Ces témoins ne sont pas essentiels, mais nous aident à personnaliser et améliorer l’expérience de l’utilisateur. Par exemple : ils peuvent nous aider à nous souvenir de vos préférences, ou alors de votre identifiant et de votre mot de passe, pour que vous n’ayez pas à les saisir à nouveau chaque fois que vous utilisez nos services.</p><br />' +
        '<p>Le Produit n’utilise pas de témoins à des fins publicitaires.</p><br />' +
        '<p>Si vous souhaitez désactiver l’utilisation des témoins sur votre appareil, veuillez vous rendre dans le menu des préférences de votre navigateur, puis désactiver les témoins ou utilisez le mode privé lorsque vous utilisez nos services.</p>',
    side5Intro: '<p>Les Produits ou Services d\'eduplan solutions / edpro, recueillent et utilisent que les renseignements nécessaires pour s’acquitter de leurs obligations et pour fournir ou améliorer ses services. Plus précisément, les renseignements sont recueillis pour les principales raisons suivantes :</p><br />' +
        '<p> •    exploiter le ou les Produits ou Services;</p><br />' +
        '<p> •    permettre aux usagers de se connecter au (x) Produit (s);</p><br />' +
        '<p> •    permettre aux professionnels enseignants ou non enseignants de gérer le (s) Produit (s) et d’interagir avec le (s) Produit (s);</p><br />' +
        '<p> •    permettre aux administrateurs et gestionnaires du ou des Produits ou Services d’attribuer les rôles et les permissions au regard de ou des Produit (s)</p><br />' +
        '<p> •    permettre aux parents ou tuteurs de l’élève d’utiliser le (s) Produit(s);</p><br />' +
        '<p> •    permettre à l’élève d’utiliser le (s) Produit (s);</p><br />' +
        '<p> •    permettre aux utilisateurs de partager des fichiers pédagogiques à des fins d’utilisation</p><br />' +
        '<p> •    permettre aux utilisateurs de voir la progression de l’élève au regard d’habiletés ciblées ou des objectifs qui ont été fixés pour lui;</p><br />' +
        '<p> •    fournir des données analytiques aux administrateurs ou gestionnaires du ou des Produits ou Services ; à des fins d’analyses statistiques administratives, à des fins de soutien logiciel, de soutien à la clientèle; des fins de vente et de facturation.</p><br />' +
        '<p>Les Produits ou Services d\'eduplan solutions / edpro ne font pas de publicité ou de marketing auprès des élèves ou de leurs parents.</p><br />' +
        '<p>Aucun renseignement personnel concernant un élève ne sera utilisé à des fins de marketing.</p><br />' +
        '<p>Les Produits ou Services d\'eduplan solutions / edpro ne communiquent pas des renseignements personnels à l’extérieur de l’Environnement scolaire, clinique ou pédagogique sauf aux fins établies dans la présente Politique, sans avoir préalablement obtenu le consentement écrit de ou des personnes concernées.</p><br />' +
        '<p> •    Les parents qui se connectent au (x) Produit (x) n’ont accès qu’aux renseignements personnels qui concernent leur enfant.</p><br />' +
        '<p> •    Les élèves qui se connectent au (x) Produit (x) n’ont accès qu’aux renseignements personnels qui les concernent.</p><br />' +
        '<p> •    Les professionnels enseignants ou non enseignants qui se connectent au (x) Produit (x) n’ont accès qu’aux renseignements personnels qui concernent leur élève ou client.</p><br />' +
        '<p>Les renseignements collectés sous une forme agrégée, c’est-à-dire ceux qui ne permettent pas d’identifier des utilisateurs (les « données anonymisées »), sont utilisés pour les fins suivantes :</p><br />' +
        '<p> •    amélioration des Produit (s) ou Services;</p><br />' +
        '<p> •    recherche et analyse statistique;</p><br />' +
        '<p> •    marketing lié au (x( Produit (s);</p><br />' +
        '<p> •    et, soutien à la clientèle.</p><br />' +
        '<p>Pour créer les données anonymisées, tous les marqueurs directs et indirects de l’identité sont retirés. Ces marqueurs comprennent notamment le nom, l’identifiant d’utilisateur, la date de naissance et les renseignements de localisation. De plus, eduplan solutions / edpro s’engage à ne pas tenter de redonner à ces données leur caractère permettant l’identification, et à transférer ces données anonymisées à aucune autre partie qui refuse de s’engager de cette façon.</p><br />' +
        '<p>Concernant les données anonymisées, tous les renseignements seront détruits de façon irrévocable après la collecte et l’utilisation de ces données. Aucune précaution liée à la préservation de l’anonymat des utilisateurs n’est à prévoir. Comme aucun renseignement personnel n’est associé aux données, aucune précaution liée à l’anonymat n’est à prévoir.</p>',
    side6Intro: '<p>Tous les accès des utilisateurs au (x) Produit (s), ou aux Services, ainsi que toutes les interactions entre elles dans le ou les Produits ou par l’entremise des Services, prennent place dans l’Environnement scolaire, clinique ou pédagogique et entre les utilisateurs.</p><br />' +
        '<p>Les différents utilisateurs concernés ont accès aux dossiers des élèves ou clients et sont tenus de respecter les politiques d\'eduplan solutions / edpro ainsi que les conditions d\'utilisation.</p><br />' +
        '<p>Les dossiers des élèves appartiennent à l’école, au centre scolaire, au un centre et / ou à une clinique, et ils sont sous leur contrôle, le cas échéant. Les élèves peuvent accéder à leur dossier, si ces derniers ont été invités par un administrateur, en se connectant à leur compte d’élèves. Les parents des élèves âgés de moins de 18 ans peuvent accéder aux renseignements de leur enfant par le biais du compte de parent en se connectant avec leur compte, en communiquant avec l\'administrateur du compte ou en écrivant à eduplan solutions / edpro à l’adresse info@eduplan.ca.</p><br />' +
        '<p>Si des renseignements erronés doivent être corrigés, la question doit d’abord être portée à l’attention de l’administrateur du dossier. Si l’enseignant ou l’école ne sont pas en mesure de faire la correction, les parents doivent en aviser eduplan solutions / edpro en écrivant à l’adresse info@eduplan.ca, après quoi celle-ci collaborera avec l’administrateur du dossier pour que l’information erronée soit corrigée.</p><br />' +
        '<p>Les tiers n’ont pas accès à des renseignements personnels, à des dossiers d’élève, sauf de la façon prévue dans la présente Politique ou après avoir obtenu le consentement écrit du parent de l’élève en question, ou de l’élève lui-même s’il est âgé d’au moins 18 ans.</p><br />' +
        '<p>Si eduplan solutions / edpro découvre qu’un dossier d’élève a été communiqué de façon non autorisée, elle doit, dans les trois jours suivants, en aviser par courriel l’école, la clinique ou le centre pédagogique, ainsi que l’administrateur du compte, du parent et, le cas échéant, de l’élève.</p><br />' +
        '<p>Aucun marketing n’est fait auprès des élèves et des parents.</p><br />' +
        '<p>eduplan solutions / edpro ne communique pas de renseignements personnels à des tiers pour des fins marketing. Pour en savoir plus au sujet de nos pratiques en matière de confidentialité dans le cadre de l’exploitation de notre site Web d’entreprise, veuillez consulter la Politique relative au site Web d\'eduplan solutions ici.</p>',
    side7Intro: '<p>Les dossiers d’élève ou clients sont supprimés en fonction des besoins de l\'utilisateur. De manière générale, ces types de dossiers sont archivés à des fins pédagogiques et d’utilité pour l’élève ou pour le client par l\'utilisateur, ou, supprimés lorsque ce dernier n’a plus d’utilité.</p><br />' +
        '<p>Les parents et les élèves âgés de plus de 18 ans peuvent demander à eduplan solutions / edpro de supprimer de sa base de données, respectivement, le compte de leur enfant ou leur propre compte, ainsi que tous les renseignements s’y rapportant en écrivant à l’adresse info@eduplan.ca.</p>',
    side8Intro: '<p>En somme, eduplan solutions / edpro utilisent des méthodes conformes aux normes de l\'industrie pour garantir la confidentialité, la sécurité et l\'intégrité des données de ses clients, y compris les informations personnelles des enfants. Nous nous assurons que ces données sont protégées contre tout accès, consultation, communication, modification, perte, destructions non autorisées, que ce soit de manière intentionnelle ou accidentelle. De plus, nous veillons à ce que ces données soient chiffrées lorsqu\'elles sont stockées ou en cours de transmission, et qu\'elles soient traitées conformément à notre politique ou à des accords préexistants avec des organismes éducatifs.</p><br />' +
        '<p>eduplan solutions / edpro garantit que les informations couvertes par la présente politique ne sont pas partagées de manière informelle avec ses employés. Seuls ceux qui nécessitent l\'accès à ces données pour remplir leurs fonctions professionnelles peuvent les consulter. Dans ces situations, le principe du droit d\'accès minimal est appliqué, ce qui signifie que chaque programme ou employé obtient un accès aussi limité que possible tout en permettant l\'accomplissement de leurs tâches. Les employés autorisés à accéder à ces données reçoivent une formation sur leurs responsabilités en matière de traitement de ces informations, ainsi que sur les diverses lois et accords relatifs à la sécurité des données. De plus, ces employés se voient attribuer un identifiant d\'utilisateur unique à des fins de responsabilité.</p><br />' +
        '<p>eduplan solutions / edpro réalise régulièrement des évaluations pour identifier les risques et les faiblesses en matière de sécurité et de confidentialité des données. En cas de découverte d\'une vulnérabilité, eduplan solutions / edpro s\'engage à la corriger rapidement. De plus, nous avons mis en place un plan écrit pour réagir efficacement en cas d\'incident de sécurité ou de violation de la confidentialité. Ce plan inclut l\'envoi immédiat d\'une notification à l\'école ou au district scolaire concerné et établit les meilleures pratiques pour faire face à la compromission d\'informations personnelles. Vous pouvez consulter le plan de réaction aux incidents via ce lien.</p><br />' +
        '<p>eduplan solutions / edpro s\'engage à maintenir la sécurité de nos systèmes et de nos services en effectuant des mises à jour régulières. Nous améliorons constamment nos systèmes en appliquant des correctifs, que l\'on appelle aussi "rustines", afin d\'assurer leur efficacité, leur performance optimale, et leur sécurité. Ces mises à jour peuvent inclure des modifications visant à renforcer la sécurité, corriger des erreurs ou améliorer les fonctionnalités. Nous nous réservons le droit d\'effectuer ces mises à jour sans préavis, tout en veillant à ne pas perturber l\'expérience utilisateur et en respectant en permanence notre Politique de confidentialité.</p><br />' +
        '<p>eduplan solutions / edpro maintient un enregistrement précis des événements, appelé "log", qui consigne les actions des utilisateurs dans le système à des fins de sécurité. Cette pratique nous permet d\'identifier, de comprendre et de réagir de manière appropriée à tout comportement inhabituel ou suspect, ainsi qu\'à toute éventuelle violation de la sécurité. Le journal des événements peut inclure des informations telles que la date et l\'heure des actions, les détails de l\'action effectuée et l\'identifiant de l\'utilisateur. Ces informations sont collectées et stockées conformément à notre politique de confidentialité, et elles sont exclusivement utilisées pour garantir la sécurité de nos utilisateurs et préserver l\'intégrité de notre service.</p><br />' +
        '<p>Bien que eduplan solutions / edpro fasse de son mieux pour assurer la sécurité de vos informations et données, il est impossible de garantir une sécurité totale. Les menaces informatiques, telles que les virus et le piratage, évoluent constamment, et il existe d\'autres risques imprévisibles liés au matériel ou aux logiciels. Par conséquent, eduplan solutions / edpro ne peut être tenue responsable des pertes ou altérations de données. Si une faille de sécurité affecte un utilisateur, eduplan solutions / edpro le notifiera par courriel dans les trois jours suivant la découverte de la situation.</p><br />' +
        '<p>Si vous souhaitez en savoir plus sur nos mesures de sécurité ou obtenir une copie de notre politique en matière de sécurité des données, veuillez nous écrire à l’adresse info@eduplan.ca.</p>',
    side9Intro: '<p>États-Unis d’Amérique</p><br />' +
        '<p>FERPA</p><br />' +
        '<p>L’acronyme FERPA désigne la loi fédérale américaine Family Education Rights and Privacy Act, qui vise à protéger la confidentialité des dossiers éducatifs des élèves. La loi FERPA confère aux parents certains droits relatifs aux dossiers éducatifs de leurs enfants mineurs. Parmi ces droits, on trouve le droit de passer en revue les dossiers éducatifs de l’élève tenus par l’école, ainsi que le droit de demander à une école de rectifier tout dossier éducatif que le parent estime inexact ou trompeur.</p><br />' +
        '<p>En outre, en vertu de la loi FERPA, les écoles doivent généralement obtenir la permission écrite d’un parent avant de communiquer tout renseignement tiré du dossier éducatif d’un élève, ce qui comprend l’ensemble des dossiers, des fichiers et des documents contenant de l’information concernant directement l’élève et qui sont conservés par un organisme ou un établissement d’enseignement, ainsi que les renseignements personnels contenus dans ces dossiers. Cependant, la loi FERPA permet aux écoles de communiquer sans consentement ces dossiers à certaines parties dans des situations particulières, notamment celles-ci (voir la disposition 34 CFR § 99.31) :</p><br />' +
        '<p> •    à des représentants de l’école qui ont un intérêt éducatif légitime;</p><br />' +
        '<p> •    à une autre école vers laquelle l’élève est transféré;</p><br />' +
        '<p> •    à des représentants déterminés à des fins de vérification ou d’évaluation;</p><br />' +
        '<p> •    à des parties appropriées dans le cadre de l’aide financière offerte à un élève;</p><br />' +
        '<p> •    à des organisations qui réalisent certaines études pour l’école ou au nom de celle-ci;</p><br />' +
        '<p> •    à des organisations d’agrément;</p><br />' +
        '<p> •    pour se conformer à une ordonnance judiciaire ou à une assignation à comparaître légalement signifiée;</p><br />' +
        '<p> •    à des représentants appropriés en cas d’urgence de nature médicale ou sécuritaire;</p><br />' +
        '<p> •    et aux autorités étatiques et locales dans le cadre du système de justice pour mineurs, conformément à une loi étatique précise.</p><br />' +
        '<p>Les écoles peuvent aussi, sans consentement, communiquer des renseignements susceptibles d’être consignés dans un annuaire, par exemple le nom de l’élève, son adresse, son numéro de téléphone, sa date et son endroit de naissance, ses distinctions et ses récompenses, ainsi que les dates de fréquentation de l’établissement. Cependant, les écoles doivent informer les parents de ces renseignements d’annuaire et leur accorder une période suffisante pour leur permettre d’indiquer s’ils ne souhaitent pas la publication de cette information.</p><br />' +
        '<p>Bien que la loi FERPA vise principalement les établissements d’enseignement, les Produits ou Services eduplan solutions / edpro jouent le rôle de « représentant de l’école » (School Official) avec un intérêt éducatif légitime en vertu de la FERPA; notre politique de confidentialité est conçue de façon à obtenir les données relatives aux élèves conformément à la loi FERPA et à permettre aux établissements d’enseignement qui utilisent les Produits d\'eduplan solutions / edpro de respecter cette loi. eduplan solutions / edpro ne communique pas les dossiers éducatifs des élèves ou les renseignements de l’annuaire hors de l’Environnement scolaire.</p>',
};
export var politicsConditionsFR = {
    mainTitle: 'Conditions d\'utilisation',
    side1: 'Introduction',
    side2: 'Définitions',
    side3: 'Termes spécifiques employés dans nos Produits ou nos Services ayant des permissions ou accès différents',
    side4: 'Portée',
    side5: 'Comptes',
    side6: 'Admissibilité et responsabilité',
    side7: 'Licence, droit d\'utilisation et propriété des Produits ou Services d’eduplan solutions',
    side8: 'Modifications',
    side9: 'Résiliation',
    side10: 'Politique de confidentialité',
    side11: 'Informations personnelles',
    side12: 'Banque d’objectifs ou répertoire de moyens',
    side13: 'Choix d’instruments ou d’outils d’évaluation',
    side14: 'Collecte et utilisation d\'informations par des moyens automatiques',
    side15: 'Exclusion de garanties',
    side16: 'Limitation de responsabilité',
    side17: 'Indemnité',
    side18: 'Relation entre les parties',
    side19: 'Droit applicable ; Juridiction',
    side20: 'Intégralité de l\'accord',
    side21: 'Divisibilité',
    side22: 'Renonciation ; Mission',
    side23: 'Langue',
    side24: 'Service client',
    side25: 'Mots clés',
    side26: 'Accès autorisés',
    side27: 'Données récoltées',
    side28: 'Protection des données personnelles et confidentielles',
    side29: 'Droits d’auteur, logos et marques de commerce, de produits et de services d’edpro',
    side1Intro: '<p>9201-8688 Québec Inc., eduplan solutions (« eduplan solutions », « Nous » ou « Notre » ou « Nos) développe et donne accès à certains logiciels, sites Web, certaines applications, plateformes et services en ligne, y compris, sans s\'y limiter [banqo, edpro, iplan, smartplan et proplan], qui peuvent être utilisé pour développer, préparer et gérer des programmes éducatifs, des plans d\'intervention individualisés, des plans de traitements médicaux et/ou des plans de transition scolaire (les « Services ») ou pour identifier les besoins d’un client, élève, apprenant ou une personne.</p><br/>' +
        '<br />' +
        '<p>Votre utilisation des Services est soumise aux présentes Conditions d\'utilisation (« Conditions »). Tout au long de ces Conditions, les mots « vous » et « votre » font référence à l\'utilisateur des Services.</p><br/>' +
        '<br />' +
        '<p>En accédant ou en utilisant les Services, vous acceptez d\'être lié par ces Conditions et acceptez d\'accéder ou d\'utiliser les Services conformément à ces Conditions. Si vous n\'acceptez pas et n\'acceptez pas d\'être légalement lié et de vous conformer à ces Conditions, vous ne pouvez pas accéder ou utiliser les Services.</p><br/>' +
        '<br />' +
        '<p>Notez que ces conditions sont soumises aux lois de l\'endroit où vous vivez, et certaines d\'entre elles peuvent ne pas vous lier en vertu de ces lois.</p><br/>' +
        '<p>Il est attendu que chaque utilisateur (ci-après l\'« Utilisateur ») qui désire utiliser les Produits ou Services d’eduplan solutions doit accepter d\'être lié par les conditions d\'utilisation qui suivent;</p><br/>' +
        '<br />' +
        '<p>Il est attendu que 9201-8688 Québec Inc. / eduplan solutions. (ci-après « eduplan solutions ») et les établissements d’éducation, de santé ou de services sociaux, où travaille l\'Utilisateur ont conclu une entente pour rendre disponible des Services par lesquelles des utilisateurs, y incluant des professionnels de l’éducation, de la santé, des services sociaux, du personnel administratif, ont accès à des renseignements nominatifs et cliniques de patients de l\'établissement de santé (ces renseignements constituant des dossiers médicaux au sens de la loi);</p><br/>' +
        '<br />' +
        '<p>Veuillez lire les présentes conditions d’utilisation attentivement avant de commencer à utiliser les Services (comme définis aux présentes) offerts par eduplan solutions, qui possède et exploite les Produits ou Services et le Site Web (comme définis aux présentes). EN VOUS INSCRIVANT AUX SERVICES DE QUELQUE FAÇON QUE CE SOIT, VOUS CONFIRMEZ AVOIR LU LES MODALITÉS DE LA PRÉSENTE ENTENTE ET VOUS ACCEPTEZ D’ÊTRE UNE PARTIE LIÉE PAR CELLES-CI, À L’EXCLUSION DE TOUTE AUTRE MODALITÉ. Si vous n’acceptez pas de façon inconditionnelle la totalité des modalités de la présente Entente, vous ne pouvez pas accéder aux Services ou les utiliser. L’utilisation des Services est conditionnelle à votre acceptation de la totalité des modalités de cette Entente, à l’exclusion de toute autre modalité. Si les modalités de la présente Entente sont considérées comme une offre, toute acceptation se limite expressément à ces modalités.</p><br/>' +
        '<p>À NOTER : SI VOUS AVEZ MOINS DE 18 ANS, VOTRE PARENT OU VOTRE TUTEUR DOIT LIRE LES CONDITIONS D’UTILISATION CI-DESSOUS ET LES ACCEPTER POUR VOUS. SI VOTRE PARENT OU VOTRE TUTEUR NE LIT PAS OU N’ACCEPTE PAS LES PRÉSENTES CONDITIONS POUR VOUS, VOUS NE POUVEZ PAS UTILISER LES SERVICES.</p><br/>' +
        '<br />' +
        '<p>Merci pour votre intérêt envers les Services de eduplan solutions. La présente Entente contient des modalités qui s’appliquent à vous à titre d’utilisateur général des Services, ainsi que d’autres qui peuvent s’appliquer à vous à titre d’utilisateur d’un type précis, dont Enseignant, Employé de district scolaire ou d’école, Parent ou Élève.</p>',
    side2Intro: '<p>Les termes suivants ont le sens qui leur est attribué ici lorsqu’ils sont utilisés dans l’Entente, ainsi que dans les Politiques d’eduplan solutions, que ce soit au singulier ou au pluriel :</p><br/>' +
        '<br />' +
        '<p>Termes usagés dans les Politiques d’eduplan solutions et les Conditions d’utilisation</p><br/>' +
        '<br />' +
        '<p>Le terme « Environnement clinique ou pédagogique » sert à désigner les centres, les cliniques ou les différents organismes du secteur de la santé et des services sociaux où les professionnels non-enseignants ou intervenants et l’administration du dit milieu s’y retrouvent.</p><br/>' +
        '<br />' +
        '<p>Le terme « Client » se rapporte à une personne de tous âges recevant les services d’un Intervenant qui utilisent nos Produits ou Services.</p><br/>' +
        '<br />' +
        '<p>Le terme « Intervenant » se réfère à une personne qui offre des services spécialisés ou de relation d’aide comme par exemple: auxiliaire d\'intégration, conseiller d\'orientation, enseignant en adaptation scolaire, éducateur spécialisé, ergothérapeute, orthophoniste, orthopédagogue, neuropsychologue, personne-ressource, physiothérapeute, préposé aux personnes en situation de handicap, psychoéducateur, psychologue, psychothérapeute, technicien en éducation spécialisée, tuteur, travailleur social, etc.).</p><br/>' +
        '<br />' +
        '<p>Le terme « Identifiant d’utilisateur » désigne le mot de passe et le nom d’utilisateur donnant à un utilisateur accès aux Services.</p><br/>' +
        '<br />' +
        '<p>Le terme « Élève » fait référence à un enfant scolarisé au préscolaire, primaire ou secondaire âgé de moins de 18 ans, ou, un élève ayant plus de 18 ans scolarisé dans une école spéciale.</p><br/>' +
        '<br />' +
        '<p>Le terme « Parent » sert à désigner le parent ou tuteur d’un enfant scolarisé aux préscolaire, primaire ou secondaire âgé de moins de 18 ans, un enfant ayant plus de 18 ans scolarisé dans une école spéciale, ou, un adulte en situation de handicap dont le parent ou tuteur a été désigné par un juge comme curateur ou tuteur qui pourra assumer la curatelle ou tutelle handicap.</p><br/>' +
        '<br />' +
        '<p>Le terme « Professionnel enseignant » sert à désigner les enseignants du préscolaire, primaire et secondaire, les enseignants de classe alternative, spéciale ou autre.</p><br/>' +
        '<br />' +
        '<p>Le terme « Professionnel non enseignant » sert à désigner tout autre professionnel comme les conseillers pédagogiques, les coordonnateurs de services, les cadres intermédiaires ou les directions.</p><br/>' +
        '<br />' +
        '<p>Le terme « Utilisateurs » sert à désigner les différents types d\'utilisateurs ; client, intervenant, élève, parent, professionnel enseignant et professionnel non enseignant.</p><br/>' +
        '<br />' +
        '<p>Le terme « Entente » désigne les présentes conditions d’utilisation.</p><br/>' +
        '<br />' +
        '<p>Le terme « Consentement » désigne toute manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle la personne concernée accepte, par une déclaration ou par un acte positif clair (soit un communiqué écrit par un Parent, un Intervenant ou un Professionnel), que des données à caractère personnel la concernant fassent l\'objet d\'un traitement ou l’objet d’accessibilité aux Produits ou Services d’eduplan solutions.</p><br/>' +
        '<br />' +
        '<p>« Contenu » désigne tous les éléments présentés dans le cadre des Services, notamment les textes, les graphiques, les tableaux, les ressources documentaires ou tout autre élément de la propriété intellectuelle d’eduplan solutions. Le Contenu ne couvre pas les Publications d’utilisateur, les Dossiers éducatifs ou les Renseignements personnels.</p><br/>' +
        '<br />' +
        '<p>« Données nominatives » se réfère au terme Personally Identifiable Information dans la loi FERPA, et comprend l’information pouvant servir à distinguer ou à retracer l’identité d’une personne, que ce soit directement (par exemple, le nom d’un élève) ou indirectement (comme la date de naissance d’un élève, ou d’un client) (voir la disposition 34 CFR § 99.3 [en anglais seulement]).</p><br/>' +
        '<br />' +
        '<p>« Dossiers d’élèves » a le sens attribué au terme* Education Records* dans la loi américaine Family Educational Rights and Privacy Act (FERPA) et englobe notamment les dossiers, les fichiers, les documents et tout autre support contenant de l’information concernant directement un élève et qui est conservé par un organisme ou un établissement scolaire ou par toute personne au nom de cet organisme ou établissement (voir la disposition 20 U.S.C. § 1232g(a)(4) [en anglais seulement]).</p><br/>' +
        '<br />' +
        '<p>« Dossiers clients » désigne les dossiers, les fichiers, les documents et tout autre support contenant de l’information concernant directement un client et qui est conservé par un Intervenant ou Professionnel du secteur de l’éducation, de la santé et des services sociaux. Les Intervenants ou Professionnel en santé mentale ou adaptation scolaire, ouvre et conservent les dossiers de leurs clients (évaluations, plan d’intervention, plans de traitements, interventions, notes évolutives, etc.) dans un environnement virtuel, organisé et facilitant ainsi que la gestion des aspects administratifs relatifs à leur pratique (facturation, gestion du calendrier, lien avec la clientèle, dossier d’aide particulière, plan d’intervention, etc.). En plus de créer et de maintenir les dossiers de clients, ils permettent d’héberger, de téléverser ou de télécharger du contenu tel que des notes d\'évolutions, des rapports professionnels, des données personnelles ou toutes informations jugées nécessaires au dossier client.</p><br/>' +
        '<br />' +
        '<p>« Mode de paiement ». L’utilisateur choisit le mode de paiement de sa licence individuelle ou collective à l’exécution de cette Entente. Ce mode de paiement peut être modifié à tout moment par la suite, mais il doit faire partie des choix offerts à ce moment par eduplan solutions pendant le processus d’inscription.</p><br/>' +
        '<br />' +
        '<p>« Renseignements personnels » a le sens attribué au terme Personal Information dans la loi américaine Children’s Online Privacy Protection Act (COPPA) et englobe des données nominatives comme le prénom et le nom de famille, les coordonnées en ligne, le nom d’utilisateur, tout identifiant permanent qui permet d’identifier un utilisateur à plusieurs moments et sur différents sites ou services Web, et les renseignements de géolocalisation suffisants pour relever un nom de rue ou le nom d’une ville (voir la disposition 16 CFR § 312.2 [en anglais seulement]).</p><br/>' +
        '<br />' +
        '<p>« Partenaire tiers d’intégration » désigne toute plateforme tierce qui est intégrée dans le ou les Produits ou Services ou à laquelle le ou les Produits ou Services sont intégrés afin d’échanger des données (notamment du Contenu, des Renseignements personnels ou des Données nominatives) entre ces plateformes ou pour donner un accès simplifier à ces autres produits ou services. Aucune intégration du ou des Produits ou Services ne peut être réalisée avec un Partenaire tiers d’intégration sans la permission d’eduplan solutions.</p><br/>' +
        '<br />' +
        '<p>« le ou les Produits ou les Services » désignent les différents Produits ou Services ou Services d’eduplan solutions, accessibles sur le site www.eduplan.ca, ou www.edpro.solutions; www.banqo.net; www.iplan.solutions; www.smartplan.solutions; www.proplan.solutions; et dans les boutiques d’applications pour ordinateurs de bureau, appareils mobiles ou tablettes, notamment l’App Store d’Apple, le Web Store de Chrome, Google Play et Microsoft Store.</p><br/>' +
        '<br />' +
        '<p>« Politique de confidentialité » désigne la politique de confidentialité en vigueur pour le Site Web ou les Produits ou les Services d’eduplan solutions, ces politiques étant respectivement accessibles au et au et eduplan solutions pouvant les modifier à son gré à tout moment.</p><br/>' +
        '<br />' +
        '<p>« Services » désigne le ou les Produits ou Services, le Site Web et son nom de domaine, ainsi que tous les autres sites Web et noms de domaine associés à eduplan solutions et aux autres pages auxquelles renvoient des liens, des fonctionnalités, des éléments de contenu ou des services d’application offerts par eduplan solutions, ce qui comprend notamment tout service qu’eduplan solutions effectue pour vous et le Contenu offert par eduplan solutions sur le Produit ou les Services d’eduplan solutions, sur le Site Web ou sur toute autre plateforme.</p><br/>' +
        '<br />' +
        '<p>« Publications d’utilisateur » désigne tout renseignement ou contenu publié par un Utilisateur ou en son nom par le biais des Produits ou des Services d’eduplan solutions, du Site Web d’eduplan solutions ou de tout Partenaire tiers d’intégration.</p><br/>' +
        '<p>« Site Web » désigne le site www.eduplan.ca et tout autre site public associé à eduplan solutions.</p>',
    side3Intro: '<p>Le terme « Administrateur » ou « Administrateur principal » sert à désigner le premier et principal utilisateur de nos Produits ou Services, ayant le pouvoir de créer son espace de travail (ajouter, modifier ou supprimer des utilisateurs), changer ou supprimer son abonnement, archiver, supprimer ou transférer les dossiers des élèves ou des clients selon les conditions établies, les lois applicables et avec consentement des principaux acteurs concernés), ainsi qu’attribuer les rôles à chacun des utilisateurs de son espace de travail, y inclus les rôles du Co-administrateur et du Gestionnaire de plans.</p><br/>' +
        '<br />' +
        '<p>Le terme « Co-administrateur » sert à désigner l’utilisateur de nos Produits ou Services, ayant le pouvoir d’archiver, de supprimer ou de transférer les dossiers des élèves ou des clients (selon les conditions établies, les lois applicables et avec consentement des principaux acteurs concernés), d’attribuer les rôles à chacun des utilisateurs de son espace de travail, y inclus les rôles du Co-administrateur et du Gestionnaire de plans.</p><br/>' +
        '<br />' +
        '<p>Le terme « Élève » représente un enfant scolarisé aux préscolaire, primaire ou secondaire âgé de moins de 18 ans, ou, un élève ayant plus de 18 ans scolarisés dans une école spéciale.</p><br/>' +
        '<br />' +
        '<p>Le terme « Parent » représente un parent ou tuteur d’un enfant scolarisé au préscolaire, primaire ou secondaire âgé de moins de 18 ans, un enfant ayant plus de 18 ans scolarisé dans une école spéciale, ou, un adulte en situation de handicap dont le parent ou tuteur a été désigné par un juge comme curateur ou tuteur qui pourra assumer la curatelle ou tutelle handicap.</p><br/>' +
        '<br />' +
        '<p>Le terme « Gestionnaire de plans » sert à désigner l’utilisateur de nos Produits ou Services, ayant le pouvoir d’élaborer les plans, mais non pas d’archiver, de supprimer ou de transférer les dossiers des élèves ou des clients.</p><br/>' +
        '<br />' +
        '<p>Le terme « Gestionnaire de plans » sert à désigner l’utilisateur de nos Produits ou Services, ayant le pouvoir d’élaborer les plans, mais non pas d’archiver, de supprimer ou de transférer les dossiers des élèves ou des clients.</p><br/>' +
        '<br />' +
        '<p>Le terme « Professionnel » se réfère aux professionnels enseignants et aux professionnels non enseignants. C’est -à-dire, une personne qui offre des services spécialisés ou de relation d’aide comme par exemple: auxiliaire d\'intégration, conseiller d\'orientation, enseignant en adaptation scolaire, éducateur spécialisé, ergothérapeute, orthophoniste, orthopédagogue, neuropsychologue, personne-ressource, physiothérapeute, préposé aux personnes en situation de handicap, psychoéducateur, psychologue, psychothérapeute, technicien en éducation spécialisée, tuteur, travailleur social, etc.), désigner les enseignants du préscolaire, primaire et secondaire, les enseignants de classe alternative, spéciale ou autre, ainsi que tout autre professionnel comme les conseillers pédagogiques, les coordonnateurs de services, les cadres intermédiaires ou les directions.</p><br/>' +
        '<p>Le terme « Répondant » se rapporte à un Professionnel, un Parent ou un Client qui est invité à participer à répondre à un questionnaire ou inventaire dans un contexte ou processus d’évaluation.</p>',
    side4Intro: '<p>Ces conditions s\'appliquent à toute utilisation des Services par vous, quel que soit le type d\'appareil utilisé pour accéder, utiliser ou afficher les Services. Des conditions supplémentaires et/ou supplémentaires, telles que des politiques, des directives, des conditions d\'utilisation ou des contrats de licence utilisateur, peuvent s\'appliquer à certains Services (dans chacun de ces cas, et collectivement, les « Conditions supplémentaires ») et ces Conditions supplémentaires vous seront divulguées en relation avec les Services applicables. Toutes ces Conditions supplémentaires s\'ajoutent aux présentes Conditions et sont réputées en faire partie, sauf indication contraire explicite, aux fins des Services applicables. En cas de conflit entre les présentes Conditions et les Conditions supplémentaires, les Conditions supplémentaires prévaudront sur les présentes Conditions en ce qui concerne les Services applicables, à moins que les Conditions supplémentaires n\'en disposent expressément autrement.</p>',
    side5Intro: '<p>Afin d\'accéder ou d\'utiliser certains aspects des Services, vous devrez peut-être créer et maintenir un compte (votre « Compte »). Votre compte est accessible uniquement à l\'aide de votre nom d\'utilisateur et de votre mot de passe. Vous êtes seul responsable de toute utilisation ou mauvaise utilisation de votre compte, de votre nom d\'utilisateur et de votre mot de passe, et de toutes les activités qui s\'y déroulent. Pour des raisons de sécurité, vous devez garder votre nom d\'utilisateur et votre mot de passe confidentiel, et ne pas les divulguer à qui que ce soit, ni autoriser une autre personne à les utiliser. Nous vous recommandons de choisir un mot de passe qui vous est propre et qui ne sera pas facilement deviné par les autres. Vous devez changer votre mot de passe régulièrement et vous déconnecter à la fin de chaque session. Tous les Comptes demeurent la propriété d\'eduplan solutions et peuvent être annulés ou suspendus par nous en tout temps, sans responsabilité envers vous ou toute autre personne. Nous n\'avons aucune obligation de vérifier l\'identité réelle ou l\'autorité de l\'utilisateur de tout nom de connexion ou mot de passe.</p><br/>' +
        '<p>Vous devez nous informer immédiatement de toute utilisation non autorisée de votre compte, nom de connexion ou mot de passe, ou si vous savez ou soupçonnez que votre nom de connexion ou mot de passe a été perdu ou volé, est devenu connu de toute autre personne ou a été autrement compromis. Nous ne sommes pas responsables de votre manquement à le faire, ou de tout retard dans la suspension de votre compte à la suite de votre notification. Vous acceptez de coopérer pleinement avec nous pour remédier à tout problème lié à l\'utilisation non autorisée suspectée ou réelle de votre compte.</p><br/>' +
        '<br />' +
        '<p>Vous certifiez que les informations que vous fournissez au cours du processus d\'enregistrement du compte seront complètes et exactes, et que vous ne fournirez pas une fausse adresse courriel, ne vous ferez pas passer pour une personne ou une entité, ou ne nous induirez pas en erreur concernant les informations que vous fournissez. Vous mettrez rapidement à jour toutes les informations que vous avez fournies afin que votre compte reste complet et exact à tout moment.</p><br/>' +
        '<br />' +
        '<p>Vous acceptez que si vous fournissez des informations intentionnellement inexactes, obsolètes ou incomplètes de manière significative, ou si nous avons des motifs raisonnables de croire que ces informations sont fausses, inexactes, obsolètes ou incomplètes de manière significative, nous ayons le droit de suspendre ou de résilier votre compte et votre utilisation des services, ou toute partie de ceux-ci.</p><br/>' +
        '<br />' +
        '<p>Vous comprenez et acceptez que nous pouvons vous contacter si vous « acceptez » pour recevoir des informations de notre part, y compris des actualités, des lancements de produits et des promotions, même si votre compte a été résilié, à condition que vous n\'ayez pas expressément retiré votre consentement à recevoir une telle communication. Vous pouvez vous désinscrire de la réception de tels messages à tout moment.</p>',
    side6Intro: '<p>En accédant ou en utilisant les Services, vous déclarez et garantissez que</p><br/>' +
        '<p> •    Vous avez atteint l\'âge de la majorité dans la juridiction dans laquelle vous résidez et que vous êtes pleinement capable et compétent pour accepter ces Conditions. Dans les cas où vous avez autorisé un mineur à utiliser votre compte, vous reconnaissez que vous êtes entièrement responsable : (a) de la conduite dudit mineur ; (b) contrôler l\'accès et l\'utilisation du mineur à votre compte et aux services ; (c) les conséquences de toute utilisation ou mauvaise utilisation de votre Compte ou des Services par le mineur ; et (d) toute responsabilité légale et obligation financière encourues par le mineur du fait de son utilisation de votre Compte et/ou des Services;</p><br/>' +
        '<p> •    Vous êtes une personne physique et non une société;</p><br/>' +
        '<p> •    Vous avez au moins 18 ans, ou, si vous avez moins de 18 ans, votre parent ou votre tuteur a lu et a accepté la présente Entente en votre nom;</p><br/>' +
        '<p> •    Vous êtes un Intervenant, un Professionnel enseignant ou non-enseignant, un Parent (ou tuteur) ou un Élève qui souhaite utiliser les Produits ou les Services d’eduplan solutions;</p><br/>' +
        '<p> •    Vous fournissez des renseignements, dans le cadre de votre inscription, exacts et véridiques;</p><br/>' +
        '<p> •    En accédant à nos Produits ou Services vous consentez à le faire de façon volontaire, sans aucune obligation ou contrainte externe.</p><br/>' +
        '<p> •    Vous veillerez à ce que ces renseignements demeurent à jour; et,</p><br/>' +
        '<p> •    Si vous acceptez la présente Entente au nom d’un établissement, d’une société ou de toute autre entité juridique, vous avez le pouvoir de lier ainsi cette entité. Vous confirmez aussi que vous avez le droit d’utiliser les Produits et les Services d’eduplan solutions et d’y accéder, et vous assumez l’entière responsabilité relative à votre sélection et à votre utilisation des Produits et des Services d’Eduplan solutions, ainsi qu’à votre accès à ceux-ci.</p><br/>' +
        '<p> •    L’Intervenant ou Professionnel est responsable de l’entièreté du contenu importé et exporté via nos Produits ou Services. Son contenu importé lui appartient en tout temps. Par conséquent, eduplan solutions n’est pas responsable de la qualité ou de l’exactitude du contenu hébergé sur nos Produits ou Services, et, n’est pas responsable des services dispensés par les Intervenants ou Professionnels, ni du non-respect déontologique ou éthique relatifs aux dossiers de ses clients. En vous inscrivant, vous reconnaissez être entièrement responsable de votre pratique professionnelle et du respect de la déontologie relative à votre profession, de même que de toute réclamation, plainte ou avis en lien avec votre pratique sur nos Produits ou Services.</p><br/>' +
        '<p> •    L\'Utilisateur est responsable de la qualité et de l\'exactitude des données qu\'il dépose dans les Produits et Services d’eduplan solutions.</p><br/>' +
        '<p> •    L\'Utilisateur est responsable de l\'usage qu\'il fait des Produits ou Services d’eduplan solutions et des informations qu\'il y dépose.</p><br/>' +
        '<p> •    L\'Utilisateur doit respecter tous les lois et règlements en vigueur, les codes de déontologie qui le concernent et les directives de l\'Établissement, ainsi que de son employeur, le cas échéant. Il doit, de plus, respecter les règles habituelles de la tenue d\'un dossier d’aide particulière, d’un dossier professionnel ou d’un dossier de santé.</p><br/>' +
        '<p> •    L\'Utilisateur s\'engage à préserver en tout temps, même après la fin de toute entente qui le lie à eduplan solutions , ses Produits ou ses Services, la plus stricte confidentialité des données qu\'il dépose dans les Produits ou Services d\'eduplan solutions ou auxquelles il aura eu accès par l\'entremise des Produits ou Services d’eduplan solutions. En outre, il reconnaît qu\'il ne doit faire usage des Produits et Services d’Eduplan solutions que dans un but professionnel et de façon responsable.</p><br/>' +
        '<p> •    En tout temps, l\'Utilisateur s\'assure de la réception d\'un consentement valide du client lorsque les Produits ou Services d\'eduplan solutions demandent à l\'Utilisateur un tel consentement pour accéder à une information. L\'Utilisateur est responsable de conserver tous les documents en lien avec ces demandes de consentements et les consentements obtenus.</p><br/>' +
        '<p> •    L\'Utilisateur qui est un autre professionnel de la santé soumis à un code de déontologie est responsable des actions posées en son nom dans les Produits ou Services d’eduplan solutions, par d\'autres utilisateurs qu\'il mandate à cet effet. Cette responsabilité comprend notamment celle de contrôler les droits d\'accès aux renseignements nominatifs et cliniques des personnes qu\'il mandate ainsi que d\'assurer le respect de la confidentialité de ces renseignements.</p><br/>' +
        '<p> •    L\'Utilisateur qui est un professionnel de la santé est responsable de répondre aux demandes de renseignements et d\'accès à l\'information provenant de patients, de clients, ou de tiers, en conformité avec les règles déontologiques applicables. eduplan solutions, ses Produits ou ses Services ne seront en aucun cas responsable de fournir cette information.</p><br/>' +
        '<br />' +
        '<p>Toute demande reçue par eduplan solutions sera déférée à l\'Utilisateur qui est le professionnel traitant ou le professionnel en charge, qui s\'engage à y répondre dans les meilleurs délais.</p><br/>' +
        '<br />' +
        '<p>La présente Entente est nulle dans tous les territoires où elle est interdite par la loi, auquel cas tout droit d’accès aux Services y est aussi révoqué.</p>',
    side7Intro: '<p>Les Services contiennent du contenu, des informations et du matériel exclusifs appartenant à eduplan solutions qui sont protégés par les lois applicables en matière de propriété intellectuelle, y compris, mais sans s\'y limiter, les droits d\'auteur, les marques de commerce et/ou les lois sur les brevets. Sauf indication contraire, tout le matériel comprenant, présenté sur, affiché ou contenu dans les Services est la propriété d\'eduplan solutions et le droit d\'auteur sur ce matériel appartient à eduplan solutions. Sauf disposition expresse présente, rien dans les présentes Conditions ou dans l\'un des Services ne sera interprété comme conférant une licence ou une concession de droits. Vous acceptez de ne pas adapter, traduire, modifier, décompiler, désassembler ou désosser tout logiciel, application ou programme utilisés en relation avec les Services. Vous vous engagez à ne pas copier, modifier, reproduire, altérer, supprimer, dégrader, publier ou afficher les marques de commerce, marques de service ou autres contenus, informations ou éléments exclusifs affichés ou mis à disposition par le biais des Services, ou à utiliser tout ce qui précède à des fins autres que celles pour lesquelles cette propriété intellectuelle est mise à la disposition des utilisateurs des Services.</p><br/>' +
        '<br />' +
        '<p>En cas de réclamation d\'un tiers selon laquelle les Services ou votre utilisation des Services enfreignent les droits de propriété intellectuelle d\'un tiers, vous acceptez de fournir, aux frais d\'eduplan solutions, toute l\'aide raisonnable demandée par eduplan solutions dans les efforts de défense ou de règlement d\'eduplan solutions.</p><br/>' +
        '<br />' +
        '<p>Sans limiter ce qui précède, il peut y avoir des logiciels et des technologies contenus dans ou disponibles via les Services qui ont été concédés sous licence à eduplan solutions par des Tiers (« Logiciels tiers »). Le Logiciel Tiers est soumis aux droits de propriété de son ou ses propriétaires. Vous reconnaissez et acceptez que les propriétaires du logiciel tiers ne seront pas tenus responsables de la suppression ou de toute autre indisponibilité de ce Logiciel Tiers dans les services. En outre, les mêmes conditions, y compris toutes les limitations et restrictions, énoncées dans les présentes Conditions s\'appliquent à l\'utilisation du Logiciel Tiers contenu dans les Services.</p><br/>' +
        '<br />' +
        '<p>À l\'exception de tout renseignement personnel ou autre information que vous fournissez à eduplan solutions, eduplan solutions détiendra tous les droits, titres et intérêts sur tous les programmes et plans, y compris les programmes d\'éducation individualisés, les plans d\'intervention et les plans de transition scolaire, créés à l\'aide des Services (les « Plans ») et vous cédez irrévocablement à eduplan solutions toute la propriété intellectuelle des Plans. Sur demande, vous obtiendrez de toutes les personnes impliquées dans la création ou le développement de ces Plans une renonciation expresse et irrévocable en faveur d\'eduplan solutions à tous les droits moraux découlant de la Loi sur le droit d\'auteur (Canada) telle que modifiée (ou de la législation qui lui succède) ou de lois similaires dans d\'autres juridictions ou de common law que vous ou ces personnes, en tant qu\'auteurs, détenez à l\'égard des Plans.</p><br/>' +
        '<br />' +
        '<p>Lors de l\'enregistrement de votre Compte et de votre abonnement aux Produits ou aux Services, eduplan solutions vous accorde par les présentes une licence limitée, non exclusive, non sous-licenciable, non transférable et révocable d\'utilisation et d\'accès aux Services, dans le but de vous aider dans votre démarcher d’évaluation et déterminer les besoins de la personne évaluée, et, de créer, gérer et réviser des programmes éducatifs individualisés, des plans d\'intervention et/ou des plans de transition scolaire à usage personnel et/ou professionnel dans les domaines de l\'éducation, de la santé et des services sociaux, à condition que vous ne contreveniez pas aux présentes Conditions.</p><br/>' +
        '<br />' +
        '<p>Les informations obtenues ou fournies via les Services sont à titre informatif uniquement et ne doivent pas être invoquées ou interprétées comme une recommandation pour un produit ou un plan d\'action spécifique.</p><br/>' +
        '<br />' +
        '<p>eduplan solutions se réserve le droit de modifier, suspendre et/ou interrompre, temporairement ou définitivement, tout ou partie des Services, à tout moment, avec ou sans préavis, ou d\'établir des lignes directrices générales et des limites à leur utilisation, sans encourir aucune responsabilité ou obligation que ce soit envers vous ou toute autre personne. Vous reconnaissez et acceptez que : (a) vous n\'avez aucun droit de propriété sur les Services ; et (b) vous n\'avez qu\'une licence limitée pour utiliser les Services. La propriété des Services et tous les droits de propriété intellectuelle y afférents appartiennent en tout temps à eduplan solutions.</p><br/>' +
        '<br />' +
        '<p>Vous reconnaissez que seuls les utilisateurs enregistrés peuvent accéder et utiliser les Services. Toute autre utilisation non autorisée des Services par toute personne, entreprise, société, gouvernement ou toute autre entité est strictement interdit et constitue une violation des présentes Conditions. L\'achat d\'un seul abonnement permet à l\'acheteur d\'utiliser les Services pour son usage uniquement pendant la durée de l\'abonnement. Il est strictement interdit de partager l\'accès aux Services avec d\'autres (sauf dans lorsque l’utilisateur invite un utilisateur à collaborer et dont il peut gérer son compte). Toute institution ayant plusieurs utilisateurs des Services doit acheter un abonnement de groupe, et chacun de ces utilisateurs doit créer son propre compte. Un établissement titulaire d\'un abonnement collectif doit restreindre l\'accès aux Services au nombre d\'utilisateurs pour lesquels un abonnement a été souscrit.</p><br/>' +
        '<br />' +
        '<p>En outre, sauf dans la mesure permise par les présentes Conditions, toute copie de contenu et/ou matériel exclusif contenu dans ou fourni en association avec les Services, ou toute partie de ceux-ci, constituera une violation du droit d\'auteur. La violation de ces Conditions et/ou de toute loi applicable de quelque manière que ce soit mettra automatiquement fin aux droits qui vous sont accordés par les présentes et vous obligera à cesser toute utilisation des Services.</p><br/>' +
        '<p>Vous acceptez de vous conformer à toutes les lois, règles et réglementations applicables (y compris les lois relatives au droit d\'auteur), ainsi qu\'aux présentes Conditions, lors de l\'utilisation des Services. Vous reconnaissez et acceptez de ne pas utiliser les Services de quelque manière que ce soit, sauf dans la mesure expressément autorisée par les présentes Conditions.</p><br/>' +
        '<br />' +
        '<p>Lors de l\'enregistrement de votre Compte et de votre abonnement aux Services, vous avez droit à un essai gratuit de trente (30) jours (la « Période d\'essai gratuite ») ou pour la période qui a été déterminée pour vous ou selon la promotion obtenue. Si vous annulez votre abonnement pendant la période d\'essai gratuite, votre carte de crédit ne sera pas débitée à la fin de la période d\'essai gratuite. Vous pouvez annuler à tout moment pendant la période d\'essai gratuite sans pénalité. Votre abonnement se poursuivra automatiquement au-delà de l\'expiration de la période d\'essai gratuite et votre carte de crédit sera immédiatement débitée, sauf si vous annulez votre abonnement avant l\'expiration de la période d\'essai gratuite.</p><br/>' +
        '<p>Vous pouvez résilier votre abonnement à tout moment sans pénalité en suivant le protocole de résiliation fourni sur la Politique. Il n\'y a pas de frais d\'annulation. Aucun remboursement ne sera émis pour le temps restant dans la période d\'abonnement en cours au moment de l\'annulation.</p><br/>' +
        '<br />' +
        '<p>eduplan solutions se réserve le droit discrétionnaire de modifier la présente Entente à tout moment en publiant un avis sur les Services ou en vous envoyant un avis à cet effet par courriel avant l’entrée en vigueur de la modification, comme le précise notre Politique de confidentialité relative au Produit.</p><br/>' +
        '<br />' +
        '<p>Vous avez la responsabilité de prendre connaissance de ces modifications. Le fait que vous continuiez d’utiliser les Services après cet avis signifie que vous acceptez les modalités mises à jour de la présente Entente.</p><br/>' +
        '<br />' +
        '<p>Vous avez la responsabilité d’obtenir et de maintenir en état tout l’équipement ou les services auxiliaires dont vous avez besoin pour vous connecter aux Services, y accéder ou les utiliser, notamment les modems, le matériel informatique, les logiciels, l’équipement de projection, l’accès Internet et le service téléphonique; vous devez vous assurer que cet équipement ou ces services auxiliaires sont compatibles avec les Services.</p><br/>' +
        '<br />' +
        '<p>Vous reconnaissez que vous êtes responsable de la sécurité de votre Identifiant d’utilisateur et que vous devez préserver sa confidentialité. Vous vous engagez aussi à ne pas accorder d’accès à une autre personne de façon non sécuritaire.</p><br/>' +
        '<br />' +
        '<p>Vous vous engagez à superviser l’accès des Élèves aux Services afin d’assurer que ces derniers sont utilisés de façon conforme aux présentes conditions d’utilisation.</p><br/>' +
        '<br />' +
        '<p>eduplan solutions vous recommande d’imprimer une copie des présentes conditions d’utilisation et de la Politique de confidentialité relative au produit et de la remettre à chaque Élève mineur afin que ces documents soient présentés à leurs Parents.</p><br/>' +
        '<br />' +
        '<p>Il vous incombe de surveiller et de gérer l’ensemble des Publications d’utilisateur qui se rapportent à des Élèves dans votre Environnement. eduplan solutions n’effectue aucune surveillance ou gestion des Publications d’utilisateur, et ne peut être tenue responsable de celles-ci d’aucune façon. Vous acceptez la responsabilité exclusive d’assurer que les Publications d’utilisateur respectent les lignes directrices suivantes :</p><br/>' +
        '<p> •    Les Publications d’utilisateur ne doivent comprendre aucune forme de communication ou de sollicitation visant l’obtention d’un mot de passe, d’un compte ou de renseignements privés auprès d’un Utilisateur des Services.</p><br/>' +
        '<p> •    Les Publications d’utilisateur ne doivent comprendre aucun contenu illégal, y compris tout contenu potentiellement menaçant ou obscène.</p><br/>' +
        '<p> •    Les Publications d’utilisateur ne doivent comprendre aucun logiciel ni aucun code de logiciel qu’il est possible d’exécuter à partir des Services.</p><br/>' +
        '<p>Vous pourriez être responsable d’envoyer des invitations aux Parents afin qu’ils participent aux Services, ce qui leur donnerait accès aux renseignements d’Élève relatifs à leur enfant, à leurs enfants ou à tout mineur dont ils ont la charge légale. Vous convenez que vous n’enverrez ces invitations qu’aux Parents de vos Élèves, selon la définition de ce terme à la présente Entente. Vous reconnaissez que ces invitations sont conçues pour donner accès aux Renseignements personnels de l’Élève en question, et que tout manquement au présent article constitue une violation de la Politique de confidentialité relative au produit d’eduplan solutions, et, est susceptible d’entraîner la résiliation de la présente Entente.</p><br/>' +
        '<br />' +
        '<p>Vous reconnaissez que vous assurez la gestion de tous les aspects des Services relatifs, notamment l’ensemble des fonctionnalités personnalisables, l’octroi des conséquences positives ou négatives, et l’ensemble des dialogues et des communications dans les Produits ou Services entre vous et les Élèves ou leurs Parents.</p><br/>' +
        '<br />' +
        '<p>Vous vous engagez à vous acquitter des responsabilités établies dans l’article précédent de façon diligente et équitable, sans abus de pouvoir.</p><br/>' +
        '<p>eduplan solutions se réserve le droit de mettre fin à la licence de tout Utilisateur qui, selon son estimation, a abusé de ce pouvoir. eduplan solutions seules est habilitée à trancher cette question de façon définitive et totale.</p>',
    side8Intro: '<p>Nous pouvons, à notre seule discrétion, de temps à autre et pour quelque raison que ce soit, modifier, compléter ou amender ces Conditions sans préavis ni responsabilité envers vous ou toute autre personne. La version la plus récente des Conditions remplacera toutes les versions précédentes. Votre accès continu et/ou votre utilisation des Services signifient votre acceptation de toutes les Conditions révisées.</p>',
    side9Intro: '<p>eduplan solutions, à sa seule discrétion, se réserve le droit d\'annuler, de résilier, de restreindre ou de suspendre votre accès aux Services, avec ou sans préavis, pour quelque raison que ce soit, et sans aucune responsabilité ou obligation envers vous ou tout tiers. Si eduplan solutions annule, résilie, restreint ou suspend votre accès aux Services en raison de la conviction raisonnable d\'eduplan solutions, que vous enfreignez les présentes Conditions et/ou toute loi applicable, une telle annulation, résiliation, restriction, suspension ou suppression s\'ajoute et ne remplace pas les droits et recours dont dispose eduplan solutions en vertu des présentes Conditions ou de toute loi applicable. Vos obligations en vertu des présentes Conditions et de la Politique de confidentialité, y compris toute indemnisation, garantie et limitation de responsabilité, restent en vigueur après une telle annulation, résiliation, restriction, suspension ou suppression.</p>',
    side10Intro: '<p>Sans limiter la généralité des présentes Conditions, votre utilisation des Produits ou Services est également assujettie aux conditions de la politique de confidentialité d\'eduplan solutions, disponibles à https://www.eduplan.ca/politiques (la « Politique de confidentialité »), qui est intégrée aux présentes Conditions. Veuillez lire attentivement la politique de confidentialité. La version la plus récente de la politique de confidentialité sera publiée sur notre site Web et vous devez vérifier régulièrement la version la plus récente, car la version la plus récente est celle qui s\'applique à votre utilisation des services. En accédant ou en utilisant les Services, vous signifiez que vous avez lu, compris et accepté d\'être légalement lié par la Politique de confidentialité.</p>',
    side11Intro: '<p>En utilisant les Produits ou Services ou en nous fournissant des informations, y compris en enregistrant votre Compte, vous consentez à la collecte, à l\'utilisation et à la divulgation de vos informations personnelles par eduplan solutions conformément aux présentes Conditions et aux conditions et aux fins énoncées dans la Politique de confidentialité.</p><br/>' +
        '<br />' +
        '<p>eduplan solutions prend au sérieux la confidentialité de vos renseignements personnels, y compris votre nom, votre adresse électronique, votre adresse postale, votre téléphone et toute autre information identifiable. eduplan solutions ne recueille que les renseignements personnels nécessaires aux fins énoncées dans la Politique de confidentialité. Les renseignements personnels recueillis par eduplan solutions sont gérés à partir de nos bureaux à l\'adresse suivante: .6900 Decarie Blvd, Côte Saint-Luc, Quebec H3X 2T8.</p><br/>' +
        '<br />' +
        '<p>Dans l\'éventualité où eduplan solutions aurait besoin de communiquer vos renseignements personnels à un tiers fournisseur de services ou de confier à un tiers fournisseur de services la tâche de détenir, d\'utiliser ou de communiquer vos renseignements personnels au nom d\'eduplan solutions, eduplan solutions prendra d\'abord toutes les mesures raisonnables pour s\'assurer que les renseignements ne seront utilisés qu\'aux fins de faciliter votre utilisation des Services. Vos informations personnelles ne seront pas divulguées à des tiers sans votre permission, sauf si cela est nécessaire pour faciliter votre utilisation des Services ou lorsque la loi applicable l\'exige ou le permet. eduplan solutions ne vendra jamais vos renseignements personnels.</p><br/>' +
        '<br />' +
        '<p>Sans limiter la généralité de ce qui précède, en ce qui concerne les renseignements personnels que vous fournissez à eduplan solutions, eduplan solutions s\'engage : (a) à s\'assurer que tous les dossiers, documents et autres éléments contenant ces renseignements personnels sont protégés contre tout accès ou divulgation non autorisés; (b) ne pas collecter, utiliser, divulguer ou détruire, directement ou indirectement, ces informations personnelles à des fins non directement liées à l\'exécution de ses obligations en vertu des présentes Conditions ; (c) que ces renseignements personnels ne peuvent être utilisés par eduplan solutions à ses propres fins ou à toute autre fin non identifiée dans les présentes Conditions; (d) pour vous informer immédiatement par écrit lorsque ces informations personnelles sont utilisées ou divulguées contrairement aux dispositions des présentes Conditions ; et (e) de ne pas faire correspondre ou autrement lier ou associer ces informations personnelles avec des informations personnelles obtenues à partir d\'autres sources.</p>',
    side12Intro: '<p>Toutes les informations que vous fournissez lors de l\'utilisation des Services, y compris, mais sans s\'y limiter, votre principale collection d\'objectifs personnels, toutes les sous-collections d\'objectifs, ainsi que le répertoire de moyens personnalisé, seront accessibles via votre Compte. Elle peut également être consultée par des employés autorisés d\'eduplan solutions ou des fournisseurs de services tiers autorisés dans le but de faciliter votre utilisation des Services. Toutes les informations que vous entrez seront supprimées une fois que vous aurez annulé votre abonnement.</p><br/>' +
        '<br />' +
        '<p>Vous reconnaissez que toute information que vous entrez concernant un élève ou un client (par exemple, une sous-collection d\'objectifs personnalisée) ne doit identifier l\'élève que par son prénom (et un numéro si deux élèves ou plus portent le même prénom). Toute information que vous entrez relative à un élève ne sera divulguée à aucun tiers, y compris une école ou un centre scolaire, sans votre consentement et l\'autorisation écrite de l\'élève (si majeur) ou de l\'autorité parentale ou légale de l\'élève (si mineur).</p><br/>' +
        '<br />' +
        '<p>eduplan solutions respecte la loi américaine de 1998 sur la protection de la vie privée en ligne des enfants et les lignes directrices de l\'unité d\'examen de la publicité pour les enfants du Council of Better Business Bureau, Inc. sur tous ses sites Web adaptés aux enfants. Nos Services ne comportent pas de publicité destinée aux enfants de moins de treize (13) ans.</p>',
    side13Intro: '<p>Toutes les informations que vous fournissez lors de l\'utilisation des Services, y compris, mais sans s\'y limiter, le choix d’instruments ou d’outils d’évaluation, seront accessibles via votre Compte. Elle peut également être consultée par des employés autorisés d\'eduplan solutions ou des fournisseurs de services tiers autorisés dans le but de faciliter votre utilisation des Services. Toutes les informations que vous entrez seront supprimées une fois que vous aurez annulé votre abonnement.</p>',
    side14Intro: '<p>eduplan solutions utilise des plateformes comme Klaviyo ou Google Analytics un service d\'analyse fourni par Google, Inc., pour mieux comprendre comment les individus utilisent les Produits ou Services et pour améliorer les Services afin de mieux répondre à vos besoins. Google Analytics peut utiliser des mécanismes tels que les caches de données d\'application pour collecter des informations standard sur le comportement des utilisateurs sous une forme anonyme. Les informations anonymes générées par Google Analytics concernant votre utilisation des Services sont transmises et stockées par Google, Inc. sur ses serveurs aux États-Unis et traités pour compiler des rapports statistiques agrégés qui sont utilisés par eduplan solutions pour améliorer les Services. En utilisant les Services, vous consentez à la collecte et à l\'utilisation de ces informations sur le comportement des utilisateurs.</p>',
    side15Intro: '<p>TOUTES LES INFORMATIONS OBTENUES OU FOURNIES PAR L\'INTERMÉDIAIRE DES SERVICES SONT UNIQUEMENT À DES FINS D\'INFORMATION, NE SONT PAS DESTINÉES À FOURNIR UNE RECOMMANDATION SPÉCIFIQUE ET NE DOIVENT PAS ÊTRE UTILISÉES À CET ÉGARD OU À TOUT AUTRE ÉGARD. VOTRE CONFIANCE À TOUTE INFORMATION OBTENUE OU FOURNIE PAR LE BIAIS DES SERVICES EST UNIQUEMENT À VOS PROPRES RISQUES. SANS LIMITER LA GÉNÉRALITÉ DE CE QUI PRÉCÈDE, EN AUCUN CAS EDUPLAN SOLUTIONS N\'EST RESPONSABLE DES DÉCISIONS PRISES OU DES ACTIONS PRISES PAR VOUS À LA SUITE DE L\'UTILISATION DES SERVICES.</p><br/>' +
        '<br />' +
        '<p>VOUS RECONNAISSEZ ET ACCEPTEZ EXPRESSÉMENT QUE L\'UTILISATION DES SERVICES EST À VOS PROPRES RISQUES. DANS LA MESURE MAXIMALE AUTORISÉE PAR LA LOI APPLICABLE, LES SERVICES SONT FOURNIS « EN L\'ÉTAT » ET « SELON LA DISPONIBILITÉ ». EDUPLAN SOLUTIONS DÉCLINE EXPRESSÉMENT TOUTE GARANTIE ET CONDITION DE QUELQUE NATURE QUE CE SOIT RELATIF AUX SERVICES OU À TOUT MATÉRIEL, CONTENU, INFORMATION, PRODUITS OU SERVICES DISPONIBLES PAR L\'INTERMÉDIAIRE DES SERVICES, QU\'ILS SOIENT EXPLICITES, IMPLICITES OU LÉGAUX, Y COMPRIS TOUTE GARANTIE OU CONDITION IMPLICITE DE QUALITÉ MARCHANDE, DE QUALITÉ SATISFAISANTE, D\'ADÉQUATION À UN USAGE PARTICULIER , JOUISSANCE PAISIBLE, TITRE ET NON CONTREFAÇON. DE PLUS, ÉDUPLAN NE FAIT AUCUNE DÉCLARATION OU GARANTIE CONCERNANT L\'AUTHENTICITÉ, L\'EXACTITUDE, LA FIABILITÉ, L\'EXHAUSTIVITÉ, L\'ACTUALITÉ, L\'UTILITÉ, LA NATURE SÉCURISÉE OU LA DISPONIBILITÉ DES MATÉRIAUX, LOGICIELS, SON, TEXTE, GRAPHIQUES, LIENS OU INFORMATIONS DANS LES SERVICES, OU QUE LES FONCTIONS CONTENUES DANS OU PAR FORMÉ OU FOURNI PAR LES SERVICES SERA ININTERROMPU, SANS ERREUR OU RÉPONDRA À VOS EXIGENCES. AUCUNE INFORMATION OU CONSEIL ORAL OU ÉCRIT DONNÉ PAR ÉDUPLAN NE CONSTITUE UNE GARANTIE.</p>',
    side16Intro: '<p>DANS LA MESURE MAXIMALE AUTORISÉE PAR LA LOI APPLICABLE, EDUPLAN SOLUTIONS NE SERA PAS RESPONSABLE ENVERS VOUS OU UN TIERS DES BLESSURES PERSONNELLES OU DES DOMMAGES INDIRECTS, ACCESSOIRES, CONSÉCUTIFS, SPÉCIAUX, PUNITIFS OU EXEMPLAIRES, Y COMPRIS LES DOMMAGES POUR PERTE DE PROFITS, PERTE DE COMMERCE, PERTE D\'UTILISATION, PERTE DE DONNÉES, INTERRUPTION D\'AFFAIRES OU AUTRES PERTES INCORPORELLES, RÉSULTANT OU NON DE OU DÉCOULANT DE OU LIÉES À VOTRE UTILISATION OU INCAPACITÉ À UTILISER LES SERVICES, QUELLE QU\'EN SOIT LA CAUSE, QU\'ELLE SOIT CONTRACTUELS, DÉLICTUELS OU AUTRES (Y COMPRIS, SANS LIMITATION, RUPTURE DE CONTRAT, NÉGLIGENCE, NÉGLIGENCE GRAVE, INFRACTION FONDAMENTALE, FAUSSE DÉCLARATION OU AUTRE) MÊME SI ÉDUP LAN A ÉTÉ INFORMÉ DE LA POSSIBILITÉ DE TELS DOMMAGES. EN AUCUN CAS LA RESPONSABILITÉ GLOBALE D\'ÉDUPLAN ENVERS VOUS POUR TOUS LES DOMMAGES (AUTRE QUE CE QUI PEUT ÊTRE EXIGÉ PAR LA LOI APPLICABLE DANS LES CAS IMPLIQUANT DES BLESSURES CORPORELLES) NE DÉPASSERA CINQUANTE DOLLARS (50,00 $). AUX FINS DU PRÉSENT ARTICLE 11, « EDUPLAN SOLTUIONS » INCLUT LES AFFILIÉS, LES ADMINISTRATEURS, LES DIRIGEANTS, LES EMPLOYÉS, LES AGENTS, LES ENTREPRENEURS, LES SUCCESSEURS ET LES ATTRIBUTEURS D\'EDUPLAN SOLUTIONS ET CEUX DE SES AFFILIÉS.</p>',
    side17Intro: '<p>Dans la mesure maximale permise par la loi applicable, vous acceptez d\'indemniser, de défendre (au choix d\'eduplan solutions) et de dégager eduplan solutions de toute responsabilité en cas de réclamations, perte, responsabilité, dommage, poursuite, action, coût, pénalité et demande, y compris les frais juridiques raisonnables, découlant de ou liés à : (a) votre accès, votre utilisation ou votre mauvaise utilisation des Services ; (b) votre violation de toute loi applicable en rapport avec votre utilisation des Services ; (c) une violation des présentes Conditions ; (d) toute fausse déclaration faite par vous ; et (e) la violation par vous de toute propriété intellectuelle ou autre droit de toute personne ou entité.</p><br/>' +
        '<br />' +
        '<p>Aux fins du présent article 12, « eduplan solutions » comprend les sociétés affiliées, administrateurs, dirigeants, employés, agents, entrepreneurs, successeurs et ayants droit d\'eduplan solutions ainsi que ceux de ses sociétés affiliées.</p>',
    side18Intro: '<p>La relation entre eduplan solutions et vous sera celle d\'entrepreneurs indépendants, et aucune des parties ni aucun de leurs dirigeants, agents ou employés respectifs ne seront tenus ou interprétés comme des partenaires, des co-entreprises, des fiduciaires, des employés ou des agents de l\'autre en raison des présentes Conditions ou de votre utilisation des Services.</p>',
    side19Intro: '<p>Les présentes Conditions, y compris la Politique de confidentialité, votre utilisation des Services et toutes les questions connexes sont régies par les lois de la province du e l\'Ontario,Quebec, Canada et les lois fédérales du Canada qui s\'y appliquent, sans égard aux dispositions relatives aux conflits de lois de toute juridiction. Par la présente, vous vous soumettez irrévocablement à la compétence exclusive des tribunaux de la province de Québec en ce qui concerne tous les litiges découlant de ou liés aux présentes Conditions, y compris la Politique de confidentialité, votre utilisation des Services et toute question connexe.</p>',
    side20Intro: '<p>Les présentes Conditions et les documents référencés aux présentes constituent l\'intégralité de l\'accord entre les parties en ce qui concerne l\'objet des présentes et remplacent tous les accords et accords antérieurs entre les parties en ce qui concerne l\'objet des présentes.</p>',
    side21Intro: '<p>Si une disposition des présentes Conditions est jugée invalide ou inapplicable par un tribunal compétent, cette disposition sera appliquée dans la mesure maximale permise par la loi applicable, et les autres dispositions des présentes Conditions resteront en vigueur et de plein effet.</p>',
    side22Intro: '<p>Aucun manquement à l\'exercice ou à la renonciation à l\'une des dispositions des présentes Conditions ne sera réputé constituer une renonciation supplémentaire ou continue à cette disposition ou à toute autre disposition des présentes Conditions. Ces Conditions vous lient, vous, vos héritiers, exécuteurs testamentaires, bénéficiaires, successeurs et ayants droit et vous ne pouvez pas céder ces Conditions à une autre partie sans le consentement écrit préalable d\'eduplan solutions, lequel consentement peut être refusé à sa seule et absolue discrétion.</p>',
    side23Intro: '<p>Les parties ont demandé que les présentes Conditions et tous les documents envisagés par ces Conditions soient rédigés en anglais. Les parties aux présentes ont exigé que cette entente et tous les autres documents retenus par les présentes soient rédigés en anglais.</p>',
    side24Intro: '<p>Vous adresserez à eduplan solutions , à l\'adresse indiquée ci-dessous, toute question, plainte ou réclamation que vous pourriez avoir (ou toute réclamation d\'un tiers) concernant les Services ou votre possession et/ou utilisation des Services, y compris : (a) les réclamations en responsabilité du fait des produits ; (b) toute réclamation selon laquelle les Services ne sont pas conformes à toute exigence légale ou réglementaire applicable ; et (c) les réclamations découlant de la protection des consommateurs ou d\'une législation similaire :</p><br/>' +
        '<br />' +
        '<p>5400 av. de Westbury</p><br/>' +
        '<p>Montréal, Québec, Canada H3W2W8 </p><br/>' +
        '<p>E-mail : info@eduplan.ca</p><br/>' +
        '<br />' +
        '<p><br />' +
        'Les conditions complémentaires d’utilisation à l’intention des administrateurs du Produit ou Services d’edpro</p><br/>' +
        '<br />' +
        '<p>Les conditions d’utilisation ainsi que les conditions d’utilisation spécifiques des outils d’évaluation (désignés « conditions ») régissent votre utilisation d’edpro et l’administration de tout outil d’évaluation d’edpro.</p><br/>' +
        '<br />' +
        '<p>En cochant « J’accepte les conditions d’utilisation et les politiques de protection de la vie privée », vous confirmez que vous avez lu ces conditions et que vous consentez à vous y conformer.</p>',
    side25Intro: '<p>Les « informations ou données » désigne l’ensemble des informations, des données et des documents comportant des informations nominatives ou non.</p><br/>' +
        '<br />' +
        '<p>Le “traitement de données” désigne toute opération, ou un ensemble d’opérations, portant sur des informations confidentielles, quel que soit le procédé utilisé (collecte, enregistrement, organisation, conservation, adaptation, modification, extraction, consultation, utilisation, communication, par transmission, diffusion ou toute autre forme de mise à disposition, rapprochement ou interconnexion, verrouillage, effacement ou destruction, etc.).</p><br/>' +
        '<br />' +
        '<p>La “protection des données” désigne l’ensemble des principes et obligations juridiques à respecter lors de la collecte, du traitement, de la diffusion et de la conservation de données.</p><br/>' +
        '<br />' +
        '<p>Le « consentement » de la personne concernée : toute manifestation de volonté, libre, spécifique, éclairée et univoque par laquelle la personne concernée accepte, par une déclaration ou par un acte positif clair, que des informations ou des données à caractère personnelles la concernant fassent l’objet d’un traitement.</p>',
    side26Intro: '<p>Afin de pouvoir accéder à edpro et participer au processus d’évaluation d’un professionnel désigné (soit l’administrateur de l’outil d’évaluation) vous devez cliquer sur le lien envoyé par l’administrateur de l’outil (une orthopédagogue, un psychoéducateur, une clinique, un chercheur, un conseiller, etc.)</p><br/>' +
        '<br />' +
        '<p>Vous comprenez que votre participation à l’outil d’évaluation est entièrement volontaire et que vous pouvez en tout temps refuser ou interrompre votre participation en informant l’administrateur de l’outil d’évaluation.</p>',
    side27Intro: '<p>Les renseignements ou données personnelles et confidentielles se réfèrent à toute information qui permet d’identifier directement ou indirectement une personne physique.</p><br/>' +
        '<br />' +
        '<p>À titre de répondant, edpro / eduplan solutions (9201-8688 Québec inc.) récolte votre nom et prénom, votre adresse courriel à des fins de participation.</p><br/>' +
        '<br />' +
        '<p>Vous pouvez compléter l’outil par l’entremise d’un lien envoyé par l’administrateur de l’outil d’évaluation.</p><br/>' +
        '<br />' +
        '<p>Les résultats tiennent compte des réponses fournies et omises. Cela dit, plusieurs réponses omises (n/ a) auraient, possiblement, une incidence sur les résultats.</p><br/>' +
        '<br />' +
        '<p>La plateforme edpro traite les données d’évaluation reçues dans le but de fournir le service attendu et générer un rapport correspondant aux données récoltées.</p><br/>' +
        '<br />' +
        '<p>Dans le cadre du processus d’évaluation, il est demandé de fournir des renseignements (nom, prénom et date de naissance) afin de pouvoir établir un score et générer un rapport cohérent. Certains renseignements sont obligatoires alors que d’autres sont facultatifs.</p>',
    side28Intro: '<p>edpro / eduplan solutions (9201-8688 Québec inc.) s’engage à protéger la confidentialité et la sécurité des renseignements transmis sur sa plateforme, et emploie une technologie qui sécurise les renseignements et empêche toute utilisation, toute divulgation ou tout accès non autorisé.</p><br/>' +
        '<br />' +
        '<p>Vous reconnaissez qu’Internet constitue un média qui n’est pas parfaitement protégé et que la confidentialité de vos renseignements, de vos communications et de vos consultations ne peut pas être garantie. Veuillez consulter la politique d’eduplan solutions (9201-8688 Québec inc.) à l’adresse suivante : https://www.eduplan.ca/politiques.</p><br/>' +
        '<br />' +
        '<p>Les données récoltées sont transmises et stockées sur des serveurs privés situés aux États-Unis par l’entremise d’un fournisseur de services infonuagiques qui participe et se conforme au cadre du bouclier de protection des données UE-États-Unis et Suisse-États-Unis concernant la collecte, l’utilisation et la conservation des informations personnelles des pays membres de l’Union européenne, du Royaume-Uni et de la Suisse, certifiée auprès du ministère du Commerce.</p><br/>' +
        '<br />' +
        '<p>Les employés d’eduplan solutions (9201-8688 Québec inc.) et ceux de ses fournisseurs de services qui ont besoin des renseignements personnels aux fins de l’exécution de leurs tâches peuvent avoir accès à des données. À cet égard, eduplan solutions (9201-8688 Québec inc.) et ses fournisseurs de services pourraient se situer en dehors de votre juridiction de résidence, et les renseignements personnels pourraient être transmis dans la juridiction concernée et y être assujettis aux lois en vigueur. Dans ces limites prévues par la loi, vous pouvez demander d’avoir accès au dossier contenant les renseignements personnels et d’y apporter des corrections, en communiquant avec eduplan solutions (9201-8688 Québec inc.) à l’adresse suivante : info@eduplan.ca.</p><br/>' +
        '<br />' +
        '<p>Internet est un environnement virtuel et international; en utilisant nos produits et services, vous consentez à la transmission transfrontalière et internationale de tout renseignement personnel récolté ou traité par l’entremise de nos produits et services.</p><br/>' +
        '<br />' +
        '<p>En acceptant ces conditions, vous consentez à cette utilisation des renseignements ou données. Vos responsabilités</p><br/>' +
        '<p> •    Vous consentez à ne pas autoriser une autre personne que vous, à utiliser vos accès;</p><br/>' +
        '<p> •    Vous consentez à utiliser edpro à bon escient;</p><br/>' +
        '<p> •    Vous consentez à ne pas commettre des activités interdites sur edpro, activités qui pourraient altérer, compromettre, corrompre, dénaturer ou endommager les services d’edpro;</p><br/>' +
        '<p> •    Vous consentez à utiliser edpro de façon éthique et avec justesse;</p><br/>' +
        '<p> •    Vous consentez à respecter les droits d’auteur, logos et marques de commerce, marques de produits, marques de services;</p><br/>' +
        '<p> •    Vous reconnaissez l’importance de fournir des réponses adéquates;</p>',
    side29Intro: '<p>eduplan solutions (9201-8688 Québec inc.) est le titulaire des droits d’auteur d’edpro et de tout son contenu. Ces droits sont protégés par les lois canadiennes en matière de droit de propriété intellectuelle, les dispositions des traités internationaux et d’autres lois nationales applicables.</p><br/>' +
        '<br />' +
        '<p>Aucune partie d’edpro, y compris les renseignements, le texte, les images, le contenu audio ou vidéo se rapportant à edpro ne peut être copiée, modifiée ou utilisée sans l’autorisation écrite préalable d’eduplan solutions (9201-8688 Québec inc.).</p><br/>' +
        '<br />' +
        '<p>Les noms, logos, marques de commerce, marques de produits ou marques de service contenus dans edpro sont détenus ou utilisés sous licence par eduplan solutions (9201-8688 Québec inc.) et ne peuvent pas être utilisés sans l’autorisation écrite préalable d’eduplan solutions (9201-8688 Québec inc.).</p><br/>' +
        '<br />' +
        '<p>L’utilisation non autorisée en partie intégrale ou partielle d’edpro est strictement interdite et peut constituer une violation des droits d’eduplan solutions (9201-8688 Québec inc.) en matière de droits d’auteur, de marques de commerce, de marques de produits, de marques de services, de confidentialité et de publicité.</p><br/>' +
        '<br />' +
        '<p>En cochant « J’accepte les conditions d’utilisation et les politiques de protection de la vie privée », vous indiquez que vous comprenez et acceptez les présentes conditions d’utilisation.</p>',
};
export var politicsConfidentialFR = {
    mainTitle: 'Programme de gestion de la confidentialité',
    side1: 'Introduction',
    side2: 'Processus de recherche et de développement des produits d\'eduplan solutions',
    side3: 'Pourquoi les données sont-elles collectées en premier lieu?',
    side4: 'Engagement d\'eduplan solutions',
    side5: 'Démarche du programme de gestion de la confidentialité des données',
    side1Intro: '<p>Dans le but d\'assurer le meilleur service possible, eduplan solutions a développé un programme de gestion de la confidentialité pour s\'assurer que les données de nos utilisateurs sont en sécurité et que leurs informations personnelles sont protégées. Contrairement à certains autres services, qui accèdent et conservent des informations personnelles, eduplan solutions s\'engage à donner la priorité à la sécurité de ses utilisateurs. Chez eduplan solutions, la protection de la vie privée des utilisateurs guide la manière dont les opérations sont structurées. </p><br />' +
        '<br />' +
        '<p>Les lignes qui suivent expliquent la structure opérationnelle adoptée par eduplan solutions pour protéger les données privées et le cadre procédural adopté par eduplan solutions en cas de violation potentielle des données.</p>',
    side2Intro: '<p>La confidentialité des informations privées des utilisateurs est une priorité dans la philosophie d’eduplan solutions. Les principes de protection de la vie privée dès la conception (Privacy by design (Pbd)) orientent le développement et la mise en œuvre des produits d’eduplan solutions. Le PbD prend en compte la vie privée tout au long du processus de recherche et de développement tel que prévu par le rapport publié en 1995 par le Commissaire à l’Information et la Vie Privée de l’Ontario et de Ann Cavoukian de l’Autorité de protection des données néerlandaise et de l’Organisation néerlandaise pour la recherche scientifique appliquée.</p>',
    side3Intro: '<p>Les utilisateurs de services se demandent souvent pourquoi les données privées sont collectées par les fournisseurs de services. Les points suivants tenteront de déterminer pourquoi les données sont collectées et comment elles sont utilisées par eduplan solutions :  </p><br />' +
        '<br />' +
        '<p> •    eduplan solutions recueille, stocke, traite et partage les informations personnelles des apprenants / des élèves, uniquement dans le but de fournir notre service tel qu’autorisé par un apprenant, un centre, une clinique, une école, un professionnel ou un parent.</p><br />' +
        '<br />' +
        '<p> •    eduplan solutions collecte, conserve et utilise (avec consentement éclairé de l\'utilisateur) des données à des fins de recherche et de développement. La collecte de données permet le développement de la structure d\'intelligence artificielle d\'eduplan solutions et une meilleure expérience utilisateur;  </p><br />' +
        '<br />' +
        '<p> •    eduplan solutions n\'est pas propriétaire des données personnelles collectées de l\'utilisateur, mais conserve la propriété intellectuelle résultant de l\'utilisation des produits eduplan solutions. Les utilisateurs d\'eduplan solutions conservent à tout moment la possession de leurs informations personnelles et se verront offrir une option de désinscription conduisant à la suppression ou à l\'anonymisation des données privées une fois qu\'ils n\'utiliseront plus les services d’eduplan solutions.  </p><br />' +
        '<br />' +
        '<p> •    eduplan solutions ne partagent, ne vendent ou n\'utilisent pas des informations privées collectées auprès des utilisateurs à des fins autres que la recherche et le développement afin de fournir un meilleur service. À ce titre, eduplan solutions n\'utilise, ne partage ni ne vend aucune information collectée à des fins publicitaires ou marketing. </p><br />' +
        '<p></p>',
    side4Intro: '<p>Pour assurer le plus haut niveau de confidentialité de ses clients et de ses utilisateurs, eduplan solutions s\'engage à:  </p><br />' +
        '<br />' +
        '<p> •    Prioriser la confidentialité et à la protection des données privées tant dans la conception que dans le design du site Web et des logiciels; </p><br />' +
        '<br />' +
        '<p> •    Créer des protocoles qui tiendront l\'utilisateur informé de la manière et de l\'endroit où ses données sont utilisées; </p><br />' +
        '<br />' +
        '<p> •    Établir et créer des documents facilement accessibles et compréhensibles expliquant la collecte et l\'utilisation des données privées. Ces documents seront rédigés pour répondre aux préoccupations des clients et utilisateurs concernant la sécurité des données privées; </p><br />' +
        '<br />' +
        '<p> •    Développer une solution de désengagement qui assure la suppression ou l\'anonymisation des informations personnelles précédemment collectées à la demande de l\'utilisateur.</p><br />' +
        '<br />' +
        '<p> •    S\'assurer de la mise en œuvre d\'un protocole à l\'intention des employés d\'eduplan solutions  </p>',
    side5Intro: '<p>Eduplan solutions a développé et mis en œuvre des protocoles de sécurité pour assurer la protection des informations privées de ses utilisateurs. Ces protocoles sont expliqués dans les paragraphes suivants:  </p><br />' +
        '<br />' +
        '<p>Étape #1 – Nomination d\'un responsable de la confidentialité. </p><br />' +
        '<br />' +
        '<p>Le responsable de la confidentialité est chargé de veiller à la mise en œuvre de protocoles visant à protéger les données privées de l\'utilisateur. Le responsable de la confidentialité joue un rôle central dans le respect du code et des objectifs déontologiques d\'Eduplan solutions. Certaines des tâches du responsable de la confidentialité incluent:  </p><br />' +
        '<br />' +
        '<p> •    Fournir à chaque département d\'Eduplan solutions une formation relative à la mise en œuvre des mesures de confidentialité; </p><br />' +
        '<br />' +
        '<p> •    Mettre en place un protocole de sécurité interne et une structure externe pour informer les clients /utilisateurs en cas de violation de données; </p><br />' +
        '<br />' +
        '<p> •    Présider un comité de pilotage de la structure de protection des données d\'Eduplan Solution et veiller à ce que la vie privée dès la conception (Privacy by design) reste au cœur de la philosophie de l\'entreprise; </p><br />' +
        '<br />' +
        '<p> •    Veiller à ce que tout le personnel et les associés d\'Eduplan solutions signent un accord de non-divulgation, garantissant ainsi que les données de l\'utilisateur sont protégées contre les erreurs humaines; </p><br />' +
        '<br />' +
        '<p> •    Mettre en œuvre des mesures disciplinaires pour garantir un engagement strict à la confidentialité des données par le personnel et la direction de l\'entreprise. </p><br />' +
        '<br />' +
        '<p>Étape # 2 – Organisation du processus de gestion de la confidentialité </p><br />' +
        '<br />' +
        '<p>Dans le cadre de son engagement envers la vie privée dès la conception (Privacy by design), Eduplan solutions a créé une structure de gouvernance interne solide qui contribue à favoriser une culture de confidentialité pour les informations des utilisateurs. Le processus est expliqué en résumé ci-dessous. </p><br />' +
        '<br />' +
        '<p>a. Inventaire des informations personnelles : </p><br />' +
        '<br />' +
        '<p>La première étape du programme est une évaluation constante des données détenues par l\'entreprise, où elles sont conservées et comment elles sont utilisées. Comprendre et documenter les types d’informations collectées par Eduplan solutions est d\'une importance cruciale, car cela permet de déterminer: </p><br />' +
        '<br />' +
        '<p> •    La quantité de données détenues par l\'entreprise; </p><br />' +
        '<br />' +
        '<p> •    Une mise à jour régulière des protocoles de sécurité. </p><br />' +
        '<br />' +
        '<p>Cette évaluation est la première étape d\'un programme de gestion de la confidentialité responsable et conforme. </p><br />' +
        '<br />' +
        '<p>b. Élaboration et la mise en œuvre des politiques. </p><br />' +
        '<br />' +
        '<p>La deuxième étape du processus est centrée sur l\'élaboration et la mise en œuvre de politiques internes. Les politiques comprennent ce qui suit: </p><br />' +
        '<br />' +
        '<p> •    Politiques définissant les pratiques normalisées en matière de collecte, d\'utilisation et de divulgation des informations personnelles, y compris les exigences en matière de consentement et de notification; </p><br />' +
        '<br />' +
        '<p> •    Politiques relatives à l\'accès et à la correction des informations personnelles par les utilisateurs; </p><br />' +
        '<br />' +
        '<p> •    Politiques relatives à la conservation et à l\'élimination des informations personnelles; </p><br />' +
        '<br />' +
        '<p> •    Politiques définissant l\'utilisation responsable des informations, y compris les contrôles de sécurité technologiques et les contrôles d\'accès appropriés; </p><br />' +
        '<br />' +
        '<p> •    Politiques relatives à la contestation de la conformité. </p><br />' +
        '<br />' +
        '<p>c. Évaluation périodique des risques. </p><br />' +
        '<br />' +
        '<p>Eduplan solutions fait des évaluations périodiques des risques pour se tenir au courant des nouveaux risques liés à la confidentialité. La conduite d\'une évaluation semestrielle ou annuelle des risques pour la vie privée a les objectifs suivants: </p><br />' +
        '<br />' +
        '<p> •    S’assurer que tous les nouveaux services et produits proposés par Eduplan solutions sont alignés et conformes aux législations applicables; </p><br />' +
        '<br />' +
        '<p> •    Examiner et vérifier les nouveaux produits et actions du point de vue de la confidentialité pour garantir la minimisation de tout impact négatif sur la confidentialité </p><br />' +
        '<br />' +
        '<p>d. Formation relatives aux exigences</p><br />' +
        '<br />' +
        '<p>Afin de favoriser une culture de confidentialité solide et efficace, les employés d\'Eduplan solutions suivent une formation qui porte sur la protection des données confidentielles. Les employés suivent une formation spécifique au département pour s\'assurer que: </p><br />' +
        '<br />' +
        '<p> •    Les problèmes de confidentialité sont signalés et identifiés de manière rapide et plus efficace; </p><br />' +
        '<br />' +
        '<p> •    Les mesures appropriées sont prises plus rapidement; </p><br />' +
        '<br />' +
        '<p> •    Les employés sont sensibilisés et conscientisés des risques pour la vie privée. </p><br />' +
        '<br />' +
        '<p>Ces formations visent à encourager le développement de produits et services conformes à la loi sur la protection de la vie privée. </p><br />' +
        '<br />' +
        '<p>e. Application d\'un protocole de gestion des violations et des incidents. </p><br />' +
        '<br />' +
        '<p>Pour assurer la protection de la vie privée, Eduplan solutions a développé un protocole de gestion des violations et des incidents ainsi qu\'un protocole des procédures de réponse. Le protocole de gestion des violations se fait en collaboration entre tous les départements d’Eduplan solutions. </p><br />' +
        '<br />' +
        '<p>Définition  : une atteinte à la sécurité se produit lorsqu\'il y a une perte ou un accès non autorisé à des renseignements personnels. </p><br />' +
        '<br />' +
        '<p>Conformément aux exigences du gouvernement canadien telles qu\'énoncées dans la « Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE) », Eduplan solutions met en œuvre les étapes obligatoires suivantes en cas de violation de la sécurité. </p><br />' +
        '<br />' +
        '<p>En cas de manquement :</p><br />' +
        '<br />' +
        '<p>Lorsque notre équipe est informée d\'une violation, les mesures suivantes sont prises: </p><br />' +
        '<br />' +
        '<p>1. Toute violation des mesures de sécurité qui présente un risque de préjudice important pour les utilisateurs est signalée par écrit à l\'OPC. Le rapport comprend les éléments suivants: </p><br />' +
        '<br />' +
        '<p> •    Une description des circonstances de la violation et de sa cause (si elle est connue); </p><br />' +
        '<p> •    Où et quand la violation s\'est produite; </p><br />' +
        '<p> •    Une description des informations personnelles qui ont fait l\'objet de la violation; </p><br />' +
        '<p> •    Le nombre approximatif de personnes qui ont été touchées par la violation; </p><br />' +
        '<p> •    Les mesures prises par l\'organisation pour réduire tout risque de préjudice; </p><br />' +
        '<p> •    Le nom et les coordonnées du responsable de la confidentialité. </p><br />' +
        '<br />' +
        '<p>2. Les personnes concernées et les tiers concernés seront informés de la violation qui pourrait présenter un risque de préjudice important. Cette notification contiendra les éléments suivants: </p><br />' +
        '<br />' +
        '<p> •    Les circonstances et le moment de l\'infraction; </p><br />' +
        '<p> •    Les informations ont été divulguées ou perdues lors de la violation; </p><br />' +
        '<p> •    Les mesures prises par Eduplan solutions pour réduire les risques de préjudice; </p><br />' +
        '<p> •    Les mesures que la personne touchée peut prendre pour réduire les risques de préjudice; </p><br />' +
        '<p> •    L’adresse courriel à contacter en cas de questions ou de préoccupations. </p><br />' +
        '<br />' +
        '<p>3. Un enregistrement détaillé de toutes les violations sera conservé, quel que soit le préjudice potentiel de la violation de données. Le dossier comprendra les éléments suivants: </p><br />' +
        '<br />' +
        '<p> •    Une évaluation des risques de préjudice. </p><br />' +
        '<p> •    Une fiche détaillée pour les fiches OPC; </p><br />' +
        '<p> •    La nature et la sensibilité de la violation; </p><br />' +
        '<p> •    Des informations quant à la probabilité que des informations personnelles soient utilisées à mauvais escient. </p><br />' +
        '<br />' +
        '<p>Étape # 3 Évaluation de la sécurité des fournisseurs tiers </p><br />' +
        '<br />' +
        '<p>L\'élaboration d\'un solide processus de gestion de la vie privée nécessite de tenir compte de tous les endroits où des informations privées sont détenues. Eduplan Solution souhaite s\'assurer que des processus appropriés sont en place chez les collaborateurs tiers pour protéger les informations personnelles. Eduplan solutions prendra en considération si les informations quittent le pays et quelle est la politique en matière de confidentialité dans un pays étranger. </p><br />' +
        '<br />' +
        '<p>Plus d\'informations peuvent être trouvées dans la directive de l\'OPC pour le traitement des données personnelles au-delà des frontières. </p><br />' +
        '<br />' +
        '<p>Ces considérations font partie de l\'évaluation des risques. Les exigences de confidentialité pour les fournisseurs de services doivent inclure les éléments suivants: </p><br />' +
        '<br />' +
        '<p> •    Les clauses de confidentialité dans les contrats. Eduplan solutions inclura des conditions contractuelles qui lieront les fournisseurs de services aux politiques et protocoles de confidentialité d\'Eduplan solutions et exigeront que ces tiers informent l\'organisation en cas de violation; </p><br />' +
        '<br />' +
        '<p> •    eduplan solutions offrira une formation et une éducation à tous les employés des fournisseurs de services susceptibles d\'avoir accès à des renseignements personnels. </p><br />' +
        '<br />' +
        '<p>L’audit et les accords avec le fournisseur de services indiquant qu\'il se conformera à la politique et au protocole privés de l\'entreprise. </p>',
};
export var politicsCoppaFR = {
    mainTitle: 'COPPA\n' +
        'Conformité réglementaire selon le territoire',
    side1: 'États-Unis d’Amérique',
    side2: 'Union européenne',
    side3: 'Québec',
    side1Intro: '<p>La loi fédérale américaine Family Education Rights and Privacy Act (FERPA), vise la protection et la confidentialité des dossiers scolaires des élèves et confère aux parents certains droits relatifs aux dossiers scolaires de leurs enfants mineurs. Un de ces droits se rapporte au droit de passer en revue les dossiers scolaires de l’élève tenus par l’école, le droit de demander à une école d\'ajuster, de corriger ou de rectifier un ou plusieurs dossiers dont le parent juge inexact ou trompeur.</p><br />' +
        '<br />' +
        '<p>En outre, la loi FERPA prévoit que l\'école ou le centre scolaire doit généralement obtenir une permission écrite du parent ou tuteur de l\'enfant mineur pour communiquer un ou plusieurs renseignements tirés du ou des dossiers scolaires d’un élève. Les dossiers scolaires représentent l’ensemble des dossiers, tels que des dossiers d\'aide particulière et des dossiers professionnels, des fichiers et tout autre document contenant de l’information concernant l’élève, y compris des renseignements personnels contenus dans ces dossiers.</p><br />' +
        '<p> </p><br />' +
        '<p>Bien que la loi FERPA a pour objet de protéger les données confidentielles et exige une permission pour le partage d\'informations concernant l\'élève mineur, elle permet de communiquer certaines informations sans consentement issues des dossiers scolaires à certaines parties dans des circonstances ou situations particulières (voir la disposition 34 CFR § 99.31) :</p><br />' +
        '<p> •    à des représentants de l’école qui ont un intérêt éducatif légitime au regard de l\'élève;</p><br />' +
        '<p> •    à une autre école ou une autre centre scolaire vers laquelle l’élève est transféré;</p><br />' +
        '<p> •    à des représentants déterminés à des fins de vérification ou d’évaluation;</p><br />' +
        '<p> •    à des parties appropriées dans le cadre de l’aide financière offerte à un élève;</p><br />' +
        '<p> •    à des organisations qui réalisent certaines études pour l’école ou au nom de celle-ci;</p><br />' +
        '<p> •    à des organisations d’agrément;</p><br />' +
        '<p> •    à une ordonnance judiciaire ou à une assignation à comparaître légalement signifiée;</p><br />' +
        '<p> •    à des représentants appropriés en cas d’urgence de nature médicale ou sécuritaire; et aux autorités étatiques et locales dans le cadre du système de justice pour mineurs, conformément à une loi étatique précise.</p><br />' +
        '<br />' +
        '<p>Les écoles ou les centres scolaires peuvent aussi, sans consentement, communiquer des renseignements susceptibles d’être consignés dans un annuaire, par exemple le nom de l’élève, son adresse, son numéro de téléphone, sa date, son lieu de naissance, ses distinctions, ses récompenses, ainsi que les dates de fréquentation de l’établissement. Cependant, les écoles ou les centres scolaires doivent informer les parents de ces renseignements d’annuaire et leur accorder une période suffisante pour leur permettre d’indiquer s’ils ne souhaitent pas la publication de cette information.</p><br />' +
        '<br />' +
        '<p>Bien que la loi FERPA vise principalement les établissements d’enseignement, eduplan solutions, ses Produits et ses Services, remplissent le rôle de « représentant de l’école » (School Official) avec un intérêt éducatif légitime en vertu de la FERPA; notre politique de confidentialité est conçue de façon à obtenir les données relatives aux élèves conformément à la loi FERPA et à permettre aux établissements d’enseignement qui utilisent nos Produits ou Services de respecter cette loi. </p><br />' +
        '<br />' +
        '<p>La loi fédérale américaine Child Online Privacy Protection Act (COPPA), vise à protéger la vie privée des enfants de moins de 13 ans. La loi COPPA exige que les fournisseurs de services en ligne comme les Produits et Services d\'eduplan solutions (banqo, edpro, smartplan, etc.), que les écoles ou centres scolaires, que les professionnels enseignants ou les professionnels non enseignants, qui agissent comme mandataires d’un parent, fournissent une notification parentale et obtiennent un consentement parental vérifiable avant de recueillir sciemment des renseignements personnels auprès d’enfants de moins de 13 ans, sauf dans certaines situations précises (pour en savoir plus sur ces exceptions, veuillez consulter la disposition 16 CFR § 312.5(c)).</p><br />' +
        '<br />' +
        '<p>En plus des conditions d’utilisation des Produits ou Services d\'eduplan solutions, les utilisateurs ont l\'obligation d\'obtenir un consentement parental vérifiable si la loi COPPA l’exige.  </p><br />' +
        '<br />' +
        '<p>Si vous constatez que des informations personnelles ont été partagés sans le consentement parental ou d’une façon qui dépasse la stricte portée nécessaire pour la participation relative aux Produits ou aux Services d\'eduplan solutions,eduplan solutions, ses Produits ou ses Services supprimeront ces informations ou renseignements le plus tôt possible.</p><br />' +
        '<p> </p><br />' +
        '<p>Si vous croyez que des renseignements ont été obtenus d’un élève âgé de moins de 13 ans ou un enfant plus de 13 ans, mais incapable de prendre la décision et dont le titulaire ou le parent a l’autorité, à l\'encontre de nos politiques veuillez nous en aviser à rapidement en cliquant ici.</p><br />' +
        '<br />' +
        '<p>Veuillez lire les rubriques suivantes pour obtenir une description des types de renseignements personnels que nous recueillons, de la façon dont nous les recueillons, de la façon dont nous les utilisons et de nos politiques pour empêcher les tiers d’y accéder.</p><br />' +
        '<br />' +
        '<p>eduplan solutions, ses Produits et ses Services prennent très au sérieux les droits des enfants et des parents. C’est pourquoi ils prescrivent aux enfants de communiquer uniquement ce qui est nécessaire pour utiliser les Produits ou les Services d\'eduplan solutions. </p><br />' +
        '<br />' +
        '<p>Les parents peuvent consulter les renseignements personnels de leur enfant, ordonner à eduplan solutions de les supprimer, ainsi que refuser toute collection ou utilisation ultérieure de ceux-ci.</p><br />' +
        '<br />' +
        '<p>Les parents peuvent consentir à la collecte des renseignements relatifs à leur enfant, mais refuser de les communiquer à des tiers, sauf dans le cadre de ses Services.</p><br />' +
        '<p> </p><br />' +
        '<p>En plus du fonctionnement des Produits et des Services d\'eduplan solutions, ses Produits et ses Services, considèrent que les intervenants agissent comme le mandataire du parent en acceptant la collecte d’informations sur leurs élèves ou clients, et, de ce fait, nous présumons que les intervenants ont obtenu le consentement des parents à cet égard. De plus, nous recommandons aux intervenants de conserver une preuve vérifiable de ce consentement parental et de permettre aux parents de prendre connaissance de l’information recueillie.</p>',
    side2Intro: '<p>Depuis le 24 mai 2018, le Règlement général sur la protection des données de l’Union européenne (RGPD) établit les règles relatives à la collecte, à l’utilisation et à la conservation des données personnelles des citoyens de l’Union européenne.</p><br />' +
        '<p> </p><br />' +
        '<p>eduplan solutions, ses Produits et ses Services,  se conforment pleinement au RGPD.</p><br />' +
        '<br />' +
        '<p>eduplan solutions est une société par actions canadienne dont le siège se trouve à Montréal, au Canada. Pour exploiter ses Produits ou ses Services,  ils peuvent recueillir, utiliser et conserver des données personnelles d’utilisateurs du Produits ou des Services qui se trouvent dans l’Union européenne, le tout conformément à la présente Politique. La Commission européenne a décrété que les entités commerciales au Canada satisfont aux exigences du RGPD, ou encore fournissent déjà un niveau de protection adéquat des données. Si vous souhaitez consulter une liste à jour des pays qui fournissent un niveau de protection adéquat des données, veuillez visiter le site de l’Union européenne.</p><br />' +
        '<br />' +
        '<p>De plus, afin d’exploiter ses Produits et ses Services,  eduplan solutions fait affaire avec des sous-traitants tiers aux États-Unis, pays dans lequel sont stockés les renseignements personnels des utilisateurs des Produits ou Services, le tout conformément à la présente Politique. Par conséquent, quand eduplan solutions, ses Produits et ses Services transfèrent des renseignements personnels hors de l’espace économique européen et vers un pays ou un cadre législatif n’assurant pas, selon la Commission européenne, un niveau de protection suffisant des données personnelles (ce qui comprend les États-Unis), ces transferts sont régis par des ententes de traitement des données qui sont conformes aux exigences de l’Union européenne en matière de protection des données.</p>',
    side3Intro: '<p>Les droits des utilisateurs au Québec sont régis par la Loi sur la protection des renseignements personnels dans le secteur privé.  De plus, les données sont stockées sur les serveurs d’Amazon aux États-Unis, et eduplan solutions, ses Produits et ses Services se conforment à l’article 17 de la Loi sur la protection des renseignements personnels dans le secteur privé relativement à l’utilisation des renseignements personnels.</p><br />' +
        '<br />' +
        '<p>Selon la loi, les écoles aux États-Unis sont dans l\'obligation de se conformer au Children\'s Online Privacy Protection Act (COPPA) avant d\'utiliser les Produits ou les Services d\'eduplan solutions en ligne. Cette loi exige un consentement parental écrit pour les enfants de moins de 13 ans ou plus de 13 ans dans le cas ou le jeune en ait jugé incapable et que le parent ou tuteur de l\'enfant en est responsable, avant qu\'ils ne puissent utiliser des services en ligne qui recueillent tout type d\'informations personnelles (y compris le nom et prénom des enfants, et les données liées à leurs activités, etc.). Ces informations seront protégées conformément à la Politique de confidentialité relative aux Produits et Services d\'eduplan solutions.</p><br />' +
        '<br />' +
        '<p>Si vous êtes un intervenant avec des élèves ou des clients de moins de 13 ans ou de plus de 13 ans dans le cas ou le jeune en ait jugé incapable et que le parent ou tuteur de l\'enfant en est responsable, vous devez informer leurs parents et obtenir leur consentement avant de le créer un compte.</p><br />' +
        '<p> </p><br />' +
        '<p>Pour ce faire, vous avez deux moyens mis à votre disposition.: 1) via les Produits ou les Services d\'eduplan solutions, vous serez en mesure d"envoyer un formulaire directement aux parents; ou en téléchargeant le ou les formulaires en version PDF (formulaire 1 ou formulaire 2). Conservez ces formulaires dans vos dossiers une fois qu\'ils sont remplis.</p><br />' +
        '<br />' +
        '<p>Si vous êtes le parent ou le tuteur d\'un enfant de moins de 13 ans ou de plus de 13 ans, mais incapable de prendre la décision et dont vous en avez l’autorité, vous devez donner votre consentement.</p><br />' +
        '<br />' +
        '<p>Pour ce faire, vous avez deux moyens mis à votre disposition.: 1) vous allez recevoir un formulaire directement par l\'utilisateur principal de nos Produits ou Services; ou en téléchargeant le formulaire en version PDF (formulaire 1 ou formulaire 2).  Une fois remplis, le ou les formulaires devront être envoyés à l\'utilisateur principal de nos Produits ou nos Services.</p>',
};
export var politicsCookiesFR = {
    mainTitle: 'Cookies',
    side1: 'Qu\'est-ce qu\'un cookie ?',
    side2: 'Pourquoi utilisons-nous des cookies ?',
    side3: 'Tableau des cookies :',
    side4: 'Vos choix :',
    side1Intro: '<p>Un cookie est un petit fichier constitué de lettres et de chiffres et téléchargé sur votre ordinateur lorsque vous accédez à certains sites web. En général, les cookies permettent à un site web de reconnaître l\'ordinateur de l’utilisateur.</p><br />' +
        '<br />' +
        '<p>La chose la plus importante à savoir sur les cookies que nous plaçons est qu\'ils servent à améliorer la convivialité de notre site web, par exemple en mémorisant les préférences du site et les paramètres linguistiques.</p>',
    side2Intro: '<p>Nous pouvons utiliser des cookies et d\'autres technologies similaires pour un certain nombre de raisons, par exemple : i) pour des besoins de sécurité ou de protection contre la fraude, et afin d\'identifier et de prévenir les cyber-attaques, ii) pour vous fournir le service que vous avez choisi de recevoir de notre part, iii) pour contrôler et analyser les performances, le fonctionnement et l\'efficacité de notre service et iv) d\'améliorer votre expérience utilisateur.</p>',
    side3Intro: '<p>eduplan solutions, ses Produits et ses Services utilisent les types de cookies et de stockage local suivants (collectivement appelés « Cookies » dans les présentes) :</p><br />' +
        '<br />' +
        '<p>Cookies de performance et de fonctionnalité : ces Cookies ne sont pas essentiels, mais nous aident à personnaliser et à améliorer votre expérience utilisateur.</p><br />' +
        '<br />' +
        '<p>Par exemple, ils peuvent nous aider à mémoriser vos préférences et vous éviter d\'avoir à ressaisir des informations plus d\'une fois, ou à mémoriser votre identifiant et votre mot de passe afin que vous n\'ayez pas à les saisir à chaque fois que vous utilisez nos services.</p><br />' +
        '<br />' +
        '<p>eduplan solutions, ses Produits et ses Services ne fait pas usage des cookies à des fins de publicités ou de marketing.</p>',
    side4Intro: '<p>Pour en savoir plus sur les cookies, notamment sur la manière de voir quels cookies ont été définis et de comprendre comment les gérer, les supprimer ou les bloquer, visitez https://aboutcookies.org/ ou https://www.allaboutcookies.org/fr/.</p><br />' +
        '<br />' +
        '<p>Il est également possible d\'empêcher votre navigateur d\'accepter les cookies en modifiant les paramètres concernés dans votre navigateur. Vous pouvez généralement trouver ces paramètres dans le menu « Options » ou « Préférences »de votre navigateur.</p><br />' +
        '<br />' +
        '<p>Veuillez noter que la suppression de nos cookies ou la désactivation de futurs cookies ou technologies de suivi pourront vous empêcher d\'accéder à certaines zones ou fonctionnalités de nos services, ou pourront autrement affecter négativement votre expérience d\'utilisateur.</p><br />' +
        '<br />' +
        '<p>Les liens suivants peuvent être utiles, ou vous pouvez utiliser l\'option « Aide » de votre navigateur.</p><br />' +
        '<br />' +
        '<p> •    Paramètres des cookies dans Firefox</p><br />' +
        '<p> •    Paramètres des cookies dans Internet Explorer</p><br />' +
        '<p> •    Paramètres des cookies dans Google Chrome</p><br />' +
        '<p> •    Paramètres des cookies dans Safari (OS X)</p><br />' +
        '<p> •    Paramètres des cookies dans Safari (iOS)</p><br />' +
        '<p> •    Paramètres des cookies dans Android</p><br />' +
        '<br />' +
        '<p>Pour refuser et empêcher que vos données soient utilisées par Google Analytics sur tous les sites web, consultez les instructions suivantes : https://tools.google.com/dlpage/gaoptout?hl=fr.</p><br />' +
        '<br />' +
        '<p>Il se peut que nous modifiions cette politique en matière de cookies. Nous vous encourageons à consulter régulièrement cette page pour obtenir les dernières informations sur les cookies.</p>',
};
