import React, { useEffect, useState } from "react";
var LightText = React.forwardRef(function (props, ref) {
    var _a = useState(''), content = _a[0], setContent = _a[1];
    useEffect(function () {
        var content = props.children;
        if (props.searchText != null && props.searchText != '') {
            var expression = props.searchText.replace(/[^a-zA-Z0-9]/gi, "\\$&");
            var regex = new RegExp(expression, "gi");
            content = content.replace(regex, "<span class='bg-c-highlight'>$&</span>");
        }
        setContent(content);
    }, [props.children, props.searchText]);
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: " ".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : ''), dangerouslySetInnerHTML: { __html: content }, onClick: props.onClick, ref: ref })));
    };
    return render();
});
export default LightText;
