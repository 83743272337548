import React from "react";
var EdproIcon = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { onClick: props.onClick, className: props.additionalClass, xmlns: "http://www.w3.org/2000/svg", width: "96px", height: "24px", fill: props.color, viewBox: "0 0 885.6 232.3" },
            React.createElement("g", { id: "Layer_2" },
                React.createElement("g", { id: "Layer_1-2" },
                    React.createElement("path", { d: "M275.2,174c-36.2,0-63-25.6-63-66,0-40.2,25.8-65.5,63-65.5,36.9,0,61.1,26,61.1,60.9a109.81,109.81,0,0,1-.7,12.8H233.8c1.6,25.8,19.8,40,41.4,40,19,0,31.1-9.8,35.8-23.5h22.8C327.2,155.9,306.8,174,275.2,174ZM234.1,99h80.4c.2-24.9-18.4-38.6-40.2-38.6C253.6,60.4,236.4,74.1,234.1,99Z" }),
                    React.createElement("path", { d: "M416.9,42.5c20,0,38.3,10.2,47.2,24.9V0h21.4V171.9H464V148c-7.7,14.2-24.2,26-47.4,26-34.1,0-60.4-26.7-60.4-66.2C356.2,68.1,382.5,42.5,416.9,42.5Zm3.9,18.4c-23.2,0-43,17-43,46.9s19.7,47.6,43,47.6S464,138,464,108c0-29.5-19.9-47.1-43.2-47.1Z" }),
                    React.createElement("path", { d: "M590,42.5c34.2,0,60.4,25.6,60.4,65.3,0,39.5-26.2,66.2-60.4,66.2-23.2,0-39.7-12.5-47.6-25.6v83.9H521.2V44.6h21.1V68.1C550,54.6,566.7,42.5,590,42.5Zm-4.4,18.4c-23,0-43.2,17.6-43.2,47.2,0,30,20.2,47.4,43.2,47.4,23.5,0,43.2-17.7,43.2-47.6s-19.8-47-43.2-47Z" }),
                    React.createElement("path", { d: "M699.4,171.9H678.3V44.6h21.1V65.3c7.2-14.2,20.9-23,41.1-23V64.1h-5.6c-20.2,0-35.5,9.1-35.5,38.6Z" }),
                    React.createElement("path", { d: "M820.4,174c-36,0-63.4-25.6-63.4-66,0-40.2,28.3-65.5,64.3-65.5,36.2,0,64.3,25.3,64.3,65.5C885.7,148.4,856.6,174,820.4,174Zm0-18.6c22.1,0,43.7-15.1,43.7-47.4,0-32.1-21.1-47.2-43-47.2-22.3,0-42.5,15.1-42.5,47.2C778.6,140.3,798.3,155.4,820.4,155.4Z" }),
                    React.createElement("path", { d: "M124.8,156.4a24.92,24.92,0,0,0-6.67.91L101.88,114A49.61,49.61,0,1,0,40.55,89.61a3.86,3.86,0,0,0,.15.39,28,28,0,0,1,3,13.9,28.55,28.55,0,0,1-21,26.26,25.6,25.6,0,1,0,27.08,33.72l50.57,13A24.8,24.8,0,1,0,124.8,156.4Zm-74,3.61a25.74,25.74,0,0,0,.39-4.41,25.24,25.24,0,0,0-.82-6.43A28.51,28.51,0,0,1,66.7,119.4a28.17,28.17,0,0,1,14-2.5,5.05,5.05,0,0,0,.88,0c1.1.07,2.2.12,3.32.12a49.65,49.65,0,0,0,13.16-1.78l16.32,43.47a24.86,24.86,0,0,0-13,14.29Zm74,42a20.8,20.8,0,1,1,20.8-20.8A20.78,20.78,0,0,1,124.8,202Z" }))))));
};
export default EdproIcon;
