var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestionnaire, updateAnswerActivity, updateAnswerText, updateAnswerArray, updateCurrentPage, updateDateArray, saveAnswer, updateFileAns, uploadFile, } from "../../feature/anaquestionnaire";
import { updateSectionIndex } from "../../feature/questionnaire";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../utils/functions";
import { useQueryClients } from "../../hooks/client/useQueryClient";
import ModalInAnswerQuestionnaire from "../../components/Layouts/ModalInAnswerQuestionnaire";
import BaseTitle from "../../components/Title/BaseTitle";
import { lang } from "../../lang/lang";
import Text from "../../components/Field/Text";
import Text1FromDesign from "../../components/Title/Text1FromDesign";
import UncheckedCheckbox from "../../assets/icons/UncheckedCheckbox";
import { EAnaQuestionRadio } from "../../enums/questionnaire";
import { dropdownFR, dropdownEN } from "../../enums/dropdown";
import CheckedCheckbox from "../../assets/icons/CheckedCheckbox";
import TextField from "../../components/Input/TextField";
import LightSubmitButtonWithIcon from "../../components/Button/LightSubmitButtonWithIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
import HelpIcon from "../../assets/icons/HelpIcon";
import SubmitButtonWithIcon from "../../components/Button/SubmitButtonWithIcon";
import ForwardIcon from "../../assets/icons/ForwardIcon";
import { useForm } from "react-hook-form";
import SectionBar from "./SectionBar";
import ArrowButton from "../../components/Button/ArrowButton";
import env from "../../config/env";
import TextError from "../../components/Field/TextError";
import LightModal from "../../components/Layouts/LightModal";
import IconContainer from "../../components/IconContainer/IconContainer";
import CloseIcon from "../../assets/icons/CloseIcon";
import Title from "../../components/Title/Title";
import LightSubmitButton from "../../components/Button/LightSubmitButton";
import LightReverseColorButtonWithIcon from "../../components/Button/LightReverseColorButtonWithIcon";
import FormError from "../../components/Error/FormError";
import TextArea from "../../components/Input/TextArea";
import UserGuideSet from "./UserGuideSet";
import DropDownArray from "../../components/DropDown/DropDownArray";
import { anaPlaceholderFR, anaSubsectionTitleFR, anaPlaceholderEN, anaSubsectionTitleEN } from "../../enums/anaPlaceholder";
import TextIconField from "../../components/Input/TextIconField";
import { validation_lang } from "../../lang/validations";
import DatePicker from "../../components/DatePicker/DatePicker";
import YearPicker from "../../components/DatePicker/YearPicker";
import CustomCheckbox from "../../components/Input/CustomCheckbox";
import File from "../../components/Input/File";
var AnaQuestionnaire = function (props) {
    var _a;
    var _b = useForm(), register = _b.register, resetField = _b.resetField, setValue = _b.setValue, errors = _b.formState.errors;
    var _c = useState(false), madeChanges = _c[0], setMadeChanges = _c[1];
    var _d = useState(false), showRadio = _d[0], setShowRadio = _d[1];
    var _e = useState(), detail = _e[0], setDetail = _e[1];
    var anaquestionnaireStore = useSelector(function (store) { return store === null || store === void 0 ? void 0 : store.anaQuestionnaire; });
    var answers = useSelector(function (store) { return store.anaQuestionnaire.answers; });
    var user = useSelector(function (store) { return store.user.value; });
    var QuestionlistborderTopClass = "border-b-2 border-c-fuscous-grey";
    var _f = useState(0), returnPageNumber = _f[0], setReturnPageNumber = _f[1];
    var _g = useState({
        activity: []
    }), isPageValid = _g[0], setIsPageValid = _g[1];
    var dispatch = useDispatch();
    var params = useParams();
    var LG = (detail === null || detail === void 0 ? void 0 : detail.language) == 'english' ? 'en' : 'fr';
    var dropdown = LG == 'fr' ? dropdownFR : dropdownEN;
    var anaPlaceholder = LG == 'fr' ? anaPlaceholderFR : anaPlaceholderEN;
    var anaSubsectionTitle = LG == 'fr' ? anaSubsectionTitleFR : anaSubsectionTitleEN;
    var searchTitleAndName = useQueryClients().searchTitleAndName;
    var navigate = useNavigate();
    var _h = useState(false), ifRenderSaveAndQuitPage = _h[0], SetIfRenderSaveAndQuitPage = _h[1];
    var fetchDetail = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, url;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, searchTitleAndName.mutateAsync({
                        hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                        language: LG
                    })];
                case 1:
                    response = _c.sent();
                    if (response === null || response === void 0 ? void 0 : response.data.isCompletedQuestionnaire) {
                        url = Functions.removeParams(env.routes.alreadyAnsweredPage);
                        navigate("".concat(url).concat((_b = params.hash) !== null && _b !== void 0 ? _b : ''));
                    }
                    setDetail(response === null || response === void 0 ? void 0 : response.data);
                    console.log(detail);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a;
        dispatch(fetchQuestionnaire({
            hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
        }));
        fetchDetail();
    }, []);
    var getCurrSection = function () {
        var _a;
        var index = anaquestionnaireStore.currentSection;
        return (_a = anaquestionnaireStore.sections[index]) !== null && _a !== void 0 ? _a : undefined;
    };
    var section = getCurrSection();
    var getPageTitle = function () {
        if (getCurrSection() == null)
            return;
        if (anaquestionnaireStore.saved || anaquestionnaireStore.savedAndQuit) {
            return detail === null || detail === void 0 ? void 0 : detail.questionnaireTitle;
        }
        return lang[LG].anaQuestionnaire.titleS1
            .replace('{0}', getCurrSection().index.toString())
            .replace('{1}', getCurrSection().sectionName);
    };
    var selectActivity = function (question, activity) {
        var questionId = question.questionId, subSectionId = question.subSectionId;
        setMadeChanges(true);
        dispatch(updateAnswerActivity({
            questionId: questionId,
            subSectionId: subSectionId,
            activity: activity,
            sectionId: section.sectionId
        }));
    };
    var textArea = "SEC-PAG";
    var addFormFields = function (question) {
        var _a, _b;
        if (typeof question != 'undefined') {
            var questionId = question.questionId, subSectionId = question.subSectionId;
            var multitext = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.multitext) !== null && _b !== void 0 ? _b : [];
            multitext = __spreadArray(__spreadArray([], multitext, true), [''], false);
            dispatch(updateAnswerArray({
                questionId: questionId,
                subSectionId: subSectionId,
                multitext: multitext,
                sectionId: section.sectionId
            }));
        }
    };
    var addFileFields = function (question) {
        var _a, _b;
        if (typeof question != 'undefined') { //&& typeof answers[question.questionId] != 'undefined'
            var questionId = question.questionId, subSectionId = question.subSectionId;
            var file = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.file) !== null && _b !== void 0 ? _b : {
                displayName: '',
                filePath: '',
                error: "false"
            };
            dispatch(updateFileAns({
                questionId: questionId,
                subSectionId: subSectionId,
                file: file,
                sectionId: section.sectionId
            }));
        }
    };
    var deleteFile = function (question) {
        if (typeof question != 'undefined') { //&& typeof answers[question.questionId] != 'undefined'
            var questionId = question.questionId, subSectionId = question.subSectionId;
            var file = {
                displayName: '',
                filePath: '',
                error: "false"
            };
            dispatch(updateFileAns({
                questionId: questionId,
                subSectionId: subSectionId,
                file: file,
                sectionId: section.sectionId
            }));
        }
    };
    var addDateFields = function (question) {
        var _a, _b;
        if (typeof question != 'undefined') { //&& typeof answers[question.questionId] != 'undefined'
            var questionId = question.questionId, subSectionId = question.subSectionId;
            var dates = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.dates) !== null && _b !== void 0 ? _b : {
                day: 0,
                month: 0,
                year: 0,
                dayStr: '',
                monthStr: '',
                yearStr: ''
            };
            dispatch(updateDateArray({
                questionId: questionId,
                subSectionId: subSectionId,
                dates: dates,
                sectionId: section.sectionId
            }));
        }
    };
    var updateDate = function (question, dates) {
        if (typeof question != 'undefined') {
            var questionId = question.questionId, subSectionId = question.subSectionId;
            console.log(dates);
            dispatch(updateDateArray({
                questionId: questionId,
                subSectionId: subSectionId,
                dates: dates,
                sectionId: section.sectionId
            }));
        }
    };
    var upload = function (question, e) {
        var _a;
        var file = e.target.files[0];
        var size = file.size; // it's in bytes
        if (size / 1024 > 200000000) {
            var questionId = question.questionId, subSectionId = question.subSectionId;
            var file1 = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.file;
            var file_1 = __assign(__assign({}, file1), { error: "true" });
            dispatch(updateFileAns({
                questionId: questionId,
                subSectionId: subSectionId,
                file: file_1,
                sectionId: section.sectionId
            }));
            return;
        }
        dispatch(uploadFile(question, {
            file: file
        }, section.sectionId));
    };
    var removeField = function (question, i) {
        var _a, _b;
        var questionId = question.questionId, subSectionId = question.subSectionId;
        var multitext1 = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.multitext) !== null && _b !== void 0 ? _b : [];
        var multitext = __spreadArray([], multitext1, true);
        multitext.splice(i, 1);
        dispatch(updateAnswerArray({
            questionId: questionId,
            subSectionId: subSectionId,
            multitext: multitext,
            sectionId: section.sectionId
        }));
    };
    var updateText = function (question, answer) {
        var questionId = question.questionId, subSectionId = question.subSectionId;
        dispatch(updateAnswerText({
            questionId: questionId,
            subSectionId: subSectionId,
            answer: answer,
            sectionId: section.sectionId
        }));
    };
    var updateArray = function (question, value, index) {
        var _a, _b;
        var questionId = question.questionId, subSectionId = question.subSectionId;
        var multitext1 = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.multitext) !== null && _b !== void 0 ? _b : [];
        var multitext = __spreadArray([], multitext1, true);
        multitext[index] = value;
        dispatch(updateAnswerArray({
            questionId: questionId,
            subSectionId: subSectionId,
            multitext: multitext,
            sectionId: section.sectionId
        }));
    };
    var updateChekbox = function (question, value, select) {
        var _a, _b;
        var questionId = question.questionId, subSectionId = question.subSectionId;
        var multitext1 = (_b = (_a = answers[question.questionId]) === null || _a === void 0 ? void 0 : _a.multitext) !== null && _b !== void 0 ? _b : [];
        var multitext = __spreadArray([], multitext1, true);
        if (select && !multitext1.includes(value)) {
            if (multitext[0] == null) {
                multitext[0] = value;
            }
            else {
                multitext = __spreadArray(__spreadArray([], multitext, true), [value], false);
            }
        }
        else if (!select && multitext.includes(value)) {
            var index = multitext.indexOf(value);
            if (index !== -1) {
                multitext.splice(index, 1);
            }
        }
        dispatch(updateAnswerArray({
            questionId: questionId,
            subSectionId: subSectionId,
            multitext: multitext,
            sectionId: section.sectionId
        }));
    };
    var getActivityCheckbox = function (question, activity) {
        var _a;
        var answer = answers[question.questionId];
        if (answer != null && answer.activity === activity) {
            return React.createElement(CheckedCheckbox, { onClick: function () {
                } });
        }
        else {
            var attributes = {};
            if (isPageValid.activity.indexOf(question.questionId) !== -1) {
                attributes.stroke = env.iconRed;
            }
            if (((_a = attributes.stroke) === null || _a === void 0 ? void 0 : _a.length) === undefined) {
                attributes.stroke = "#6C0C6C";
                // @ts-ignore
                attributes.fill = "#0000";
            }
            return (React.createElement(UncheckedCheckbox, __assign({}, attributes, { onClick: function () {
                    selectActivity(question, activity);
                } })));
        }
    };
    var getLoopQuestions = function () {
        var questions = [];
        if (section != null) {
            var currentPage_1 = section.currentPage;
            var counter_1 = 0;
            var subsectionId_1 = 0;
            var start_1 = -1;
            var end_1 = 0;
            section.questions.map(function (question, index) {
                if (question.subSectionId != subsectionId_1) {
                    counter_1 = counter_1 + 1;
                    subsectionId_1 = question.subSectionId;
                }
                if (counter_1 == currentPage_1) {
                    if (start_1 == -1)
                        start_1 = index;
                    end_1 = end_1 + 1;
                }
            });
            questions = section.questions.slice(start_1, end_1 + start_1);
        }
        return questions;
    };
    var questions = getLoopQuestions();
    var checkIfPageValid = function () {
        var valid = __assign({}, isPageValid);
        valid.activity = [];
        questions.forEach(function (question) {
            var _a, _b;
            if (question.type.includes('radio')) {
                var answer = answers[question.questionId];
                if (answer == null) {
                    (_a = valid.activity) === null || _a === void 0 ? void 0 : _a.push(question.questionId);
                }
                else if (answer.activity === EAnaQuestionRadio.na) {
                    //    do nothing
                }
                else {
                    if (answer.activity == null) {
                        (_b = valid.activity) === null || _b === void 0 ? void 0 : _b.push(question.questionId);
                    }
                }
            }
        });
        setIsPageValid(valid);
        return valid.activity.length === 0;
    };
    var clearPageValidationErrors = function () {
        setIsPageValid({
            activity: []
        });
    };
    var beforeUnloadHandler = function (e) {
        e.preventDefault();
        if (madeChanges === true &&
            anaquestionnaireStore.saved === false &&
            anaquestionnaireStore.savedAndQuit === false) {
            e.returnValue = '';
        }
    };
    useEffect(function () {
        window.addEventListener('beforeunload', beforeUnloadHandler);
        return function () { return window.removeEventListener('beforeunload', beforeUnloadHandler); };
    }, [anaquestionnaireStore.saved, anaquestionnaireStore.savedAndQuit, madeChanges]);
    var isLastSection = function () {
        return (section === null || section === void 0 ? void 0 : section.currentPage) === (section === null || section === void 0 ? void 0 : section.noOfPages)
            && (section === null || section === void 0 ? void 0 : section.index) === (anaquestionnaireStore === null || anaquestionnaireStore === void 0 ? void 0 : anaquestionnaireStore.totalSections);
    };
    var getQuestionnaireDetail = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(showRadio ? 'w-full' : '', " mb-20") },
                React.createElement("div", { className: "grid grid-cols-3" },
                    React.createElement("div", { className: "col-span-2" },
                        React.createElement(Text, null, section === null || section === void 0 ? void 0 : section.sectionDesc))),
                React.createElement("div", { className: "grid grid-cols-3 gap-3 mt-10" },
                    React.createElement("div", { className: "" },
                        React.createElement(BaseTitle, { additionalClass: "text-sm" }, getPageTitle()))),
                showRadio && anaquestionnaireStore.currentSection == 5 &&
                    React.createElement("div", { className: "grid grid-cols-4 mt-4" },
                        React.createElement("div", { className: "col-span-1" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[51])),
                        React.createElement("div", { className: "flex justify-between px-4 pt-4 rounded-t col-span-1" }),
                        React.createElement("div", { className: "col-span-2" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[52]))),
                showRadio && anaquestionnaireStore.currentSection == 11 &&
                    React.createElement("div", { className: "grid grid-cols-4 mt-4" },
                        React.createElement("div", { className: "col-span-2" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[111])),
                        React.createElement("div", { className: "col-span-1" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[112]),
                            " "),
                        React.createElement("div", { className: "col-span-1 ml-16" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[113]))),
                showRadio && anaquestionnaireStore.currentSection == 13 &&
                    React.createElement("div", { className: "grid grid-cols-4 mt-4" },
                        React.createElement("div", { className: "col-span-2" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[13]))),
                showRadio && anaquestionnaireStore.currentSection == 14 &&
                    React.createElement("div", { className: "grid grid-cols-4 mt-4" },
                        React.createElement("div", { className: "col-span-1" },
                            React.createElement(Text, { isbold: true, fontSize: "text-md" }, anaSubsectionTitle[14]))),
                showRadio &&
                    React.createElement("div", { className: "grid grid-cols-4" },
                        React.createElement("div", { className: "col-span-1 ".concat(QuestionlistborderTopClass) }),
                        React.createElement("div", { className: "flex justify-between px-4 pb-2 rounded-t col-span-1 ".concat(QuestionlistborderTopClass) },
                            React.createElement(Text1FromDesign, { additionalClass: "w-10 flex justify-center" }, lang[LG].answerQuestionnaire.radioOption[1]),
                            anaquestionnaireStore.currentSection == 13 &&
                                React.createElement(Text1FromDesign, { additionalClass: "w-10 flex justify-center" }, lang[LG].answerQuestionnaire.radioOption[4]),
                            React.createElement(Text1FromDesign, { additionalClass: "w-10" }, lang[LG].answerQuestionnaire.radioOption[2]),
                            React.createElement(Text1FromDesign, { additionalClass: "w-15" }, lang[LG].answerQuestionnaire.radioOption[3])),
                        React.createElement("div", { className: "col-span-2" })),
                questions.map(function (question, index) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    var answer = answers[question.questionId];
                    var registerTextAreaName = typeof (answer === null || answer === void 0 ? void 0 : answer.answer) == 'undefined' || (answer === null || answer === void 0 ? void 0 : answer.answer) == '' ? textArea : answer === null || answer === void 0 ? void 0 : answer.answer;
                    var placeholder = anaPlaceholder[question.questionId];
                    if (question.type == 'dropdown') {
                        var options = dropdown[question.questionId];
                        return (React.createElement("div", { key: index, className: "mb-4" },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description.replace('1', ''))),
                            React.createElement("div", { className: " w-2/5  justify-between", key: question.questionId },
                                React.createElement(DropDownArray, { selectedItem: (_a = answer === null || answer === void 0 ? void 0 : answer.answer) !== null && _a !== void 0 ? _a : '', additionalClass: "mt-1", 
                                    //error={errors.gender}
                                    items: options, onChange: function (item) {
                                        updateText(question, item);
                                    }, placeHolder: placeholder }))));
                    }
                    else if (question.type == 'multidropdown') {
                        var options_1 = dropdown[question.questionId];
                        if (typeof (answer === null || answer === void 0 ? void 0 : answer.multitext) == 'undefined')
                            addFormFields(question);
                        return (React.createElement("div", { key: question.questionId + '-' + index, className: "mb-4" },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description)),
                            typeof (answer === null || answer === void 0 ? void 0 : answer.multitext) != 'undefined' && ((_b = answer === null || answer === void 0 ? void 0 : answer.multitext) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                                answer.multitext.map(function (element, index1) {
                                    return (React.createElement("div", { className: "w-2/5  justify-between", key: "d-".concat(index1) },
                                        React.createElement(DropDownArray, { selectedItem: element !== null && element !== void 0 ? element : '', additionalClass: "mt-1", 
                                            //error={errors.gender}
                                            items: options_1, onChange: function (item) {
                                                updateArray(question, item, index1);
                                            }, placeHolder: placeholder })));
                                }),
                            React.createElement("div", { className: "w-2/5  justify-between pt-4" },
                                React.createElement(LightSubmitButton, { type: "button", title: lang[LG].anaQuestionnaire.addItem, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            addFormFields(question);
                                            return [2 /*return*/];
                                        });
                                    }); } }))));
                    }
                    else if (question.type == 'multicheckbox') {
                        var options = dropdown[question.questionId];
                        // if(typeof answer?.multitext == 'undefined')  addFormFields(question);
                        return (React.createElement("div", { className: "mb-4", key: index },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description)),
                            React.createElement("div", { className: 'mt-4 shadow-lg shadow-gray-400 w-2/5 p-5 rounded-b' },
                                React.createElement("div", { className: 'grid grid-cols-2 gap-2  max-h-88 overflow-y-scroll ' }, options.map(function (element, index1) {
                                    var _a, _b;
                                    return (React.createElement("div", { className: 'flex w-2/3', key: "checkbox-".concat(index1) },
                                        React.createElement(CustomCheckbox, { selected: (_b = (_a = answer === null || answer === void 0 ? void 0 : answer.multitext) === null || _a === void 0 ? void 0 : _a.includes(element)) !== null && _b !== void 0 ? _b : false, additionalClass: "hover:border-purple-800 hover:border-4 hover:rounded hover:border-spacing-0.5", onUpdate: function (select) {
                                                updateChekbox(question, element, select);
                                            }, 
                                            //error={errors.readTermConditions != null}
                                            inline: false }),
                                        React.createElement("p", { className: "font-poppins text-sm font-light mt-1 ml-1" },
                                            React.createElement("span", null,
                                                " ",
                                                element,
                                                " "))));
                                })))));
                    }
                    else if (question.type == 'text') {
                        if (typeof (answer === null || answer === void 0 ? void 0 : answer.answer) == 'undefined')
                            updateText(question, '');
                        return (React.createElement("div", { key: index, className: "mb-4" },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description)),
                            React.createElement("div", { className: "w-2/5  justify-between" },
                                React.createElement(TextField, { title: placeholder, additionalClass: "mt-1", type: 'text', value: (_c = answer === null || answer === void 0 ? void 0 : answer.answer) !== null && _c !== void 0 ? _c : '', 
                                    //error={errors.gender}
                                    onChange: function (e) {
                                        updateText(question, e.target.value);
                                    } }))));
                    }
                    else if (question.type == 'textarea') {
                        return (React.createElement("div", { key: index, className: "mb-4" },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description)),
                            React.createElement("div", { className: "w-2/5  justify-between" },
                                React.createElement(TextArea, { register: register(registerTextAreaName, {
                                        maxLength: {
                                            value: 1000,
                                            message: validation_lang[LG].messageMaxLength2,
                                        }
                                    }), additionalClass: "mt-1", rows: 3, title: placeholder, onChange: function (e) {
                                        updateText(question, e.target.value);
                                    }, 
                                    //error={errors.customMessage}
                                    value: (_d = answer === null || answer === void 0 ? void 0 : answer.answer) !== null && _d !== void 0 ? _d : '' }))));
                    }
                    else if (question.type == 'multitext') {
                        if (typeof (answer === null || answer === void 0 ? void 0 : answer.multitext) == 'undefined')
                            addFormFields(question);
                        return (React.createElement("div", { key: question.questionId + '-' + index, className: "mb-4" },
                            React.createElement("div", { className: "flex w-full items-center" },
                                React.createElement(Text, { additionalClass: "pt-8", isbold: true, fontSize: 'text-md' }, question.description)),
                            typeof (answer === null || answer === void 0 ? void 0 : answer.multitext) != 'undefined' && ((_e = answer === null || answer === void 0 ? void 0 : answer.multitext) === null || _e === void 0 ? void 0 : _e.length) > 0 &&
                                answer.multitext.map(function (element, index1) {
                                    console.log(element);
                                    return (React.createElement("div", { className: "w-2/5  justify-between", key: index1 },
                                        React.createElement(TextIconField, { title: placeholder, additionalClass: "mt-1", type: 'text', value: element, key: index1, icon: React.createElement(CloseIcon, { color: env.iconColor, onClick: function () {
                                                    removeField(question, index1);
                                                } }), position: 'right', onChange: function (e) {
                                                updateArray(question, e.target.value, index1);
                                            } })));
                                }),
                            React.createElement("div", { className: " w-1/3  justify-between pt-4" },
                                React.createElement(LightSubmitButton, { type: "button", title: lang[LG].anaQuestionnaire.addItem, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            addFormFields(question);
                                            return [2 /*return*/];
                                        });
                                    }); } }))));
                    }
                    else if (question.type.includes('radio')) {
                        var options = [];
                        if (typeof (answer === null || answer === void 0 ? void 0 : answer.dates) == 'undefined' && question.type == 'radiodropdown')
                            addDateFields(question);
                        if (question.type == 'radioyear') {
                            options = dropdown[question.questionId];
                        }
                        if (typeof (answer === null || answer === void 0 ? void 0 : answer.file) == 'undefined' && question.type == 'radiofile')
                            addFileFields(question);
                        //if(question.type == 'radiofile')  addValueToFile(question.questionId, undefined);
                        if (showRadio == false)
                            setShowRadio(true);
                        return (React.createElement("div", { key: index, className: "grid grid-cols-4" },
                            React.createElement("div", { className: "flex items-center col-span-1 ".concat(QuestionlistborderTopClass) },
                                React.createElement(Text, { additionalClass: "py-4", isbold: true, fontSize: 'text-md' }, question.description.replace('1', ''))),
                            React.createElement("div", { className: "flex justify-between px-4 py-4 col-span-1 ".concat(QuestionlistborderTopClass) },
                                React.createElement("div", { className: "w-10 my-2 flex justify-center" }, getActivityCheckbox(question, EAnaQuestionRadio.oui)),
                                anaquestionnaireStore.currentSection == 13 &&
                                    React.createElement("div", { className: "w-6 my-2 flex justify-center" }, getActivityCheckbox(question, EAnaQuestionRadio.plus)),
                                React.createElement("div", { className: "w-6 my-2 flex justify-center" }, getActivityCheckbox(question, EAnaQuestionRadio.nan)),
                                React.createElement("div", { className: "w-10 my-2 flex justify-center" }, getActivityCheckbox(question, EAnaQuestionRadio.na))),
                            isPageValid.activity.length !== 0 && anaquestionnaireStore.currentSection == 6 &&
                                React.createElement("div", { className: "col-span-4" },
                                    React.createElement(TextError, { message: lang[LG].answerQuestionnaire.error.error3 })),
                            question.type == 'radiodropdown' &&
                                React.createElement("div", { className: "flex justify-between px-4 py-4 col-span-2 ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? 'opacity-50' : '') },
                                    React.createElement(DatePicker, { date: answer === null || answer === void 0 ? void 0 : answer.dates, labelHide: true, hideDate: (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? true : false, validate: function (date) {
                                            var _a, _b, _c;
                                            var day = (_a = date.day) !== null && _a !== void 0 ? _a : 0;
                                            var month = (_b = date.month) !== null && _b !== void 0 ? _b : 0;
                                            var year = (_c = date.year) !== null && _c !== void 0 ? _c : 0;
                                            updateDate(question, date);
                                        } })),
                            question.type == 'radioyear' &&
                                React.createElement("div", { className: "flex justify-between px-4 py-4 col-span-2 ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? 'opacity-50' : ''), key: question.questionId },
                                    React.createElement(YearPicker, { date: answer === null || answer === void 0 ? void 0 : answer.dates, hideDate: (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? true : false, validate: function (date) {
                                            updateDate(question, date);
                                        } }),
                                    React.createElement(DropDownArray, { selectedItem: (_f = answer === null || answer === void 0 ? void 0 : answer.answer) !== null && _f !== void 0 ? _f : '', additionalClass: "w-2/3 mt-1", disabled: (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? true : false, 
                                        //error={errors.gender}
                                        items: options, onChange: function (item) {
                                            updateText(question, item);
                                        }, placeHolder: placeholder, ikey: question.questionId })),
                            question.type == 'radiofile' &&
                                React.createElement("div", { className: "flex px-4 py-4 col-span-2 ".concat((answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? 'opacity-50' : '') },
                                    React.createElement("div", { className: "col-span-1 mx-5 w-1/2" },
                                        React.createElement(File, { type: "file", onChange: function (e) { return upload(question, e); }, disabled: (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.na || (answer === null || answer === void 0 ? void 0 : answer.activity) === EAnaQuestionRadio.nan ? true : false, id: "file-".concat(question.questionId), files: answer === null || answer === void 0 ? void 0 : answer.file })),
                                    React.createElement("div", { className: "flex col-span-1 mx-5 mt-2 w-1/2" },
                                        ((_g = answer === null || answer === void 0 ? void 0 : answer.file) === null || _g === void 0 ? void 0 : _g.displayName) != '' && ((_h = answer === null || answer === void 0 ? void 0 : answer.file) === null || _h === void 0 ? void 0 : _h.displayName) != null && React.createElement("div", { className: 'flex shadow-md shadow-gray-600 px-5 py-2 h-fit' },
                                            React.createElement("label", { color: 'gray', className: 'text-sm' }, (_j = answer === null || answer === void 0 ? void 0 : answer.file) === null || _j === void 0 ? void 0 : _j.displayName),
                                            React.createElement(CloseIcon, { additionalClass: 'pl-2', color: 'black', height: 20, width: 20, onClick: function () { return deleteFile(question); } })),
                                        ((_k = answer === null || answer === void 0 ? void 0 : answer.file) === null || _k === void 0 ? void 0 : _k.error) == 'true' && React.createElement("div", { className: 'error' },
                                            React.createElement(Text, { additionalClass: 'text-c-red' }, lang['fr'].anaQuestionnaire.fileError)),
                                        ((_l = answer === null || answer === void 0 ? void 0 : answer.file) === null || _l === void 0 ? void 0 : _l.error) == 'progress' && React.createElement("div", { className: "w-full rounded-full h-1.5 mb-4" },
                                            React.createElement(Text, { additionalClass: 'text-sm' }, lang['fr'].anaQuestionnaire.fileProgress),
                                            React.createElement("div", { className: "w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700" },
                                                React.createElement("div", { className: "bg-green-600 h-2.5 rounded-full dark:bg-green-500", style: { width: (anaquestionnaireStore === null || anaquestionnaireStore === void 0 ? void 0 : anaquestionnaireStore.progress) + '%' } })))))));
                    }
                }),
                React.createElement("div", { className: "grid grid-cols-1" },
                    React.createElement("div", { className: "px-3" }, isPageValid.activity.length !== 0 && anaquestionnaireStore.currentSection != 6 &&
                        React.createElement(TextError, { message: lang[LG].answerQuestionnaire.error.error1 })))),
            React.createElement("div", { className: "flex w-full mt-5 mr-36 space-x-4 gap-4 bottom-0 absolute mb-5" },
                React.createElement("div", { className: "rounded-full px-3 py-3.5" }, ((section === null || section === void 0 ? void 0 : section.index) > 1 || (section === null || section === void 0 ? void 0 : section.currentPage) > 1)
                    &&
                        React.createElement(ArrowButton, { title: "", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var page, newIndex;
                                var _a;
                                return __generator(this, function (_b) {
                                    clearPageValidationErrors();
                                    if (showRadio == true) {
                                        setTimeout(function () {
                                            setShowRadio(false);
                                        }, 1000);
                                    }
                                    if ((section === null || section === void 0 ? void 0 : section.currentPage) > 1 && section.noOfPages > 1) {
                                        page = section.currentPage - 1;
                                        dispatch(updateCurrentPage({
                                            currentPage: page,
                                            index: section.index,
                                        }));
                                    }
                                    else if ((section === null || section === void 0 ? void 0 : section.currentPage) === 1 && section.index > 0) {
                                        newIndex = section.index - 2;
                                        if (typeof anaquestionnaireStore.sections[newIndex] !== "undefined") {
                                            dispatch(updateSectionIndex({
                                                currentSection: newIndex
                                            }));
                                        }
                                        else {
                                            dispatch(fetchQuestionnaire({
                                                hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                                position: newIndex,
                                                action: 'backward'
                                            }));
                                        }
                                    }
                                    return [2 /*return*/];
                                });
                            }); } })),
                React.createElement("div", { className: "flex space-x-4 right-0 pr-10 absolute py-3.5" },
                    React.createElement(LightSubmitButtonWithIcon, { type: "button", title: lang[LG].answerQuestionnaire.bt1, icon: React.createElement(SaveIcon, { color: env.iconRed }), RedTypeOrPurpleType: "red", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                SetIfRenderSaveAndQuitPage(true);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(LightSubmitButtonWithIcon, { type: "button", title: lang[LG].answerQuestionnaire.bt2, icon: React.createElement(HelpIcon, { color: env.iconColorPurple }), RedTypeOrPurpleType: "purple", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setReturnPageNumber(1);
                                return [2 /*return*/];
                            });
                        }); } }),
                    React.createElement(SubmitButtonWithIcon, { type: "button", icon: isLastSection() ? null : React.createElement(ForwardIcon, { color: env.iconColorWhite }), title: isLastSection() ? lang[LG].submit : lang[LG].answerQuestionnaire.bt3, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var page;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                if (showRadio == true) {
                                    setTimeout(function () {
                                        setShowRadio(false);
                                    }, 1000);
                                }
                                if (!checkIfPageValid()) {
                                    return [2 /*return*/];
                                }
                                if (isLastSection()) {
                                    dispatch(saveAnswer({
                                        hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                        saved: true,
                                        savedAndQuit: false,
                                        submit: true,
                                    }));
                                }
                                else if ((section === null || section === void 0 ? void 0 : section.currentPage) === (section === null || section === void 0 ? void 0 : section.noOfPages)) {
                                    //resetFormValue();
                                    //setFormValues(['']);
                                    dispatch(fetchQuestionnaire({
                                        hashedId: (_b = params.hash) !== null && _b !== void 0 ? _b : '',
                                        position: section.index,
                                        action: 'forward',
                                    }));
                                }
                                else {
                                    page = section.currentPage + 1;
                                    dispatch(updateCurrentPage({
                                        currentPage: page,
                                        index: section.index,
                                    }));
                                }
                                return [2 /*return*/];
                            });
                        }); }, disabled: !isPageValid })))));
    };
    var getThankYouDetail = function () {
        var _a;
        var p2 = (detail === null || detail === void 0 ? void 0 : detail.isSelf) ? lang[LG].generateReportRecommendations : lang[LG].answerQuestionnaire.page3.p2;
        p2 = p2.replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : '');
        return (React.createElement("div", { className: "min-h-[30rem] flex flex-col justify-between" },
            React.createElement("div", { className: "mb-64" },
                React.createElement(BaseTitle, { additionalClass: "mt-10" }, lang[LG].answerQuestionnaire.page3.title2),
                React.createElement(Text1FromDesign, { additionalClass: "mt-5" }, lang[LG].answerQuestionnaire.page3.p1),
                React.createElement(Text1FromDesign, { additionalClass: "mt-5" }, anaquestionnaireStore.saved ?
                    p2 :
                    lang[LG].answerQuestionnaire.page3.savedAndQuit)),
            React.createElement("div", { className: "flex justify-end mt-72" },
                React.createElement(LightSubmitButton, { type: "button", title: lang[LG].answerQuestionnaire.page3.button, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            navigate(env.routes.login);
                            return [2 /*return*/];
                        });
                    }); } }),
                (detail === null || detail === void 0 ? void 0 : detail.isSelf) != 0 && React.createElement(SubmitButtonWithIcon, { type: "button", additionalClass: 'ml-2', title: lang[LG].generateReport, onClick: generateReport }))));
    };
    var userGuide = (React.createElement(UserGuideSet, { returnPage: 1, onClick: function () {
            setReturnPageNumber(0);
        }, onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setReturnPageNumber(0);
                return [2 /*return*/];
            });
        }); } }));
    var saveAndQuitPage = (React.createElement(LightModal, { backgroundCls: "bg-questionnaire bg-cover bg-center", layoutClass: "w-1/2" },
        React.createElement(IconContainer, { icon: React.createElement(CloseIcon, { color: env.iconColor }), onClick: function () {
                SetIfRenderSaveAndQuitPage(false);
            } }),
        React.createElement(Title, null, lang[LG].saveAndQuitPage.title),
        React.createElement("div", { className: "mt-4 text-left" },
            React.createElement(Text, null, lang[LG].saveAndQuitPage.content)),
        React.createElement("div", { className: "mt-4 text-left" },
            React.createElement(Text, { isbold: true }, lang[LG].saveAndQuitPage.content2.replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : ''))),
        React.createElement("div", { className: "w-full justify-center flex" }, anaquestionnaireStore.error !== null && React.createElement(FormError, { response: anaquestionnaireStore.error })),
        React.createElement("div", { className: "mt-4 inline-flex justify-end w-full" },
            React.createElement(LightSubmitButton, { additionalClass: "ml-4", title: lang[LG].saveAndQuitPage.btn, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        SetIfRenderSaveAndQuitPage(false);
                        return [2 /*return*/];
                    });
                }); } }),
            React.createElement(LightReverseColorButtonWithIcon, { additionalClass: "ml-4", type: "button", title: lang[LG].saveAndQuitPage.btn2, icon: React.createElement(SaveIcon, { color: "#ffffff" }), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, dispatch(saveAnswer({
                                    hashedId: (_a = params.hash) !== null && _a !== void 0 ? _a : '',
                                    saved: false,
                                    savedAndQuit: true,
                                    submit: false
                                }))];
                            case 1:
                                response = _b.sent();
                                response && navigate(env.routes.login);
                                return [2 /*return*/];
                        }
                    });
                }); } }))));
    var generateReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var id, url;
        var _a;
        return __generator(this, function (_b) {
            id = (_a = detail === null || detail === void 0 ? void 0 : detail.questionnaireToClientId.toString()) !== null && _a !== void 0 ? _a : '';
            if (user.isLoggedIn) {
                url = Functions.createUrl(env.routes.clientQuestionnaire, id.toString());
            }
            else {
                url = Functions.createUrl(env.routes.loginParam, "clientQuestionnaire-".concat(id));
            }
            navigate(url);
            return [2 /*return*/];
        });
    }); };
    var deletedPage = (React.createElement(ModalInAnswerQuestionnaire, null,
        React.createElement("div", null,
            React.createElement("div", { className: "container flex inline flex w-full" },
                React.createElement("div", { className: "container flex justify-start" },
                    React.createElement("div", { className: "w-full" },
                        React.createElement("div", null,
                            React.createElement(BaseTitle, { additionalClass: "text-base capitalize" }, detail === null || detail === void 0 ? void 0 : detail.questionnaireTitle)),
                        React.createElement("div", { className: "-mt-1" },
                            React.createElement(BaseTitle, { additionalClass: "text-sm capitalize" },
                                (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.introductionPage.for : lang["fr"].answerQuestionnaire.introductionPage.for,
                                " ", detail === null || detail === void 0 ? void 0 :
                                detail.clientName)))),
                React.createElement("div", { className: "container flex justify-end w-1/3" },
                    React.createElement("div", { className: "w-fit" },
                        React.createElement("div", { className: "flex justify-end" },
                            React.createElement("div", { className: "text-c-purple-500 font-poppins text-sm" }, (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.page3.rightTop : lang["fr"].answerQuestionnaire.page3.rightTop)),
                        React.createElement("div", { className: "inline-flex justify-end my-2" },
                            React.createElement("div", { className: "h-2 w-66" },
                                React.createElement("div", { className: "w-full h-full bg-c-purple rounded-full" })))))),
            React.createElement("div", { className: "mt-12" },
                React.createElement(BaseTitle, { additionalClass: "text-base" }, lang[LG].answerQuestionnaire.deletedPage.title)),
            React.createElement("div", { className: "mt-7 w-2/3" },
                React.createElement(Text1FromDesign, null, lang[LG].answerQuestionnaire.deletedPage.text)),
            React.createElement("div", { className: "container inline flex pt-4 mt-72" },
                React.createElement("div", { className: "flex justify-end w-full" },
                    React.createElement("div", { className: "inline flex" },
                        React.createElement("div", { className: "ml-2" },
                            React.createElement(SubmitButtonWithIcon, { type: "button", icon: React.createElement(ForwardIcon, { color: "#FFFFFF" }), title: (detail === null || detail === void 0 ? void 0 : detail.language) === "english" ? lang["en"].answerQuestionnaire.page3.button : lang["fr"].answerQuestionnaire.page3.button, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        navigate(env.routes.login);
                                        return [2 /*return*/];
                                    });
                                }); } }))))))));
    var render = function () {
        var _a, _b, _c, _d, _e;
        var showSuccessPage = anaquestionnaireStore.saved || anaquestionnaireStore.savedAndQuit;
        var defaultPage = (React.createElement(ModalInAnswerQuestionnaire, { layoutClass: "w-5/6 h-7/8 my-6 absolute overflow-y-scroll max-h-full" },
            React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "w-8/12" },
                    React.createElement(BaseTitle, { additionalClass: "text-base" }, detail === null || detail === void 0 ? void 0 : detail.questionnaireTitle),
                    React.createElement(BaseTitle, { additionalClass: "text-sm" }, lang[LG]
                        .answerQuestionnaire
                        .smallTitle
                        .replace('{0}', (_a = detail === null || detail === void 0 ? void 0 : detail.clientName) !== null && _a !== void 0 ? _a : ''))),
                React.createElement("div", { className: "w-4/12 text-right" },
                    React.createElement(SectionBar, { currentSection: (_b = section === null || section === void 0 ? void 0 : section.index) !== null && _b !== void 0 ? _b : 1, currentPage: (_c = section === null || section === void 0 ? void 0 : section.currentPage) !== null && _c !== void 0 ? _c : 1, totalSections: (_d = anaquestionnaireStore === null || anaquestionnaireStore === void 0 ? void 0 : anaquestionnaireStore.totalSections) !== null && _d !== void 0 ? _d : 1, totalPages: (_e = section === null || section === void 0 ? void 0 : section.noOfPages) !== null && _e !== void 0 ? _e : 1, sectionName: section === null || section === void 0 ? void 0 : section.sectionName, isCompleted: anaquestionnaireStore.saved, savedAndQuit: anaquestionnaireStore.savedAndQuit }))),
            !showSuccessPage && getQuestionnaireDetail(),
            showSuccessPage && getThankYouDetail()));
        var pageController = returnPageNumber == 1 ? userGuide
            : defaultPage;
        return (React.createElement(React.Fragment, null, (detail === null || detail === void 0 ? void 0 : detail.isDeleted) ? deletedPage : (ifRenderSaveAndQuitPage ? saveAndQuitPage : (returnPageNumber ? pageController : defaultPage))));
    };
    return render();
};
export default AnaQuestionnaire;
