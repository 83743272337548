import React, { useEffect, useRef, useState } from 'react';
import Text from "../Field/Text";
var PointerPopover = function (props) {
    var _a = useState(false), showPopover = _a[0], setShowPopover = _a[1];
    var elementRef = useRef(null);
    useEffect(function () {
        if (elementRef.current != null) {
            elementRef.current.addEventListener('mouseover', onHover);
            elementRef.current.addEventListener('mouseout', onHoverOut);
        }
        return function () {
            if (elementRef.current != null) {
                elementRef.current.removeEventListener('mouseover', onHover);
                elementRef.current.removeEventListener('mouseout', onHoverOut);
            }
        };
    }, [elementRef, props.hide]);
    var onHover = function (_) {
        if (props.hide === true)
            return;
        setShowPopover(true);
    };
    var onHoverOut = function (_) {
        if (props.hide === true)
            return;
        setShowPopover(false);
    };
    var getLocationContainerCls = function () {
        var cls = '';
        switch (props.position) {
            case "LEFT":
                cls = 'right-full';
                break;
            case "RIGHT":
                cls = 'left-full';
                break;
            case "BOTTOM":
                cls = 'top-full -left-2/4';
            default:
                break;
        }
        return cls;
    };
    var getPointerContainerCls = function () {
        var cls = '';
        switch (props.position) {
            case "LEFT":
                cls = 'top-2/4 left-full';
                break;
            case "RIGHT":
                cls = 'top-2/4 left-0';
                break;
            case "BOTTOM":
                cls = 'left-2/4 top-0';
                break;
            default:
                break;
        }
        return cls;
    };
    var render = function () {
        var _a;
        var showOrHideCls = showPopover ? 'absolute' : 'hidden';
        // const showOrHideCls = 'absolute';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center relative" },
                React.createElement("div", { ref: elementRef, className: props.elementCls }, props.element),
                React.createElement("div", { className: "\n                            ".concat(showOrHideCls, "\n                            bg-white\n                            drop-shadow-[0_15px_15px_rgba(0,0,0,0.5)]\n                            p-6\n                            m-4\n                            rounded\n                            z-10\n                            ").concat(getLocationContainerCls(), "\n                            ").concat((_a = props.additionalCls) !== null && _a !== void 0 ? _a : '', "\n                            ") },
                    React.createElement("span", { className: "\n                                    bg-white\n                                    absolute\n                                    h-8\n                                    w-8\n                                    translate-x-[-50%]\n                                    translate-y-[-50%]\n                                    rotate-45\n                                    z-10\n                                    ".concat(getPointerContainerCls(), "\n                                ") }),
                    React.createElement(Text, null, props.content)))));
    };
    return render();
};
export default PointerPopover;
