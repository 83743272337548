import clsx from "clsx";
import React from "react";
var Label = function (props) {
    var render = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: clsx((_a = { "font-poppins inline-block text-base text-left font-semibold": true }, _a[props.additionalClass] = true, _a)) }, props.children)));
    };
    return render();
};
export default Label;
