import React from "react";
import Input from "./Input";
var TextIconField = React.forwardRef(function (props, ref) {
    var render = function () {
        var disableBgColor = props.disableBgColor == true ? '' : 'bg-c-scroll';
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "relative ".concat(props.additionalClass) },
                React.createElement("span", { className: "absolute inset-y-0 flex p-2 ".concat(props.position === 'left' ? 'left-0' : 'right-0') }, props.icon),
                React.createElement(Input, { ref: ref, register: props.register, title: props.title, placeholder: props.title, type: props.type, value: props === null || props === void 0 ? void 0 : props.value, error: props.error, additionalClass: "".concat(props.position === 'left' ? 'pl-9' : '', "  ").concat(disableBgColor, " ").concat(props.additionalInputClass, " ").concat(props.position === 'right' ? 'pr-9' : ''), errorClass: props.errorClass, onChange: props.onChange }),
                props.icon2 && React.createElement("span", { className: "absolute inset-y-0 flex p-2 ".concat(props.position === 'left' ? 'right-0' : 'left-0') }, props.icon2))));
    };
    return render();
});
export default TextIconField;
