var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { lang } from "../lang/lang";
import { validation_lang } from "../lang/validations";
import F from "./functions";
var Validations = /** @class */ (function () {
    function Validations() {
    }
    Validations.password = function (message) {
        var LG = F.getLang();
        return {
            required: lang[LG].requiredPassword,
            pattern: {
                value: /^(?=.{8,})(?=.*[A-Z])(?=.*[0-9]).*$/,
                message: message !== null && message !== void 0 ? message : lang[LG].passwordMustBe
            }
        };
    };
    Validations.phone = function (required) {
        if (required === void 0) { required = false; }
        var LG = F.getLang();
        var validations = {
            maxLength: {
                value: 17,
                message: lang[LG].phoneLength
            },
            minLength: {
                value: 15,
                message: lang[LG].phoneLength
            },
            pattern: {
                value: /\d/,
                message: lang[LG].phoneDigitsOnly
            }
        };
        if (required) {
            validations = __assign(__assign({}, validations), { required: lang[LG].requiredPhone });
        }
        return validations;
    };
    Validations.email = function (LG) {
        return {
            required: lang[LG].requiredEmail,
            pattern: {
                value: /\S+@\S+\.\S+/,
                message: lang[LG].invalidEmail
            }
        };
    };
    Validations.required = function (LG) {
        return {
            required: validation_lang[LG].required
        };
    };
    Validations.coupon = function (LG) {
        return {
            pattern: {
                value: /[A-Z]{3}\d{3}/,
                message: 'Invalid Coupon code.'
            }
        };
    };
    Validations.captcha = function (LG) {
        return {
            required: validation_lang[LG].captcha
        };
    };
    return Validations;
}());
export default Validations;
