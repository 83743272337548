var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryQuestionnaires } from "../../hooks/questionnair/useQueryQuestionnaires";
import { useFullPageLoader } from "../../hooks/miscellaneous/useFullPageLoader";
import { lang } from "../../lang/lang";
import BaseTitle from "../../components/Title/BaseTitle";
import Text1FromDesign from "../../components/Title/Text1FromDesign";
import TextField from "../../components/Input/TextField";
import env from "../../config/env";
import { useListCards } from "../../hooks/payment/stripe/useListCards";
import DropDown from "../../components/DropDown/DropDown";
import CreditCardSelector from "../../components/DropDown/CreditCardSelector";
import Text from "../../components/Field/Text";
import CloseIcon from "../../assets/icons/CloseIcon";
import ModalInPaymentPage from "../../components/Layouts/ModalInPaymentPage";
import ArrowButton from "../../components/Button/ArrowButton";
import SubmitButton from "../../components/Button/SubmitButton";
import BasicPurpleUnderlineText from "../../components/Text/BasicPurpleUnderlineText";
import F from "../../utils/functions";
import { useForm } from "react-hook-form";
import { validation_lang } from "../../lang/validations";
import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";
import CardCvcNumber from "../../components/Stripe/CardCvcNumber";
import { CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import useQuestionnaireInfo from "../../hooks/questionnaire/useQuestionnaireInfo";
import Invoice from "../../components/Payment/Invoice";
import { Api } from "../../classes/api";
import FormError from "../../components/Error/FormError";
import CustomCheckbox from "../../components/Input/CustomCheckbox";
import Functions from "../../utils/functions";
import TextComponent from "../../components/Text/TextComponent";
var PaymentPage = function () {
    var _a;
    var _b = useParams(), id = _b.id, num = _b.num;
    var questionId = Number(id);
    var LG = F.getLang();
    var _c = useState(false), isCvvValid = _c[0], setIsCvvValid = _c[1];
    var _d = useState(false), isSubmitted = _d[0], setIsSubmitted = _d[1];
    var _e = useState(false), isDisableCredit = _e[0], setIsDisableCredit = _e[1];
    var _f = useState(), questionnaire = _f[0], SetQuestionnaire = _f[1];
    var _g = useState(), errorResponse = _g[0], setErrorResponse = _g[1];
    var _h = useState(), questionnaireInfo = _h[0], setQuestionnaireInfo = _h[1];
    var getSingleQuestionnaireInPreviewPage = useQueryQuestionnaires().getSingleQuestionnaireInPreviewPage;
    var _j = useState(0), creditError = _j[0], setCreditLimitError = _j[1];
    var _k = useState(F.convertToNum(num)), quantityValue = _k[0], getQuantityValue = _k[1];
    var _l = useForm({
        mode: 'onChange',
    }), register = _l.register, getValues = _l.getValues, setValue = _l.setValue, watch = _l.watch, handleSubmit = _l.handleSubmit, errors = _l.formState.errors;
    var _m = useState(true), isSameSpAddress = _m[0], setIsSameSpAddress = _m[1];
    var fullPageLoader = useFullPageLoader();
    var _o = useState(), getErrorCoupon = _o[0], SetErrorCoupon = _o[1];
    var _p = useState({
        questionnaireId: F.convertToNum(id),
        quantity: quantityValue,
        coupon: '',
        credits: 0,
        shippingAddress: {
            country: '',
            province: '',
            city: '',
            postcode: '',
            address: '',
            apartment: '',
            sameShippingAddress: false,
        }
    }), qInfoState = _p[0], setQInfoState = _p[1];
    var getQuestionnaireInfo = useQuestionnaireInfo(qInfoState).getQuestionnaireInfo;
    useEffect(function () {
        var _a;
        if (getQuestionnaireInfo.status === 'success') {
            setQuestionnaireInfo((_a = getQuestionnaireInfo.data) === null || _a === void 0 ? void 0 : _a.data);
        }
        else {
            setQuestionnaireInfo(undefined);
        }
    }, [getQuestionnaireInfo.status, getQuestionnaireInfo.isFetching]);
    useEffect(function () {
        if ((qInfoState.shippingAddress.province != undefined && getValues('postcode') != '' && getValues('address') != '' && getValues('city') != '' && errors.postcode == undefined)) {
            setIsDisableCredit(false);
        }
        else if ((isSameSpAddress == true && cards.length > 0)) {
            setIsDisableCredit(false);
        }
        else {
            setIsDisableCredit(true);
        }
    }, [watch('city'), watch('postcode'), watch('address'), qInfoState.shippingAddress.province]);
    var checkDisableCreditError = useCallback(function (valueCredit) {
        setCreditLimitError(valueCredit);
    }, []);
    var getUpdatedQuantity = useCallback(function (qty) {
        getQuantityValue(qty);
    }, []);
    var fetchSingleQuestionnaire = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fullPageLoader(true);
                    return [4 /*yield*/, getSingleQuestionnaireInPreviewPage.mutateAsync({
                            questionnaireId: questionId
                        })];
                case 1:
                    response = _a.sent();
                    fullPageLoader(false);
                    SetQuestionnaire(response === null || response === void 0 ? void 0 : response.data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchSingleQuestionnaire();
    }, []);
    useEffect(function () {
        getQuestionnaireInfo.refetch();
    }, [qInfoState]);
    useEffect(function () {
        setQInfoState(__assign(__assign({}, qInfoState), { shippingAddress: __assign(__assign({}, qInfoState.shippingAddress), { country: getValues('country'), province: isSameSpAddress ?
                    getValues('paymentMethod.data.billing_details.address.state') :
                    qInfoState.shippingAddress.province }) }));
    }, [isSameSpAddress, watch('paymentMethod')]);
    var _q = useState([]), cards = _q[0], setCards = _q[1];
    var getCards = useListCards().getCards;
    useEffect(function () {
        var _a, _b;
        if (getCards.status === "success") {
            var response = (_b = (_a = getCards.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
            if ("message" in response) {
                setCards([]);
            }
            else {
                setCards(response);
            }
        }
    }, [getCards.status, (_a = getCards.data) === null || _a === void 0 ? void 0 : _a.data.length]);
    var navigate = useNavigate();
    var setData = function (title, value) {
        if (title == 'credits') {
            setValue('credits', value);
            setQInfoState(__assign(__assign({}, qInfoState), { credits: value, coupon: '' }));
        }
        else if (title == 'coupon') {
            setValue('coupon', value);
            setQInfoState(__assign(__assign({}, qInfoState), { coupon: value, credits: 0 }));
        }
        // getQuestionnaireInfo.refetch()
    };
    var getCardItems = function () {
        return cards === null || cards === void 0 ? void 0 : cards.map(function (card, index) {
            return ({
                key: index + 1,
                data: card,
                card: {
                    company: card.card.brand,
                    code: card.card.last4,
                    payment_id: card.payment_id
                }
            });
        });
    };
    var validatePostcode = function (postcode) {
        var response;
        if (postcodeValidatorExistsForCountry(getValues("country").toUpperCase())) {
            if (postcodeValidator(postcode, getValues("country").toUpperCase())) {
            }
            else {
                response = validation_lang[LG].invalidZipcode;
            }
        }
        return response;
    };
    var getShippingDetail = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({ type: "hidden" }, register("paymentMethod"))),
            React.createElement("input", __assign({ type: "hidden" }, register("country", {
                required: validation_lang[LG].country,
            }))),
            React.createElement("input", __assign({ type: "hidden" }, register("province", {
                required: validation_lang[LG].state,
            }))),
            React.createElement("div", { className: "container inline flex mt-6" },
                React.createElement("div", { className: "container w-fit" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].country),
                    React.createElement("div", { className: "w-80" },
                        React.createElement(DropDown, { items: F.getCountriesItems(), selectedItem: F.getCountriesItems().filter(function (item) { return item.data.code === 'ca'; })[0], onChange: function (item) {
                                setValue("country", item.data.code);
                                setValue("province", "");
                            }, error: errors.country }))),
                React.createElement("div", { className: "container w-fit ml-10" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].state),
                    React.createElement("div", { className: "w-52" },
                        React.createElement(DropDown, { items: F.getStateItems(getValues('country')), placeHolder: lang[LG].select, onChange: function (item) {
                                setValue("province", item.title);
                                setQInfoState(__assign(__assign({}, qInfoState), { shippingAddress: __assign(__assign({}, qInfoState.shippingAddress), { country: getValues('country'), province: item.title }) }));
                            }, error: errors.province })))),
            React.createElement("div", { className: "container inline flex mt-5" },
                React.createElement("div", { className: "container w-fit" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].city),
                    React.createElement(TextField, { register: register("city", {
                            required: validation_lang[LG].city
                        }), error: errors.city, title: lang[LG].placeHolder2, type: "text", additionalClass: "w-80", disableOnChange: true })),
                React.createElement("div", { className: "container w-fit ml-10" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].zipCode),
                    React.createElement(TextField, { register: register("postcode", {
                            required: validation_lang[LG].postcode,
                            validate: function (value) { return validatePostcode(value); },
                        }), error: errors.postcode, title: lang[LG].examplePostCode, type: "text", additionalClass: "w-52", disableOnChange: true }))),
            React.createElement("div", { className: "container inline flex mt-5" },
                React.createElement("div", { className: "container w-fit" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].address),
                    React.createElement(TextField, { register: register("address", {
                            required: validation_lang[LG].billingAddress
                        }), error: errors.address, title: lang[LG].placeHolder3, type: "text", additionalClass: "w-80", disableOnChange: true })),
                React.createElement("div", { className: "container w-fit ml-10" },
                    React.createElement(Text, { isbold: true, additionalClass: "mb-2" }, lang[LG].apartment),
                    React.createElement(TextField, { register: register("apartment"), title: lang[LG].placeHolder4, type: "text", additionalClass: "w-52" })))));
    };
    var paymentFailed = function () {
        var url = F.removeParams(env.routes.paymentFail);
        navigate("".concat(url).concat(id, "/").concat(num));
    };
    var stripe = useStripe();
    var elements = useElements();
    var submit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var priceCheck, address, response, response2, url;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (creditError == 1 && (questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.prices.total) != 0) {
                        return [2 /*return*/, false];
                    }
                    setIsSubmitted(true);
                    priceCheck = (_a = questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.prices.price) !== null && _a !== void 0 ? _a : true;
                    if (!isCvvValid && priceCheck != 0) {
                        return [2 /*return*/, false];
                    }
                    fullPageLoader(true);
                    address = ((_d = (_c = (_b = data.paymentMethod) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.billing_details) !== null && _d !== void 0 ? _d : { state: data.province }).address;
                    return [4 /*yield*/, Api.post({
                            paymentMethodId: (_g = (_f = (_e = data.paymentMethod) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.payment_id) !== null && _g !== void 0 ? _g : '',
                            questionnaireId: id,
                            quantity: quantityValue,
                            credits: qInfoState.credits,
                            shippingAddress: {
                                province: isSameSpAddress && (address === null || address === void 0 ? void 0 : address.state) != undefined ? address === null || address === void 0 ? void 0 : address.state : data.province,
                            },
                        }, env.api.getPaymentIntend)];
                case 1:
                    response = _h.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                        setErrorResponse(response);
                        return [2 /*return*/, false];
                    }
                    else {
                        setErrorResponse(undefined);
                    }
                    if (!(response.data.status === "completed")) return [3 /*break*/, 3];
                    return [4 /*yield*/, Api.post({
                            questionnaireId: id,
                            quantity: quantityValue,
                            credits: qInfoState.credits,
                            coupon: qInfoState.coupon,
                            shippingAddress: isSameSpAddress && (address === null || address === void 0 ? void 0 : address.state) != undefined ? __assign(__assign({}, address), { province: address === null || address === void 0 ? void 0 : address.state }) : { province: data.province, city: data.city },
                            paymentResult: 'k',
                            lang: LG
                        }, env.api.sendZeroPriceInvoice)];
                case 2:
                    response2 = _h.sent();
                    if ((response2 === null || response2 === void 0 ? void 0 : response2.status) === 200) {
                        url = F.removeParams(env.routes.paymentSuccess);
                        navigate("".concat(url).concat(questionId));
                    }
                    else {
                        paymentFailed();
                    }
                    fullPageLoader(false);
                    return [3 /*break*/, 4];
                case 3:
                    stripe.confirmCardPayment(response.data.client_secret, {
                        payment_method_options: {
                            card: {
                                cvc: elements.getElement(CardCvcElement)
                            }
                        },
                    }).then(function (result) {
                        return __awaiter(this, void 0, void 0, function () {
                            var response2, url;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!result.error) return [3 /*break*/, 1];
                                        // Show error to your customer
                                        paymentFailed();
                                        return [3 /*break*/, 3];
                                    case 1:
                                        if (!(result.paymentIntent.status === 'succeeded')) return [3 /*break*/, 3];
                                        return [4 /*yield*/, Api.post({
                                                questionnaireId: id,
                                                quantity: quantityValue,
                                                credits: qInfoState.credits,
                                                coupon: qInfoState.coupon,
                                                shippingAddress: isSameSpAddress ? __assign(__assign({}, address), { province: address.state }) : data,
                                                paymentResult: result.paymentIntent,
                                                lang: LG
                                            }, env.api.sendInvoice)];
                                    case 2:
                                        response2 = _a.sent();
                                        if ((response2 === null || response2 === void 0 ? void 0 : response2.status) === 200) {
                                            url = F.removeParams(env.routes.paymentSuccess);
                                            navigate("".concat(url).concat(questionId));
                                        }
                                        else {
                                            paymentFailed();
                                        }
                                        _a.label = 3;
                                    case 3:
                                        fullPageLoader(false);
                                        return [2 /*return*/];
                                }
                            });
                        });
                    });
                    _h.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var updateQuantity = function (quantity) {
        setQInfoState(__assign(__assign({}, qInfoState), { quantity: quantity }));
    };
    var render = function () {
        var _a, _b, _c;
        var errorMsg = (questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.message) !== undefined ? questionnaireInfo : '';
        return (React.createElement(React.Fragment, null,
            React.createElement(ModalInPaymentPage, { additionalCls: "bg-client-list bg-cover bg-center", layoutClass: "h-fit mt-16 p-5 lg:w-4/5" },
                React.createElement("div", { className: "container inline-flex justify-end mt-1" },
                    React.createElement(CloseIcon, { color: env.iconColor, additionalClass: "absolute m-2 right-0 top-0", onClick: function () { return navigate(env.routes.dashboard); } })),
                React.createElement("form", { onSubmit: handleSubmit(submit) },
                    React.createElement("div", { className: "min-h-[30rem] mx-5 h-fit" },
                        React.createElement(Title, { additionalClass: "max-w-fit" }, lang[LG]
                            .paymentPage
                            .title
                            .replace('{0}', '')),
                        React.createElement("div", { className: "w-full inline-flex container mt-3" },
                            React.createElement("div", { className: "w-3/5" },
                                React.createElement(Text, { isbold: false, additionalClass: "mb-5 pr-2 " }, lang[LG].paymentMethodInfo),
                                React.createElement(Title, { additionalClass: "mb-2" }, lang[LG].paymentMethod),
                                getCardItems().length > 0 &&
                                    React.createElement("div", { className: "container" },
                                        React.createElement(CreditCardSelector, { additionalClass: "w-72", items: (_a = getCardItems()) !== null && _a !== void 0 ? _a : [], selectedItem: getCardItems()[0], onChange: function (data) {
                                                setValue('paymentMethod', data);
                                            } }),
                                        React.createElement("div", { className: "w-fit cursor-pointer self-center flex mt-2" },
                                            React.createElement(BasicPurpleUnderlineText, { onClick: function () {
                                                    var url = F.removeParams(env.routes.payWithNewCard);
                                                    url = "".concat(url).concat(id, "/").concat(num);
                                                    navigate(url);
                                                }, additionalClass: "text-sm" }, lang[LG].paymentPage.useANewMethod)),
                                        React.createElement(BaseTitle, { additionalClass: "mt-5" }, lang[LG].paymentPage.securityCode),
                                        React.createElement(CardCvcNumber, { isValid: function (value) { return setIsCvvValid(value); }, showErrors: isSubmitted && !isCvvValid })),
                                getCardItems().length === 0 && React.createElement(React.Fragment, null,
                                    React.createElement(Text, { additionalClass: "text-sm" }, lang[LG].emptyCard),
                                    React.createElement(BasicPurpleUnderlineText, { onClick: function () {
                                            var url = F.removeParams(env.routes.payWithNewCard);
                                            url = "".concat(url).concat(id, "/").concat(num);
                                            navigate(url);
                                        }, additionalClass: "text-sm mt-2 cursor-pointer" }, lang[LG].paymentPage.useANewMethod))),
                            React.createElement(Invoice
                            /*qty={num ?? ''}
                            prices={questionnaireInfo?.prices}*/
                            , { 
                                /*qty={num ?? ''}
                                prices={questionnaireInfo?.prices}*/
                                questionnaireId: Functions.convertToNum(id), prices: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.prices, quantity: quantityValue, setData: setData, credits: qInfoState.credits, coupon: qInfoState.coupon, errorCoupon: errorMsg, isDisable: isDisableCredit, creditUser: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.creditsUser, qty: num !== null && num !== void 0 ? num : '', successCouponMessage: questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.successCouponMessage, updateQuantity: updateQuantity, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        checkDisableCreditError(e);
                                        return [2 /*return*/];
                                    });
                                }); }, onClickCallbackAction: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        getUpdatedQuantity(e);
                                        return [2 /*return*/];
                                    });
                                }); } })),
                        React.createElement(Title, { additionalClass: "mt-5" }, lang[LG].deliveryAddress),
                        React.createElement("div", null,
                            React.createElement(Text, null, lang[LG].deliveryAdTax)),
                        getCardItems().length > 0 && React.createElement("div", { className: "mt-3 inline-flex" },
                            React.createElement(CustomCheckbox, { onUpdate: function (select) {
                                    setIsSameSpAddress(!isSameSpAddress);
                                }, selected: getCardItems().length == 0 ? false : true, additionalClass: "inline mr-3" }),
                            React.createElement(Text1FromDesign, { additionalClass: 'mt-1' }, lang[LG].isSameBillingAddress)),
                        (!isSameSpAddress || getCardItems().length == 0) && getShippingDetail(),
                        React.createElement("div", { className: "container inline flex mt-6" },
                            React.createElement("div", { className: "inline flex justify-start" },
                                React.createElement(ArrowButton, { title: "none", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            navigate("".concat(F.removeParams(env.routes.questionnairePreview)).concat(questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.id));
                                            return [2 /*return*/];
                                        });
                                    }); } })),
                            React.createElement("div", { className: "flex justify-end w-full" },
                                React.createElement("div", { className: "inline flex" },
                                    React.createElement("div", { className: "flex flex-col items-end self-center" },
                                        React.createElement(TextComponent, { hasActions: true, className: 'font-poppins text-left text-xs text-c-text1FromDesign ml-2', actionClasses: ["text-c-purple cursor-pointer"], actionOnClicks: [function () {
                                                }], actionReplacements: [lang[LG].paymentPage.purpleText], title: lang[LG].paymentPage.term }),
                                        React.createElement(TextComponent, { hasActions: true, actionClasses: ["text-c-purple cursor-pointer"], actionReplacements: [lang[LG].clickHere2.toLowerCase()], actionOnClicks: [function () {
                                                    window.open('https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe', '_blank');
                                                }], title: lang[LG].paymentCompliance, className: 'font-poppins text-left text-xs text-c-text1FromDesign ml-2' })),
                                    React.createElement("div", { className: "ml-2" },
                                        React.createElement(SubmitButton, { title: lang[LG].paymentPage.button, disabled: cards.length === 0 && ((_c = (_b = questionnaireInfo === null || questionnaireInfo === void 0 ? void 0 : questionnaireInfo.prices) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0) > 0 }))))),
                        errorResponse != null && React.createElement(FormError, { response: errorResponse }))))));
    };
    return render();
};
export default PaymentPage;
