var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clsx from "clsx";
import React from "react";
import TextError from "../Field/TextError";
var Input = React.forwardRef(function (props, ref) {
    var render = function () {
        var _a;
        var _b, _c, _d, _e, _f;
        return (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({ ref: ref, key: props.key, placeholder: (_b = props.placeholder) !== null && _b !== void 0 ? _b : props.title, type: props.type, autoComplete: "off", className: clsx((_a = {
                        "focus:ring-c-purple focus:border-c-purple border border-solid rounded border-c-gray h-10 text-sm w-full font-poppins": true,
                        "border-c-red": props.error != null ? true : false
                    },
                    _a[(_c = props.additionalClass) !== null && _c !== void 0 ? _c : ''] = true,
                    _a)) }, props.register ? '' : __assign({
                value: props.value
            }), { readOnly: props.readonly, disabled: props.disabled }, props.register, props.disableOnChange ? '' : __assign({
                onChange: props.onChange
            }), { onFocus: function (event) {
                    if (event.target.autocomplete) {
                        event.target.autocomplete = "whatever";
                    }
                } }, props.onBlur == null ? '' : __assign({
                onBlur: props.onBlur
            }))),
            props.error &&
                React.createElement(TextError, { extraSmall: true, addtionalClass: props.errorClass, message: (_e = (_d = props.error) === null || _d === void 0 ? void 0 : _d.message) !== null && _e !== void 0 ? _e : '' }),
            props.errorMsg &&
                React.createElement(TextError, { extraSmall: true, addtionalClass: props.errorClass, message: (_f = props.errorMsg) !== null && _f !== void 0 ? _f : '' })));
    };
    return render();
});
export default Input;
