import React from "react";
var Title = function (props) {
    var render = function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { id: props.id, className: "font-poppins text-left text-xl ".concat(props.notBold == null ? 'font-semibold' : '', " ").concat((_a = props.display) !== null && _a !== void 0 ? _a : '', " ").concat((_b = props.additionalClass) !== null && _b !== void 0 ? _b : ''), onClick: props.onClick }, props.children)));
    };
    return render();
};
export default Title;
