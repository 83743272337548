var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clsx from "clsx";
import React from "react";
import TextError from "../Field/TextError";
var TextArea = function (props) {
    var _a, _b;
    var rows = (_a = props.rows) !== null && _a !== void 0 ? _a : 4;
    var cols = (_b = props.cols) !== null && _b !== void 0 ? _b : 50;
    var render = function () {
        var _a;
        var _b, _c, _d;
        return (React.createElement(React.Fragment, null,
            React.createElement("textarea", __assign({}, props.register, { placeholder: props.title, autoComplete: "off", className: clsx((_a = {
                        "border border-solid rounded border-c-gray text-sm w-full font-poppins": true,
                        "border-c-red focus:ring-c-red focus:border-c-red": props.error != null ? true : false
                    },
                    _a[(_b = props.additionalClass) !== null && _b !== void 0 ? _b : ''] = true,
                    _a["focus:ring-c-purple focus:border-c-purple"] = props.error == null ? true : false,
                    _a)), readOnly: props.readonly, disabled: props.disabled }, props.disableOnChange ? '' : __assign({
                onChange: props.onChange
            }), { onBlur: props.onBlur, rows: rows, cols: cols, value: props.value })),
            props.error && React.createElement(TextError, { addtionalClass: props.errorClass, message: (_d = (_c = props.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : '' })));
    };
    return render();
};
export default TextArea;
