import React from "react";
var HelpIcon = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() : '24';
        var width = props.width ? props.width.toString() : '24';
        return (React.createElement("svg", { ref: props.customRef, className: "".concat((_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", height: "".concat(height, "px"), width: "".concat(width, "px"), viewBox: "0 0 ".concat(height, " ").concat(width), fill: props.color },
            React.createElement("path", { d: "M0 0h24v24H0V0z", fill: "none" }),
            React.createElement("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z" })));
    };
    return render();
};
export default HelpIcon;
