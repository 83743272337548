var _a, _b;
export var domain = (_a = process.env.SITE_BASE_PATH) !== null && _a !== void 0 ? _a : "";
export var apiPath = domain + "/api";
export var env = {
    env: (_b = process.env.REACT_APP_BUILD) !== null && _b !== void 0 ? _b : "DEV",
    routes: {
        home: "/",
        login: "/login",
        loginParam: "/login/:param",
        contactUs: "/contactus",
        aboutUs: "/about-us",
        politics: "/privacy-politics/:value",
        helpCenter: "/help-center",
        register: "/register",
        dashboard: "/dashboard",
        forgotPassword: "/forgot-password",
        forgotEmail: "/forgot-email",
        findEmail: "/find-email/:email",
        resetPassword: "/reset-password/:token",
        clientAdd: "/add-client/:id/:sid",
        testPage: "/test-page",
        clientPage: "/client/:id",
        editClient: "/edit-client/:id",
        removeCollaborator: "/remove-collaborator/:id/:clientId",
        archiveConfirmation: "/archive-confirmation",
        deleteConfirmation: "/delete-confirmation",
        profileSetting: "/profile-setting",
        modifyPassword: "/modify-password",
        preferencesAndNotifications: "/preferences-notifications",
        paymentInformation: "/payment-information",
        paymentHistory: "/payment-history",
        deleteAccount: "/delete-account",
        verifyPhoneNumber: '/verify-phone/:phone',
        emailVerified: '/email-verified',
        paymentSignUp: "/payment-signup",
        paymentSignUpParam: "/payment-signup/:param",
        addCard: "/add-card",
        editCard: "/edit-card/:paymentMethodId",
        questionnairePreview: "/questionnaire-preview/:id",
        questionnairePreviewHome: "/questionnaire-preview-home/:id",
        selectClient: "/select-client/:id",
        launchQuestionnaire: "/launch-questionnaire/:qid/:cid",
        answerQuestionnaire: "/questionnaire-answer/:hash",
        answerQuestionnaireStep: "/questionnaire-answer/:hash/:step",
        answerQuestionnaire2: "/answerQuestionnaire2",
        answerQuestionnaireSuccess: "/answerQuestionnaireSuccess",
        launchQuestionnaireSuccess: "/launchQuestionnaireSuccess",
        clientQuestionnaire: "/client/questionnaire/:id",
        dynamicAnswers: "/questionnaire-answers/:hash",
        anaQuestionnaire: "/ana-answers/:hash",
        alreadyAnsweredPage: "/alreadyAnsweredPage/:hash",
        contactInformation: '/contact-information/:hash',
        paymentPage: "/payment/:id/:num",
        payWithNewCard: '/pay-with-new-card/:qid/:qty',
        paymentSuccess: "/payment-success/:qid",
        paymentFail: "/payment-fail/:qid/:qty",
        paymentFailNewCard: "/payment-fail/new/:qid/:qty",
        generateReport: '/generate-report/:qid',
        selectReportType: '/select-report-type/:qid',
        landingPage: '/',
        clientUpdatedSuccess: '/client-updated-successfully',
        clientAddedSuccess: '/client-added-successfully',
        linkExpired: '/link-expired',
        pageNotFound: '/page-not-found',
        resources: '/resources'
    },
    api: {
        login: "".concat(apiPath, "/login"),
        logout: "".concat(apiPath, "/logout"),
        register: "".concat(apiPath, "/register"),
        validateRegister: "".concat(apiPath, "/validate-register"),
        forgotPassword: "".concat(apiPath, "/forgot-password"),
        forgotEmail: "".concat(apiPath, "/forgot-email"),
        verificationNotification: "".concat(apiPath, "/email/verification-notification"),
        verificationEmail: "".concat(apiPath, "/email/verification-email"),
        findEmail: "".concat(apiPath, "/find-email/:email"),
        resetPassword: "".concat(apiPath, "/reset-password"),
        user: "".concat(apiPath, "/user"),
        addClient: "".concat(apiPath, "/add-client"),
        searchClients: "".concat(apiPath, "/search-clients"),
        clientPage: "".concat(apiPath, "/client"),
        editClient: "".concat(apiPath, "/edit-client"),
        getClient: "".concat(apiPath, "/get-client"),
        updateClient: "".concat(apiPath, "/update-client"),
        archiveClient: "".concat(apiPath, "/archive-client"),
        unarchiveClient: "".concat(apiPath, "/unarchive-client"),
        deleteClient: "".concat(apiPath, "/delete-client"),
        updateName: "".concat(apiPath, "/profile-setting/name"),
        updateUserEmail: "".concat(apiPath, "/profile-setting/email"),
        resendEmailVerification: "".concat(apiPath, "/profile-setting/email/resend-verification"),
        cancelEmailVerification: "".concat(apiPath, "/profile-setting/email/cancel-edit"),
        modifyPassword: "".concat(apiPath, "/profile-setting/modify-password"),
        deleteAccount: "".concat(apiPath, "/profile-setting/delete-account"),
        updatePhoneNumber: "".concat(apiPath, "/profile-setting/phoneNumber"),
        verifyPhoneNumber: "".concat(apiPath, "/profile-setting/phoneNumber/verify"),
        resendVerifyPhoneNumber: "".concat(apiPath, "/profile-setting/phoneNumber/resend-verification"),
        cancelVerifyPhoneNumber: "".concat(apiPath, "/profile-setting/phoneNumber/cancel-edit"),
        getPaymentHistory: "".concat(apiPath, "/profile-setting/payment-history"),
        stripeSetupIntend: "".concat(apiPath, "/stripe-setup-intend"),
        stripeListCards: "".concat(apiPath, "/list-cards"),
        stripeListCard: "".concat(apiPath, "/list-card"),
        stripeAddCreditCard: "".concat(apiPath, "/profile-setting/add-credit-card"),
        stripeEditCreditCard: "".concat(apiPath, "/profile-setting/edit-credit-card"),
        stripeDeleteCreditCard: "".concat(apiPath, "/profile-setting/delete-credit-card"),
        getQuestionnaires: "".concat(apiPath, "/get-questionnaires"),
        searchQuestionnaires: "".concat(apiPath, "/search-questionnaires"),
        searchQuestionnaire: "".concat(apiPath, "/get-questionnaire"),
        assignEvaluation: "".concat(apiPath, "/evaluation/assign-evaluation"),
        updateFavouriteQuestionnaire: "".concat(apiPath, "/update-favourite-questionnaire"),
        searchTitleAndName: "".concat(apiPath, "/details-introduction"),
        getRespondents: "".concat(apiPath, "/evaluation/get-respondents"),
        addRespondent: "".concat(apiPath, "/evaluation/add-respondent"),
        getClientProfile: "".concat(apiPath, "/get-client-profile"),
        loadQuestionnaire: "".concat(apiPath, "/load-questionnaire"),
        loadSectionQuestion: "".concat(apiPath, "/load-section-question"),
        uploadFile: "".concat(apiPath, "/upload-file"),
        updateAnswers: "".concat(apiPath, "/update-answers"),
        sentReminder: "".concat(apiPath, "/evaluation/send-reminder"),
        contactInformation: "".concat(apiPath, "/contact-information"),
        payWithNewCard: "".concat(apiPath, "/purchase/checkout-newCard"),
        questionnaireInfo: "".concat(apiPath, "/purchase/get-payment-info"),
        getPaymentIntend: "".concat(apiPath, "/purchase/get-paymentIntent"),
        sendInvoice: "".concat(apiPath, "/purchase/send-invoice"),
        sendZeroPriceInvoice: "".concat(apiPath, "/purchase/send-credit-invoice"),
        generateReport: "".concat(apiPath, "/generate-report"),
        anaReport: "".concat(apiPath, "/ana-report"),
        getTags: "".concat(apiPath, "/get-tags"),
        deleteQuestionnaire: "".concat(apiPath, "/evaluation/delete-questionnaire"),
        downloadInvoice: "".concat(apiPath, "/download-invoice"),
        deleteRespondent: "".concat(apiPath, "/evaluation/delete-respondent"),
        env: "".concat(apiPath, "/env"),
        resource: "".concat(apiPath, "/resource/get-resource"),
        resourceDownload: "".concat(apiPath, "/resource/resource-download"),
        resourceDownloadFile: "".concat(apiPath, "/resource/download-file"),
    },
    cookies: {
        token: "token",
        lang: "lang",
    },
    iconColor: "#0d0c0c",
    iconColorPurple: "#6c0c6c",
    iconColorWhite: "#ffffff",
    iconGreen: "#00D137",
    iconGrey: "#718096",
    iconRed: '#E11660',
    iconLightGrey: '#C5C5C8',
    recaptcha: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    currencySign: '$',
    anaQuestionnaireId: 23
};
export default env;
