import { useDispatch } from "react-redux";
import { saveLoader } from "../../feature/loader";
import { useCookies } from "react-cookie";
export function useFullPageLoader() {
    var _a = useCookies(["languageCookie"]), cookies = _a[0], setCookie = _a[1];
    var dispatch = useDispatch();
    var useFullPageLoader = function (value) {
        dispatch(saveLoader({
            fullPage: value,
        }));
    };
    return useFullPageLoader;
}
