import React from "react";
import env from "../../config/env";
var UpArrow = function (props) {
    var render = function () {
        var _a, _b;
        var height = props.height ? props.height.toString() + 'px' : '24px';
        var width = props.width ? props.width.toString() + 'px' : '24px';
        var color = (_a = props.color) !== null && _a !== void 0 ? _a : env.iconColorPurple;
        return (React.createElement("svg", { className: "".concat((_b = props.additionalClass) !== null && _b !== void 0 ? _b : '', " cursor-pointer"), xmlns: "http://www.w3.org/2000/svg", fill: "".concat(color), viewBox: "0 96 960 960", strokeWidth: "1.5", stroke: "currentColor", height: "".concat(height), width: "".concat(width) },
            React.createElement("path", { d: "M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm230-153V372L330 492l-43-43 193-193 193 193-43 43-120-120v371h-60Z" })));
    };
    return render();
};
export default UpArrow;
