import React from "react";
var ErrorOutline = function (props) {
    var render = function () {
        var _a;
        var height = props.height ? props.height.toString() : '24';
        var width = props.width ? props.width.toString() : '24';
        return (React.createElement("svg", { onClick: props.onClick, className: (_a = props.additionalClass) !== null && _a !== void 0 ? _a : '', xmlns: "http://www.w3.org/2000/svg", height: "".concat(height, "px"), width: "".concat(width, "px"), viewBox: "0 0 ".concat(height, " ").concat(width), fill: props.color },
            React.createElement("path", { d: "M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-3h-2v-2h2v2z" })));
    };
    return render();
};
export default ErrorOutline;
