import React from "react";
import F from "../../utils/functions";
import { lang } from "../../lang/lang";
import Navigation from "./Navigation";
var PageNotFound = function () {
    var LG = F.getLang();
    var render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "" },
                React.createElement(Navigation, { title: lang[LG].pageNotFound }))));
    };
    return render();
};
export default PageNotFound;
